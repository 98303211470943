import { useEffect } from 'react';
import { ValidationError } from '@/Errors/ValidationError';
import { type UseFormSetError } from 'react-hook-form/dist/types/form';
import { type FieldValues } from 'react-hook-form/dist/types/fields';
import { type Path } from 'react-hook-form';

export const useValidationErrors = <TFieldValues extends FieldValues>(
    setError: UseFormSetError<TFieldValues>,
    errorBag?: Error[]
) => {
    useEffect(() => {
        if (
            errorBag &&
            errorBag.length > 0
        ) {
            for (const errorInstance of errorBag) {
                if (errorInstance instanceof ValidationError) {
                    for (const [errorField, errorList] of Object.entries(errorInstance.errors)) {
                        errorList.forEach(message => {
                            setError(errorField as Path<TFieldValues>, { type: 'validation', message });
                        });
                    }
                }
            }
        }
    }, [setError, errorBag]);
};
