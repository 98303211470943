export const InboxPresetOptions = {
    MyQueue: 'My Queue',
    Today: 'Today',
    ThreeWDsToday: '3WDs Today',
    OpenComplaints: 'Open Complaints',
    FRLs: 'FRLs',
    All: 'All'
} as const;

export type InboxPresetOptions = typeof InboxPresetOptions[keyof typeof InboxPresetOptions];
