import React, { useEffect, useState } from 'react';
import {
    UpdateUserRolesRequestAdditionalRolesEnum,
    UpdateUserRolesRequestMainRoleEnum,
    User
} from "@/stub";
import { Divider } from "primereact/divider";
import BaseCheckbox from "@/components/Core/Form/BaseCheckbox";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { useUpdateAuthUser } from "@/Service/Api/ApiHooks/AuthUser/useUpdateAuthUser";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { DropdownChangeEvent } from "primereact/dropdown";


const additionalRolesList = Object.entries(UpdateUserRolesRequestAdditionalRolesEnum).map(([key, value]) => ({
    key,
    value,
    name: key.replace(/([A-Z])/g, ' $1').trim() // For better readability
}));

const mainRoles = Object.entries(UpdateUserRolesRequestMainRoleEnum).map(([key, value]) => ({
    key,
    value,
    label: key.replace(/([A-Z])/g, ' $1').trim()
}));

export type RoleProps = {
    user: User | undefined
};



const Role: React.FC<RoleProps> = ({ user }) => {
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [mainRole, setMainRole] = useState(user?.main_role || '');
    const [additionalRoles, setAdditionalRoles] = useState<Array<string>>(user?.additional_roles || []);
    const { mutateAsync } = useUpdateAuthUser();

    useEffect(() => {
        setMainRole(user?.main_role || '');
        setAdditionalRoles(user?.additional_roles || []);
    }, [user]);

    const handleMainRoleChange = (event: DropdownChangeEvent) => {
        setMainRole(event.value);
    };

    const handleCheckboxChange = (role: string, checked: boolean) => {
        setAdditionalRoles(prevRoles =>
            checked ? [...prevRoles, role] : prevRoles.filter(r => r !== role)
        );
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            main_role: mainRole,
            additional_roles: additionalRoles
        };

        await mutateAsync({
            user_id: user!.id,
            UpdateUserRolesRequest: data
        }, {
            onSuccess: () => {
                addToastMessage({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'User roles updated successfully'
                });
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };


    return (
        <form onSubmit={handleSubmit}>
            <h4 className='mb-4'>Main Role</h4>
            <BaseDropdown
                name="main_role"
                value={mainRole}
                onChange={handleMainRoleChange}
                options={mainRoles}
                optionLabel="label"
            />

            <Divider className='my-4' />

            <h4 className='mb-4'>Additional Role</h4>
            {additionalRolesList.map((role) => {
                const isChecked = additionalRoles.includes(role.value);
                return (
                    <div key={role.key}>
                        <BaseCheckbox
                            className="flex flex-row-reverse gap-3 justify-content-end"
                            name={`additional_roles[${role.value}]`}
                            label={role.name}
                            onChange={(e) => handleCheckboxChange(role.value, e.target.checked || false)}
                            checked={isChecked}
                        />
                    </div>
                );
            })}

            <button type="submit" className="mt-4 p-button p-component">
                Save
            </button>
        </form>
    );
};

export default Role;
