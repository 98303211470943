import React from 'react';
import styled from 'styled-components';

const StyledWrap = styled.div`
    .content-header {
        padding: 1rem;
        border-bottom: var(--gray-300) 1px solid;

        &__title {
            margin: 0.25rem 0;
            font-weight: 600;
            font-size: 1.5rem;
        }

        &__description {
            font-size: 0.9rem;
            color: var(--gray-500);
        }
    }
`;

export type ContentHeaderProps = {
    title: string
    description: string,
    rightSectionTemplate?: React.ReactNode
};

const ContentHeader: React.FC<ContentHeaderProps> = ({
    title,
    description,
    rightSectionTemplate
}: ContentHeaderProps) => {
    return (
        <StyledWrap>
            <div className="flex justify-content-between content-header">
                <div className="">
                    <h2 className="content-header__title">{title}</h2>
                    <p className="content-header__description">{description}</p>
                </div>
                {
                    rightSectionTemplate
                    && <div>{rightSectionTemplate}</div>
                }
            </div>
        </StyledWrap>
    );
};

export default ContentHeader;
