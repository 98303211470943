import React, { useMemo, useState } from 'react';
import type { BaseFormInputProps } from '@/Type/BaseFormInputProps';
import { useSearchAddress } from "@/Service/Api/ApiHooks/SearchAddress/useSearchAddress";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { Divider } from "primereact/divider";
import BaseAutocomplete, { BaseAutocompleteProps } from "@/components/Core/Form/BaseAutocomplete";

export type CustomerAddressProps = BaseAutocompleteProps & BaseFormInputProps;

const CustomerAddress: React.FC<CustomerAddressProps> = (props: CustomerAddressProps) => {
    const [searchTerm, setSearchTerm] = useState<string>();

    const {
        data: locations,
    } = useSearchAddress({
        requestParams: {
            search: searchTerm
        },
        enabled: !!searchTerm,
        savePreviousData: true
    });

    const suggestions = useMemo(() => {
        if (!locations) {
            return [];
        }
        return locations;
    }, [locations]);

    return (
        <>
            <BaseAutocomplete
                {...props}
                className="col-6"
                field="label"
                name='address1'
                label="Address Line 1"
                delay={700}
                required
                suggestions={suggestions}
                errorMessages={props.errorMessages?.address1?.message}
                onClear={() => {
                    setSearchTerm(undefined);
                }}
                completeMethod={(e) => {
                    setSearchTerm(e.query);
                }}
            />
            <BaseTextInput
                className="col-6"
                control={props.control}
                name="address2"
                label="Address Line 2"
                errorMessages={props.errorMessages?.address2?.message}
            />
            <BaseTextInput
                className="col-6"
                control={props.control}
                required
                name="postcode"
                label="Postcode"
                errorMessages={props.errorMessages?.postcode?.message}
            />
            <Divider className="col-12"/>
            <BaseTextInput
                className="col-6"
                control={props.control}
                required
                name="county"
                label="County"
                errorMessages={props.errorMessages?.county?.message}
            />
            <BaseTextInput
                className="col-6"
                control={props.control}
                required
                name="city"
                label="City"
                errorMessages={props.errorMessages?.city?.message}
            />
            <BaseTextInput
                className="col-12"
                control={props.control}
                required
                name="country"
                label="Country"
                errorMessages={props.errorMessages?.country?.message}
            />
        </>
    );
};

export default CustomerAddress;
