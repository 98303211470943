import { useQuery } from '@tanstack/react-query';
import { complaintsApiClient } from '@/Service/Api/ApiClients/ComplaintsApiClient';
import { type GetComplaintRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

type useGetComplaintParams = {
    requestParameters: GetComplaintRequest
    enabled?: boolean
};

export const useGetComplaint = ({ requestParameters, enabled }: useGetComplaintParams) => useQuery({
    ...QueryKeys.complaints.detail(requestParameters.complaint_id),
    queryFn: async () => await complaintsApiClient.getComplaint(requestParameters),
    enabled
});
