export class OptionsTree<TValue> {
    key: number;
    data: TValue;
    label: string;
    parent_names: string;
    selectable: boolean;
    children: OptionsTree<TValue>[];

    constructor(
        key: number,
        data: TValue,
        label: string,
        parent_names: string,
        selectable: boolean,
        children: OptionsTree<TValue>[],
    ) {
        this.key = key;
        this.data = data;
        this.label = label;
        this.parent_names = parent_names;
        this.selectable = selectable;
        this.children = children;
    }
}
