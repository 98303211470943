import { useMutation } from '@tanstack/react-query';
import { EscalateComplaintRequest } from '@/stub';
import { complaintsApiClient } from '@/Service/Api/ApiClients/ComplaintsApiClient';

export const useEscalateComplaint = () =>
    useMutation({
        mutationFn: async (data: EscalateComplaintRequest) => await complaintsApiClient.escalateComplaint(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    throw new Error(errorBody.message);
                }
                )
            )
    });
