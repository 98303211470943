import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GetComplaintChannelRequest } from '@/stub';
import { ComplaintChannelsApiClient } from '../../ApiClients/ComplaintChannelsApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetComplaintChannelsParams = {
    requestParams: GetComplaintChannelRequest
    enabled?: boolean
    savePreviousData?: boolean,
    select?: any
};

export const useGetComplaintChannel = ({
    requestParams,
    savePreviousData,
    enabled,
    select
}: useGetComplaintChannelsParams) => useQuery({
    ...QueryKeys.channels.detail(requestParams.channel_id),
    queryFn: async () => await ComplaintChannelsApiClient.getComplaintChannel(requestParams),
    placeholderData: savePreviousData ? keepPreviousData : undefined,
    enabled,
    select
});
