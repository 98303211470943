import { Column, type ColumnBodyOptions } from 'primereact/column';
import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { useGetRootCauseFinal } from "@/Service/Api/ApiHooks/Reports/useGetRootCauseFinal";
import { useGetRootCauseInitial } from "@/Service/Api/ApiHooks/Reports/useGetRootCauseInitial";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import { RootCauseFinalReport, RootCauseInitialReport } from "@/stub";
import { DataTable } from "primereact/datatable";
import styled from "styled-components";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { percentBodyTemplate } from "@/components/templates/DatatableColumnTemplates";
import { formatToApiDate } from "@/Util/formatToApiDate";

const StyledWrap = styled.div`
    .datatable-container {
        margin: 2rem;
    }
`;

type TableColumnDefinition = {
    label: string
    sortable?: boolean
    field: string
    sortField?: string
    body?: React.ReactNode | ((data: RootCauseFinalReport, options: ColumnBodyOptions) => React.ReactNode)
};

const initialTableColumns: TableColumnDefinition[] = [
    {
        label: 'Root Cause',
        field: 'name',
    },
    {
        label: 'Under Investigation',
        field: 'investigated',
    },
    {
        label: 'Closed - Pending FRL',
        field: 'resolved',
    },
    {
        label: 'Closed',
        field: 'closed',
    },
    {
        label: 'All',
        field: 'all',
    },
];

const finalTableColumns: TableColumnDefinition[] = [
    {
        label: 'Root Cause',
        field: 'name',
    },
    {
        label: 'Closed',
        field: 'closed',
    },
    {
        label: 'Average Days Closed',
        field: 'avg_days_close',
    },
    {
        label: 'Closure 3D Percent',
        field: 'closure_3d_percent',
        body: (data) => percentBodyTemplate(data.closure_3d_percent)
    },
    {
        label: 'Closure 3D 8Wk Percent',
        field: 'closure_3d_8wk_percent',
        body: (data) => percentBodyTemplate(data.closure_3d_8wk_percent)
    },
    {
        label: 'Closure 8Wk Percent',
        field: 'closure_8wk_percent',
        body: (data) => percentBodyTemplate(data.closure_8wk_percent)
    },
    {
        label: 'Closure Not Upheld Percent',
        field: 'closure_not_upheld_percent',
        body: (data) => percentBodyTemplate(data.closure_not_upheld_percent)
    },
    {
        label: 'Closure Partial Percent',
        field: 'closure_partial_percent',
        body: (data) => percentBodyTemplate(data.closure_partial_percent)
    },
    {
        label: 'Closure Uphold Percent',
        field: 'closure_uphold_percent',
        body: (data) => percentBodyTemplate(data.closure_uphold_percent)
    },
];

/**
 * KPI root cause datatable
 */
const KpiRootCauseDatatable: React.FC = () => {

    const [dateRangeFilter, setDateRangeFilter] = useState([]);

    const {
        data: rootCauseInitialReport,
        isLoading: loadingRootCauseInitialReport
    } = useGetRootCauseInitial({
        from: dateRangeFilter?.[0] ? formatToApiDate(dateRangeFilter[0]) : undefined,
        to: dateRangeFilter?.[1] ? formatToApiDate(dateRangeFilter[1]) : undefined
    });

    const {
        data: rootCauseFinalReport,
        isLoading: loadingRootCauseFinalReport
    } = useGetRootCauseFinal({
        from: dateRangeFilter?.[0] ? formatToApiDate(dateRangeFilter[0]) : undefined,
        to: dateRangeFilter?.[1] ? formatToApiDate(dateRangeFilter[1]) : undefined
    });


    return (
        <StyledWrap>
            <div>
                <div className="page-header flex justify-content-between">
                    <div>
                        <h3 className="page-title">KPI Root Cause</h3>
                        <span className="page-subtitle">Forseti Complaints Manager</span>
                    </div>
                </div>

                <div className="page-item filter-cards">
                    <Card>
                        <div className="flex align-items-center">
                            <BaseCalendar
                                name='date-range-filter'
                                value={dateRangeFilter}
                                selectionMode='range'
                                dateFormat='dd/mm/yy'
                                placeholder='Select date range'
                                showButtonBar
                                selectOtherMonths
                                readOnlyInput
                                hideOnRangeSelection
                                onChange={(event) => {
                                    setDateRangeFilter(event.value);
                                }}
                            />
                        </div>
                    </Card>
                </div>
            </div>

            <div className="general-table-wrap mb-5">
                <div className="datatable-container">
                    <h4 className='ml-2'>Initial</h4>
                    <DataTable
                        <RootCauseInitialReport[]>
                        id='initial-root-cause-report'
                        lazy
                        removableSort
                        loading={loadingRootCauseInitialReport}
                        value={rootCauseInitialReport}
                    >
                        {initialTableColumns.map(
                            column => <Column
                                key={column.label}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                                sortable={column.sortable}
                                sortField={column.sortField}
                            />
                        )}
                    </DataTable>
                </div>
            </div>

            <div className="general-table-wrap mb-5">
                <div className="datatable-container">
                    <h4 className='ml-2'>Final</h4>
                    <DataTable
                        <RootCauseFinalReport[]>
                        id='final-root-cause-report'
                        lazy
                        removableSort
                        loading={loadingRootCauseFinalReport}
                        value={rootCauseFinalReport}
                    >
                        {finalTableColumns.map(
                            column => <Column
                                key={column.label}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                                sortable={column.sortable}
                                sortField={column.sortField}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
        </StyledWrap>
    );
};

export default KpiRootCauseDatatable;
