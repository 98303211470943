import React, { useEffect } from "react";
import { Card } from 'primereact/card';
import PublicPageLayout from "../themes/default/layouts/PublicPageLayout";
import { Link } from "react-router-dom";
import { setDocumentTitle } from "../helpers/general";
import {useAuth0} from "@auth0/auth0-react";

/**
 * Site's cookies page
 */
const CookiesPage = () => {
    const { isAuthenticated} = useAuth0();
    useEffect(() => {
        setDocumentTitle('Cookies');
    }, []);

    const pageContent = (
        <div className="footer-pages min-height ml-6 mt-5">
            <h1>Cookies</h1>

            <h3>Forseti Cookie Policy (the "Cookie Policy")</h3>

            <h4>1. What is Forseti?</h4>

            <p>This cookie policy sets out important information in relation to cookies generally and those specific cookies that Bridgetech Connect, as a data controller, uses on its corporate website (and its associated portals).</p>
            <p>Forseti is a complaint management system created and managed by Bridgetech Connect, part of Bridgetech Group - a company that provides technology, data and people solutions to regulated clients.</p>
            <p>You should read this Cookie Policy carefully, alongside our <Link to="/privacy-policy">Privacy Policy</Link> and raise any questions you may have with the data protection enquiry team (outlined below).</p>


            <h4>2. What are cookies?</h4>
            <p>A cookie, also known as a browser cookie, is a small text file that may be downloaded to your computer or device when you visit a website or use an app. You may be asked to consent to this, depending on the jurisdiction from which you are accessing this website.</p>
            <p>Different types of cookies are used to do different things. These include letting you navigate between different pages on a website efficiently, remembering your preferences and helping us identify ways to improve your overall site experience. Others are used to provide you with advertising that is more tailored to your interests to measure the number of sites visited and/or the most popular pages visited.</p>

            <h4>3. Are there different types of cookies?</h4>
            <p>Yes, there are different types of cookies - detailed below:</p>
            <h4>"First party" and "third party" cookies</h4>
            <p>Cookies can be set and controlled by the operator of a website or an app (known as a "first party" cookie) or a third party (known as a "third party" cookie).</p>

            <h4>4. Other cookie distinctions</h4>
            <p>The following distinctions of cookies are:</p>

            <p>a) <u>Session cookies</u></p>
            <p>Session cookies are stored in your computer or device's memory during your browsing session and are automatically deleted from your computer when you leave a website or an app. These cookies usually store a session ID, allowing you to move from page to page without having to log in repeatedly.</p>
            <p>They are widely used by commercial websites, for example, to track items that a consumer has added to a shopping basket. Session cookies do not collect any information from your computer or device and they expire at the end of your browser session.</p>
            <p>The cookies we use in this regard are set out in the Table in Section 7 below.</p>

            <p>b) <u>Persistent cookies</u></p>
            <p>Persistent cookies are stored on your computer or device and are not deleted when your browser is closed. Persistent cookies can be used to retain your preferences for a particular website or app, allowing those preferences to be used in future browsing sessions.</p>
            <p>Persistent cookies usually assign a unique ID to your browser and they are usually configured to identify you for a prolonged period of time, from days to months or even years.</p>
            <p>The cookies we use in this regard are set out in the Table in Section 7 below.</p>

            <p>c) <u>Strictly necessary cookies</u></p>
            <p>These are essential for the operation of a website or an app, for example, to enable users to log in to secure areas of a website.</p>
            <p>These let you move around a website or app and use essential features such as accessing secure areas and identifying you as being logged in. Accepting these cookies is a condition of using a website or an app as they are required for its proper operation. If you disable them, a website or app will not be able to function properly.</p>
            <p>These cookies don't gather any information about you that could be used for marketing or remembering where you have been on the internet.</p>
            <p>The cookies that we use on our website, which are essential for its operation, are set out in the Table in Section 7 below.</p>

            <p>d) <u>Analytical/performance cookies</u></p>
            <p>Statistic cookies help website or app owners understand how visitors interact with the website or app by collecting and reporting information.</p>
            <p>We use these cookies, sometimes referred to as "web analytics" information, to collect information about how visitors use the website. This includes details of the site where the visitor has come from, how much time they spend on which page, which content visitors click on, what visitors do and do not like and at what point a visitor leaves our website. We use visitor feedback to improve how our website works, for example, by ensuring that users find what they are looking for easily.</p>
            <p>All information these cookies collect is aggregated and therefore anonymous.</p>
            <p>On occasion, we may share this information with analytics and search engine providers that assist us in improving and optimising the website. Alternatively, we may work with third-party suppliers who place cookies on your device and report on "web analytics" information.</p>
            <p>The cookies we use in this regard are set out in the Table in Section 7 below.</p>

            <p>e) <u>Functionality cookies</u></p>
            <p>These are used to recognise users when they return to a website or app, enabling personalisation of content and user preferences, for example, language or region.</p>
            <p>We use these cookies to recognise visitors when they return to our website, as well as track a visitor's location, enabling us to personalise website content and remember visitor preferences (for example, their choice of language or region). We use the information to improve our website and provide visitors with relevant content that is easy to find, enhancing their experience.</p>
            <p>The cookies that we use in this regard are set out in the Table in Section 7 below.</p>

            <p>f) <u>Targeting cookies</u></p>
            <p>These record a user's visit to a website or app, the pages visited and the links followed, thereby tracking a user's movements, which allows behavioural profiles to be created and used for targeted online advertising.</p>
            <p>These cookies are used to collect information about your browsing habits in order to deliver adverts more relevant to you and your interests. They remember that you have visited a website and this information is shared with other organisations such as advertisers. They are also used to limit the number of times you see an advertisement and help measure the advertising campaign's effectiveness.</p>
            <p>We do not currently use these cookies on the Forseti website.</p>

            <p><u>Generally</u></p>
            <p>The cookies that Forseti uses do not generally collect personal information such as name, address, email address and do not link any information they collect to an individual. From time to time, however, Forseti may analyse IP addresses, user agent strings or other anonymous data sources.</p>
            <p>Please note that although this cookie policy covers the use of data by Forseti, in addition, the use of third-party cookies is covered by third-party cookie policies, which we have referred to in the following tables. Alternatively, they are available on the relevant company's website.</p>

            <h4>5. When do we share data collected by cookies?</h4>
            <p>Forseti may disclose data collected from cookies, such as visitor trends, to third parties, in an anonymous form, for research and statistical purposes and to help us optimise the Forseti website.</p>
            <p>Your data is shared where appropriate technical and organisational measures are in place to protect that data in line with our privacy policy and also our obligations or as permitted by applicable law and/or regulation.</p>

            <h4>6. How can you control the use of cookies?</h4>
            <p>When visiting the Forseti website for the first time, the cookie notification alert displayed at the bottom of the screen provides you with facilities for consenting to the cookies listed in this cookie policy.</p>
            <p>It is also possible to disable and to delete/remove cookies on most web browsers. However, you may lose some functionality of the Forseti website.</p>

            <h4>7. Cookies we use</h4>

            <p>Below we set out in detail the cookies that we use, their function and the data they collect.</p>

            <table border="0">
                <tbody>
                    <tr>
                        <td>
                            <strong>Cookie source</strong>
                        </td>
                        <td>
                            <strong>Cookie</strong>
                        </td>
                        <td>
                            <strong>Type of Cookie - Session or Persistent?</strong>
                        </td>
                        <td>
                            <strong>Category of Cookies - Essential / Analytics/ Preference/ Ad/ Targeting?</strong>
                        </td>
                        <td>
                            <strong>How long is the Cookie stored for?</strong>
                        </td>
                        <td>
                            <strong>1st or 3rd party Cookie?</strong>
                        </td>
                        <td>
                            <strong>Further information for 3rd party cookies</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>_ga</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>2 years</td>
                        <td>3rd</td>
                        <td rowSpan="4">
                            For more information please see:{' '}
                            <a href="https://support.google.com/analytics/answer/6004245" target="_blank">
                                https://support.google.com/analytics/answer/6004245
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>_gid</td>
                        <td>
                            <span>Persistent</span>
                        </td>
                        <td>
                            <span>Analytics</span>
                        </td>
                        <td>24 hours</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>_gat</td>
                        <td>Session</td>
                        <td>
                            <span>Analytics</span>
                        </td>
                        <td>Until the session has ended</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>_utm.gif</td>
                        <td>Session</td>
                        <td>
                            <span>Analytics</span>
                        </td>
                        <td>Until the session has ended</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>Wistia</td>
                        <td>__distillery</td>
                        <td>
                            <span>Persistent</span>
                        </td>
                        <td>
                            <span>Analytics</span>
                        </td>
                        <td>1 year</td>
                        <td>3rd</td>
                        <td rowSpan="2">
                            For more information please see:&nbsp;
                            <a href="https://wistia.com/privacy" target="_blank">
                                https://wistia.com/privacy
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Wistia</td>
                        <td>muxData</td>
                        <td>
                            <span>Persistent</span>
                        </td>
                        <td>
                            <span>Analytics</span>
                        </td>
                        <td>20 years</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>Hotjar</td>
                        <td>_hjClosedSurveyInvites</td>
                        <td>
                            <span>Persistent</span>
                        </td>
                        <td>
                            <span>Analytics</span>
                        </td>
                        <td>365 days</td>
                        <td>3rd</td>
                        <td rowSpan="8">
                            <p>&nbsp;</p>
                            <p>
                                &nbsp;For further information please see:{' '}
                                <a href="https://www.hotjar.com/legal/policies/privacy" target="_blank">
                                    https://www.hotjar.com/legal/policies/privacy
                                </a>
                            </p>
                            <p>
                                <a href="https://www.hotjar.com/legal/compliance/opt-out" target="_blank">
                                    https://www.hotjar.com/legal/compliance/opt-out
                                </a>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>Hotjar</span>
                        </td>
                        <td>_hjDonePolls</td>
                        <td>Persistent</td>
                        <td>Analytics&nbsp;</td>
                        <td>365 days</td>
                        <td>3rd&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <span>Hotjar</span>
                        </td>
                        <td>_MinimizedPolls</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>
                            <span>Hotjar</span>
                        </td>
                        <td>_hjDoneTestersWidgets</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>
                            <span>Hotjar</span>
                        </td>
                        <td>_hjMinimizedTestersWidgets</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>
                            <span>Hotjar</span>
                        </td>
                        <td>_hjIncludedInSample</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>
                            <span>Hotjar</span>
                        </td>
                        <td>_hjShownFeedbackMessage</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>
                            <span>Hotjar</span>
                        </td>
                        <td>_hjid</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>
                            <span>Unbounce</span>
                        </td>
                        <td>ubpv</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                        <td rowSpan="3">
                            For more information please see:&nbsp;
                            <a href="https://unbounce.com/privacy/" target="_blank">
                                https://unbounce.com/privacy/
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Unbounce</td>
                        <td>ubvt</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                    <tr>
                        <td>Unbounce</td>
                        <td>ubvs</td>
                        <td>Persistent</td>
                        <td>Analytics</td>
                        <td>365 days</td>
                        <td>3rd</td>
                    </tr>
                </tbody>
            </table>

            <h4>8. Learn more about cookies</h4>
            <p>To find out more about cookies in general, visit <a href="https://aboutcookies.org/" target="_blank">aboutcookies.org</a> or <a href="https://www.allaboutcookies.org/" target="_blank">allaboutcookies.org</a> (which is run by IBA Europe).</p>
            <p>The internet advertising industry has produced a guide to behavioural advertising and online privacy, which can be found at <a href="http://www.youronlinechoices.eu" target="_blank">www.youronlinechoices.eu</a>. The guide contains an explanation of the Internet Advertising Bureau's self-regulatory scheme to allow you greater control of the advertising you see.</p>

            <h4>9. Email tracking</h4>
            <p>As detailed in our <Link to="/privacy-policy">privacy policy</Link>, for individuals who register for or receive our direct marketing and/or attend our seminars, offices, receive our legal updates, and/or who visit our website, we may collect information about you in the following ways:</p>

            <ul>
                <li>Opening emails: if you open the email either by downloading images in the email or clicking on a link, we log such activity on our database.</li>
                <li>View as web page: if you click on the "view it as a web page" link, a tracking code is passed in the link so that the web page is personalised in the same way as the email.</li>
                <li>Links to web pages: if you click on any web link, we pass a tracking code in the link to the web page to log such activity on our database.</li>
                <li>Unsubscribe: if you click unsubscribe, we will automatically log this information on our database. If you unsubscribe from any email invitation or alert, we will continue to store your personal data on a "marketing suppression list" so as to record your preference.</li>
                <li>Event RSVP buttons: in our event invitations and confirmations, we provide buttons to allow you to accept, decline, cancel (and register if you are not the original recipient of the invitation) for that event. Clicking on these buttons will pass a tracking code so we can record your choice in our database to help us manage the event.</li>
            </ul>

            <h4>10. Additional information on data processing</h4>
            <p>For additional information on the processing of your information and particularly your rights and the exercise of the same, you may consult our privacy policy.</p>
            <p>In order to exercise your rights against the third parties identified above in the list of cookies used by Forseti (Section 7), please proceed in accordance with the provisions of their corresponding privacy policies.</p>

            <h4>Last updated: January 2024</h4>
        </div>
    );

    return isAuthenticated ? (
        <>{pageContent}</>
    ) : (
        <PublicPageLayout>
            <Card>{pageContent}</Card>
        </PublicPageLayout>
    );
};

export default CookiesPage;
