import { useQuery } from '@tanstack/react-query';
import { type GetComplaintDecisionRequest } from '@/stub';
import { ComplaintDecisionApiClient } from '@/Service/Api/ApiClients/ComplaintDecisionApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

type useGetComplaintDecisionParams = {
    requestParams: GetComplaintDecisionRequest
    retry?: boolean
    enabled?: boolean
    select?
};

export const useGetComplaintDecision = ({
    requestParams,
    retry = false,
    enabled = true,
    select
}: useGetComplaintDecisionParams) => useQuery({
    ...QueryKeys.decision.detail(requestParams),
    queryFn: async () => await ComplaintDecisionApiClient.getComplaintDecision(requestParams),
    retry,
    enabled,
    select
});
