import { useMutation } from '@tanstack/react-query';
import { type CreateComplaintRequest } from '@/stub';
import { complaintsApiClient } from '@/Service/Api/ApiClients/ComplaintsApiClient';
import { processValidationErrors } from "@/Util/Api/processValidationErrors";

export const useCreateComplaint = () =>
    useMutation({
        mutationFn: async (data: CreateComplaintRequest) => {
            try {
                return await complaintsApiClient.createComplaint(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
