/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserTeams } from './UserTeams';
import {
    UserTeamsFromJSON,
    UserTeamsFromJSONTyped,
    UserTeamsToJSON,
} from './UserTeams';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    organisation_id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    main_role: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_login_at?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_active_at?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    assigned_complaints: number;
    /**
     * 
     * @type {UserTeams}
     * @memberof User
     */
    teams: UserTeams | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    additional_roles: Array<string>;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organisation_id" in value;
    isInstance = isInstance && "firstname" in value;
    isInstance = isInstance && "surname" in value;
    isInstance = isInstance && "display_name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "main_role" in value;
    isInstance = isInstance && "assigned_complaints" in value;
    isInstance = isInstance && "teams" in value;
    isInstance = isInstance && "additional_roles" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organisation_id': json['organisation_id'],
        'firstname': json['firstname'],
        'surname': json['surname'],
        'display_name': json['display_name'],
        'email': json['email'],
        'main_role': json['main_role'],
        'last_login_at': !exists(json, 'last_login_at') ? undefined : json['last_login_at'],
        'last_active_at': !exists(json, 'last_active_at') ? undefined : json['last_active_at'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'assigned_complaints': json['assigned_complaints'],
        'teams': UserTeamsFromJSON(json['teams']),
        'additional_roles': json['additional_roles'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organisation_id': value.organisation_id,
        'firstname': value.firstname,
        'surname': value.surname,
        'display_name': value.display_name,
        'email': value.email,
        'main_role': value.main_role,
        'last_login_at': value.last_login_at,
        'last_active_at': value.last_active_at,
        'created_at': value.created_at,
        'assigned_complaints': value.assigned_complaints,
        'teams': UserTeamsToJSON(value.teams),
        'additional_roles': value.additional_roles,
    };
}

