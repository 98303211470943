/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChannelType = {
    Email: 'email',
    Letter: 'letter',
    PhoneCall: 'phone_call',
    Website: 'website',
    SocialMedia: 'social_media',
    Fos: 'fos',
    ReferredComplaints: 'referred_complaints',
    Intercom: 'intercom',
    ThirdParty: 'third_party',
    Aggregator: 'aggregator'
} as const;
export type ChannelType = typeof ChannelType[keyof typeof ChannelType];


export function ChannelTypeFromJSON(json: any): ChannelType {
    return ChannelTypeFromJSONTyped(json, false);
}

export function ChannelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelType {
    return json as ChannelType;
}

export function ChannelTypeToJSON(value?: ChannelType | null): any {
    return value as any;
}

