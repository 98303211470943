import { useMutation } from "@tanstack/react-query";
import { RestoreComplaintRequest } from "@/stub";
import { complaintsApiClient } from "@/Service/Api/ApiClients/ComplaintsApiClient";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";

export const useRestoreComplaint = () => useMutation({
    mutationFn: async (data: RestoreComplaintRequest) => {
        try {
            await complaintsApiClient.restoreComplaint(data);
        } catch (error) {
            await processValidationErrors(error);
            throw error;
        }
    }
});
