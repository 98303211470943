import React from 'react';
import { Button } from 'primereact/button';
import { useDownloadComplaintArchive } from '@/Service/Api/ApiHooks/ComplaintArchive/useDownloadComplaintArchive';

type DsarDownloadButtonProps = {
    complaintId: number
}

const DsarDownloadButton: React.FC<DsarDownloadButtonProps> = ({ complaintId }: DsarDownloadButtonProps) => {
    const downloadComplaintArchive = useDownloadComplaintArchive();

    return (
        <Button
            className="p-button-outlined p-button-sm"
            icon="pi pi-download"
            onClick={() => {
                downloadComplaintArchive.mutate({ complaint_id: complaintId });
            }}
        >
            Download Archive
        </Button>
    );
};

export default DsarDownloadButton;
