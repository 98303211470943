import { useEffect, useMemo, useState } from 'react';
import ComplaintsDatatable from '@/components/Datatables/ComplaintsDatatable';
import ContentHeader from '@/components/Blocks/ContentHeader';
import styled from 'styled-components';
import { type GetComplaintsRequest, GetComplaintsStatesEnum } from '@/stub';
import { useDebounce } from "react-use";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import OutboxFiltersList from "@/components/Filters/Inbox/OutboxFiltersList";
import { TableFilters } from "@/components/Filters/FiltersList";
import { formatToApiDate } from "@/Util/formatToApiDate";
import useGetInboxFiltersQuery, { InboxFilterType } from "@/Hooks/useGetInboxFiltersQuery";
import { useUpdateSearchParams } from "@/Hooks/useUpdateSearchParams";

const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }
    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;

export type OutboxPageProps = {
    className?: string
};

export type OutboxPageFilters = GetComplaintsRequest & TableFilters;

const OutboxPage = ({
    className
}: OutboxPageProps) => {
    const updateFilterHandler = useUpdateSearchParams();
    const {
        customerQuery,
        ownerQuery,
        rootCauseIdQuery,
        productIdQuery,
        dataRangeQueryFrom,
        dataRangeQueryTo,
        searchQuery
    } = useGetInboxFiltersQuery();

    const [searchInput, setSearchInput] = useState<string>(searchQuery || '');

    useDebounce(
        () => {
            updateFilterHandler(InboxFilterType.Search, searchInput);
        },
        600,
        [searchInput]
    );

    useEffect(() => {
        setSearchInput(searchQuery || '');
    }, [searchQuery]);

    const outboxRequestParams = useMemo<GetComplaintsRequest>(() => {
        const fromDate = dataRangeQueryFrom ? new Date(dataRangeQueryFrom) : undefined;
        const toDate = dataRangeQueryTo ? new Date(dataRangeQueryTo) : undefined;
        return {
            from: formatToApiDate(fromDate),
            to: formatToApiDate(toDate),
            root_cause_id: rootCauseIdQuery || undefined,
            customer_id: customerQuery || undefined ,
            assigned_to_id: ownerQuery || undefined,
            product_id: productIdQuery || undefined,
            search: searchQuery || undefined,
            states: [
                GetComplaintsStatesEnum.Closed
            ],
        };
    }, [customerQuery, dataRangeQueryFrom, dataRangeQueryTo, ownerQuery, productIdQuery, rootCauseIdQuery, searchQuery]);

    return (
        <div>
            <StyledWrap className={className}>
                <ContentHeader
                    title={'Outbox'}
                    description={'Forseti Complaints Manager'}
                    rightSectionTemplate={
                        <BaseTextInput
                            value={searchInput}
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                        />
                    }
                />
                <div className="content-container">
                    <div className="page-actions">
                        <OutboxFiltersList
                            className="flex gap-2 justify-content-start"
                        />
                    </div>
                    <ComplaintsDatatable
                        frozenData
                        requestParams={outboxRequestParams}
                    />
                </div>
            </StyledWrap>
        </div>
    );
};

export default OutboxPage;
