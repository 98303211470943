import React from 'react';
import { Button } from 'primereact/button';
import { ComplaintDocument } from "@/stub/models/ComplaintDocument";
import { ChannelDocument } from "@/stub";
import { useDownloadComplaintDocument } from "@/Service/Api/ApiHooks/ComplaintDocument/useDownloadComplaintDocument";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { classNames } from "primereact/utils";
import { downloadBlob } from "@/Util/downloadBlob";

export type DownloadButtonProps = {
    complaintId?: number
    file: ComplaintDocument | ChannelDocument
    className?: string
    text?: string
    loading?: boolean
};

const DownloadButton: React.FC<DownloadButtonProps> = ({
    file,
    complaintId,
    className,
    text,
    loading
}: DownloadButtonProps) => {
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const downloadFileMutation = useDownloadComplaintDocument();

    const downloadFile = async (event) => {
        event.preventDefault();
        await downloadFileMutation.mutateAsync({
            complaint_id: complaintId,
            document_id: file.id
        }, {
            onSuccess: (blob) => {
                downloadBlob(blob, file.name);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    return (
        <Button
            className={' ' + className}
            loading={loading || downloadFileMutation.isPending}
            loadingIcon="pi pi-spin pi-sun"
            onClick={downloadFile}
        >
            {!downloadFileMutation.isPending && <i className={"pi pi-download " + classNames({ 'mr-2': !!text })}></i>}
            {text}
        </Button>
    );
};

export default DownloadButton;
