/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneralReportResource
 */
export interface GeneralReportResource {
    /**
     * 
     * @type {string}
     * @memberof GeneralReportResource
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    received?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    resolved?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    closed?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    avg_days_close?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    closure_3d_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    closure_3d_8wk_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    closure_8wk_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    closure_not_upheld_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    closure_partial_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    closure_uphold_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReportResource
     */
    remediation_total?: number;
}

/**
 * Check if a given object implements the GeneralReportResource interface.
 */
export function instanceOfGeneralReportResource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GeneralReportResourceFromJSON(json: any): GeneralReportResource {
    return GeneralReportResourceFromJSONTyped(json, false);
}

export function GeneralReportResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralReportResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'received': !exists(json, 'received') ? undefined : json['received'],
        'resolved': !exists(json, 'resolved') ? undefined : json['resolved'],
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
        'avg_days_close': !exists(json, 'avg_days_close') ? undefined : json['avg_days_close'],
        'closure_3d_percent': !exists(json, 'closure_3d_percent') ? undefined : json['closure_3d_percent'],
        'closure_3d_8wk_percent': !exists(json, 'closure_3d_8wk_percent') ? undefined : json['closure_3d_8wk_percent'],
        'closure_8wk_percent': !exists(json, 'closure_8wk_percent') ? undefined : json['closure_8wk_percent'],
        'closure_not_upheld_percent': !exists(json, 'closure_not_upheld_percent') ? undefined : json['closure_not_upheld_percent'],
        'closure_partial_percent': !exists(json, 'closure_partial_percent') ? undefined : json['closure_partial_percent'],
        'closure_uphold_percent': !exists(json, 'closure_uphold_percent') ? undefined : json['closure_uphold_percent'],
        'remediation_total': !exists(json, 'remediation_total') ? undefined : json['remediation_total'],
    };
}

export function GeneralReportResourceToJSON(value?: GeneralReportResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'received': value.received,
        'resolved': value.resolved,
        'closed': value.closed,
        'avg_days_close': value.avg_days_close,
        'closure_3d_percent': value.closure_3d_percent,
        'closure_3d_8wk_percent': value.closure_3d_8wk_percent,
        'closure_8wk_percent': value.closure_8wk_percent,
        'closure_not_upheld_percent': value.closure_not_upheld_percent,
        'closure_partial_percent': value.closure_partial_percent,
        'closure_uphold_percent': value.closure_uphold_percent,
        'remediation_total': value.remediation_total,
    };
}

