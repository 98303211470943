import {Button} from "primereact/button";
import {isEdge, isFirefox, isSafari} from "./helpers/general";

/**
 *
 * @param toastRef
 * @param accessToken
 */
export default function registerServiceWorker(toastRef, accessToken) {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/sw.js", { type: 'module' });
    }

    function urlBase64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }

        return outputArray;
    }

    function subscribe(sub) {
        const key = sub.getKey('p256dh')
        const token = sub.getKey('auth')
        const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

        const data = {
            endpoint: sub.endpoint,
            public_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            auth_token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
            encoding: contentEncoding,
        };

        fetch(import.meta.env.VITE_API_URL + 'notifications/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            body: JSON.stringify(data)
        }).then(data => {
        }).catch((error) => {
        });
    }

    const VAPID_PUBLIC_KEY = import.meta.env.VITE_VAPID_PUBLIC_KEY;

    function enablePushNotifications() {
        navigator.serviceWorker.ready.then(registration => {
            registration.pushManager.getSubscription().then(subscription => {
                if (subscription) {
                    return subscription;
                }

                const serverKey = urlBase64ToUint8Array(VAPID_PUBLIC_KEY);

                return registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: serverKey
                });
            }).then(subscription => {
                if (!subscription) {
                    alert('Error occured while subscribing');
                    return;
                }
                subscribe(subscription);
            });
        });
    }

    function disablePushNotifications() {
        navigator.serviceWorker.ready.then(registration => {
            registration.pushManager.getSubscription().then(subscription => {
                if (!subscription) {
                    return;
                }

                subscription.unsubscribe().then(() => {
                    fetch(import.meta.env.VITE_API_URL + 'notifications/unsubscribe', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        },
                        body: JSON.stringify({
                            endpoint: subscription.endpoint
                        })
                    }).then(data => {
                    }).catch((error) => {
                    });
                })
            });
        });
    }

    if (isFirefox() || isEdge() || isSafari()) {
        if (Notification.permission === 'default') {
            toastRef.current.show({
                severity: 'info',
                sticky: true,
                closable: false,
                content: (<>
                    <div>
                        Allow Forseti to send you push notifications?
                    </div>
                    <Button className="p-button-outlined p-button-secondary p-button-sm block mt-3" onClick={() => {
                        Notification.requestPermission(function (result) {
                            if (result === 'granted') {
                                enablePushNotifications();
                            }
                        });

                        toastRef.current.clear();
                    }}>Accept and Close</Button>
                </>)
            });
        }

    } else {
        enablePushNotifications();
    }
};










