import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer-links-wrap">
            <ul className="footer-links">
                <li>
                    <Link className="text-highlight" to={"/terms-of-use"}>Terms of Use</Link>
                </li>
                <li>
                    <Link className="text-highlight" to={"/about-us"}>About Us</Link>
                </li>
                <li>
                    <Link className="text-highlight" to={"/cookies"}>Cookies</Link>
                </li>
                <li>
                    <Link className="text-highlight" to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
            </ul>
        </div>
    );
};

export default Footer;
