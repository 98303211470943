import React from 'react';
import { Complaint, ComplaintInvestigation, type ComplaintPoints } from '@/stub';
import { Button } from 'primereact/button';
import InfoTooltipTemplate from "@/components/templates/InfoTooltipTemplate";
import ErrorsBlock from "@/components/Core/Form/ErrorsBlock";
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import {
    UseFormGetValues,
    UseFormSetValue
} from "react-hook-form";
import ComplaintPointInputForm from "@/components/Core/Investigation/ComplaintPointInputForm";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { FieldErrors } from "react-hook-form/dist/types/errors";

export type ComplaintPointsFormProps = BaseFormInputProps & {
    complaint: Complaint
    addPoint: () => void
    removePoint: (point: ComplaintPoints, index: number) => void
    getValues:  UseFormGetValues<ComplaintInvestigation>
    setValue:  UseFormSetValue<ComplaintInvestigation>
    errorMessages: FieldErrors<ComplaintInvestigation>
};

const ComplaintPointsForm: React.FC<ComplaintPointsFormProps> = ({
    complaint,
    control,
    errorMessages,
    addPoint,
    removePoint,
    getValues,
    setValue
}: ComplaintPointsFormProps) => {
    return <div className="mb-5">
        <div className="flex gap-1 align-items-center">
            <h4 className="font-semibold">Complaint Points Raised</h4>
            <InfoTooltipTemplate
                target='tooltip-complaint-points'
                message='This information will be used to populate the FRL letter'
                className="my-auto ml-auto"
            />
        </div>
        <ErrorsBlock
            className="block p-error pt-0"
            errorMessages={errorMessages?.points?.message}
        />

        {(getValues('points') ?? [])?.map((point, index) => (
            <ComplaintPointInputForm
                key={point.id}
                complaint={complaint}
                point={point}
                removePoint={removePoint}
                control={control}
                index={index}
                errorMessages={errorMessages}
                getValues={getValues}
                setValue={setValue}
            />
        ))}

        <Button
            className='p-button-outlined p-button-secondary p-button-sm'
            label="Add complaint point"
            icon="pi pi-plus"
            disabled={isReadonlyComplaint(complaint)}
            onClick={(event) => {
                event.preventDefault();
                addPoint();
            }}
        />
    </div>;
};

export default ComplaintPointsForm;
