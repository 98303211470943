import React, { useId } from 'react';
import { Controller } from 'react-hook-form';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';
import { RadioButton, type RadioButtonProps } from 'primereact/radiobutton';
import { snakeToNormalCase } from '@/helpers/general';

export type BaseRadioProps = RadioButtonProps & BaseFormInputProps & {
    options?: string[]
};

const BaseCheckbox: React.FC<BaseRadioProps> = ({
    control,
    label,
    errorMessages,
    className,
    options = [],
    ...props
}: BaseRadioProps) => {
    const localId = useId();

    return (
        <div className={className}>
            <label className="block mb-2" htmlFor={localId}>{label}{props.required ? '*' : ''}</label>
            {
                control && props.name &&
                <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-wrap gap-3">
                            {options.map((option, index) => {
                                return (
                                    <div className="flex align-items-center" key={index}>
                                        <RadioButton
                                            {...props}
                                            inputId={localId + '-' + index}
                                            inputRef={field.ref}
                                            name={field.name}
                                            invalid={fieldState.invalid}
                                            checked={field.value === option}
                                            disabled={field.disabled}
                                            onChange={(e) => {
                                                field.onChange(option);
                                                if (props.onChange) {
                                                    props.onChange(e);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                field.onBlur();
                                                if (props.onBlur) {
                                                    props.onBlur(e);
                                                }
                                            }}
                                        />
                                        <label htmlFor={localId + '-' + index}
                                            className="radio-label ml-2">{snakeToNormalCase(option)}</label>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseCheckbox;
