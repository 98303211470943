import React, { useId } from "react";
import { Calendar, CalendarProps } from "primereact/calendar";
import { Controller } from "react-hook-form";
import ErrorsBlock from "@/components/Core/Form/ErrorsBlock";
import { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import { fieldsPlaceholders } from "@/config/forms";

const DefaultDateFormat = 'dd.mm.yy';

export type BaseCalendarProps = CalendarProps & BaseFormInputProps;

const BaseCalendar: React.FC<BaseCalendarProps> = ({
    control,
    label,
    errorMessages,
    className,
    ...props
}: BaseCalendarProps) => {
    const localId = useId();

    return (
        <div className={className}>
            {label && <label className="block mb-2" htmlFor={localId}>{label}{props.required ? '*' : ''}</label>}
            {
                control && props.name
                && <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <Calendar
                            id={localId}
                            name={field.name}
                            invalid={fieldState.invalid}
                            value={field.value}
                            disabled={field.disabled}
                            placeholder={props.placeholder ?? fieldsPlaceholders.date}
                            dateFormat={props.dateFormat ?? DefaultDateFormat}
                            selectOtherMonths={props.selectOtherMonths ?? true}
                            showButtonBar={props.showButtonBar ?? true}
                            onChange={(e) => {
                                field.onChange(e.value);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            {...props}
                        />
                    )}
                />
            }
            {
                !control
                && <Calendar
                    placeholder={props.placeholder ?? fieldsPlaceholders.date}
                    dateFormat={props.dateFormat ?? DefaultDateFormat}
                    selectOtherMonths={props.selectOtherMonths ?? true}
                    showButtonBar={props.showButtonBar ?? true}
                    id={localId}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseCalendar;
