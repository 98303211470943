import React from 'react';
import {
    Complaint,
    ComplaintOutcomeTypeEnum, ComplaintPoints,
    ComplaintStateEnum,
    UpsertComplaintOutcomeRequestTypeEnum
} from '@/stub';
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { fieldsPlaceholders } from "@/config/forms";
import BaseMultiselect from "@/components/Core/Form/BaseMultiselect";
import { snakeToNormalCase } from "@/helpers/general";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { useWatch } from "react-hook-form";
import { classNames } from "primereact/utils";

export type ComplaintOutcomeInputFormProps = BaseFormInputProps & {
    complaint: Complaint
    index: number
    removeOutcome: (index: number) => void
    availablePoints: ComplaintPoints[]
};

type OutcomeTypeOption = {
    value: UpsertComplaintOutcomeRequestTypeEnum
    label: string
};

type ProcessedComplaintPoint = {
    content: string
};

const outcomeTypeOptions = (): OutcomeTypeOption[] => {
    const options: OutcomeTypeOption[] = [];
    Object.values(ComplaintOutcomeTypeEnum).forEach(item => {
        options.push({
            value: item,
            label: snakeToNormalCase(item)
        });
    });
    return options;
};

const multiselectItemTemplate = (option: ComplaintPoints): React.ReactNode => {
    return <div style={{ whiteSpace: 'pre-wrap', maxWidth: '200px', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: option?.content ?? '' }}/>;
};

const multiselectSelectedItemTemplate = (value: ProcessedComplaintPoint): React.ReactNode => {
    if (!value) {
        return <span>Select option</span>;
    }
    return <div className='overflow-hidden multiselect-selected-label' dangerouslySetInnerHTML={{ __html: value ?? '' }}/>;
};

const ComplaintOutcomeInputForm: React.FC<ComplaintOutcomeInputFormProps> = ({
    complaint,
    control,
    errorMessages,
    removeOutcome,
    index,
    availablePoints
}: ComplaintOutcomeInputFormProps) => {
    const controlledValue = control ? useWatch({
        control: control,
        name: `outcomes[${index}].points`,
    }) : undefined;
    return <div className="mb-3">
        <div className="flex flex-column gap-3">
            <div className="flex flex-grow-1 flex-row gap-2 w-full align-items-center justify-content-center flex-column xl:flex-row">
                <div className="mt-4 w-2rem">#{index + 1}</div>
                <BaseDropdown
                    className='input'
                    control={control}
                    name={`outcomes[${index}].type`}
                    label="Type"
                    disabled={isReadonlyComplaint(complaint)}
                    placeholder={fieldsPlaceholders.dropdown}
                    options={outcomeTypeOptions()}
                    errorMessages={errorMessages?.outcomes?.[index]?.type?.message}
                />

                <BaseTextInput
                    className='input'
                    control={control}
                    label='Considerations'
                    name={`outcomes[${index}].content`}
                    placeholder={fieldsPlaceholders.text}
                    disabled={isReadonlyComplaint(complaint)}
                    errorMessages={errorMessages?.outcomes?.[index]?.content?.message}
                />

                <BaseMultiselect
                    className={classNames('w-full',{ 'input': controlledValue?.length === 0, 'xl:w-3': controlledValue?.length > 0 })}
                    control={control}
                    optionLabel="content"
                    optionValue='content'
                    name={`outcomes[${index}].points`}
                    label="Related complaint points"
                    placeholder={fieldsPlaceholders.dropdown}
                    options={availablePoints}
                    maxSelectedLabels={1}
                    itemTemplate={multiselectItemTemplate}
                    selectedItemTemplate={multiselectSelectedItemTemplate}
                    showClear
                    pt={{
                        root: {
                            className: 'w-12',
                        },
                    }}
                    filter
                    disabled={isReadonlyComplaint(complaint)}
                    filterBy='content'
                    errorMessages={errorMessages?.outcomes?.[index]?.points?.message}
                />

                {!isReadonlyComplaint(complaint) &&
                    <div className="ml-2 mt-5 w-2rem cursor-pointer" onClick={() => {
                        removeOutcome(index);
                    }}>
                        <i className="pi pi-times"/>
                    </div>}
            </div>
        </div>
    </div>;
};

export default ComplaintOutcomeInputForm;
