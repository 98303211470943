// Local Storage helper

/**
 * Store an object in the local storage
 *
 * @param {string} name the local storage key name
 * @param {object} obj object data
 */
export const createStorageObject = (name, obj) => {
    localStorage.setItem(name, JSON.stringify(obj));
};

/**
 * Retrieve an object from the local storage
 *
 * @param {string} name the local storage key name
 *
 * @returns {object}
 */
export const getStorageObject = (name) => {
    let storageItem = localStorage.getItem(name),
        storageObj = null;

    if (storageItem) {
        storageObj = JSON.parse(storageItem);
    }

    return storageObj;
};

/**
 * Updates (changes) a property value in an object stored in the local storage
 * 
 * @param {string} name the local storage key name 
 * @param {string} property the name of the object's property
 * @param {string} newValue the new value of the property
 */
export const updateStorageObject = (name, property, newValue) => {
    let storageObj = getStorageObject(name);

    if (storageObj && Object.hasOwn(storageObj, property)) {
        storageObj[property] = newValue;

        createStorageObject(name, storageObj);
    }
};

/**
 * Delete all the local storage (except "accepted cookies" option)
 */
export const clearStorage = () => {
    for (const key in localStorage) {
        if (key !== 'accepted_cookies') {
            delete localStorage[key];
        }
    }
};
