import { useEffect } from "react";
import { setDocumentTitle, snakeToNormalCase } from "@/helpers/general";
import "../styles/forms.scss";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import UserAvatar from "../components/partials/UserAvatar";
import { useAuthStore } from "@/Stores/AuthStore";
import styled from "styled-components";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";

const StyledWrap = styled.div`
    .form {
        padding: 1rem;
        margin: 2rem 0 0 2rem;

        .p-inputtext{
            width: 100%;
        }
    }
`;

/*
 *
 * My profile page
 */
const MyProfilePage = () => {
    const authUser = useAuthStore((state) => state.authUser);

    useEffect(() => {
        setDocumentTitle("My profile");
    }, []);

    return (
        <StyledWrap>
            <div className="form formgrid grid mb-5">
                <div className="col-12 md:col-3 lg:col-2">
                    <UserAvatar avatar={authUser?.profile_photo_path}/>
                </div>

                <div className="col-12 md:col-9 lg:col-10 pl-3">
                    <div className="grid">
                        <div className="field col-12 md:col-6 lg:col-6">
                            <BaseTextInput
                                label="Name"
                                value={authUser?.display_name ?? `${authUser?.firstname} ${authUser?.middlename ?? ''} ${authUser?.surname ?? ''}`}
                                readOnly
                            />
                        </div>

                        <div className="field col-12 md:col-6 lg:col-6">
                            <BaseTextInput
                                label="Email"
                                value={authUser?.email}
                                readOnly
                            />
                        </div>

                        <div className="field col-12 md:col-6 lg:col-6">
                            <BaseTextInput
                                label="Organisation"
                                value={authUser?.organisation_name}
                                readOnly
                            />
                        </div>

                        <div className="field col-12 md:col-6 lg:col-6">
                            <BaseTextInput
                                label="Role"
                                value={snakeToNormalCase(authUser?.main_role)}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrap>
    );
};

export default withAuthenticationRequired(MyProfilePage);
