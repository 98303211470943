/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Channel } from './Channel';
import {
    instanceOfChannel,
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';

/**
 * @type ComplaintChannel
 * 
 * @export
 */
export type ComplaintChannel = Channel;

export function ComplaintChannelFromJSON(json: any): ComplaintChannel {
    return ComplaintChannelFromJSONTyped(json, false);
}

export function ComplaintChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ChannelFromJSONTyped(json, true) };
}

export function ComplaintChannelToJSON(value?: ComplaintChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfChannel(value)) {
        return ChannelToJSON(value as Channel);
    }

    return {};
}

