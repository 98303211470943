import React from 'react';
import { Complaint } from "@/stub";
import { Divider } from "primereact/divider";
import BespokesDatatable from "@/components/Datatables/BespokesDatatable";
import ComplaintLettersDatatable from "@/components/Datatables/ComplaintLettersDatatable";

export type LettersProps = {
    complaint: Complaint
};

const Letters: React.FC<LettersProps> = ({ complaint }: LettersProps) => {
    return <div>
        <h4 className='mb-4'>Letters</h4>
        <ComplaintLettersDatatable complaint={complaint}/>

        <Divider className='my-4'/>

        <h4 className='mb-4'>Bespokes</h4>
        <BespokesDatatable complaint={complaint}/>
    </div>;
};

export default Letters;
