import React, { useEffect, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import styled from 'styled-components';
import { classNames } from 'primereact/utils';

const TooltipWrap = styled.div`
    i.pi {
        color: var(--blue-500);

        &.large {
            font-size: 20px;
        }
    }
`;

/**
 * A customizable information icon, showing a message as a tooltip
 */
const InfoTooltipTemplate = ({ target, message, className }) => {
    const [cssClasses, setCssClasses] = useState(classNames({
        'pi pi-info-circle': true
    }));

    const htmlMessage = (msg) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: msg }}></div>
        );
    }

    useEffect(() => {
        if (className) {
            setCssClasses(cssClasses + ' ' + className);
        }
    }, []);

    return (
        <TooltipWrap>
            <i className={cssClasses} id={target}></i>
            <Tooltip target={'#' + target} position="top">{htmlMessage(message)}</Tooltip>
        </TooltipWrap>
    );
};

export default InfoTooltipTemplate;