import React, { useEffect, useMemo, useState } from 'react';
import '../styles/datatables.scss';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { GetFcaRmarReportRequest } from "@/stub";
import ContentHeader from "@/components/Blocks/ContentHeader";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import styled from "styled-components";
import FcaRmarDatatable from "@/components/Datatables/FcaRmarDatatable";
import { Button } from "primereact/button";
import FcaRmarFilterPresetsList from "@/components/FcaRmar/FcaRmarFilterPresetsList";
import { FcaRmarPresetOptions } from "@/Enums/FcaRmarPresetOptions";
import { yearStart } from "@formkit/tempo";
import { useGetFcaRmarPdf } from "@/Service/Api/ApiHooks/Reports/useGetFcaRmarPdf";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { downloadBlob } from "@/Util/downloadBlob";


const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;

const defaultInterval = [
    yearStart(new Date()),
    new Date(new Date().getFullYear(), 5, 30),
];

/**
 * FCA RMAR page
 */
const FcarRmarPage: React.FC = () => {
    const [dateRangeFilter, setDateRangeFilter] = useState<Date[]>(defaultInterval);
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [
        selectedFilterPreset,
        setSelectedFilterPreset
    ] = useState<FcaRmarPresetOptions | undefined>(FcaRmarPresetOptions["H1-2024"]);

    const requestParams = useMemo<GetFcaRmarReportRequest>(() => ({
        from: formatToApiDate(dateRangeFilter?.[0]) ?? undefined,
        to: formatToApiDate(dateRangeFilter?.[1]) ?? undefined
    }), [dateRangeFilter]);

    const {
        isPending,
        mutateAsync,
    } = useGetFcaRmarPdf();

    const onDownload = async () => {
        await mutateAsync(requestParams, {
            onSuccess: (data) => {
                downloadBlob(data, 'FCA RMAR Report');
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    useEffect(() => {
        if (selectedFilterPreset){
            setDateRangeFilter(defaultInterval);
        }
    }, [selectedFilterPreset]);

    return (
        <div>
            <StyledWrap>
                <ContentHeader
                    title='Compliance Centre'
                    description='Financial Conduct Authority RMAR Report'
                    rightSectionTemplate={
                        <BaseCalendar
                            name='date-range-filter'
                            value={dateRangeFilter}
                            selectionMode='range'
                            dateFormat='dd/mm/yy'
                            placeholder='Select date range'
                            showButtonBar
                            selectOtherMonths
                            readOnlyInput
                            hideOnRangeSelection
                            onChange={(event) => {
                                setDateRangeFilter(event.value);
                                setSelectedFilterPreset(undefined);
                            }}
                        />
                    }
                />
                <div className="content-container">
                    <div className="page-actions">
                        <p>Choose filter:</p>
                        <div className="flex justify-content-between">
                            <FcaRmarFilterPresetsList
                                value={selectedFilterPreset}
                                onChange={(presetName) => {
                                    setSelectedFilterPreset(presetName);
                                }}
                            />
                            <Button
                                label="Download"
                                icon="pi pi-plus"
                                loading={isPending}
                                onClick={onDownload}
                            />
                        </div>
                    </div>
                    <FcaRmarDatatable
                        requestParams={requestParams}
                    />
                </div>
            </StyledWrap>
        </div>
    );
};

export default withAuthenticationRequired(FcarRmarPage);
