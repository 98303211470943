/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpsertComplaintInvestigationPointsRequestPointsInner } from './UpsertComplaintInvestigationPointsRequestPointsInner';
import {
    UpsertComplaintInvestigationPointsRequestPointsInnerFromJSON,
    UpsertComplaintInvestigationPointsRequestPointsInnerFromJSONTyped,
    UpsertComplaintInvestigationPointsRequestPointsInnerToJSON,
} from './UpsertComplaintInvestigationPointsRequestPointsInner';

/**
 * 
 * @export
 * @interface UpsertComplaintInvestigationPointsRequest
 */
export interface UpsertComplaintInvestigationPointsRequest {
    /**
     * 
     * @type {Array<UpsertComplaintInvestigationPointsRequestPointsInner>}
     * @memberof UpsertComplaintInvestigationPointsRequest
     */
    points: Array<UpsertComplaintInvestigationPointsRequestPointsInner>;
}

/**
 * Check if a given object implements the UpsertComplaintInvestigationPointsRequest interface.
 */
export function instanceOfUpsertComplaintInvestigationPointsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "points" in value;

    return isInstance;
}

export function UpsertComplaintInvestigationPointsRequestFromJSON(json: any): UpsertComplaintInvestigationPointsRequest {
    return UpsertComplaintInvestigationPointsRequestFromJSONTyped(json, false);
}

export function UpsertComplaintInvestigationPointsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertComplaintInvestigationPointsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'points': ((json['points'] as Array<any>).map(UpsertComplaintInvestigationPointsRequestPointsInnerFromJSON)),
    };
}

export function UpsertComplaintInvestigationPointsRequestToJSON(value?: UpsertComplaintInvestigationPointsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'points': ((value.points as Array<any>).map(UpsertComplaintInvestigationPointsRequestPointsInnerToJSON)),
    };
}

