import React, { useState } from "react";
import { useGetUser } from "@/Service/Api/ApiHooks/User/useGetUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { getUserFullName } from "@/helpers/user";
import { Card } from "primereact/card";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { TabPanel, TabView } from "primereact/tabview";
import Role from "@/components/partials/tabs/Role";
import AuditUser from "@/components/partials/tabs/AuditUser";
import ContentLoadingTemplate from "@/components/templates/ContentLoadingTemplate";



interface Tab {
    key: 'role' | 'audit';
    name: string;
}

const tabs:Tab[] = [
    {
        key: 'role',
        name: 'Role',
    },
    {
        key: 'audit',
        name: 'Audit',
    },

];

const OrganisationUserPage: React.FC = () => {
    const { userId  } = useParams();
    const navigate = useNavigate();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const { data: user,isLoading } = useGetUser({
        requestParams: {
            user_id: Number(userId)
        },
        enabled: !!userId
    });


    const generateTabContent:React.FC<Tab> = (tab) => {
        switch (tab.key) {
            case 'role':
                return (
                    <div className="tab-content">
                        <div className='tc-header mt-2'>
                            <Role
                                user={user}
                            />
                        </div>
                    </div>
                );

            case 'audit':
                return (
                    <div>
                        <AuditUser
                            user={user}
                        />
                    </div>
                );

            default:
                return (
                    <div></div>
                );
        }
    };

    if(isLoading) {
        return <ContentLoadingTemplate/>;
    }

    return (
        <>
            <div className="page-header flex justify-content-between align-items-center">
                <div>
                    <div>
                        <Button className="p-0" link onClick={() => navigate(-1)}>
                            <i className="pi pi-chevron-left mr-1 fake-link"></i>
                            <span className="fake-link">Back to list</span>
                        </Button>
                    </div>
                    <div className="flex align-items-center">
                        <h3 className="page-title">{getUserFullName(user)}</h3>
                    </div>
                </div>

            </div>

            <div className="page-item">
                <Card>
                    <div className="grid grid-nogutter">
                        <div className="header-info-wrap col-4 mb-3">
                            <div className="hi-title">Last login</div>
                            <div className="hi-subtitle">{formatToUKDate(user?.last_login_at, true) ?? 'N/A'}</div>
                        </div>

                        <div className="header-info-wrap col-4 mb-3">
                            <div className="hi-title">Created at</div>
                            <div className="hi-subtitle">{formatToUKDate(user?.created_at, true) ?? "N/A"}</div>
                        </div>

                        <div className="header-info-wrap col-4">
                            <div className="hi-title">Complaints Assigned</div>
                            <Link className="hi-subtitle" to={`/inbox?presets=All&assigned_to_id=${user?.id}`}>{user?.assigned_complaints ?? "N/A"}</Link>
                        </div>

                        <div className="header-info-wrap col-4">
                            <div className="hi-title">Team</div>
                            <div className="hi-subtitle">{user?.teams?.name ?? "N/A"}</div>
                        </div>

                        <div className="header-info-wrap col-4">
                            <div className="hi-title">Last Action</div>
                            <div className="hi-subtitle">{formatToUKDate(user?.last_active_at, true) ?? 'N/A'}</div>
                        </div>


                        <div className="header-info-wrap col-4">
                            <div className="hi-title">Main Role</div>
                            <div className="hi-subtitle">{user?.main_role ?? 'N/A'}</div>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="page-item">
                <div className="grid grid-nogutter">
                    <div className="col-9 mr-4 mb-4">
                        <Card className="p-tight">
                            <TabView
                                id={'tabpanels'}
                                activeIndex={activeTabIndex}
                                onTabChange={(e) => {
                                    setActiveTabIndex(e.index);
                                }}
                                className="main-tabs no-control-btns"
                                scrollable
                            >
                                {tabs.map((tab) => {

                                    return (
                                        <TabPanel header={tab.name} key={tab.key}>
                                            {generateTabContent(tab)}
                                        </TabPanel>
                                    );

                                })}
                            </TabView>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrganisationUserPage;
