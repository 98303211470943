import { ResponseError } from "@/stub";
import { ValidationError, ValidationErrorContract } from "@/Errors/ValidationError";

export const processValidationErrors = async (error: unknown) => {
    if (error instanceof ResponseError) {
        if (error.response.status === 422) {
            const validationResponse: ValidationErrorContract = await error.response.json();
            throw new ValidationError(validationResponse.message, validationResponse.errors);
        }
    }
};
