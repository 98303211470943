import React from "react";
import { clsx } from "clsx";

export type UserAvatarProps = React.HTMLProps<HTMLDivElement> & {
    user?: {
        profile_photo_path?: string | null;
    }
};

const UserAvatar: React.FC<UserAvatarProps> = ({
    user,
    /* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
    className,
    ...props
}: UserAvatarProps) => {
    return (
        <div
            className={clsx('user-avatar-wrap', className)}
            {...props}
        >
            {
                user?.profile_photo_path
                    ? <img className="avatar" src={user.profile_photo_path} alt="user avatar"/>
                    : <div className="avatar-placeholder">
                        <i className="pi pi-user"></i>
                    </div>
            }
        </div>
    );
};

export default UserAvatar;
