// General functions helper

import axios from 'axios';
import moment from 'moment';
import { COMPLAINT_LIST_TYPES, FLASH_MESSAGES_DURATION } from '@/config/constants';
import { routes } from '@/config/routes';
import { splitUppercase } from './regex';
import { clearStorage, getStorageObject } from './storage';

/**
 * @deprecated The native env bundler substitution must be used
 *
 * Get an environment variable
 *
 * @param {string} name the "lowercase" name of the variable
 *
 * @returns {string|null}
 */
export const env = (name) => {
    return process.env[name];
};

/**
 * Change dates from timezone format to UTC format
 *
 * @param {object} data which needs parsing
 *
 * @returns {object} data parsed
 */
export const parseDatesUTC = (data) => {
    for (const key in data) {
        if (typeof (data[key]) === 'object' && moment.isDate(data[key])) {
            data[key] = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
        }
    }

    return data;
};

/**
 * Make customized api requests using axios
 *
 * @param {string} type the type of the request (get, post, delete etc)
 * @param {string} route route of the request (gets appended to the api base url)
 * @param {object} params optional object with data parameter, onSuccess and onFailure callback functions
 * @param {object|null} accessToken authenticated user access token
 */
export const apiRequest = async (type, route, params, accessToken = null) => {
    const requestParams = {
        method: type,
        url: import.meta.env.VITE_API_URL + route,
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    };

    if (params.data) {
        params.data = parseDatesUTC(params.data);

        for (const key in params.data) {
            if (typeof (params.data[key]) === 'undefined') {
                params.data[key] = null;
            }
        }

        if (type === 'get') {
            requestParams.params = params.data;

        } else {
            requestParams.data = params.data;
        }
    }

    await axios(requestParams)
        .then((response) => {
            if (params.onSuccess) {
                const customResponse = {
                    data: null,
                    result: null,
                    pagination: null
                };

                if (response) {
                    if (response.data) {
                        customResponse.data = response.data;

                        if (response.data?.data) {
                            // only one item
                            customResponse.result = response.data.data;

                            // paginated items
                            if (response.data.data?.data && response.data.data?.per_page) {
                                customResponse.result = response.data.data.data;
                            }

                            if (response.data.data?.total) {
                                customResponse.pagination = {};

                                customResponse.pagination.totalEntries = response.data.data.total;
                                customResponse.pagination.totalPages = response.data.data.total;
                            }
                        }

                        // if (response.data?.data?.data) {
                        //     customResponse.result = response.data.data.data;
                        // }

                        // if (response.data?.data?.total) {
                        //     customResponse.pagination = {};

                        //     customResponse.pagination.totalEntries = response.data.data.total;
                        //     customResponse.pagination.totalPages = response.data.data.total;
                        // }

                        if (response.data?.appVersion) {
                            localStorage.setItem('api_version', response.data?.appVersion);
                        }
                    }
                }

                params.onSuccess(customResponse);
            }
        })
        .catch(async (error) => {
            if (error?.response?.status && error.response.status === 401) {
                console.log('no auth', error);
                clearStorage();
                window.location.href = import.meta.env.VITE_SITE_URL;
            } else {
                if (params.onFailure) {
                    const customError = {
                        data: null,
                        response: null,
                        responseData: null,
                        status: null,
                        messages: null
                    };

                    if (error) {
                        customError.data = error;

                        if (error.response) {
                            customError.response = error.response;

                            if (error.response.data) {
                                customError.responseData = error.response.data;

                                if (error.response.data.errorMessage) {
                                    customError.messages = error.response.data.errorMessage;
                                }
                            }

                            if (error.response.status) {
                                customError.status = error.response.status;
                            }
                        }
                    }

                    params.onFailure(customError);
                }
            }
        });
};

const findRoute = (name: string, params, type: 'public' | 'private') => {
    const findRoute = routes[type].find((item) => item.name === name);
    let path = findRoute?.path;
    if (!path) {
        throw new Error(`Route - ${name} not found`);
    }
    if (path && params) {
        for (const key in params) {
            path = path.replace(new RegExp(`:${key}\\??`), params[key]);
        }
    }
    return path;
};

export const url = (name: string, params = {}) => {
    return findRoute(name, params, 'private');
};

/**
 * Get a public route path
 *
 * @param {string} name the name of the route
 * @param {array} params route parameters
 *
 * @returns {string} the path of the route
 */
export const publicUrl = (name, params = []) => {
    return findRoute(name, params, 'public');
};

/**
 * Transform a camel case string into a phrase separated by space where the first letter is uppercase
 * Eg: from 'camelCase' to 'Camel Case'
 *
 * @param {string} string a camel case string
 *
 * @returns {string} the parsed string
 */
export const parseCamelCase = (string) => {
    let parsedString = string.split(splitUppercase).join(' ');

    parsedString = parsedString.charAt(0).toUpperCase() + parsedString.slice(1);

    return parsedString;
};

/**
 * Shows a notification message in the top right corner of the screen
 *
 * @param {object} ref html dom reference object
 * @param {string} type the type of message: success/info/warn/error
 * @param {string} message the message to be displayed
 */
export const toast = (ref, type, message) => {
    const title = type.charAt(0).toUpperCase() + type.slice(1);

    ref?.current?.show({
        severity: type,
        title,
        detail: message,
        life: FLASH_MESSAGES_DURATION
    });
};

/**
 * Show any flash messages saved in the local storage as toast notifications
 *
 * @param {object} ref html dom reference object
 */
export const showFlashMessages = (ref) => {
    const flashMessages = getStorageObject('flashMessages');

    if (flashMessages && flashMessages.length > 0) {
        for (let i = 0; i < flashMessages.length; i++) {
            toast(ref, flashMessages[i].type, flashMessages[i].message);
        }
    }

    localStorage.removeItem('flashMessages');
};

/**
 * Format a date coming from the API
 *
 * @param {string} date a date in string format
 *
 * @param noTime
 * @returns {string} the formatted date
 */
export const formatDate = (date, noTime = false) => {
    return date ? moment(date).format('DD.MM.YYYY' + (!noTime ? ' HH:mm:ss' : '')) : '';
};

/**
 *
 * @param url
 * @param accessToken
 * @returns {Promise<Response>}
 */
export const fetchWithAuthentication = async (url, accessToken) => {
    const headers = new Headers();

    headers.set('Authorization', 'Bearer ' + accessToken);

    return await fetch(url, { headers });
};

/**
 *
 * @param imageUrl
 * @param accessToken
 * @returns {Promise<string>}
 */
export const getAuthImage = async (imageUrl, accessToken) => {
    // Fetch the image.
    const response = await fetchWithAuthentication(imageUrl, accessToken);

    // Create an object URL from the data.
    const blob = await response.blob();
    return URL.createObjectURL(blob);
};

/**
 * Format a file size
 *
 * @param {integer} bytes the file size in bytes
 *
 * @returns {string} the formatted size
 */
export const formatFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 B';
    }

    if (bytes) {
        const k = 1000,
            dm = 3,
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
};

/**
 * Convert a string from snake case to normal text with uppercase first letter words
 *
 * @param {string} inputString which needs parsing
 *
 * @returns {string} outputString data parsed
 */
export const snakeToNormalCase = (inputString) => {
    let outputString = inputString.toLowerCase(),
        outputArray = outputString.split('_');

    for (let i = 0; i < outputArray.length; i++) {
        outputArray[i] = outputArray[i].charAt(0).toUpperCase() + outputArray[i].slice(1);
    }

    outputString = outputArray.join(' ');

    return outputString;
};

/**
 * Format big numbers using comma and decimal separator
 *
 * @param {float} value number to format
 *
 * @returns {string} formatted number
 */
export const formatBigNo = (value) => {
    return parseFloat(value).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

export const getBrowserType = () => {
    const browserMatch = regexp => {
        return regexp.test(navigator.userAgent);
    };

    if (browserMatch(/opr\//i) || !!window.opr) {
        return 'Opera';
    } else if (browserMatch(/edg/i)) {
        return 'Microsoft Edge';
    } else if (browserMatch(/chrome|chromium|crios/i)) {
        return 'Google Chrome';
    } else if (browserMatch(/firefox|fxios/i)) {
        return 'Mozilla Firefox';
    } else if (browserMatch(/safari/i)) {
        return 'Apple Safari';
    } else if (browserMatch(/trident/i)) {
        return 'Microsoft Internet Explorer';
    } else if (browserMatch(/ucbrowser/i)) {
        return 'UC Browser';
    } else if (browserMatch(/samsungbrowser/i)) {
        return 'Samsung Browser';
    } else {
        return 'Unknown browser';
    }
};

/**
 * Check if the current browser is Firefox
 *
 * @returns {boolean}
 */
export const isFirefox = () => {
    return getBrowserType() === 'Mozilla Firefox';
};

/**
 * Check if the current browser is Safari
 *
 * @returns {boolean}
 */
export const isSafari = () => {
    return getBrowserType() === 'Apple Safari';
};

/**
 * Check if the current browser is Edge
 *
 * @returns {boolean}
 */
export const isEdge = () => {
    return getBrowserType() === 'Microsoft Edge';
};

/**
 * Make a user name depending on first, middle and last name
 *
 * @returns {string}
 */
export const generateUserName = (user) => {
    let userName = user?.firstname || '';

    if (user?.middlename) {
        userName += ' ' + user?.middlename;
    }

    if (user?.surname) {
        userName += ' ' + user?.surname;
    }

    return userName;
};

/**
 * Check if the current url path matched the given one
 *
 * @param {string} path an url path
 *
 * @returns {boolean}
 */
export const checkUrlPath = (path) => {
    return location && location?.pathname === '/' + path;
};

/**
 * Get the default complaints listing view depending on the user permission
 *
 * @param {array|null} userPermissions
 * @param {string} listType the type of listing (inbox|outbox|fos)
 *
 * @returns {string} the default inbox view
 */
export const getDefaultListView = (userPermissions, listType) => {
    let filter = '';
    userPermissions?.every((permission) => {
        if (COMPLAINT_LIST_TYPES[listType].default_filters[permission] !== undefined) {
            filter = COMPLAINT_LIST_TYPES[listType].default_filters[permission];
            return false;
        }
        return true;
    });
    return filter;
};

/**
 * Check if a given user role can view reports related info
 *
 * @param {array} userPermissions
 * @param {string} neededPermission
 *
 * @returns {boolean}
 */
export const hasPermission = (userPermissions: string[] | null | undefined = [], neededPermission: string) => {
    return userPermissions?.includes(neededPermission);
};

/**
 * Parse date coming from api
 *
 * @param {string} dateString a date in a string format
 *
 * @returns {string}
 */
export const getDateFromApi = (dateString) => {
    let getDate = moment(dateString),
        parsedDate = '';

    if (getDate.isValid()) {
        parsedDate = getDate.toDate();
    }

    return parsedDate;
};

/**
 * Parse date which will be sent to the api
 *
 * @param {Date} dateObj date object
 *
 * @returns {string|null}
 */
export const getDateForApi = (dateObj) => {
    let getDate = moment(dateObj),
        parsedDate = null;

    if (getDate.isValid()) {
        parsedDate = getDate.format('YYYY-MM-DD HH:mm:ss');
    }

    return parsedDate;
};

/**
 * Parse date which will be sent to the api
 *
 * @param {Date} dateObj date object
 *
 * @returns {string|null}
 */
export const parseDateAsString = (dateObj: Date): null | string => {
    let getDate = moment(dateObj),
        parsedDate = null;

    if (getDate.isValid()) {
        parsedDate = getDate.format('YYYY-MM-DD');
    }

    return parsedDate;
};

/**
 * Capitalize ONLY the first letter of a string
 *
 * @param {any} testString a given string
 *
 * @returns {string}
 */
export const capitalizeString = (testString) => {
    const str = String(testString);

    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};

/**
 * Check if a given value is a valid date object
 *
 * @param {Date} testDate a given date object
 *
 * @returns {boolean}
 */
export const checkDateObject = (testDate) => {
    return testDate &&
        typeof testDate === 'object' && true &&
        'getTime' in testDate;
};

/**
 * Remove array style brackets from a string (eg: Element[1] becomes Element 1)
 *
 * @param {string} testString a given string
 */
export const cleanArrayLabel = (testString) => {
    if (testString) {
        let newString = testString.replace('[', ' ');

        newString = newString.replace(']', '');

        return newString;
    }
};

/**
 * Set the page's title
 *
 * @param {string} title the new title
 */
export const setDocumentTitle = (title) => {
    document.title = title + ' · Forseti';
};

/**
 * Download a file
 *
 * @param {string} url the url of the request
 * @param {object} params additional params
 * @param {function} onSuccess run this function on success
 * @param {function} onFailure run this function on failure
 * @param {string} accessToken
 */
export const downloadFile = async (url, params, onSuccess, onFailure, accessToken) => {
    let apiUrl = url.search(import.meta.env.VITE_API_URL) > -1 ? url : (import.meta.env.VITE_API_URL + url),
        options = {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        };

    // additional headers
    if (params?.headers) {
        options.headers = { ...options.headers, ...params.headers };
    }

    // url params
    if (params?.urlParams) {
        apiUrl += '?' + new URLSearchParams(params.urlParams);
    }

    // additional request options
    if (params?.options) {
        options = { ...options, ...params.options };
    }

    try {
        const req = await fetch(apiUrl, options);

        if (req.ok) {
            const blob = await req.blob(),
                file = window.URL.createObjectURL(blob);

            window.location.assign(file);
            onSuccess();

        } else {
            onFailure(req.statusText);
        }

    } catch (e) {
        onFailure(e);
    }
};

/**
 * Check if any of the advanced filters is on
 *
 * @param {array|null} dateRange date range input data
 * @param {integer} customerName id of the customer
 * @param {integer} product id of the product
 * @param {integer} status id of the status
 * @param {integer} assignedTo id of the user
 * @param {integer} complaintCode value of the complaint code
 */
export const checkAdvancedFilters = (dateRange, customerName, product, status, assignedTo, complaintCode) => {
    let hasFilter = false;

    if ((dateRange?.[0] != null && dateRange?.[1] != null) || dateRange === null) {
        hasFilter = true;
    }

    if (customerName || product || status || assignedTo || complaintCode) {
        hasFilter = true;
    }

    return hasFilter;
};

/**
 * Truncate a string to a desired number or characters
 *
 * @param {string} testString a string to truncate
 * @param {number} desiredLength the length of the truncated string
 *
 * @return {string} the truncated string
 */
export const truncateString = (testString: string, desiredLength: number): string => {
    let truncatedString: string = '';

    if (testString.length > 0 && testString.length > desiredLength) {
        truncatedString = testString.substring(0, desiredLength);

    } else {
        truncatedString = testString;
    }

    return truncatedString;
};
export const resolve = (obj, path) => {
    const r = path.split('.');
    if (path && obj) { return resolve(obj[r.shift()], r.join('.')); }
    return obj;
};
export const findPath = (object, path) => {
    const keys = path.split('.');
    let temp = object;
    for (let i = 0; i < keys.length; i++) {
        try {
            if (!temp.hasOwnProperty(keys[i])) { return undefined; }
            temp = temp[keys[i]];
        } catch {
            return undefined;
        }
    }
    return temp;
};
// /**
//  *
//  * @param object
//  * @param keyPath
//  */
export const fetchObjectDeepKey = (object, keyPath) => {
    // console.log(object, keyPath)
    return keyPath.split('.').reduce((deepObject, deepKey) => (deepObject[deepKey] !== undefined ? deepObject[deepKey] : null), object);
};
