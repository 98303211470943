import { useAuthStore } from "@/Stores/AuthStore";
import { Complaint, ComplaintStateEnum } from "@/stub";

export const checkPermission = (haystack: string[] | null | undefined, permission: string): boolean => {
    if (!haystack)
        return false;
    return haystack.includes(permission);
};

export const authUserCan = (permission: string): boolean => {
    return checkPermission(useAuthStore.getState().authUser?.permissions, permission);
};

export const checkAllPermissions = (haystack: string[] | null | undefined, permissions: string[]): boolean => {
    if (!haystack || !permissions.length)
        return false;
    for (const permission of permissions) {
        if (!checkPermission(haystack, permission)) {
            return false;
        }
    }
    return true;
};

export const checkAnyPermission = (haystack: string[] | null | undefined, permissions: string[]): boolean => {
    if (!haystack || !permissions.length)
        return false;
    for (const permission of permissions) {
        if (checkPermission(haystack, permission)) {
            return true;
        }
    }
    return false;
};

export const isReadonlyComplaint = (complaint: Complaint | undefined): boolean => {
    const authUser = useAuthStore.getState().authUser;

    if (!authUserCan('edit:org_complaints') && complaint?.owner && authUser?.id !== complaint?.owner?.id) {
        return true;
    }

    if (complaint?.state === ComplaintStateEnum.Closed) {
        // Check if the user has the right to edit closed complaints.
        if (!authUserCan('update:closed_complaint')) {
            return true;
        }
    }

    return false;
};
