import React from 'react';
import BaseTextInput from '@/components/Core/Form/BaseTextInput';
import { useForm } from 'react-hook-form';
import { clsx } from 'clsx';
import { z } from 'zod';
import { Button } from 'primereact/button';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseRootCauseSelect from "@/components/Core/Form/Selector/BaseRootCauseSelect";
import BaseRootCauseFcaSelect from "@/components/Core/Form/Selector/BaseRootCauseFcaSelect";


const RootCauseFormSchema = z.object({
    id: z.number().optional(),
    code: z.string().optional(),
    label: z.string(),
    lvl: z.number().optional(),
    parent_id: z.number(),
    fca_root_cause_id: z.number().optional(),
}).superRefine((data, ctx) => {
    if (!data.parent_id) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Parent root is required',
            path: ['parent_id']
        });
    }

    if ((data.lvl == 1) && !data.fca_root_cause_id) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Fca root is required',
            path: ['fca_root_cause_id']
        });
    }
    if ((data.lvl == 1) && !data.code) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Code is required',
            path: ['code']
        });
    }
});

export type RootCauseFormData = z.infer<typeof RootCauseFormSchema>;

type RequiredFields = Pick<RootCauseFormData, 'label' | 'parent_id'>;
type OptionalFields = Partial<Omit<RootCauseFormData, 'label' | 'parent_id'>>;

export type RootCauseInfoInitData = RequiredFields & OptionalFields;

export type RootCauseFormFormProps = {
    className?: string
    onSubmit?: (data: RootCauseInfoInitData) => void
    onSubmitButtonLabel?: string
    onSubmitButtonDisabled?: boolean
    isProcessing?: boolean
    initFormData?: RootCauseInfoInitData
};

const RootCauseForm: React.FC<RootCauseFormFormProps> = ({
    className,
    onSubmit,
    isProcessing,
    initFormData,
    onSubmitButtonLabel,
    onSubmitButtonDisabled = false

}: RootCauseFormFormProps) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        getValues
    } = useForm<RootCauseInfoInitData>({
        resolver: zodResolver(RootCauseFormSchema),
        defaultValues: { ...initFormData }
    });

    const onFormSubmit = (data: RootCauseInfoInitData) => {
        if (onSubmit) {
            onSubmit(data);
        }
    };

    const lvl = watch('lvl');

    return (
        <div className={clsx(className, 'customer-form')}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="flex gap-3 flex-column">
                    {lvl && lvl == 1 ?
                        <BaseTextInput
                            className="rounded-md w-full"
                            control={control}
                            name="code"
                            label={'Code'}
                            errorMessages={errors.code?.message}
                        />
                        : null}
                    <BaseTextInput
                        className="rounded-md w-full"
                        control={control}
                        required
                        name="label"
                        label={'Label'}
                        errorMessages={errors.label?.message}
                    />
                    <BaseRootCauseSelect
                        name={'parent_id'}
                        label="Parent"
                        required
                        value={String(getValues('parent_id')) ?? null}
                        control={control}
                        onNodeSelect={(e) => {
                            setValue('lvl', e.node.data.lvl);
                            if(e.node.data.lvl === 0) {
                                setValue('fca_root_cause_id', undefined);
                                setValue('code', undefined);
                            }
                        }}
                        selectableLvl={0}
                        isSelectedRoot
                        className="rounded-md w-full"
                        placeholder="Root Cause"
                        showClear
                        errorMessages={errors.parent_id?.message}
                    />
                    {lvl && lvl == 1 ?
                        <BaseRootCauseFcaSelect
                            name={'fca_root_cause_id'}
                            label="Fca Root Cause"
                            required
                            control={control}
                            queryEnabled={true}
                            selectableLvl={2}
                            className="rounded-md w-full"
                            placeholder="Root Cause"
                            showClear
                            errorMessages={errors.fca_root_cause_id?.message}
                        /> : null
                    }
                </div>
                <div className="flex mt-4">
                    <Button
                        className="ml-auto"
                        label={onSubmitButtonLabel}
                        disabled={onSubmitButtonDisabled}
                        type="submit"
                        severity="success"
                        loading={isProcessing}
                    />
                </div>
            </form>
        </div>
    );
};

export default RootCauseForm;
