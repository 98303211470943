import React, { useId, useMemo } from "react";
import { Message } from "react-hook-form";

export type ErrorsBlockProps = {
    className?: string
    errorMessages?: Message | Message[]
};

const ErrorsBlock: React.FC<ErrorsBlockProps> = ({
    className,
    errorMessages
}: ErrorsBlockProps) => {
    const id = useId();

    const errors = useMemo<Message[] | undefined>(() => {
        if (!errorMessages)
            return undefined;
        if (!Array.isArray(errorMessages)) {
            return [errorMessages];
        }
        return errorMessages;
    }, [errorMessages]);

    return (
        <>
            {
                errors
                && errors?.map(error =>
                    <small
                        key={id + error}
                        className={className}>{error}
                    </small>
                )
            }
        </>
    );
};

export default ErrorsBlock;
