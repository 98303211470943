/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateComplaintRequest
 */
export interface UpdateComplaintRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintRequest
     */
    summary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintRequest
     */
    raised_date?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateComplaintRequest
     */
    product_id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateComplaintRequest
     */
    root_cause_id?: number | null;
}

/**
 * Check if a given object implements the UpdateComplaintRequest interface.
 */
export function instanceOfUpdateComplaintRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateComplaintRequestFromJSON(json: any): UpdateComplaintRequest {
    return UpdateComplaintRequestFromJSONTyped(json, false);
}

export function UpdateComplaintRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateComplaintRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'raised_date': !exists(json, 'raised_date') ? undefined : json['raised_date'],
        'product_id': !exists(json, 'product_id') ? undefined : json['product_id'],
        'root_cause_id': !exists(json, 'root_cause_id') ? undefined : json['root_cause_id'],
    };
}

export function UpdateComplaintRequestToJSON(value?: UpdateComplaintRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': value.summary,
        'raised_date': value.raised_date,
        'product_id': value.product_id,
        'root_cause_id': value.root_cause_id,
    };
}

