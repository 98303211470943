import { useQuery } from '@tanstack/react-query';
import { GetUserRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { usersApiClient } from "@/Service/Api/ApiClients/UsersApiClient";

export interface useGetUserParams {
    enabled?: boolean
    requestParams: GetUserRequest
}

export const useGetUser = ({
    enabled = true,
    requestParams
}: useGetUserParams) => useQuery({
    ...QueryKeys.customers.detail(requestParams.user_id),
    queryFn: async () => await usersApiClient.getUser(requestParams),
    enabled,
});
