import React from 'react';
import { Complaint, ComplaintStateEnum } from '@/stub';
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import BaseTextEditor from "@/components/Core/Form/BaseTextEditor";
import InfoTooltipTemplate from "@/components/templates/InfoTooltipTemplate";
import { isReadonlyComplaint } from "@/Util/permissionChecks";

export type SummaryInputFormProps = BaseFormInputProps & {
    complaint: Complaint
};

const SummaryInputForm: React.FC<SummaryInputFormProps> = ({
    complaint,
    control,
    errorMessages,
}: SummaryInputFormProps) => {
    return <div className="mb-3">
        <div className="flex gap-1 align-items-center">
            <h4 className="font-semibold">Summary</h4>
            <InfoTooltipTemplate
                target='tooltip-complaint-points'
                message='This information will be used to populate the FRL letter'
                className="my-auto ml-auto"
            />
        </div>
        <div>
            <BaseTextEditor
                label='Investigation Summary'
                textareaName='investigation_summary'
                control={control}
                height='130px'
                menubar={false}
                disabled={isReadonlyComplaint(complaint)}
                errorMessages={errorMessages?.investigation_summary?.message}
            />
        </div>
    </div>;
};

export default SummaryInputForm;
