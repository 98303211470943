import { Outlet, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLayout from "../../themes/default/layouts/PageLayout";

const PrivateRoute = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    if (isAuthenticated || isLoading) {
        return <PageLayout>
            <Outlet/>
        </PageLayout>;
    } else {
        return <Navigate to={'/login'}/>;
    }
};

export default PrivateRoute;
