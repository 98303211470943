import React, { type ReactNode, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ContentLoadingTemplate from '@/components/templates/ContentLoadingTemplate';
import App from '@/components/App';
import { useAuthStore } from '@/Stores/AuthStore';
import { useGetAuthUser } from '@/Service/Api/ApiHooks/AuthUser/useGetAuthUser';
import { storageGetPrimitive } from "@/Util/localStorageHelpers";

interface AuthAppWrapperProps {
    children?: ReactNode
}

const AppInitWrapper: React.FC<AuthAppWrapperProps> = ({}: AuthAppWrapperProps) => {
    const {
        user,
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
        loginWithRedirect
    } = useAuth0();

    const auth0User = useAuthStore((state) => state.auth0User);
    const accessToken = useAuthStore((state) => state.accessToken);

    const setAuth0User = useAuthStore((state) => state.setAuth0User);
    const setAccessToken = useAuthStore((state) => state.setAccessToken);
    const setImpersonatedOrgId = useAuthStore((state) => state.setImpersonatedOrgId);

    const storedAuthUser = useAuthStore((state) => state.authUser);
    const setAuthUser = useAuthStore((state) => state.setAuthUser);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setAuth0User(user);
            getAccessTokenSilently()
                .then((token) => { setAccessToken(token); })
                .catch(() => {
                    loginWithRedirect();
                });
        }
    }, [isLoading, isAuthenticated, setAuth0User, getAccessTokenSilently, user, setAccessToken, loginWithRedirect]);

    const auth0UserInfoLoading = useMemo(() => {
        return isLoading ||
            (
                (!isLoading && isAuthenticated) &&
                (!auth0User || !accessToken)
            );
    }, [isLoading, isAuthenticated, auth0User, accessToken]);

    const {
        data: authUser,
        isLoading: authUserIsLoading,
        isSuccess: authUserIsSuccess
    } = useGetAuthUser({ enabled: !auth0UserInfoLoading, retry: false });

    const authUserInfoLoading = useMemo(() => {
        return authUserIsLoading ||
            (
                (!authUserIsLoading && authUserIsSuccess) &&
                (!storedAuthUser)
            );
    }, [authUserIsLoading, authUserIsSuccess, storedAuthUser]);

    useEffect(() => {
        if (!authUserIsLoading && authUserIsSuccess) {
            setAuthUser(authUser);
            setImpersonatedOrgId(storageGetPrimitive<number>('impersonatedOrgId') ?? undefined);
        }
    }, [authUser, authUserIsLoading, authUserIsSuccess, setAuthUser, setImpersonatedOrgId]);

    const initialDataIsLoading = useMemo(() => {
        return auth0UserInfoLoading ||
            authUserInfoLoading;
    }, [auth0UserInfoLoading, authUserInfoLoading]);

    if (initialDataIsLoading) {
        return <ContentLoadingTemplate/>;
    }
    return <App/>;
};

export default AppInitWrapper;
