import { useMutation } from '@tanstack/react-query';
import { type UpdateEventAndOutcomesInComplaintRequest } from '@/stub';
import type { UseFormSetError } from 'react-hook-form';
import { ComplaintInvestigationApiClient } from '@/Service/Api/ApiClients/ComplaintInvestigationApiClient';

type useUpdateComplaintInvestigationParams = {
    setError?: UseFormSetError<never>
};

export const useUpdateComplaintInvestigation = ({ setError }: useUpdateComplaintInvestigationParams = {}) => {
    return useMutation({
        mutationFn: async (data: UpdateEventAndOutcomesInComplaintRequest) => await ComplaintInvestigationApiClient.updateEventAndOutcomesInComplaint(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    Object.keys(errorBody.errors).map(key => {
                        setError(key, { message: errorBody.errors[key][0] });
                    });
                    throw new Error(errorBody.message);
                }
                )
            )
    });
};
