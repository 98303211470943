import { useQuery } from '@tanstack/react-query';
import { type GetRootCauseInTreeStructureRequest, RootCause } from '@/stub';
import { rootCauseApiClient } from '@/Service/Api/ApiClients/RootCauseApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetRootCausesParams = {
    requestParams?: GetRootCauseInTreeStructureRequest
    enabled?: boolean
    select?: (rootCauses: RootCause[]) => RootCause[]
};

export const useGetRootCauses = ({ enabled = true, select, requestParams }: useGetRootCausesParams) => useQuery({
    ...QueryKeys.rootCauses.list(requestParams),
    queryFn: async () => await rootCauseApiClient.getRootCauseInTreeStructure(requestParams),
    enabled,
    select
});
