import Footer from '@/components/Blocks/Footer';
import { ForsetiLogo } from '@/helpers/svg-icons';
import { Link } from "react-router-dom";
import '@/themes/default/styles/fullscreen-layout.scss';
import { ReactNode } from 'react';

type FullscreenLayoutProps = {
    children?: ReactNode
};

const FullscreenLayout = ({ children }: FullscreenLayoutProps) => {

    return (
        <div className="fullscreen-layout">
            <div className="flex flex-column align-items-center justify-content-between h-full">
                <div>&nbsp;</div>

                <div>
                    <Link to={"/"} className="login-logo site-logo uppercase-dark-text p-link flex justify-content-center align-items-center">
                        <ForsetiLogo />
                    </Link>
                    {children}
                </div>

                <Footer />
            </div>
        </div>
    );
};

export default FullscreenLayout;
