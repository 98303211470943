import { useMutation } from '@tanstack/react-query';
import type { UseFormSetError } from 'react-hook-form';
import { type UpdateComplaintDecisionRequest } from '@/stub';
import { ComplaintDecisionApiClient } from '@/Service/Api/ApiClients/ComplaintDecisionApiClient';

type useUpdateComplaintDecisionParams = {
    setError?: UseFormSetError<never>
}

export const useUpdateComplaintDecision = ({ setError }: useUpdateComplaintDecisionParams = {}) => {
    return useMutation({
        mutationFn: async (data: UpdateComplaintDecisionRequest) => await ComplaintDecisionApiClient.updateComplaintDecision(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                        Object.keys(errorBody.errors).map(key => {
                            setError(key, { message: errorBody.errors[key][0] });
                        });
                        throw new Error(errorBody.message)
                    }
                )
            )
    })
}
