import { useMutation } from '@tanstack/react-query';
import { type UpdatePointsInComplaintRequest } from '@/stub';
import type { UseFormSetError } from 'react-hook-form';
import { complaintPointsApiClient } from '@/Service/Api/ApiClients/ComplaintPointsApiClient';

type useUpdateComplaintPointsParams = {
    setError?: UseFormSetError<never>
}

export const useUpdateComplaintPoints = ({ setError }: useUpdateComplaintPointsParams = {}) => {
    return useMutation({
        mutationFn: async (data: UpdatePointsInComplaintRequest) => await complaintPointsApiClient.updatePointsInComplaint(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                        Object.keys(errorBody.errors).map(key => {
                            setError(key, { message: errorBody.errors[key][0] });
                        });
                        throw new Error(errorBody.message)
                    }
                )
            )
    })
}
