import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import ContentHeader from '@/components/Blocks/ContentHeader';
import { GetLettersRequest } from "@/stub";
import LettersDatatable from "@/components/Datatables/LettersDatatable";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import { formatToApiDate } from "@/Util/formatToApiDate";

const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }
`;

const LettersPage: React.FC = () => {
    const [dateRangeFilter, setDateRangeFilter] = useState([]);

    const requestParams = useMemo<GetLettersRequest>(() => ({
        due_date_from: formatToApiDate(dateRangeFilter?.[0]) ?? undefined,
        due_date_to: formatToApiDate(dateRangeFilter?.[1]) ?? undefined
    }), [dateRangeFilter]);

    return (
        <div>
            <StyledWrap>
                <ContentHeader
                    title={'Letters'}
                    description={'Forseti Letters Manager'}
                    rightSectionTemplate={
                        <BaseCalendar
                            name='date-range-filter'
                            value={dateRangeFilter}
                            selectionMode='range'
                            dateFormat='dd/mm/yy'
                            placeholder='Select date range'
                            showButtonBar
                            selectOtherMonths
                            readOnlyInput
                            hideOnRangeSelection
                            onChange={(event) => {
                                setDateRangeFilter(event.value);
                            }}
                        />
                    }
                />
                <div className="content-container">
                    <LettersDatatable
                        requestParams={requestParams}
                    />
                </div>
            </StyledWrap>
        </div>
    );
};

export default LettersPage;
