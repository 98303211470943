import { useMutation } from '@tanstack/react-query';
import { customerApiClient } from '@/Service/Api/ApiClients/CustomerApiClient';
import { type UpdateCustomerOperationRequest } from '@/stub';
import { processValidationErrors } from "@/Util/Api/processValidationErrors";

export const useUpdateCustomer = () => {
    return useMutation({
        mutationFn: async (data: UpdateCustomerOperationRequest) => {
            try {
                return await customerApiClient.updateCustomer(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
