/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComplaintCustomerContactType } from './ComplaintCustomerContactType';
import {
    ComplaintCustomerContactTypeFromJSON,
    ComplaintCustomerContactTypeFromJSONTyped,
    ComplaintCustomerContactTypeToJSON,
} from './ComplaintCustomerContactType';
import type { Customer } from './Customer';
import {
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
} from './Customer';

/**
 * 
 * @export
 * @interface ComplaintCustomer
 */
export interface ComplaintCustomer {
    /**
     * 
     * @type {number}
     * @memberof ComplaintCustomer
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintCustomer
     */
    is_primary: boolean;
    /**
     * 
     * @type {ComplaintCustomerContactType}
     * @memberof ComplaintCustomer
     */
    type: ComplaintCustomerContactType;
    /**
     * 
     * @type {Customer}
     * @memberof ComplaintCustomer
     */
    customer: Customer;
}

/**
 * Check if a given object implements the ComplaintCustomer interface.
 */
export function instanceOfComplaintCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "is_primary" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "customer" in value;

    return isInstance;
}

export function ComplaintCustomerFromJSON(json: any): ComplaintCustomer {
    return ComplaintCustomerFromJSONTyped(json, false);
}

export function ComplaintCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'is_primary': json['is_primary'],
        'type': ComplaintCustomerContactTypeFromJSON(json['type']),
        'customer': CustomerFromJSON(json['customer']),
    };
}

export function ComplaintCustomerToJSON(value?: ComplaintCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_primary': value.is_primary,
        'type': ComplaintCustomerContactTypeToJSON(value.type),
        'customer': CustomerToJSON(value.customer),
    };
}

