// Constants file

// Datatables
export const DT_ROWS_NO = 10;
export const DT_SHORT_ROWS_NO = 5;
export const DT_ROWS_PER_PAGE_OPTIONS = [10, 20, 50];
export const DT_SHORT_ROWS_PER_PAGE_OPTIONS = [5, 10, 20];
export const DT_PAGINATOR_TEMPLATE = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown';
export const DT_PAGE_REPORT_TEMPLATE = 'Showing {first} to {last} of {totalRecords} entries';

export const DT_COMPLAINTS_RELOAD_INTERVAL = 5;//seconds

export const CUSTOMER_TITLE_OPTIONS = [
    {
        label: 'Mr',
        value: 'mr',
    },
    {
        label: 'Mrs',
        value: 'mrs',
    },
    {
        label: 'Miss',
        value: 'miss',
    },
    {
        label: 'Ms',
        value: 'ms',
    },
    {
        label: 'Mx',
        value: 'mx',
    },
    {
        label: 'Dr',
        value: 'dr',
    },
    {
        label: 'Sir',
        value: 'sir',
    },
    {
        label: 'Madam',
        value: 'madam',
    },
    {
        label: 'Lord',
        value: 'lord',
    },
    {
        label: 'Lady',
        value: 'lady',
    },
    {
        label: 'Prof',
        value: 'prof',
    },
    {
        label: 'Cpt',
        value: 'cpt',
    },
];

export const COMPLAINT_CHANNEL_OPTIONS = [
    {
        label: 'Phone call',
        value: 'phone_call',
    },
    {
        label: 'Letter',
        value: 'letter',
    },
    {
        label: 'Email',
        value: 'email',
    },
    {
        label: 'Website',
        value: 'website',
    },
    {
        label: 'Social media',
        value: 'social_media',
    },
    {
        label: 'FOS',
        value: 'fos',
    },
    {
        label: 'Referred Complaints',
        value: 'referred-complaints',
    },
];

export const MARSHMALLOW_COMPLAINT_CHANNEL_OPTIONS = [
    {
        label: 'Phone call',
        value: 'phone_call',
    },
    {
        label: 'Letter',
        value: 'letter',
    },
    {
        label: 'Email',
        value: 'email',
    },
    {
        label: 'Website',
        value: 'website',
    },
    {
        label: 'Social media',
        value: 'social_media',
    },
    {
        label: 'Third party',
        value: 'third_party',
        options: [
            {
                label: 'National Windscreens',
                value: 'national-windscreens',
            },
            {
                label: 'The AA',
                value: 'the-aa',
            },
            {
                label: 'Winns Solicitors',
                value: 'winns-solicitors',
            },
            {
                label: 'Carpenters',
                value: 'carpenters',
            },
            {
                label: 'MRN',
                value: 'mrn',
            },
            {
                label: 'S&G',
                value: 's-and-g',
            },
            {
                label: 'E2E',
                value: 'e2e',
            },
        ],
    },
    {
        label: 'Aggregator',
        value: 'aggregator',
        options: [
            {
                label: 'Compare the market',
                value: 'compare-the-market',
            },
            {
                label: 'Go Compare',
                value: 'go-compare',
            },
            {
                label: 'Confused.com',
                value: 'confused-com',
            },
            {
                label: 'Quotezone',
                value: 'quotezone',
            },
        ],
    },
    {
        label: 'FOS',
        value: 'fos',
    },
    {
        label: 'Referred Complaints',
        value: 'referred-complaints',
    },
    {
        label: 'Intercom',
        value: 'intercom'
    }
];

export const PERSON_ORGANISATION_OPTIONS = [
    {
        label: 'Person',
        value: 'person',
    },
    {
        label: 'Organisation',
        value: 'organisation',
    }
];

// Max file upload size
export const FILE_UPLOAD_MAX_SIZE = 67108864;

// Flash messages
export const FLASH_MESSAGES_DURATION = 3000;

// Infinite loader
export const IL_ROWS_NO = 15;

export const STATUSES_BOX_SHADOWS = {
    '#BFE2FA': '#8bc8f0',
    '#9BEEFF': '#6ad4e6',
    '#80F9F1': '#5ae6d9',
    '#8DFFCC': '#61cc9f',
    '#BCFF9C': '#87cc6f',
    '#F9F871': '#d5d04c',
    '#8E5090': '#65365b',
    '#5D548A': '#3c3861',
    '#395175': '#27445a',
    '#A3CCB0': '#74a596',
    '#E04662': '#b62f45',
    '#8A4A68': '#623c4a',
    '#94688F': '#66505c',
    '#9B86B2': '#6e6b7a',
};

export const CLAIMANT_OCCUPATION_OPTIONS = [
    {
        label: 'Manual Input...',
        value: 'manual-input',
    },
    {
        label: 'Professional Sportsmen',
        value: 'professional-sportsmen',
    },
    {
        label: 'Professional Sportswoman',
        value: 'professional-sportswoman',
    },
    {
        label: 'Actor',
        value: 'actor',
    },
    {
        label: 'Entertainer',
        value: 'entertainer',
    },
    {
        label: 'Gambler',
        value: 'gambler',
    },
    {
        label: 'Musician',
        value: 'musician',
    },
    {
        label: 'Publican',
        value: 'publican',
    },
    {
        label: 'Journalist',
        value: 'journalist',
    },
    {
        label: 'Convicted with unspent ban',
        value: 'convicted-with-unspent-ban',
    },
];

export const DAMAGE_ROUTE_OPTIONS = [
    {
        label: 'Repair',
        value: 'repair',
    },
    {
        label: 'Total Loss',
        value: 'total-loss',
    },
    {
        label: 'Cash In Lieu',
        value: 'cash-in-lieu',
    },
];

export const OUTCOME_CONSIDERATIONS_OPTIONS = [
    {
        label: 'Uphold',
        value: 'uphold',
    },
    {
        label: 'Not Upheld',
        value: 'not-upheld',
    }
];

export const FINAL_OUTCOME_OPTIONS = [
    'uphold',
    'partial',
    'not-upheld'
];

export const FINAL_RESPONSE_OPTIONS = [
    {
        label: 'FRL',
        value: 'frl',
    },
    {
        label: 'SRC',
        value: 'src',
    }
];

export const CONTACT_FROM_OPTIONS = [
    {
        label: 'Customer',
        value: 'customer',
    },
    {
        label: 'Third party',
        value: 'third_party',
    },
    {
        label: 'Solicitor',
        value: 'solicitor',
    },
    {
        label: 'Company',
        value: 'company',
    },
];

export const COMPLAINT_ALLOWED_EXTENSIONS = [
    "application/drafting",
    "application/msword",
    "application/paintshoppro",
    "application/pdf",
    "application/postscript",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",

    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pict",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/vnd.adobe.photoshop",
    "image/x-icon",
    "image/x-xcf",
    "image/x-raw",
    "image/webp",

    "audio/aac",
    "audio/aiff",
    "audio/alac",
    "audio/amr",
    "audio/basic",
    "audio/flac",
    "audio/mp4",
    "audio/mpeg",
    "audio/ogg",
    "audio/opus",
    "audio/voxware",
    "audio/x-ms-wma",
    "audio/x-raw",
    "audio/x-wav",

    "video/3gpp",
    "video/webm",

    "text/csv",
    "text/plain"
];

export const FOS_CASEFILE_REVIEW_OPTIONS = [
    {
        value: 'challenge_jurisdiction',
        label: 'Challenge Jurisdiction',
    },
    {
        value: 'offer_to_settle',
        label: 'Offer to settle',
    },
    {
        value: 'defend_frl_outcome',
        label: 'Defend FRL outcome',
    }
];

export const FOS_OUTCOME_OPTIONS = [
    {
        value: 'agree_with_business',
        label: 'Agree with business',
    },
    {
        value: 'agree_with_customer',
        label: 'Agree with customer',
    },
];

export const FOS_FINAL_DECISION_REQUESTED_BY_OPTIONS = [
    {
        value: 'customer',
        label: 'Customer',
    },
    {
        value: 'business',
        label: 'Business',
    },
];

export const FOS_FINAL_DECISION_OUTCOME_OPTIONS = [
    {
        value: 'agree_with_business',
        label: 'Agree with business',
    },
    {
        value: 'agree_with_customer',
        label: 'Agree with customer',
    },
];

export const NOTE_FROM_OPTIONS = [
    {
        value: 'internal',
        label: 'Internal',
    },
];

export const NOTE_TO_OPTIONS = [
    {
        value: 'internal',
        label: 'Internal',
    },
];

export const BESPOKE_TYPE_OPTIONS = [
    {
        value: 'letter_ack',
        label: 'Ack Letter',
    },
    {
        value: 'letter_34wk',
        label: '4wk Letter',
    },
    {
        value: 'letter_68wk',
        label: '8wk Letter',
    },
    {
        value: 'letter_final_upheld',
        label: 'Final Letter Upheld',
    },
    {
        value: 'letter_final_not_upheld',
        label: 'Final Letter not Upheld',
    },

];

export const NOTE_TYPE_OPTIONS = [
    {
        value: 'case_update',
        label: 'Case Update',
    },
];

export const COMPLAINT_VIEW_PAGES = ['/', '/outbox', '/fos-inbox', 'fos-outbox'];

export const COMPLAINT_LIST_TYPES = {
    inbox: {
        label: 'Inbox',
        default_filters: {
            'read:all_org_complaints': 'all-unassigned',//system admin
            'view:finances': 'today',//finance
            'read:org_complaints': 'today',//team manager/admin/leader
            'read:complaints': 'my-queue',//complaint logger/handler
        },
        filters_options: [
            {
                key: 'my-queue',
                name: 'My queue',
            },
            {
                key: 'today',
                name: 'Today',
            },
            {
                key: '3wds-today',
                name: '3WDs today',
            },
            // {
            //     key: '3t5-today',
            //     name: '3T5 - Today',
            // },
            {
                key: 'open-complaints',
                name: 'Open complaints',
            },
            {
                key: 'all-unassigned',
                name: 'All unassigned',
            },
            {
                key: 'frls',
                name: 'FRLs',
            },
            // {
            //     key: 'qa-claims',
            //     name: 'QA Claims',
            // },
        ]
    },
    outbox: {
        label: 'Outbox',
        default_filters: {
            'read:complaints': 'my-queue',
        },
        filters_options: [
            {
                key: 'my-queue',
                name: 'My queue',
            }
        ]
    },
    fos_inbox: {
        label: 'FOS inbox',
        default_filters: {
            'read:all_org_complaints': 'all-unassigned',//system admin
            'view:finances': 'today',//finance
            'read:org_complaints': 'today',//team admin/manager
            'read:complaints': 'my-queue',//complaint logger/handler
        },
        filters_options: [
            {
                key: 'my-queue',
                name: 'My queue',
            },
            {
                key: 'today',
                name: 'Today',
            },
            // {
            //     key: 'open-complaints',
            //     name: 'Open FOS complaints',
            // },
            {
                key: 'all-unassigned',
                name: 'All unassigned',
            },
        ]
    },
    fos_outbox: {
        label: 'FOS outbox',
        default_filters: {
            'read:all_org_complaints': 'all-unassigned',//system admin
            'view:finances': 'today',//finance
            'read:org_complaints': 'today',//team admin/manager
            'read:complaints': 'my-queue',//complaint logger/handler
        },
        filters_options: [
            {
                key: 'my-queue',
                name: 'My queue',
            },
            {
                key: 'today',
                name: 'Today',
            },
            // {
            //     key: 'open-complaints',
            //     name: 'Open FOS complaints',
            // },
            {
                key: 'all-unassigned',
                name: 'All unassigned',
            },
        ]
    },
};

export const KPI_SUMMARY_FILTERS = [
    {
        key: 'today',
        name: 'Today',
    },
    {
        key: 'week',
        name: 'Week',
    },
    {
        key: 'month',
        name: 'Month',
    },
    {
        key: 'all',
        name: 'All',
    },
];

export const LETTERS_FILTERS_OPTIONS = [
    {
        key: 'to_send',
        name: 'To Send',
    },
    {
        key: 'all',
        name: 'All',
    }
];
