// User helper

export const getUserAvatar = (user) => {
    return (
        <div className="user-avatar-wrap">
            {user?.profile_photo_path
                ? <img className="avatar" src={user.profile_photo_path} alt="user avatar"/>

                : <div className="avatar-placeholder">
                    <i className="pi pi-user"></i>
                </div>
            }
        </div>
    );
};

/**
 * Get the user's full name
 *
 * @param {object} user data about the user
 *
 * @returns {string} the user's full name
 */
export const getUserFullName = (user) => {
    let fullName = '';

    if (user?.display_name) {
        fullName += user.display_name;
    } else {
        if (user?.firstname) {
            fullName += user.firstname;
        }

        if (user?.middlename) {
            fullName += ' ' + user.middlename;
        }

        if (user?.surname) {
            fullName += ' ' + user.surname;
        }
    }

    return fullName;
};
