import { useMutation } from '@tanstack/react-query';
import { SentBatchLetterRequest } from '@/stub';
import { letterApiClient } from '../../ApiClients/LetterApiClient';

export const useSendBatchLetters = () =>
    useMutation({
        mutationFn: async (data: SentBatchLetterRequest) => await letterApiClient.sentBatchLetter(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    throw new Error(errorBody.message);
                }
                )
            )
    });
