import React from 'react';
import { Button } from 'primereact/button';
import { COMPLAINT_ALLOWED_EXTENSIONS, FILE_UPLOAD_MAX_SIZE } from '@/config/constants';
import { useCreateComplaintChannel } from '@/Service/Api/ApiHooks/ComplaintChannel/useCreateComplaintChannel';
import { useForm } from 'react-hook-form';
import {
    type Complaint,
    CreateComplaintChannelChannelTypeEnum,
    CreateComplaintChannelContactTypeEnum,
    type CreateComplaintChannelRequest
} from '@/stub';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Divider } from 'primereact/divider';
import { useToastMessagesStore } from '@/Stores/ToastMessagesStore';
import { Dialog } from 'primereact/dialog';
import { useQueryClient } from '@tanstack/react-query';
import { ComplaintContactAddedMessage } from "@/Messages/Toast/ComplaintContacts/ComplaintContactAddedMessage";
import ComplaintChannelSelect from "@/components/Core/Form/Selector/ComplaintChannelSelect";
import { fieldsPlaceholders } from "@/config/forms";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { formatToApiDate } from "@/Util/formatToApiDate";

export type AddContactModalProps = {
    complaint?: Complaint
    visible: boolean
    setVisible: (visible: boolean) => void
};

const FileSchema = z.instanceof(File)
    .refine((file?: File) => !file || file.size <= FILE_UPLOAD_MAX_SIZE, `Max upload size is ${FILE_UPLOAD_MAX_SIZE / 1_000_000}`)
    .refine((file?: File) => !file || COMPLAINT_ALLOWED_EXTENSIONS.includes(file.type ?? ''), 'File format not supported');

const AddContactSchema = z.object({
    channel: z.object({
        due_date: z.date().nullable().optional(),
        content: z.string().optional(),
        contact_type: z.string(),
        files: z.array(FileSchema).optional(),
        from: z.string().optional(),
        to: z.string().optional(),
        description: z.string().optional(),
        channel_type: z.string(),
        created_at: z.date().optional(),
    })
});

type AddContactFormData = z.infer<typeof AddContactSchema>;
const minDate = new Date();

const AddContactModal: React.FC<AddContactModalProps> = ({ visible, setVisible, complaint }: AddContactModalProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors }
    } = useForm<AddContactFormData>({
        resolver: zodResolver(AddContactSchema),
        defaultValues: {
            channel: {
                due_date: minDate,
                contact_type: CreateComplaintChannelContactTypeEnum.Contact,
                channel_type: CreateComplaintChannelChannelTypeEnum.Email,
                content: '',
                from: '',
                to: '',
                description: ''
            }
        }
    });

    const createComplaintChannelMutation = useCreateComplaintChannel({ setError });

    const validate = (data: AddContactFormData) => {
        return AddContactSchema.safeParse(data).success;
    };

    const onSubmit = async (data: AddContactFormData) => {
        if (validate(data)) {
            const dataToSend: CreateComplaintChannelRequest = {
                ...data.channel,
                contact_type: CreateComplaintChannelContactTypeEnum.Contact,
                complaint_id: complaint.id,
                due_date: formatToApiDate(data.channel.due_date),
            };

            await createComplaintChannelMutation.mutateAsync(dataToSend, {
                onSuccess: () => {
                    reset();
                    void Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.complaints._def
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.channels.list({ complaint_id: complaint?.id }).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.documents.list({ complaint_id: complaint?.id }).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                        })
                    ]);
                    setVisible(false);
                    addToastMessage(ComplaintContactAddedMessage);
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            });
        }
    };

    const onHide = (): void => {
        setVisible(false);
    };

    const modalContent = (
        <form id="add-contact" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column row-gap-3">
                <Divider/>

                <BaseCalendar
                    control={control}
                    label="Next action date"
                    name='channel.due_date'
                    minDate={minDate}
                    placeholder={fieldsPlaceholders.date}
                    errorMessages={errors?.channel?.due_date?.message}
                />

                <ComplaintChannelSelect
                    control={control}
                    errors={errors}
                    multipleFilesUpload
                />
            </div>
        </form>
    )
    ;

    const widgetFooter = (
        <>
            <Divider/>
            <div className="text-right">
                <Button
                    type='submit'
                    form='add-contact'
                    loading={createComplaintChannelMutation.isPending}
                    label="Add Contact"
                    severity="success"
                />
            </div>
        </>
    );

    return (
        <Dialog
            id='add-contact-dialog'
            header='Add a new contact'
            className='contact-modal'
            visible={visible}
            onHide={onHide}
            footer={widgetFooter}
            blockScroll
            draggable={false}
        >
            <div className='w-full'>
                {modalContent}
            </div>
        </Dialog>
    );
};

export default AddContactModal;
