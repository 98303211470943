type TreeLike<TValue> = {
    id: number | string
    children?: TValue[]
};

export const findTreeNode = <TValue extends TreeLike<TValue>>(nodesList: TValue[], key: number | string): TValue | undefined => {
    let result: TValue | undefined = undefined;
    for (const node of nodesList) {
        if (node.id === key) {
            result = node;
            break;
        }
        if (node.children) {
            result = findTreeNode<TValue>(node.children, key);
            if (result) {
                break;
            }
        }
    }
    return result;
};
