import { useMutation } from '@tanstack/react-query';
import { type UpdateComplaintOperationRequest } from '@/stub';
import { complaintsApiClient } from '@/Service/Api/ApiClients/ComplaintsApiClient';
import type { UseFormSetError } from 'react-hook-form';

interface useUpdateComplaintParams {
    setError?: UseFormSetError<never>
}

export const useUpdateComplaint = ({ setError }: useUpdateComplaintParams) =>
    useMutation({
        mutationFn: async (data: UpdateComplaintOperationRequest) => await complaintsApiClient.updateComplaint(data).catch(error => error.response.json()
            .then((errorBody) => {
                    Object.keys(errorBody.errors).map(key => {
                        setError(key, { message: errorBody.errors[key][0] });
                    });
                    throw new Error(errorBody.message)
                }
            )
        )
    })
