import React from 'react';
import { FilterOptions } from "@/Enums/FilterOptions";
import { ComplaintStateEnum } from "@/stub";
import { SelectItem } from "primereact/selectitem";
import FiltersList, { FilterOption } from "@/components/Filters/FiltersList";

export type InboxFiltersListProps = {
    className?: string
};

const statusLabels = {
    Investigated: 'Under investigation',
    Resolved: 'Closed - pending FRL',
    Closed: 'Closed'
};

export const availableStatuses = (): SelectItem[] => {
    const excludedStatuses = [ComplaintStateEnum.Created];
    return Object.entries(ComplaintStateEnum)
        .filter(([, value]) => !excludedStatuses.includes(value))
        .map(([key, value]) => ({
            value: value,
            label: statusLabels[key] || key
        }));
};


//Declare here all possible filters that you want to see on the page,
//then needed permissions will be checked to see if filter can be displayed or not for current authenticated user
const allAvailableInboxPresets: FilterOption[] = [
    {
        type: FilterOptions.DateRange,
        value: 'date_range'
    },
    {
        type: FilterOptions.Customer,
        value: 'customer'
    },
    {
        type: FilterOptions.Owner,
        value: 'owner'
    },
    {
        type: FilterOptions.RootCause,
        value: 'root_cause_id'
    },
    {
        type: FilterOptions.Product,
        value: 'product_id'
    },
    {
        type: FilterOptions.Status,
        value: 'status'
    }
];

const InboxFiltersList: React.FC<InboxFiltersListProps> = ({
    className
}: InboxFiltersListProps) => {
    return <FiltersList
        className={className}
        availableStatuses={availableStatuses()}
        filterOptions={allAvailableInboxPresets}
    />;
};

export default InboxFiltersList;
