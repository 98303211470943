import React from 'react';
import { Complaint, ComplaintInvestigation, ComplaintPoints } from '@/stub';
import { Button } from 'primereact/button';
import ErrorsBlock from "@/components/Core/Form/ErrorsBlock";
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import { FieldArrayWithId } from "react-hook-form";
import ComplaintOutcomeInputForm from "@/components/Core/Investigation/ComplaintOutcomeInputForm";
import { isReadonlyComplaint } from "@/Util/permissionChecks";

export type ComplaintOutcomeFormProps = BaseFormInputProps & {
    complaint: Complaint
    outcomeFields: FieldArrayWithId<ComplaintInvestigation, "outcomes", "id">[]
    addOutcome: () => void
    removeOutcome: (index: number) => void
    availablePoints: ComplaintPoints[]
};

const ComplaintOutcomeForm: React.FC<ComplaintOutcomeFormProps> = ({
    complaint,
    control,
    outcomeFields,
    errorMessages,
    addOutcome,
    removeOutcome,
    availablePoints
}: ComplaintOutcomeFormProps) => {
    return <div className="mb-5">
        <h4 className="font-semibold">Outcome Considerations</h4>
        <ErrorsBlock
            className="block p-error pt-0"
            errorMessages={errorMessages?.outcomes?.message}
        />
        <div>
            {outcomeFields.map((outcome, index) => (
                <ComplaintOutcomeInputForm
                    key={outcome.id}
                    availablePoints={availablePoints}
                    complaint={complaint}
                    index={index}
                    errorMessages={errorMessages}
                    control={control}
                    removeOutcome={removeOutcome}
                />
            ))}
            <Button
                className='p-button-outlined p-button-secondary p-button-sm'
                label="Add outcome consideration"
                icon="pi pi-plus"
                disabled={isReadonlyComplaint(complaint)}
                onClick={(event) => {
                    event.preventDefault();
                    addOutcome();
                }}
            />
        </div>

    </div>;
};

export default ComplaintOutcomeForm;
