import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { setDocumentTitle } from "@/helpers/general";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import ContentHeader from "@/components/Blocks/ContentHeader";
import styled from "styled-components";
import OrganisationUserDatable from "@/components/Datatables/OrganisationUserDatable";
import { useDebounce } from "react-use";
import { GetUsersFromOrganisationRequest } from "@/stub";


const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;


export type OrganisationUsersPageProps = {
    className?: string
};

/**
 * Organisation Users Page
 */
const OrganisationUsersPage: React.FC = ({ className }: OrganisationUsersPageProps) => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>(searchInput);

    useDebounce(
        () => {
            setDebouncedSearchInput(searchInput);
        },
        600,
        [searchInput]
    );
    useEffect(() => {
        setDocumentTitle('Organisation Users');
    }, []);

    const requestParams = useMemo<GetUsersFromOrganisationRequest>(() => {

        return {
            search: debouncedSearchInput ?? undefined
        };
    }, [debouncedSearchInput]);

    return (
        <div>
            <StyledWrap className={className}>
                <ContentHeader
                    title={'Organisation Users'}
                    description={'Forseti Complaints Manager'}
                    rightSectionTemplate={
                        <BaseTextInput
                            value={searchInput || ''}
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                        />
                    }
                />
            </StyledWrap>

            <div className="content-container">
                <OrganisationUserDatable requestParams={requestParams} />
            </div>
        </div>
    );
};

export default withAuthenticationRequired(OrganisationUsersPage);
