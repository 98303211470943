import React from 'react';
import { FilterOptions } from "@/Enums/FilterOptions";

import FiltersList, { FilterOption } from "@/components/Filters/FiltersList";

export type OutboxFiltersListProps = {
    className?: string
};

//Declare here all possible filters that you want to see on the page,
//then needed permissions will be checked to see if filter can be displayed or not for current authenticated user
const allAvailableInboxPresets: FilterOption[] = [
    {
        type: FilterOptions.DateRange,
        value: 'date_range'
    },
    {
        type: FilterOptions.Customer,
        value: 'customer'
    },
    {
        type: FilterOptions.Owner,
        value: 'owner'
    },
    {
        type: FilterOptions.RootCause,
        value: 'root_cause_id'
    },
    {
        type: FilterOptions.Product,
        value: 'product_id'
    },
];

const OutboxFiltersList: React.FC<OutboxFiltersListProps> = ({
    className
}: OutboxFiltersListProps) => {

    return <FiltersList
        className={className}
        filterOptions={allAvailableInboxPresets}
    />;
};

export default OutboxFiltersList;
