/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ComplaintCustomerContactType = {
    Customer: 'customer',
    Company: 'company',
    ThirdParty: 'third_party',
    Solicitor: 'solicitor'
} as const;
export type ComplaintCustomerContactType = typeof ComplaintCustomerContactType[keyof typeof ComplaintCustomerContactType];


export function ComplaintCustomerContactTypeFromJSON(json: any): ComplaintCustomerContactType {
    return ComplaintCustomerContactTypeFromJSONTyped(json, false);
}

export function ComplaintCustomerContactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintCustomerContactType {
    return json as ComplaintCustomerContactType;
}

export function ComplaintCustomerContactTypeToJSON(value?: ComplaintCustomerContactType | null): any {
    return value as any;
}

