import { useSearchParams } from "react-router-dom";


export const InboxFilterType = {
    CustomerId: 'customer_id',
    OwnerId: 'assigned_to_id',
    States: 'states',
    Presets: 'presets',
    RootCauseId: 'root_cause_id',
    ProductId: 'product_id',
    DateRangeTo: 'date_range_to',
    DateRangeFrom: 'date_range_from',
    Search: 'search',
    Page: 'page',
    PageSize: 'page_size',
    SortField: 'sort_field',
    SortOrder: 'sort_order',
} as const;

export type InboxFilterType = typeof InboxFilterType[keyof typeof InboxFilterType];


export default function useGetInboxFiltersQuery() {
    const [searchParams] = useSearchParams();

    const customerQuery = searchParams.get(InboxFilterType.CustomerId);
    const ownerQuery = searchParams.get(InboxFilterType.OwnerId);
    const statesQuery = searchParams.get(InboxFilterType.States);
    const presetsQuery = searchParams.get(InboxFilterType.Presets);
    const rootCauseIdQuery = searchParams.get(InboxFilterType.RootCauseId);
    const productIdQuery = searchParams.get(InboxFilterType.ProductId);
    const dataRangeQueryTo = searchParams.get(InboxFilterType.DateRangeTo);
    const dataRangeQueryFrom = searchParams.get(InboxFilterType.DateRangeFrom);
    const searchQuery = searchParams.get(InboxFilterType.Search);
    const pageQuery = searchParams.get(InboxFilterType.Page);
    const pageSizeQuery = searchParams.get(InboxFilterType.PageSize);
    const sortFieldQuery = searchParams.get(InboxFilterType.SortField);
    const sortOrderQuery = searchParams.get(InboxFilterType.SortOrder);

    return {
        sortOrderQuery,
        sortFieldQuery,
        pageSizeQuery,
        pageQuery,
        searchQuery,
        dataRangeQueryFrom,
        dataRangeQueryTo,
        productIdQuery,
        rootCauseIdQuery,
        presetsQuery,
        statesQuery,
        ownerQuery,
        customerQuery,
    };
}
