import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import PublicPageLayout from '../themes/default/layouts/PublicPageLayout';
import { setDocumentTitle } from '../helpers/general';
import {useAuth0} from "@auth0/auth0-react";

/**
 * Site's about us page
 */
const AboutUsPage = () => {
    const { isAuthenticated} = useAuth0();
    useEffect(() => {
        setDocumentTitle('About Us');
    }, []);

    const pageContent = (
        <div className="footer-pages min-height ml-6 mt-5">
            <h1>Bridgetech Group</h1>

            <p>Bridgetech Group includes these entities.</p>
            <p>Our clients may deal with any of them securely, knowing that the ethos, values and standards traditionally associated with Bridgetech Group are common to them all.</p>

            <ul>
                <li>Bridgetech Group - <a href="https://bridgetechgroup.co.uk/" target="_blank">HOME (bridgetechgroup.co.uk)</a></li>
                <li>Bridgetech Connect - <a href="https://bridgetechconnect.com/" target="_blank">Bridgetech Connect</a></li>
                <li>Bridgetech Automotive - <a href="https://bridgetechautomotive.co.uk/" target="_blank">Home - Bridgetech Automotive</a></li>
            </ul>
        </div>
    );

    return isAuthenticated ? (
        <>{pageContent}</>
    ) : (
        <PublicPageLayout>
            <Card>{pageContent}</Card>
        </PublicPageLayout>
    );
};

export default AboutUsPage;
