import { Column } from 'primereact/column';
import { useContext, useEffect, useState } from 'react';
import DatatableTemplate from '../templates/DatatableTemplate';
import { useGetUserBreakdown } from '@/Service/Api/ApiHooks/Reports/useGetUserBreakdown';
import { useAuthStore } from '@/Stores/AuthStore';
import { AppContext } from "@/components/context/AppContext";

/**
 * KPI Work in progress datatable
 */
const KpiWorkInProgressDatatable = () => {
    const {
        organisationId
    } = useContext(AppContext);
    const authUser = useAuthStore((state) => state.authUser);

    const [usersReports, setUsersReports] = useState([]);
    const [requestParams, setRequestParams] = useState({
        organisation_id: organisationId ?? authUser?.organisation_id
    });

    /**
     * The header of the datatable
     */
    const header = () => {
        return (
            <div className="relative">
                <div className="page-header flex justify-content-between">
                    <div>
                        <h3 className="page-title">KPI Work In Progress</h3>
                        <span className="page-subtitle">Forseti Complaints Manager</span>
                    </div>
                </div>
            </div>
        );
    };

    const {
        data: queryData,
        isLoading: loading
    } = useGetUserBreakdown(requestParams);

    useEffect(() => {
        if (queryData) {
            setUsersReports(queryData);
        }

    }, [queryData]);

    return (
        <div className="wip-datatables-wrap">
            <DatatableTemplate
                items={usersReports}
                header={header}
                loading={loading}
                emptyMessage="No reports found."
                customProps={{
                    className: 'reports-datatable short-dt mb-5',
                    dataKey: 'name'
                }}
                paginator={false}
            >
                <Column
                    className="column-name"
                    field="name"
                    header="Name"
                />
                <Column
                    className="column-assigned"
                    field="assigned"
                    header="Assigned"
                />
                <Column
                    className="column-under-investigation"
                    field="investigated"
                    header="Under investigation"
                />
                <Column
                    className="column-pending-frl-src"
                    field="resolved"
                    header="Closed - pending FRL"
                />
                <Column
                    className="column-closed"
                    field="closed"
                    header="Closed"
                />
            </DatatableTemplate>
        </div>
    );
};

export default KpiWorkInProgressDatatable;
