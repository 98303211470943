import React, { useId } from 'react';
import { Controller } from 'react-hook-form';
import { TreeSelect, type TreeSelectProps } from 'primereact/treeselect';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';
import { fieldsPlaceholders } from "@/config/forms";

export type BaseTreeSelectProps = TreeSelectProps & BaseFormInputProps;

const BaseTreeSelect: React.FC<BaseTreeSelectProps> = ({
    control,
    label,
    errorMessages,
    ...props
}: BaseTreeSelectProps) => {
    const localId = useId();

    return (
        <div>
            {label && <label className="block mb-2" htmlFor={localId}>{label}{props.required ? '*' : ''}</label>}
            {
                control && props.name &&
                <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <TreeSelect
                            id={localId}
                            name={field.name}
                            invalid={fieldState.invalid}
                            value={field.value}
                            disabled={field.disabled}
                            placeholder={props.placeholder ?? fieldsPlaceholders.dropdown}
                            variant={props.variant ?? 'filled'}
                            onChange={(e) => {
                                field.onChange(e.value);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            {...props}
                        />
                    )}
                />
            }
            {
                !control &&
                <TreeSelect
                    id={localId}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseTreeSelect;
