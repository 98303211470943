/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChannelFieldConfig
 */
export interface ChannelFieldConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ChannelFieldConfig
     */
    display: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChannelFieldConfig
     */
    label?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChannelFieldConfig
     */
    options?: Array<string> | null;
}

/**
 * Check if a given object implements the ChannelFieldConfig interface.
 */
export function instanceOfChannelFieldConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "display" in value;

    return isInstance;
}

export function ChannelFieldConfigFromJSON(json: any): ChannelFieldConfig {
    return ChannelFieldConfigFromJSONTyped(json, false);
}

export function ChannelFieldConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelFieldConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'display': json['display'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'options': !exists(json, 'options') ? undefined : json['options'],
    };
}

export function ChannelFieldConfigToJSON(value?: ChannelFieldConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display': value.display,
        'label': value.label,
        'options': value.options,
    };
}

