import React, { useMemo } from 'react';
import { Button } from 'primereact/button';
import { InboxPresetOptions } from '@/Enums/InboxPresetOptions';
import { useAuthStore } from "@/Stores/AuthStore";
import { checkPermission } from "@/Util/permissionChecks";
import ComplaintsReportModal from "@/components/Modals/ComplaintsReportModal";

export type InboxFilterPresetsListProps = {
    value?: InboxPresetOptions
    onChange: (presetName: InboxPresetOptions) => void
};

const InboxFilterPresetsList: React.FC<InboxFilterPresetsListProps> = ({
    value,
    onChange
}: InboxFilterPresetsListProps) => {
    const authUser = useAuthStore((state) => state.authUser);
    const impersonatedOrgId = useAuthStore((state) => state.impersonatedOrgId);

    const availableInboxPresets = useMemo<InboxPresetOptions[]>(() => {
        let options: InboxPresetOptions[] = [];
        if (!impersonatedOrgId) {
            options.push(InboxPresetOptions.MyQueue);
        }
        options = options.concat([
            InboxPresetOptions.Today,
            InboxPresetOptions.ThreeWDsToday
        ]);
        if (checkPermission(authUser?.permissions, 'read:org_complaint_detail')) {
            options.push(InboxPresetOptions.OpenComplaints);
        }
        options.push(InboxPresetOptions.FRLs);
        options.push(InboxPresetOptions.All);
        return options;
    }, [authUser?.permissions, impersonatedOrgId]);
    return (
        <div className="flex gap-2 flex-wrap">
            {
                availableInboxPresets.map(
                    presetName => <Button
                        key={presetName}
                        label={presetName}
                        outlined={value !== presetName}
                        onClick={() => {
                            onChange?.(presetName);
                        }}
                    />
                )
            }
            {checkPermission(authUser?.permissions, 'read:full_report')  && <ComplaintsReportModal/> }
        </div>
    );
};

export default InboxFilterPresetsList;
