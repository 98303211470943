import React from 'react';
import { Complaint } from "@/stub";
import FilesDatatable from "@/components/Datatables/FilesDatatable";

export type FilesProps = {
    complaint: Complaint
};

const Files: React.FC<FilesProps> = ({ complaint }: FilesProps) => {
    return <FilesDatatable complaint={complaint}/>;
};

export default Files;
