import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "primereact/button";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <Button
        className="w-full block"
        label="Login"
        onClick={() => loginWithRedirect()}
    />;
};

export default LoginButton;
