import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
    GetFcaRmarReportPdfRequest,
    GetFcaRmarReportRequest,
    GetFinalRootCausesReportRequest,
    GetInitialRootCausesReportRequest,
    GetSummaryBreachesReportRequest,
    GetSummaryFosReportRequest,
    GetSummaryGeneralReportRequest,
    GetUserBreakdownReportRequest
} from '@/stub';

export const Reports = createQueryKeys('reports', {
    breaches: (requestParams?: GetSummaryBreachesReportRequest) => [{ requestParams }],
    fos: (requestParams?: GetSummaryFosReportRequest) => [{ requestParams }],
    general: (requestParams?: GetSummaryGeneralReportRequest) => [{ requestParams }],
    userBreakdown: (requestParams?: GetUserBreakdownReportRequest) => [{ requestParams }],
    rootCauseFinal: (requestParams?: GetFinalRootCausesReportRequest) => [{ requestParams }],
    rootCauseInitial: (requestParams?: GetInitialRootCausesReportRequest) => [{ requestParams }],
    fcaRmar: (requestParams?: GetFcaRmarReportRequest) => [{ requestParams }],
    fcaRmarPdf: (requestParams?: GetFcaRmarReportPdfRequest) => [{ requestParams }],
});
