import { useMutation } from '@tanstack/react-query';
import { UpdateComplaintChannelRequest } from '@/stub';
import { ComplaintChannelsApiClient } from '../../ApiClients/ComplaintChannelsApiClient';
import type { UseFormSetError } from "react-hook-form";

interface useUpdateComplaintChannelParams {
    setError?: UseFormSetError<never>
}

export const useUpdateComplaintChannel = ({ setError }: useUpdateComplaintChannelParams) =>
    useMutation({
        mutationFn: async (data: UpdateComplaintChannelRequest) => await ComplaintChannelsApiClient.updateComplaintChannel(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    Object.keys(errorBody.errors).map(key => {
                        setError(key, { message: errorBody.errors[key][0] });
                    });
                    throw new Error(errorBody.message);
                }
                )
            )
    });
