/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LetterType = {
    Ack: 'letter_ack',
    _34wk: 'letter_34wk',
    _68wk: 'letter_68wk',
    FinalUpheld: 'letter_final_upheld',
    FinalNotUpheld: 'letter_final_not_upheld',
    Src: 'letter_src'
} as const;
export type LetterType = typeof LetterType[keyof typeof LetterType];


export function LetterTypeFromJSON(json: any): LetterType {
    return LetterTypeFromJSONTyped(json, false);
}

export function LetterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LetterType {
    return json as LetterType;
}

export function LetterTypeToJSON(value?: LetterType | null): any {
    return value as any;
}

