import { useQuery } from '@tanstack/react-query';
import { authUserApiClient } from '@/Service/Api/ApiClients/AuthUserApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetAuthUserParams = {
    enabled?: boolean
    retry?: boolean | number
};
export const useGetAuthUser = ({ enabled, retry }: useGetAuthUserParams) => useQuery({
    ...QueryKeys.authUser.detail,
    queryFn: async () => await authUserApiClient.getAuthUser(),
    enabled,
    retry
});
