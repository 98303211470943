/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BreachesReportResource,
  FcaRmarReportResource,
  FosReportResource,
  GeneralReportResource,
  ProductReportResource,
  RootCauseFinalReport,
  RootCauseInitialReport,
  UserBreakdownResource,
} from '../models/index';
import {
    BreachesReportResourceFromJSON,
    BreachesReportResourceToJSON,
    FcaRmarReportResourceFromJSON,
    FcaRmarReportResourceToJSON,
    FosReportResourceFromJSON,
    FosReportResourceToJSON,
    GeneralReportResourceFromJSON,
    GeneralReportResourceToJSON,
    ProductReportResourceFromJSON,
    ProductReportResourceToJSON,
    RootCauseFinalReportFromJSON,
    RootCauseFinalReportToJSON,
    RootCauseInitialReportFromJSON,
    RootCauseInitialReportToJSON,
    UserBreakdownResourceFromJSON,
    UserBreakdownResourceToJSON,
} from '../models/index';

export interface GetFcaRmarReportRequest {
    from?: string;
    to?: string;
    organisation_id?: number;
}

export interface GetFinalRootCausesReportRequest {
    from?: string;
    to?: string;
    organisation_id?: number;
}

export interface GetInitialRootCausesReportRequest {
    from?: string;
    to?: string;
    organisation_id?: number;
}

export interface GetProductReportRequest {
    from?: string;
    to?: string;
    organisation_id?: number;
}

export interface GetSummaryBreachesReportRequest {
    from?: string;
    to?: string;
    organisation_id?: number;
}

export interface GetSummaryFosReportRequest {
    from?: string;
    to?: string;
    organisation_id?: number;
}

export interface GetSummaryGeneralReportRequest {
    from?: string;
    to?: string;
    organisation_id?: number;
}

export interface GetUserBreakdownReportRequest {
    organisation_id?: number;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * Get FcaRmar Report
     */
    async getFcaRmarReportRaw(requestParameters: GetFcaRmarReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FcaRmarReportResource>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/fca-rmar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FcaRmarReportResourceFromJSON));
    }

    /**
     * Get FcaRmar Report
     */
    async getFcaRmarReport(requestParameters: GetFcaRmarReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FcaRmarReportResource>> {
        const response = await this.getFcaRmarReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Report by Final RootCauses
     */
    async getFinalRootCausesReportRaw(requestParameters: GetFinalRootCausesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RootCauseFinalReport>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/root-cause-final`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RootCauseFinalReportFromJSON));
    }

    /**
     * Get Report by Final RootCauses
     */
    async getFinalRootCausesReport(requestParameters: GetFinalRootCausesReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RootCauseFinalReport>> {
        const response = await this.getFinalRootCausesReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Report by Initial RootCauses
     */
    async getInitialRootCausesReportRaw(requestParameters: GetInitialRootCausesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RootCauseInitialReport>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/root-cause-initial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RootCauseInitialReportFromJSON));
    }

    /**
     * Get Report by Initial RootCauses
     */
    async getInitialRootCausesReport(requestParameters: GetInitialRootCausesReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RootCauseInitialReport>> {
        const response = await this.getInitialRootCausesReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Product Report
     */
    async getProductReportRaw(requestParameters: GetProductReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductReportResource>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/product`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductReportResourceFromJSON));
    }

    /**
     * Get Product Report
     */
    async getProductReport(requestParameters: GetProductReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductReportResource>> {
        const response = await this.getProductReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get summary Breaches report
     */
    async getSummaryBreachesReportRaw(requestParameters: GetSummaryBreachesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BreachesReportResource>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/summary-breaches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BreachesReportResourceFromJSON));
    }

    /**
     * Get summary Breaches report
     */
    async getSummaryBreachesReport(requestParameters: GetSummaryBreachesReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BreachesReportResource>> {
        const response = await this.getSummaryBreachesReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get summary fos report
     */
    async getSummaryFosReportRaw(requestParameters: GetSummaryFosReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FosReportResource>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/summary-fos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FosReportResourceFromJSON));
    }

    /**
     * Get summary fos report
     */
    async getSummaryFosReport(requestParameters: GetSummaryFosReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FosReportResource>> {
        const response = await this.getSummaryFosReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get summary general report
     */
    async getSummaryGeneralReportRaw(requestParameters: GetSummaryGeneralReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GeneralReportResource>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/summary-general`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeneralReportResourceFromJSON));
    }

    /**
     * Get summary general report
     */
    async getSummaryGeneralReport(requestParameters: GetSummaryGeneralReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GeneralReportResource>> {
        const response = await this.getSummaryGeneralReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Breakdown Report
     */
    async getUserBreakdownReportRaw(requestParameters: GetUserBreakdownReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserBreakdownResource>>> {
        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/user-breakdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBreakdownResourceFromJSON));
    }

    /**
     * Get User Breakdown Report
     */
    async getUserBreakdownReport(requestParameters: GetUserBreakdownReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserBreakdownResource>> {
        const response = await this.getUserBreakdownReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
