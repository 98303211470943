import {Calendar} from 'primereact/calendar';
import {classNames} from 'primereact/utils';
import React, {useEffect, useState} from 'react';
import InfoTooltipTemplate from '../InfoTooltipTemplate';

/**
 * A customizable date input template
 */
const DateInputTemplate = ({
                               appendTo = "self",
                               name,
                               label,
                               disabled,
                               className,
                               labelClassName,
                               floatLabel,
                               noMargins,
                               bottomMargin,
                               inline,
                               value,
                               placeholder,
                               onChangeFunction,
                               isValid,
                               errorMessage,
                               category,
                               infoMessage,
                               selectionMode = 'single',
                               formData,
                               setFormData,
                               extraOptions,
                               showTime,
                               readOnlyInput
                           }) => {
    const [inputClasses, setInputClasses] = useState(classNames({
        'p-invalid': isValid,
        'w-full': !inline,
    }));
    const [labelClasses, setLabelClasses] = useState(classNames({
        'p-error': isValid,
        'w-full': !inline,
        'block mb-2': !inline,
        'inline-block mr-3': inline,
        'pr-4': infoMessage
    }));

    useEffect(() => {
        // add input classes (if we have any)
        if (className) {
            setInputClasses(inputClasses + ' ' + className);
        }

        // add label classes (if we have any)
        if (labelClassName) {
            setLabelClasses(labelClasses + ' ' + labelClassName);
        }
    }, []);

    const inputField = <div className={'relative ' + (inline ? 'inline-flex' : '')}>
        <Calendar appendTo={appendTo} id={name} inputId={name+"-input"} value={value}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={(e) => {
                      onChangeFunction(name, e.value, category, formData, setFormData);
                  }}
                  className={inputClasses}
                  selectOtherMonths
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  selectionMode={selectionMode}
                  maxDate={extraOptions?.maxDate || null}
                  minDate={extraOptions?.minDate || null}
                  showTime={showTime}
                  readOnlyInput={readOnlyInput}
        />
        <div className="calendar-icon-wrap">
            <i className="pi pi-chevron-down"></i>
        </div>
    </div>;

    return (
        <div className={(!noMargins ? ('mb-' + (bottomMargin || '5')) : '') + (infoMessage ? ' relative' : '')}>
            {floatLabel
                ? <>
                    <span className="p-float-label">
                        {inputField}
                        {label && <label htmlFor={name} className={labelClasses} title={labelClassName == 'one-line' ? label : null}>{label}</label>}
                        {infoMessage && <div className="top-right-icon">
                            <InfoTooltipTemplate target={'tooltip-' + name} message={infoMessage} className="ml-2"/>
                        </div>}
                    </span>

                    {errorMessage}
                </>

                : <span className={inline ? 'inline-input-wrap flex align-items-center' : ''}>
                    {label && <label htmlFor={name} className={labelClasses} title={labelClassName == 'one-line' ? label : null}>{label}</label>}
                    {infoMessage && <div className="top-right-icon">
                        <InfoTooltipTemplate target={'tooltip-' + name} message={infoMessage} className="ml-2"/>
                    </div>}
                    <div className="input-wrap">
                        {inputField}
                        {errorMessage}
                    </div>
                </span>
            }
        </div>
    );
};

export default DateInputTemplate;
