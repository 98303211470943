import { useQuery } from '@tanstack/react-query';
import { customerApiClient } from '@/Service/Api/ApiClients/CustomerApiClient';
import { type GetCustomerRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export interface useGetCustomerParams {
    enabled?: boolean
    requestParams: GetCustomerRequest
    select?
}

export const useGetCustomer = ({
    enabled = true,
    select,
    requestParams
}: useGetCustomerParams) => useQuery({
    ...QueryKeys.customers.detail(requestParams.customer_id),
    queryFn: async () => await customerApiClient.getCustomer(requestParams),
    enabled,
    select
});
