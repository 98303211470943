/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ComplaintShortResource } from './ComplaintShortResource';
import {
    instanceOfComplaintShortResource,
    ComplaintShortResourceFromJSON,
    ComplaintShortResourceFromJSONTyped,
    ComplaintShortResourceToJSON,
} from './ComplaintShortResource';

/**
 * @type BespokeResourceComplaint
 * 
 * @export
 */
export type BespokeResourceComplaint = ComplaintShortResource;

export function BespokeResourceComplaintFromJSON(json: any): BespokeResourceComplaint {
    return BespokeResourceComplaintFromJSONTyped(json, false);
}

export function BespokeResourceComplaintFromJSONTyped(json: any, ignoreDiscriminator: boolean): BespokeResourceComplaint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ComplaintShortResourceFromJSONTyped(json, true) };
}

export function BespokeResourceComplaintToJSON(value?: BespokeResourceComplaint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfComplaintShortResource(value)) {
        return ComplaintShortResourceToJSON(value as ComplaintShortResource);
    }

    return {};
}

