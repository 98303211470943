import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type GetComplaintArchiveRequest } from '@/stub';
import { complaintsApiClient } from '../../ApiClients/ComplaintsApiClient';
import { downloadBlob } from '../../../../Util/downloadBlob';

export const useDownloadComplaintArchive = (): UseMutationResult => useMutation({
    mutationFn: async (requestParams: GetComplaintArchiveRequest) => {
        const complaintArchiveRaw = await complaintsApiClient.getComplaintArchiveRaw(requestParams);
        const complaintArchive = await complaintArchiveRaw.value();

        if (complaintArchive) {
            downloadBlob(complaintArchive, 'complaint-archive-' + requestParams?.complaint_id);
        }
    }
});
