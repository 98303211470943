import { useMutation } from "@tanstack/react-query";
import { CreateRootCauseOperationRequest } from "@/stub";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";
import { rootCauseApiClient } from "@/Service/Api/ApiClients/RootCauseApiClient";

export const useCreateRootCause = () => {
    return useMutation({
        mutationFn: async (data: CreateRootCauseOperationRequest) => {
            try {
                return await rootCauseApiClient.createRootCause(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
