/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportKPISummaryReportRequest,
} from '../models/index';
import {
    ExportKPISummaryReportRequestFromJSON,
    ExportKPISummaryReportRequestToJSON,
} from '../models/index';

export interface ExportFullComplaintsListRequest {
    ExportKPISummaryReportRequest?: ExportKPISummaryReportRequest;
}

/**
 * 
 */
export class ExportComplaintApi extends runtime.BaseAPI {

    /**
     * Export Full Complaints List
     */
    async exportFullComplaintsListRaw(requestParameters: ExportFullComplaintsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/export-complaints`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportKPISummaryReportRequestToJSON(requestParameters.ExportKPISummaryReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Export Full Complaints List
     */
    async exportFullComplaintsList(requestParameters: ExportFullComplaintsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.exportFullComplaintsListRaw(requestParameters, initOverrides);
    }

}
