import { Dialog } from "primereact/dialog";
import BaseTextEditor from "@/components/Core/Form/BaseTextEditor";
import { useState } from "react";
import { useUpdateComplaintActions } from "@/Service/Api/ApiHooks/ComplaintActions/useUpdateComplaintActions";
import { Button } from "primereact/button";
import { Channel, Complaint } from "@/stub";
import { useCreateComplaintChannel } from "@/Service/Api/ApiHooks/ComplaintChannel/useCreateComplaintChannel";
import { ComplaintNoteAddedMessage } from "@/Messages/Toast/ComplaintNotes/ComplaintNoteAddedMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { useQueryClient } from "@tanstack/react-query";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";


interface CompleteActionModalProps {
    visible: boolean;
    onClose: () => void;
    channelData: Channel | null;
    complaint: Complaint | null;

}
const CompleteActionModal = ({ channelData, complaint, visible, onClose }:CompleteActionModalProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [content, setContent] = useState<string>('');

    const { mutateAsync: updateComplaintActions } = useUpdateComplaintActions();
    const { mutateAsync: createComplaintChannel } = useCreateComplaintChannel();

    const handleCompleteAction = async () => {
        if (!channelData?.action?.id || !complaint?.id) return;

        try {
            await updateComplaintActions({
                action_id: channelData.action.id,
                complaint_id: complaint.id,
            });
            await createComplaintChannel({
                complaint_id: complaint.id,
                content,
                contact_type: 'note',
            });
            addToastMessage(ComplaintNoteAddedMessage);
            void Promise.all([
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.complaints._def,
                }),
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.channels.list({ complaint_id: complaint.id }).queryKey,
                }),
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.documents.list({ complaint_id: complaint.id }).queryKey,
                }),
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.activityLog.list({ complaint_id: complaint.id }).queryKey,
                }),
            ]);
            onClose();
        } catch (error) {
            addToastMessage({
                severity: 'error',
                summary: 'Error',
                detail: 'Error completing action',
            });
        }
    };

    return (
        <Dialog
            header="Complete action"
            className="preview-complete-action-modal"
            visible={visible}
            onHide={onClose}
            blockScroll
            draggable={false}
        >
            <div className="flex flex-column gap-3 w-full">
                <div className="w-full">
                    <BaseTextEditor
                        value={content}
                        onEditorChange={(e) => setContent(e)}
                        label="Content"
                        textareaName="content"
                        menubar={false}
                    />
                </div>
                <Button
                    label="Complete"
                    className="p-button-success"
                    onClick={handleCompleteAction}
                />
            </div>
        </Dialog>
    );
};

export default CompleteActionModal;
