import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useState, useMemo } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import DsarDownloadButton from '../partials/DsarDownloadButton';
import { useGetCustomersDsar } from '@/Service/Api/ApiHooks/Customers/useGetCustomersDsar';
import { DataTable, type DataTablePassThroughOptions, type DataTableStateEvent } from 'primereact/datatable';
import { dateBodyTemplate } from '../templates/DatatableColumnTemplates';
import {
    type Complaint,
    type CustomerDsar,
    type GetCustomersDsarRequest,
    GetCustomersDsarSortByEnum,
    GetCustomersDsarSortOrderEnum
} from '@/stub';
import { SortOrder } from 'primereact/api';
import { produce } from 'immer';
import AnglesSort from '@/components/Icons/AnglesSort';
import { useDebounce } from "react-use";

type TableFilterOptions = {
    first: number
    rows: number
    sortField: string
    sortOrder: SortOrder
    search: string
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .content-header {
        padding: 1rem;
        border-bottom: var(--gray-300) 1px solid;

        &__title {
            margin: 0.25rem 0;
            font-weight: 600;
            font-size: 1.5rem;
        }

        &__description {
            font-size: 0.9rem;
            color: var(--gray-500);
        }
    }

    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .datatable-container {
        margin-top: 2rem;
        font-size: 0.8rem;
    }

    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .datatable-cell {
        &__clean-text {
            margin: 0;
        }
    }

    .angles-sort {
        margin-left: 0.3rem;

        &__icon {
            color: var(--primary-200);
            font-size: 0.8rem;
            font-weight: 600;

            &.active {
                color: var(--primary-500);
            }
        }
    }
`;

/**
 * DSAR datatable
 */
const DsarDatatable = () => {
    const [expandedRows, setExpandedRows] = useState<CustomerDsar[]>([]);

    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        search: '',
        sortField: GetCustomersDsarSortByEnum.IdNumber,
        sortOrder: SortOrder.DESC,
        rows: DT_ROWS_NO,
        first: 0
    });

    const [debouncedTableSearch, setDebouncedTableSearch] = useState('');

    useDebounce(() => {
        setDebouncedTableSearch(tableFilters.search);
    }, 500, [tableFilters.search]);

    const dsarRequest = useMemo<GetCustomersDsarRequest>(() => {
        return {
            search: debouncedTableSearch,
            sort_by: tableFilters.sortField as GetCustomersDsarSortByEnum ?? undefined,
            sort_order: tableFilters.sortOrder === SortOrder.ASC ? GetCustomersDsarSortOrderEnum.Asc : tableFilters.sortOrder === SortOrder.DESC ? GetCustomersDsarSortOrderEnum.Desc : undefined,
            page: (tableFilters.first / tableFilters.rows) + 1,
            limit: tableFilters.rows
        };
    }, [debouncedTableSearch, tableFilters.first, tableFilters.rows, tableFilters.sortField, tableFilters.sortOrder]);

    const {
        data: dsarResponse,
        isLoading: loading
    } = useGetCustomersDsar(dsarRequest);

    /**
     * Clear the complaint search
     */
    const clearComplaintSearch = () => {
        setTableFilters(produce(draft => {
            draft.first = 0;
            draft.search = '';
        }));
    };

    const onSearch = (e) => {
        setTableFilters(produce(draft => {
            draft.search = e.target.value;
            draft.first = 0;
        }));
    };

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
    };

    const onSort = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            draft.sortField = e.sortField;
            draft.sortOrder = e.sortOrder ?? SortOrder.UNSORTED;
        }));
    };

    /**
     * Expand or collapse a row when it is selected
     *
     * @param {CustomerDsar} rowData object containing row data
     */
    const manageExpandedRows = (rowData: CustomerDsar): void => {
        if (rowData?.complaints?.length) {
            if (Array.isArray(expandedRows)) {
                const searchItem = expandedRows.find(item => item.id === rowData.id);

                // remove the new item
                if (searchItem) {
                    setExpandedRows(expandedRows.filter(item => item.id !== rowData.id));

                    // add the new item
                } else {
                    setExpandedRows([rowData, ...expandedRows]);
                }

            } else {
                setExpandedRows([rowData]);
            }
        }
    };

    /**
     * Actions column template
     *
     * @param {Complaint} complaint object containing row data
     */
    const actionsBodyTemplate = (complaint: Complaint) => {
        return (
            <div className="actions-column mr-3">
                <DsarDownloadButton complaintId={complaint.id}/>
            </div>
        );
    };

    /**
     * Determine if the row is expandable or not
     *
     * @param {CustomerDsar} rowData object containing row data
     */
    const allowExpansion = (rowData: CustomerDsar): boolean => {
        return !!rowData?.complaints?.length;
    };

    /**
     * Expanded row template
     *
     * @param {CustomerDsar} rowData object containing row data
     */
    const rowExpansionTemplate = (rowData: CustomerDsar): JSX.Element => {
        return (
            <div className="mx-8">
                <h3>Complaints</h3>

                <DataTable value={rowData.complaints} className="p-expanded-datatable">
                    <Column field="raised_date" header="Date Received"
                        body={(rowData) => dateBodyTemplate(rowData.raised_date, true)}></Column>
                    <Column field="next_action_date" header="Next Action"
                        body={(rowData) => dateBodyTemplate(rowData.next_action_date, true)}></Column>
                    <Column field="days_open" header="Days open"></Column>
                    <Column field="status" header="Status"></Column>
                    <Column field="ref_number" header="Complaint Reference"></Column>
                    <Column className="column-actions" body={actionsBodyTemplate}/>
                </DataTable>
            </div>
        );
    };

    return (
        <StyledWrap>
            <div className="content-header flex justify-content-between">
                <div>
                    <h2 className="content-header__title">DSAR</h2>
                    <p className="content-header__description">Forseti Complaints Manager</p>
                </div>
                <div className="sub-header-search-wrap align-items-center">
                    <i className="pi pi-search input-search-icon"/>
                    <InputText name="search" value={tableFilters.search} onChange={onSearch}
                        placeholder="Search by customer name or email"/>
                    {!!tableFilters.search &&
                        <i className="pi pi-times p-button p-button-sm p-button-danger p-button-text p-1 input-clear-icon"
                            onClick={clearComplaintSearch}/>}
                </div>
            </div>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={loading}
                        emptyMessage="No customers found."
                        value={dsarResponse?.data}
                        totalRecords={dsarResponse?.meta.total}
                        selectionMode={'single'}
                        removableSort
                        paginator
                        rows={tableFilters.rows}
                        rowsPerPageOptions={[10, 20, 50]}
                        first={tableFilters.first}
                        pt={dataTablePtOptions}
                        sortIcon={(options) => {
                            return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                        }}
                        sortField={tableFilters.sortField}
                        sortOrder={tableFilters.sortOrder}
                        onPage={e => {
                            onPagination(e);
                        }}
                        onSort={e => {
                            onSort(e);
                        }}
                        expandedRows={expandedRows}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onSelectionChange={(e) => {
                            manageExpandedRows(e.value);
                        }}
                        onRowToggle={(e) => {
                            setExpandedRows(e.data);
                        }}
                    >
                        <Column expander={allowExpansion} style={{ width: '3rem' }}/>
                        <Column className="column-complaint-id" field="id_number" sortField='id_number'
                            header="Customer ID" sortable/>
                        <Column className="column-name" field="name" header="Name" sortable/>
                        <Column className="column-email" field="email" header="Email" sortable/>
                        <Column className="column-type" field="type" header="Type" sortable/>
                    </DataTable>
                </div>
            </div>
        </StyledWrap>
    );
};

export default DsarDatatable;
