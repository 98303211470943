import React, { useId } from 'react';
import { Controller } from 'react-hook-form';
import { Editor, type IAllProps } from '@tinymce/tinymce-react';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import { fieldsPlaceholders } from '@/config/forms';

export type BaseTextEditorProps = IAllProps & BaseFormInputProps & {
    menubar?: boolean
    width?: string
    height?: string
    placeholder?: string
    required?: boolean
    className?: string
};

const editorApiKey = import.meta.env.VITE_TINYMCE_API_KEY;

const BaseTextEditor: React.FC<BaseTextEditorProps> = ({
    control,
    label,
    errorMessages,
    menubar = false,
    width,
    height,
    placeholder = fieldsPlaceholders.text,
    required,
    className,
    ...props
}: BaseTextEditorProps) => {
    const localId = useId();



    return (
        <div className={className}>
            <label className="block mb-2" htmlFor={localId}>{label}{required ? '*' : ''}</label>
            {
                control && props.textareaName &&
                <Controller
                    key={props.textareaName}
                    name={props.textareaName}
                    control={control}
                    disabled={props.disabled}
                    render={({ field }) => (
                        <Editor
                            apiKey={editorApiKey}
                            id={localId}
                            textareaName={field.name}
                            value={field.value}
                            disabled={field.disabled}
                            onEditorChange={(e, editor) => {
                                field.onChange(e);
                                if (props.onEditorChange) {
                                    props.onEditorChange(e, editor);
                                }
                            }}
                            onBlur={(e, editor) => {
                                field.onBlur();
                                if (props.onBlur) {
                                    props.onBlur(e, editor);
                                }
                            }}
                            init={{

                                width: width ?? 'auto',
                                height: height ?? '170px',
                                placeholder,
                                menubar,
                                plugins: [
                                    'autolink', 'charmap', 'visualblocks', 'wordcount'
                                ],
                                toolbar: 'blocks | bold italic underline'
                            }}
                            {...props}
                        />
                    )}
                />
            }
            {
                !control &&
                <Editor
                    apiKey={editorApiKey}
                    id={localId}
                    init={{
                        width: width ?? 'auto',
                        height: height ?? '170px',
                        placeholder,
                        menubar,
                        plugins: [
                            'autolink', 'charmap', 'visualblocks', 'wordcount'
                        ],
                        toolbar: 'blocks | bold italic underline'
                    }}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseTextEditor;
