import { useQuery } from '@tanstack/react-query';
import { type GetInvestigationFromComplaintRequest } from '@/stub';
import { ComplaintInvestigationApiClient } from '@/Service/Api/ApiClients/ComplaintInvestigationApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export interface useGetComplaintInvestigationParams {
    requestParams: GetInvestigationFromComplaintRequest,
    enabled?: boolean,
    select?
}

export const useGetComplaintInvestigation = ({
    requestParams,
    select = undefined,
    enabled = true
}: useGetComplaintInvestigationParams) => useQuery({
    ...QueryKeys.investigation.detail(requestParams),
    queryFn: async () => await ComplaintInvestigationApiClient.getInvestigationFromComplaint(requestParams),
    select,
    enabled
});
