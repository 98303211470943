/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Customer } from './Customer';
import {
    instanceOfCustomer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
} from './Customer';

/**
 * @type ChannelCustomer
 * 
 * @export
 */
export type ChannelCustomer = Customer;

export function ChannelCustomerFromJSON(json: any): ChannelCustomer {
    return ChannelCustomerFromJSONTyped(json, false);
}

export function ChannelCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...CustomerFromJSONTyped(json, true) };
}

export function ChannelCustomerToJSON(value?: ChannelCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfCustomer(value)) {
        return CustomerToJSON(value as Customer);
    }

    return {};
}

