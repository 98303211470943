import React from "react";
import ComplaintView from "@/components/partials/views/ComplaintView";

const ViewComplaintPage: React.FC = () => {
    return (
        <ComplaintView/>
    );
};

export default ViewComplaintPage;
