import { useMutation } from '@tanstack/react-query';
import { GetFcaRmarReportPdfRequest } from '@/stub';import { PdfReportApiClient } from "@/Service/Api/ApiClients/PdfReportApiClient";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";

export const useGetFcaRmarPdf = () => {
    return useMutation({
        mutationFn: async (data: GetFcaRmarReportPdfRequest) => {
            try {
                return await PdfReportApiClient.getFcaRmarReportPdf(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
