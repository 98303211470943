import { normalizeLineEndings } from "@/Util/normalizeLineEndings";

type AnyObject = { [key: string]: any };

export const normalizeContent = (obj: any): object => {
    if ((typeof obj !== 'object' || obj instanceof Date) || obj === null) return obj;
    if (Array.isArray(obj)) {
        return obj.map(normalizeContent);
    }

    return Object.keys(obj).reduce((acc: AnyObject, key: string) => {
        acc[key] = (typeof obj[key] === 'string' && obj[key].includes('<p>'))
            ? normalizeLineEndings(obj[key])
            : normalizeContent(obj[key]);
        return acc;
    }, {});
};
