/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetProducts200Response,
  Product,
} from '../models/index';
import {
    GetProducts200ResponseFromJSON,
    GetProducts200ResponseToJSON,
    ProductFromJSON,
    ProductToJSON,
} from '../models/index';

export interface GetProductRequest {
    product_id: number;
}

export interface GetProductsRequest {
    search?: string;
    sort_by?: string;
    sort_order?: GetProductsSortOrderEnum;
    page?: number;
    limit?: number;
    organisation_id?: number;
    category_id?: number;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     * Get one product
     */
    async getProductRaw(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.product_id === null || requestParameters.product_id === undefined) {
            throw new runtime.RequiredError('product_id','Required parameter requestParameters.product_id was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{product_id}`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.product_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Get one product
     */
    async getProduct(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.getProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of products
     */
    async getProductsRaw(requestParameters: GetProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        if (requestParameters.category_id !== undefined) {
            queryParameters['category_id'] = requestParameters.category_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of products
     */
    async getProducts(requestParameters: GetProductsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200Response> {
        const response = await this.getProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetProductsSortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetProductsSortOrderEnum = typeof GetProductsSortOrderEnum[keyof typeof GetProductsSortOrderEnum];
