import React, { useId } from 'react';
import { Controller } from 'react-hook-form';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';
import { InputSwitch, InputSwitchProps } from "primereact/inputswitch";

export type BaseSwitchInputProps = InputSwitchProps & BaseFormInputProps;

const BaseSwitchInput: React.FC<BaseSwitchInputProps> = ({
    control,
    label,
    errorMessages,
    className,
    ...props
}: BaseSwitchInputProps) => {
    const localId = useId();

    return (
        <div className={className}>
            {label && <label className="block mb-2" htmlFor={localId}>{label}{props.required ? '*' : ''}</label>}
            {
                control && props.name &&
                <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <InputSwitch
                            {...props}
                            id={localId}
                            name={field.name}
                            invalid={fieldState.invalid}
                            checked={field.value}
                            disabled={field.disabled}
                            onChange={(e) => {
                                field.onChange(e.checked);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                        />
                    )}
                />
            }
            {
                !control &&
                <InputSwitch
                    {...props}
                    id={localId}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseSwitchInput;
