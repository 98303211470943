import React, { useMemo, useState } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import { DataTable, type DataTablePassThroughOptions, type DataTableStateEvent } from 'primereact/datatable';
import { ActionStateEnum, Channel, type Complaint, type GetComplaintChannelsRequest, } from '@/stub';
import { produce } from 'immer';
import { Column, ColumnBodyOptions } from "primereact/column";
import DownloadButton from "@/components/partials/DownloadButton";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { useGetComplaintChannels } from "@/Service/Api/ApiHooks/ComplaintChannel/useGetComplaintChannels";
import { Button } from "primereact/button";
import ChannelPreviewModal from "@/components/Modals/ChannelPreviewModal";
import { formatType } from "@/Util/formatType";
import { dayStart } from "@formkit/tempo";
import { clsx } from "clsx";
import CompleteActionModal from "@/components/Modals/CompleteActionModal";

type TableFilterOptions = {
    first: number
    rows: number
};

type TableColumnDefinition = {
    label?: string
    field?: string
    body?: React.ReactNode | ((data: Channel, options: ColumnBodyOptions) => React.ReactNode)
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }
    .signalizing-field {
        padding: 0.15rem 0.5rem;
        border-radius: 6px;
        text-wrap: nowrap;

        &.future {
            background-color: var(--teal-200)
        }

        &.success {
            background-color: var(--green-200);
        }

        &.warning {
            background-color: var(--yellow-100);
        }

        &.danger {
            background-color: var(--red-200);
        }
`;

export type ContactsTableProps = {
    complaint: Complaint | undefined
};


const today = new Date();

const stateBodyRenderGenerator = (channel: Channel) => {
    if(!channel?.action) return;

    const isOverdue = dayStart(channel?.action?.due_date) < today;
    if(!isOverdue) {
        return (
            <span
                className={clsx('signalizing-field', { warning: !isOverdue })}
            >
                {'Due'}: {formatToUKDate(channel?.action?.due_date)}
            </span>
        );
    }
    if(isOverdue) {
        return (
            <span
                className={clsx('signalizing-field', { warning: isOverdue })}
            >
                {'Overdue'}: {formatToUKDate(channel?.action?.due_date)}
            </span>
        );
    }

    return (
        <span
            className="signalizing-field future"
        >
                    Planned: {formatToUKDate(channel?.action?.due_date)}
        </span>
    );
};

const ChannelTable = ({ complaint }: { complaint: Complaint | null }) => {
    const [channelData, setChannelData] = useState<Channel | null>(null);
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        rows: DT_ROWS_NO,
        first: 0
    });

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
    };

    const handleOpenEdit = (data: Channel) => {
        setIsEditOpen(true);
        setChannelData(data);
    };

    const handleOpenPreview = (data: Channel) => {
        setIsPreviewOpen(true);
        setChannelData(data);
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'Contact Type',
            body: (data: Channel) => {
                return formatType(data.contact_type);
            }
        },
        {
            label: 'Created',
            body: (data: Channel) => {
                return formatToUKDate(data.created_at);
            }
        },
        {
            label: 'Next Action',
            body: (data: Channel) => {
                const isClosed = data.action?.state === ActionStateEnum.Closed;

                if (!data.action || isClosed) {
                    return null;
                }

                return (
                    <div className="flex gap-3 align-items-center">
                        {stateBodyRenderGenerator(data)}
                        <Button onClick={() => handleOpenEdit(data)} icon="pi pi-pencil" link />
                    </div>
                );
            }
        },
        {
            label: 'Actions',
            body: (data: Channel) => (
                <div className="flex gap-3">
                    {data.document && <DownloadButton file={data.document} complaintId={complaint?.id} />}
                    <Button onClick={() => handleOpenPreview(data)}>View Details</Button>
                </div>
            )
        },
    ];


    const requestParams = useMemo<GetComplaintChannelsRequest>(() => {
        if (!complaint) return null;

        return {
            page: Math.floor(tableFilters.first / tableFilters.rows) + 1,
            limit: tableFilters.rows,
            complaint_id: complaint.id,
        };
    }, [tableFilters.first, tableFilters.rows, complaint]);

    const { data: contactsData, isLoading: loadingContacts } = useGetComplaintChannels({ requestParams });

    return (
        <StyledWrap>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={loadingContacts}
                        emptyMessage="No files found."
                        value={contactsData?.data}
                        totalRecords={contactsData?.meta.total}
                        removableSort
                        paginator
                        rows={tableFilters.rows}
                        rowsPerPageOptions={[10, 20, 50]}
                        first={tableFilters.first}
                        pt={dataTablePtOptions}
                        onPage={onPagination}
                    >
                        {tableColumns.map((column) => (
                            <Column
                                key={column.label}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                            />
                        ))}
                    </DataTable>
                </div>
            </div>


            {isPreviewOpen && channelData && contactsData && (
                <ChannelPreviewModal
                    contactsData={contactsData}
                    channel={channelData}
                    visible={isPreviewOpen}
                    closePreview={() => setIsPreviewOpen(false)}
                />
            )}


            {isEditOpen && channelData && (
                <CompleteActionModal
                    complaint={complaint}
                    channelData={channelData}
                    visible={isEditOpen}
                    onClose={() => setIsEditOpen(false)}
                />
            )}
        </StyledWrap>
    );
};

export default ChannelTable;
