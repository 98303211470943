/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpsertComplaintInvestigationPointsRequestPointsInner
 */
export interface UpsertComplaintInvestigationPointsRequestPointsInner {
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintInvestigationPointsRequestPointsInner
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintInvestigationPointsRequestPointsInner
     */
    content: string;
}

/**
 * Check if a given object implements the UpsertComplaintInvestigationPointsRequestPointsInner interface.
 */
export function instanceOfUpsertComplaintInvestigationPointsRequestPointsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function UpsertComplaintInvestigationPointsRequestPointsInnerFromJSON(json: any): UpsertComplaintInvestigationPointsRequestPointsInner {
    return UpsertComplaintInvestigationPointsRequestPointsInnerFromJSONTyped(json, false);
}

export function UpsertComplaintInvestigationPointsRequestPointsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertComplaintInvestigationPointsRequestPointsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': json['content'],
    };
}

export function UpsertComplaintInvestigationPointsRequestPointsInnerToJSON(value?: UpsertComplaintInvestigationPointsRequestPointsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
    };
}

