import { useMemo } from 'react';
import { type GetComplaintsRequest, GetComplaintsStatesEnum } from '@/stub';
import { checkPermission } from '@/Util/permissionChecks';
import { dayEnd, dayStart } from '@formkit/tempo';
import { useAuthStore } from '@/Stores/AuthStore';
import { InboxPresetOptions } from '@/Enums/InboxPresetOptions';

export const useInboxFilterPresets = () => {
    const authUser = useAuthStore((state) => state.authUser);
    const impersonatedOrgId = useAuthStore((state) => state.impersonatedOrgId);

    return useMemo<Record<InboxPresetOptions, GetComplaintsRequest>>(() => {
        const unclosedStates: GetComplaintsStatesEnum[] = [
            GetComplaintsStatesEnum.Investigated,
            GetComplaintsStatesEnum.Resolved
        ];

        const presets = {} as Record<InboxPresetOptions, GetComplaintsRequest>;

        // We can't show this filter to the user that is using impersonation because
        // backend has a validation check to determine if user belongs to organization
        if (!impersonatedOrgId) {
            presets[InboxPresetOptions.MyQueue] = {
                assigned_to_id: authUser?.id,
                states: [
                    GetComplaintsStatesEnum.Investigated,
                    GetComplaintsStatesEnum.Resolved
                ]
            };
        }
        presets[InboxPresetOptions.Today] = {
            from: dayStart(new Date()),
            to: dayEnd(new Date()),
            states: unclosedStates,
            is_for_today: true
        };
        presets[InboxPresetOptions.ThreeWDsToday] = {
            is_less_3day: true,
            states: unclosedStates
        };
        if (checkPermission(authUser?.permissions, 'read:org_complaint_detail')) {
            presets[InboxPresetOptions.OpenComplaints] = {
                states: unclosedStates
            };
        }
        presets[InboxPresetOptions.FRLs] = {
            states: [
                GetComplaintsStatesEnum.Resolved
            ]
        };

        // Check for org wide read permission and force set assigned ID filter to current user for each needed filter preset
        if (!checkPermission(authUser?.permissions, 'read:org_complaint_detail')) {
            (Object.keys(presets) as InboxPresetOptions[]).forEach((presetName) => {
                presets[presetName].assigned_to_id = authUser?.id;
            });
        }

        presets[InboxPresetOptions.All] = {};

        return presets;
    }, [authUser?.id, authUser?.permissions, impersonatedOrgId]);
};
