import { useInfiniteQuery } from "@tanstack/react-query";
import { notificationsApiClient } from "@/Service/Api/ApiClients/NotificationsApiClient";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { GetNotificationsRequest } from "@/stub";

export type useGetNotificationsParams = {
    requestParameters?: GetNotificationsRequest
};

export const useGetNotifications = ({ requestParameters }: useGetNotificationsParams = {}) => useInfiniteQuery({
    ...QueryKeys.notifications.list(),
    queryFn: async ({ pageParam }) => await notificationsApiClient.getNotifications({ ...requestParameters, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPageParam === lastPage.meta.last_page) {
            return undefined;
        }
        return ++lastPageParam;
    }
});
