/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetComplaintActivityLogList200Response,
} from '../models/index';
import {
    GetComplaintActivityLogList200ResponseFromJSON,
    GetComplaintActivityLogList200ResponseToJSON,
} from '../models/index';

export interface GetComplaintActivityLogListRequest {
    complaint_id: number;
    search?: string;
    limit?: number;
    page?: number;
}

/**
 * 
 */
export class ComplaintActivityLogApi extends runtime.BaseAPI {

    /**
     * Get list of complaint activity-log
     */
    async getComplaintActivityLogListRaw(requestParameters: GetComplaintActivityLogListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComplaintActivityLogList200Response>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintActivityLogList.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/activity-log`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComplaintActivityLogList200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of complaint activity-log
     */
    async getComplaintActivityLogList(requestParameters: GetComplaintActivityLogListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComplaintActivityLogList200Response> {
        const response = await this.getComplaintActivityLogListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
