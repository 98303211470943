import { Dialog } from "primereact/dialog";
import { useMemo, useState } from "react";
import { ExcelIcon } from "@/helpers/svg-icons";
import { useFullComplaintsListExport } from "@/Service/Api/ApiHooks/Complaint/useFullComplaintsListExport";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { Button } from "primereact/button";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { KPISummaryExportMessage } from "@/Messages/Toast/Reports/KPISummaryExportMessage";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { GetLettersRequest } from "@/stub";
import { addMonth } from "@formkit/tempo";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import { useOrgId } from "@/Hooks/useOrgId";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";

const today = new Date();
const sixMonthsAgo = addMonth(today, -6);

const ComplaintsReportModal = () => {
    const orgId = useOrgId();
    const [visible, setVisible] = useState(false);
    const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
    const { mutateAsync, isPending } = useFullComplaintsListExport();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const [dateRangeFilter, setDateRangeFilter] = useState([sixMonthsAgo, today]);

    const requestParams = useMemo<GetLettersRequest>(() => ({
        from: formatToApiDate(dateRangeFilter?.[0]),
        to: formatToApiDate(dateRangeFilter?.[1]),
        organisation_id: orgId,
        search: searchInput,
    }), [dateRangeFilter,orgId,searchInput]);

    const handleGenerateExport = async () => {
        await mutateAsync({
            ExportKPISummaryReportRequest: requestParams
        }, {
            onSuccess: () => {
                addToastMessage(KPISummaryExportMessage);
                setVisible(false);
                setSearchInput('');
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    return (
        <>
            <Button disabled={isPending} text className="fake-link ml-3" onClick={() => setVisible(true)}>
                <ExcelIcon />
            </Button>
            <Dialog draggable={false} header="Generate Complaints Report" visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="flex flex-column gap-3 w-full align-items-end">
                    <BaseTextInput
                        className='w-full'
                        pt={{
                            inputText: {
                                className: 'w-full'
                            }
                        }}
                        value={searchInput}
                        placeholder="Text"
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                        }} />
                    <BaseCalendar
                        className='w-full'
                        pt={{
                            root: {
                                className: 'w-full'
                            },
                            input: {
                                root: {
                                    className: 'w-full'
                                },
                            }
                        }}
                        name='date-range-filter'
                        value={dateRangeFilter}
                        selectionMode='range'
                        maxDate={today}
                        dateFormat='dd/mm/yy'
                        placeholder='Select date range'
                        onClearButtonClick={() => setDateRangeFilter([sixMonthsAgo, today])}
                        showButtonBar
                        selectOtherMonths
                        readOnlyInput
                        hideOnRangeSelection
                        onChange={(event) => {
                            setDateRangeFilter(event.value);
                        }}
                    />


                    <Button disabled={isPending} className="ml-3" onClick={handleGenerateExport}>
                        Generate Report
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export default ComplaintsReportModal;
