import { useMutation } from '@tanstack/react-query';
import { authUserApiClient } from '@/Service/Api/ApiClients/AuthUserApiClient';
import { ClearUserCacheRequest } from "@/stub";

export const useClearUserCache = () => useMutation({
    mutationFn: async (requestParameters: ClearUserCacheRequest) => await authUserApiClient.clearUserCache(requestParameters)
        .catch(error => error.response.json()
            .then((errorBody) => {
                throw new Error(errorBody.message);
            })
        )
});
