/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BespokeResourceComplaint } from './BespokeResourceComplaint';
import {
    BespokeResourceComplaintFromJSON,
    BespokeResourceComplaintFromJSONTyped,
    BespokeResourceComplaintToJSON,
} from './BespokeResourceComplaint';
import type { ChannelAction } from './ChannelAction';
import {
    ChannelActionFromJSON,
    ChannelActionFromJSONTyped,
    ChannelActionToJSON,
} from './ChannelAction';
import type { ChannelDocument } from './ChannelDocument';
import {
    ChannelDocumentFromJSON,
    ChannelDocumentFromJSONTyped,
    ChannelDocumentToJSON,
} from './ChannelDocument';
import type { LetterType } from './LetterType';
import {
    LetterTypeFromJSON,
    LetterTypeFromJSONTyped,
    LetterTypeToJSON,
} from './LetterType';

/**
 * 
 * @export
 * @interface Letter
 */
export interface Letter {
    /**
     * 
     * @type {number}
     * @memberof Letter
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Letter
     */
    complaint_id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Letter
     */
    due_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof Letter
     */
    sent_date?: Date;
    /**
     * 
     * @type {LetterType}
     * @memberof Letter
     */
    type: LetterType;
    /**
     * 
     * @type {string}
     * @memberof Letter
     */
    path?: string | null;
    /**
     * 
     * @type {ChannelAction}
     * @memberof Letter
     */
    action?: ChannelAction | null;
    /**
     * 
     * @type {BespokeResourceComplaint}
     * @memberof Letter
     */
    complaint?: BespokeResourceComplaint | null;
    /**
     * 
     * @type {ChannelDocument}
     * @memberof Letter
     */
    document?: ChannelDocument | null;
    /**
     * 
     * @type {Date}
     * @memberof Letter
     */
    created_at: Date;
}

/**
 * Check if a given object implements the Letter interface.
 */
export function instanceOfLetter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "due_date" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function LetterFromJSON(json: any): Letter {
    return LetterFromJSONTyped(json, false);
}

export function LetterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Letter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'complaint_id': !exists(json, 'complaint_id') ? undefined : json['complaint_id'],
        'due_date': (new Date(json['due_date'])),
        'sent_date': !exists(json, 'sent_date') ? undefined : (new Date(json['sent_date'])),
        'type': LetterTypeFromJSON(json['type']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'action': !exists(json, 'action') ? undefined : ChannelActionFromJSON(json['action']),
        'complaint': !exists(json, 'complaint') ? undefined : BespokeResourceComplaintFromJSON(json['complaint']),
        'document': !exists(json, 'document') ? undefined : ChannelDocumentFromJSON(json['document']),
        'created_at': (new Date(json['created_at'])),
    };
}

export function LetterToJSON(value?: Letter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'complaint_id': value.complaint_id,
        'due_date': (value.due_date.toISOString().substring(0,10)),
        'sent_date': value.sent_date === undefined ? undefined : (value.sent_date.toISOString().substring(0,10)),
        'type': LetterTypeToJSON(value.type),
        'path': value.path,
        'action': ChannelActionToJSON(value.action),
        'complaint': BespokeResourceComplaintToJSON(value.complaint),
        'document': ChannelDocumentToJSON(value.document),
        'created_at': (value.created_at.toISOString().substring(0,10)),
    };
}

