/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentResource
 */
export interface DocumentResource {
    /**
     * 
     * @type {number}
     * @memberof DocumentResource
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentResource
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResource
     */
    path: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentResource
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentResource
     */
    type?: DocumentResourceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentResource
     */
    documentable_id?: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentResource
     */
    created_at?: Date;
}


/**
 * @export
 */
export const DocumentResourceTypeEnum = {
    Channel: 'Channel',
    ComplaintFos: 'ComplaintFos',
    Letter: 'Letter',
    Evidence: 'Evidence'
} as const;
export type DocumentResourceTypeEnum = typeof DocumentResourceTypeEnum[keyof typeof DocumentResourceTypeEnum];


/**
 * Check if a given object implements the DocumentResource interface.
 */
export function instanceOfDocumentResource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "path" in value;

    return isInstance;
}

export function DocumentResourceFromJSON(json: any): DocumentResource {
    return DocumentResourceFromJSONTyped(json, false);
}

export function DocumentResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'path': json['path'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'documentable_id': !exists(json, 'documentable_id') ? undefined : json['documentable_id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function DocumentResourceToJSON(value?: DocumentResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'path': value.path,
        'size': value.size,
        'type': value.type,
        'documentable_id': value.documentable_id,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString().substring(0,10)),
    };
}

