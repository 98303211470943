import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { apiRequest, getAuthImage } from '../../helpers/general';
import { DT_ROWS_NO } from '../../config/constants';
import debounce from 'lodash.debounce';
import DatatableTemplate from '../templates/DatatableTemplate';
import { AppContext } from '../context/AppContext';
import { Card } from 'primereact/card';
import DateInputTemplate from '../templates/forms/DateInputTemplate';
import moment from 'moment';
import { ExcelIcon } from '../../helpers/svg-icons';
import { Tooltip } from 'primereact/tooltip';
import { useAuth0 } from "@auth0/auth0-react";

/**
 * KPI Volumes datatable
 */
const KpiVolumesDatatable = () => {
    const searchReportsRef = useRef();
    const exportLinkRef = useRef();
    const { userPreferences, setUserPreferences } = useContext(AppContext);

    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchReportsInput, setSearchReportsInput] = useState('');
    const [dateRangeFilter, setDateRangeFilter] = useState();

    const [volumesReports, setVolumesReports] = useState([]);
    const [volumesLoading, setVolumesLoading] = useState(true);
    const [volumesRowsPerPage, setVolumesRowsPerPage] = useState(DT_ROWS_NO);
    const [volumesFirst, setVolumesFirst] = useState(0);
    const [volumesCurrentPage, setVolumesCurrentPage] = useState(0);
    const [volumesTotalRecords, setVolumesTotalRecords] = useState(0);
    const [volumesSortField, setVolumesSortField] = useState('date');
    const [volumesSortOrder, setVolumesSortOrder] = useState(-1);
    const { getAccessTokenSilently } = useAuth0();

    /**
     * Generate the export file
     *
     */
    const generateExport = async () => {
        let exportUrl = import.meta.env.VITE_API_URL + 'export/reports/complaint-volumes',
            linkRef = exportLinkRef?.current,
            requestParams = {};

        // search param
        if (searchReportsInput && searchReportsInput !== '') {
            requestParams.search = searchReportsInput;
        }

        // sort params
        if (volumesSortField !== null) {
            requestParams.sort_by = volumesSortField;
        }

        if (volumesSortOrder !== 0) {
            requestParams.sort_order = volumesSortOrder === -1 ? 'desc' : 'asc';
        }

        // filter by start and end date
        if (Array.isArray(dateRangeFilter) && dateRangeFilter[0] && dateRangeFilter[1]) {
            requestParams.start_date = moment(dateRangeFilter[0]).format('YYYY-MM-DD');
            requestParams.end_date = moment(dateRangeFilter[1]).format('YYYY-MM-DD');

        }

        exportUrl += '?' + Object.keys(requestParams).map(key => key + '=' + requestParams[key]).join('&');

        const accessToken = await getAccessTokenSilently();
        const fileUrl = await getAuthImage(exportUrl, accessToken);

        if (fileUrl && linkRef) {
            linkRef.href = fileUrl;
            linkRef.click();
        }
    };

    /**
     * The header of the datatable
     */
    const header = () => {
        return (
            <div className="relative">
                <span className="p-input-icon-left search-input-wrap">
                    <i className="pi pi-search"/>
                    <InputText className="narrow-input pr-5" onChange={debounceReportsSearch} ref={searchReportsRef}
                        placeholder="Search for user name"/>
                    {clearSearchIcon &&
                        <i className="pi pi-times p-button p-button-sm p-button-danger p-button-text p-1"
                            onClick={clearReportsSearch}/>}
                </span>

                <div className="page-header flex justify-content-between">
                    <div>
                        <h3 className="page-title">KPI volumes</h3>
                        <span className="page-subtitle">Forseti Complaints Manager</span>
                    </div>
                </div>

                <div className="page-item filter-cards">
                    <Card>
                        <div className="flex align-items-center">
                            <DateInputTemplate
                                name="date-range"
                                value={dateRangeFilter}
                                className="date-wrap filter"
                                noMargins
                                selectionMode="range"
                                placeholder="Select date range"
                                onChangeFunction={(name, value) => {
                                    setDateRangeFilter(value);
                                }}
                                selectOtherMonths
                                showButtonBar
                                dateFormat="dd/mm/yy"
                            />

                            <span id="generate-normal-export" className="fake-link ml-3"
                                onClick={() => generateExport()}>
                                <ExcelIcon className="mr-3"/>
                            </span>
                            <a href="" className="hidden" target="_blank" ref={exportLinkRef}></a>
                            <Tooltip target="#generate-normal-export"
                                content="Download user report including all statuses for all complaints"
                                position="top"/>
                        </div>
                    </Card>
                </div>
            </div>
        );
    };

    /**
     * Get volumes reports from the api
     */
    const getVolumesReports = async () => {
        const volumesRequestParams = {
            page: volumesCurrentPage + 1,
            limit: volumesRowsPerPage
        };
        setVolumesLoading(true);

        // search param
        if (searchReportsInput && searchReportsInput !== '') {
            volumesRequestParams.search = searchReportsInput;
            setClearSearchIcon(true);
        }

        // hide clear search icon
        if (searchReportsInput === '') {
            setClearSearchIcon(false);
        }

        // sort params
        if (volumesSortField !== null) {
            volumesRequestParams.sort_by = volumesSortField;
        }

        if (volumesSortOrder !== 0) {
            volumesRequestParams.sort_order = volumesSortOrder === -1 ? 'desc' : 'asc';
        }

        // filter by start and end date
        if (Array.isArray(dateRangeFilter) && dateRangeFilter[0] && dateRangeFilter[1]) {
            volumesRequestParams.start_date = moment(dateRangeFilter[0]).format('YYYY-MM-DD');
            volumesRequestParams.end_date = moment(dateRangeFilter[1]).format('YYYY-MM-DD');
        }

        const accessToken = await getAccessTokenSilently();
        apiRequest('get', 'reports/complaint-volumes', {
            data: volumesRequestParams,
            onSuccess: (response) => {
                setVolumesLoading(false);

                if (response.result) {
                    setVolumesReports(response.result);
                }

                if (response.pagination?.totalEntries) {
                    setVolumesTotalRecords(response.pagination.totalEntries);
                }
            },
            onFailure: () => {
                setVolumesLoading(false);
            }
        }, accessToken);
    };

    /**
     * On sort column function
     *
     * @param {object} e event object containing sort data
     */
    const onSort = (e) => {
        setVolumesSortField(e.sortField);
        setVolumesSortOrder(e.sortOrder);
        setVolumesFirst(0);
        setVolumesCurrentPage(0);
    };

    /**
     * On change page function
     *
     * @param {object} e event object containing current page related data
     */
    const onPageChange = (e) => {
        setVolumesCurrentPage(e.page);
        setVolumesFirst(e.first);
        setVolumesRowsPerPage(e.rows);
    };

    /**
     * Reset variables related to the datatable
     */
    const resetTables = () => {
        setVolumesFirst(0);
        setVolumesCurrentPage(0);
    };

    /**
     * Search for reports
     *
     * @param {object} e event object containing search data
     */
    const searchReports = (e) => {
        setSearchReportsInput(e.target.value);
        resetTables();
    };

    /**
     * Clear the reports search
     */
    const clearReportsSearch = () => {
        searchReportsRef.current.value = '';
        setSearchReportsInput('');
        resetTables();
    };

    /**
     * Debounce the reports search function
     */
    const debounceReportsSearch = useMemo(() => {
        return debounce(searchReports, 500);
    }, []);

    /**
     * Clear the debounce on component unmount
     */
    useEffect(() => {
        return () => {
            debounceReportsSearch.cancel();
        };
    });

    /**
     * Get the reports by date again in case the search, page, rows number or sort has been changed
     */
    useEffect(() => {
        getVolumesReports();
    }, [
        searchReportsInput,
        volumesCurrentPage,
        volumesSortField,
        volumesSortOrder,
        volumesRowsPerPage,
        // currentFilter NO
    ]);

    /**
     * Get all reports again in case the date range has been changed
     */
    useEffect(() => {
        if ((Array.isArray(dateRangeFilter) && dateRangeFilter[0] && dateRangeFilter[1]) || dateRangeFilter === null) {
            getVolumesReports();
        }

    }, [dateRangeFilter]);

    return (
        <>
            <DatatableTemplate
                items={volumesReports}
                header={header}
                rowsPerPage={volumesRowsPerPage}
                loading={volumesLoading}
                emptyMessage="No reports found."
                totalRecords={volumesTotalRecords}
                first={volumesFirst}
                onPageChange={(e) => onPageChange(e)}
                customProps={{
                    className: 'reports-datatable short-dt',
                    sortField: volumesSortField,
                    sortOrder: volumesSortOrder,
                    onSort: (e) => onSort(e)
                }}
            >
                <Column
                    className="column-date"
                    field="date"
                    header="Month - Year"
                    sortable
                />
                <Column
                    className="column-received"
                    field="received"
                    header="Complaints received"
                    sortable
                />
                <Column
                    className="column-open"
                    field="open"
                    header="Open"
                    sortable
                />
                <Column
                    className="column-8-weeks-plus"
                    field="open_8wks"
                    header="+8wks"
                    sortable
                />
                <Column
                    className="column-resolved"
                    field="resolved"
                    header="Closed - pending FRL"
                    sortable
                />
                <Column
                    className="column-uphold-rate"
                    field="uphold_rate"
                    header="Uphold rate"
                    sortable
                />
                <Column
                    className="column-root-cause"
                    field="root_cause"
                    header="Root cause"
                    sortable
                />
                <Column
                    className="column-total-redress"
                    field="total_redress"
                    header="Total redress"
                    sortable
                />
                <Column
                    className="column-fos-escalations"
                    field="fos_escalations"
                    header="FOS escalations"
                    sortable
                />
                <Column
                    className="column-fos-adj-outcome"
                    field="fos_adj_outcome"
                    header="Adjudication outcome"
                    sortable
                />
                <Column
                    className="column-fos-fd-escalations"
                    field="fos_fd_escalations"
                    header="FD escalations"
                    sortable
                />
                <Column
                    className="column-fos-fd-outcome"
                    field="fos_fd_outcome"
                    header="FD outcome"
                    sortable
                />
                <Column
                    className="column-fos-redress"
                    field="fos_redress"
                    header="FOS redress (£)"
                    sortable
                />
                <Column
                    className="column-fos-root-cause"
                    field="fos_root_cause"
                    header="FOS root cause"
                    sortable
                />

            </DatatableTemplate>
        </>
    );
};

export default KpiVolumesDatatable;
