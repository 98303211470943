import { DataTable } from 'primereact/datatable';
import React from 'react';
import { DT_PAGE_REPORT_TEMPLATE, DT_PAGINATOR_TEMPLATE, DT_ROWS_PER_PAGE_OPTIONS, DT_SHORT_ROWS_PER_PAGE_OPTIONS } from '../../config/constants';
import styled from 'styled-components';

const MainWrap = styled.div`
    .expanded-table-wrap {
        margin: 0 0 0 3rem;

        > h3 {
            margin: 0.5rem 0 1rem 0;
        }

        .p-expanded-datatable {
            margin: 0 1rem 1rem 0;
            min-height: auto;
            border: 0;
        }
    }
`;

/**
 * A customizable datatable template which can be used for listing/searching/sorting/filtering items
 */
const DatatableTemplate = ({
    children,
    items,
    header,
    loading,
    rowsPerPage,
    totalRecords,
    first,
    emptyMessage,
    onPageChange,
    shortTable,
    paginator=true,
    customProps,
    selectionMode,
    selection,
    onSelectionChange,
    expandedRows,
    onRowToggle,
    rowExpansionTemplate
}) => {
    return (
        <MainWrap>
            <div className="general-datatable">
                <DataTable value={items} paginator={paginator} lazy header={header} rows={rowsPerPage} removableSort rowHover
                    loading={loading} loadingIcon="pi pi-sun pi-spin"
                    paginatorTemplate={DT_PAGINATOR_TEMPLATE}
                    rowsPerPageOptions={shortTable === true ? DT_SHORT_ROWS_PER_PAGE_OPTIONS : DT_ROWS_PER_PAGE_OPTIONS}
                    emptyMessage={emptyMessage}
                    currentPageReportTemplate={DT_PAGE_REPORT_TEMPLATE}
                    totalRecords={totalRecords}
                    first={first}
                    onPage={onPageChange}
                    selectionMode={selectionMode}
                    selection={selection}
                    onSelectionChange={onSelectionChange}
                    expandedRows={expandedRows}
                    onRowToggle={onRowToggle}
                    rowExpansionTemplate={rowExpansionTemplate}
                    {...customProps}
                    // sortIcon={(<i className="pi pi-chevron-down"></i>)}
                >
                    {children}
                </DataTable>
            </div>
        </MainWrap>
    );
};

export default DatatableTemplate;
