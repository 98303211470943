import { useQuery } from '@tanstack/react-query';
import { type GetComplaintCustomersRequest } from '@/stub';
import { complaintCustomersApiClient } from '@/Service/Api/ApiClients/ComplaintCustomersApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetComplaintCustomersParams = {
    enabled?: boolean
    requestParams: GetComplaintCustomersRequest
};

export const useGetComplaintCustomers = ({ enabled = true, requestParams }: useGetComplaintCustomersParams) => useQuery({
    ...QueryKeys.customers.list(requestParams),
    queryFn: async () => await complaintCustomersApiClient.getComplaintCustomers(requestParams),
    enabled
});
