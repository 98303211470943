import { useQuery } from '@tanstack/react-query';
import { type GetFosFromComplaintRequest } from '@/stub';
import { ComplaintFosApiClient } from '@/Service/Api/ApiClients/ComplaintFosApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

type useGetComplaintFosParams = {
    requestParams: GetFosFromComplaintRequest
    retry?: boolean
    enabled?: boolean
    select?
};

export const useGetComplaintFos = ({
    requestParams,
    retry = false,
    enabled,
    select
}: useGetComplaintFosParams) => useQuery({
    ...QueryKeys.fos.detail(requestParams),
    queryFn: async () => await ComplaintFosApiClient.getFosFromComplaint(requestParams),
    retry,
    enabled,
    select
});
