import { useQuery } from '@tanstack/react-query';
import { GetFcaRootCauseInTreeStructureRequest, RootCause } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { fcaRootCauseApiClient } from "@/Service/Api/ApiClients/FcrRootCauseApiClient";

export type useGetFcaRootCausesParams = {
    requestParams?: GetFcaRootCauseInTreeStructureRequest
    enabled?: boolean
    select?: (rootCauses: RootCause[]) => RootCause[]
};

export const useFcaGetRootCauses = ({ enabled = true, select, requestParams }: useGetFcaRootCausesParams) => useQuery({
    ...QueryKeys.fcaRootCauses.list(requestParams),
    queryFn: async () => await fcaRootCauseApiClient.getFcaRootCauseInTreeStructure(requestParams),
    enabled,
    select
});
