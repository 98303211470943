/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpsertComplaintDecisionRequest
 */
export interface UpsertComplaintDecisionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintDecisionRequest
     */
    decision_type?: UpsertComplaintDecisionRequestDecisionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintDecisionRequest
     */
    final_letter_type?: UpsertComplaintDecisionRequestFinalLetterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintDecisionRequest
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintDecisionRequest
     */
    refund?: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintDecisionRequest
     */
    interest?: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintDecisionRequest
     */
    financial_loss?: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintDecisionRequest
     */
    non_financial_loss?: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintDecisionRequest
     */
    ex_gratia?: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintDecisionRequest
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintDecisionRequest
     */
    approval_level?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintDecisionRequest
     */
    approved_by?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintDecisionRequest
     */
    approval_date?: string;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintDecisionRequest
     */
    root_cause_id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertComplaintDecisionRequest
     */
    is_customer_accept_outcome?: boolean;
}


/**
 * @export
 */
export const UpsertComplaintDecisionRequestDecisionTypeEnum = {
    Uphold: 'uphold',
    NotUpheld: 'not_upheld',
    Partial: 'partial'
} as const;
export type UpsertComplaintDecisionRequestDecisionTypeEnum = typeof UpsertComplaintDecisionRequestDecisionTypeEnum[keyof typeof UpsertComplaintDecisionRequestDecisionTypeEnum];

/**
 * @export
 */
export const UpsertComplaintDecisionRequestFinalLetterTypeEnum = {
    Src: 'src',
    Frl: 'frl'
} as const;
export type UpsertComplaintDecisionRequestFinalLetterTypeEnum = typeof UpsertComplaintDecisionRequestFinalLetterTypeEnum[keyof typeof UpsertComplaintDecisionRequestFinalLetterTypeEnum];


/**
 * Check if a given object implements the UpsertComplaintDecisionRequest interface.
 */
export function instanceOfUpsertComplaintDecisionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpsertComplaintDecisionRequestFromJSON(json: any): UpsertComplaintDecisionRequest {
    return UpsertComplaintDecisionRequestFromJSONTyped(json, false);
}

export function UpsertComplaintDecisionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertComplaintDecisionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decision_type': !exists(json, 'decision_type') ? undefined : json['decision_type'],
        'final_letter_type': !exists(json, 'final_letter_type') ? undefined : json['final_letter_type'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'refund': !exists(json, 'refund') ? undefined : json['refund'],
        'interest': !exists(json, 'interest') ? undefined : json['interest'],
        'financial_loss': !exists(json, 'financial_loss') ? undefined : json['financial_loss'],
        'non_financial_loss': !exists(json, 'non_financial_loss') ? undefined : json['non_financial_loss'],
        'ex_gratia': !exists(json, 'ex_gratia') ? undefined : json['ex_gratia'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'approval_level': !exists(json, 'approval_level') ? undefined : json['approval_level'],
        'approved_by': !exists(json, 'approved_by') ? undefined : json['approved_by'],
        'approval_date': !exists(json, 'approval_date') ? undefined : json['approval_date'],
        'root_cause_id': !exists(json, 'root_cause_id') ? undefined : json['root_cause_id'],
        'is_customer_accept_outcome': !exists(json, 'is_customer_accept_outcome') ? undefined : json['is_customer_accept_outcome'],
    };
}

export function UpsertComplaintDecisionRequestToJSON(value?: UpsertComplaintDecisionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decision_type': value.decision_type,
        'final_letter_type': value.final_letter_type,
        'summary': value.summary,
        'refund': value.refund,
        'interest': value.interest,
        'financial_loss': value.financial_loss,
        'non_financial_loss': value.non_financial_loss,
        'ex_gratia': value.ex_gratia,
        'total': value.total,
        'approval_level': value.approval_level,
        'approved_by': value.approved_by,
        'approval_date': value.approval_date,
        'root_cause_id': value.root_cause_id,
        'is_customer_accept_outcome': value.is_customer_accept_outcome,
    };
}

