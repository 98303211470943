/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LetterType } from './LetterType';
import {
    LetterTypeFromJSON,
    LetterTypeFromJSONTyped,
    LetterTypeToJSON,
} from './LetterType';

/**
 * 
 * @export
 * @interface UpsertBespokeRequest
 */
export interface UpsertBespokeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertBespokeRequest
     */
    bespoke?: string;
    /**
     * 
     * @type {LetterType}
     * @memberof UpsertBespokeRequest
     */
    letter_type?: LetterType;
}

/**
 * Check if a given object implements the UpsertBespokeRequest interface.
 */
export function instanceOfUpsertBespokeRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpsertBespokeRequestFromJSON(json: any): UpsertBespokeRequest {
    return UpsertBespokeRequestFromJSONTyped(json, false);
}

export function UpsertBespokeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertBespokeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bespoke': !exists(json, 'bespoke') ? undefined : json['bespoke'],
        'letter_type': !exists(json, 'letter_type') ? undefined : LetterTypeFromJSON(json['letter_type']),
    };
}

export function UpsertBespokeRequestToJSON(value?: UpsertBespokeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bespoke': value.bespoke,
        'letter_type': LetterTypeToJSON(value.letter_type),
    };
}

