import { useEffect } from "react";
import '../styles/datatables.scss';
import { setDocumentTitle } from "../helpers/general";
import KpiVolumesDatatable from "../components/Datatables/KpiVolumesDatatable";
import { withAuthenticationRequired } from "@auth0/auth0-react";

/**
 * KPI Volumes page
 */
const KpiVolumesPage = () => {
    useEffect(() => {
        setDocumentTitle('KPI volumes');
    }, []);

    return (
        <div className="page-content">
            <KpiVolumesDatatable />
        </div>
    );
};

export default withAuthenticationRequired(KpiVolumesPage);
