/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcaRmarColumnReportResource } from './FcaRmarColumnReportResource';
import {
    FcaRmarColumnReportResourceFromJSON,
    FcaRmarColumnReportResourceFromJSONTyped,
    FcaRmarColumnReportResourceToJSON,
} from './FcaRmarColumnReportResource';

/**
 * 
 * @export
 * @interface FcaRmarReportResource
 */
export interface FcaRmarReportResource {
    /**
     * 
     * @type {string}
     * @memberof FcaRmarReportResource
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof FcaRmarReportResource
     */
    product?: string;
    /**
     * 
     * @type {number}
     * @memberof FcaRmarReportResource
     */
    total?: number;
    /**
     * 
     * @type {Array<FcaRmarColumnReportResource>}
     * @memberof FcaRmarReportResource
     */
    fca?: Array<FcaRmarColumnReportResource>;
}

/**
 * Check if a given object implements the FcaRmarReportResource interface.
 */
export function instanceOfFcaRmarReportResource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcaRmarReportResourceFromJSON(json: any): FcaRmarReportResource {
    return FcaRmarReportResourceFromJSONTyped(json, false);
}

export function FcaRmarReportResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcaRmarReportResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'fca': !exists(json, 'fca') ? undefined : ((json['fca'] as Array<any>).map(FcaRmarColumnReportResourceFromJSON)),
    };
}

export function FcaRmarReportResourceToJSON(value?: FcaRmarReportResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'product': value.product,
        'total': value.total,
        'fca': value.fca === undefined ? undefined : ((value.fca as Array<any>).map(FcaRmarColumnReportResourceToJSON)),
    };
}

