export type ValidationErrorContract = {
    errors: Record<string, string[]>
    message: string
};

export class ValidationError extends Error implements ValidationErrorContract {
    errors;

    constructor(
        message: string,
        errors: Record<string, string[]>
    ) {
        super(message);
        this.errors = errors;
    }
}
