import { useQuery } from '@tanstack/react-query';
import { GetComplaintLettersRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { letterApiClient } from "@/Service/Api/ApiClients/LetterApiClient";

export type UseGetComplaintLettersParams = {
    requestParams: GetComplaintLettersRequest,
    enabled?: boolean
};

export const useGetComplaintLetters = ({ requestParams, enabled }: UseGetComplaintLettersParams) => useQuery({
    ...QueryKeys.letters.complaint(requestParams),
    queryFn: async () => await letterApiClient.getComplaintLetters(requestParams),
    enabled
});
