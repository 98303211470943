/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BespokeResource,
  UpsertBespokeRequest,
} from '../models/index';
import {
    BespokeResourceFromJSON,
    BespokeResourceToJSON,
    UpsertBespokeRequestFromJSON,
    UpsertBespokeRequestToJSON,
} from '../models/index';

export interface CreateComplaintBespokeRequest {
    complaint_id: number;
    UpsertBespokeRequest?: UpsertBespokeRequest;
}

export interface DeleteComplaintBespokeRequest {
    complaint_id: number;
    bespoke_id: number;
}

export interface GetComplaintBespokeRequest {
    complaint_id: number;
    bespoke_id: number;
}

export interface GetComplaintBespokesRequest {
    complaint_id: number;
    organisation_id?: number;
}

export interface UpdateComplaintBespokeRequest {
    complaint_id: number;
    bespoke_id: number;
    UpsertBespokeRequest?: UpsertBespokeRequest;
}

/**
 * 
 */
export class BespokeMessagesApi extends runtime.BaseAPI {

    /**
     * create Complaint Bespoke
     */
    async createComplaintBespokeRaw(requestParameters: CreateComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BespokeResource>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling createComplaintBespoke.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/bespokes`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertBespokeRequestToJSON(requestParameters.UpsertBespokeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BespokeResourceFromJSON(jsonValue));
    }

    /**
     * create Complaint Bespoke
     */
    async createComplaintBespoke(requestParameters: CreateComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BespokeResource> {
        const response = await this.createComplaintBespokeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete Complaint Bespoke
     */
    async deleteComplaintBespokeRaw(requestParameters: DeleteComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling deleteComplaintBespoke.');
        }

        if (requestParameters.bespoke_id === null || requestParameters.bespoke_id === undefined) {
            throw new runtime.RequiredError('bespoke_id','Required parameter requestParameters.bespoke_id was null or undefined when calling deleteComplaintBespoke.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/bespokes/{bespoke_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"bespoke_id"}}`, encodeURIComponent(String(requestParameters.bespoke_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete Complaint Bespoke
     */
    async deleteComplaintBespoke(requestParameters: DeleteComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteComplaintBespokeRaw(requestParameters, initOverrides);
    }

    /**
     * get Complaint Bespoke
     */
    async getComplaintBespokeRaw(requestParameters: GetComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BespokeResource>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintBespoke.');
        }

        if (requestParameters.bespoke_id === null || requestParameters.bespoke_id === undefined) {
            throw new runtime.RequiredError('bespoke_id','Required parameter requestParameters.bespoke_id was null or undefined when calling getComplaintBespoke.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/bespokes/{bespoke_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"bespoke_id"}}`, encodeURIComponent(String(requestParameters.bespoke_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BespokeResourceFromJSON(jsonValue));
    }

    /**
     * get Complaint Bespoke
     */
    async getComplaintBespoke(requestParameters: GetComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BespokeResource> {
        const response = await this.getComplaintBespokeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get list of Complaint bespoke messages
     */
    async getComplaintBespokesRaw(requestParameters: GetComplaintBespokesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BespokeResource>>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintBespokes.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/bespokes`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BespokeResourceFromJSON));
    }

    /**
     * get list of Complaint bespoke messages
     */
    async getComplaintBespokes(requestParameters: GetComplaintBespokesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BespokeResource>> {
        const response = await this.getComplaintBespokesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update Complaint Bespoke
     */
    async updateComplaintBespokeRaw(requestParameters: UpdateComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BespokeResource>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateComplaintBespoke.');
        }

        if (requestParameters.bespoke_id === null || requestParameters.bespoke_id === undefined) {
            throw new runtime.RequiredError('bespoke_id','Required parameter requestParameters.bespoke_id was null or undefined when calling updateComplaintBespoke.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/bespokes/{bespoke_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"bespoke_id"}}`, encodeURIComponent(String(requestParameters.bespoke_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertBespokeRequestToJSON(requestParameters.UpsertBespokeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BespokeResourceFromJSON(jsonValue));
    }

    /**
     * update Complaint Bespoke
     */
    async updateComplaintBespoke(requestParameters: UpdateComplaintBespokeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BespokeResource> {
        const response = await this.updateComplaintBespokeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
