import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Hook to update URL search parameters.
 *
 * @returns {function} A function to update the URL search parameters.
 */
export const useUpdateSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return useCallback(
        /**
         * Updates the URL search parameter.
         *
         * @param {string} key - The key of the search parameter to update.
         * @param {string | undefined | null} value - The value of the search parameter. If null or undefined, the parameter is removed.
         */
        (key: string, value: string | undefined | null) => {
            if (value === null || value === '' || value === undefined) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, value);
            }
            setSearchParams(searchParams, { replace: true });
        },
        [searchParams, setSearchParams]
    );
};
