import React, { useEffect } from 'react';
import { Card } from 'primereact/card';
import PublicPageLayout from '../themes/default/layouts/PublicPageLayout';
import { setDocumentTitle } from '../helpers/general';
import {useAuth0} from "@auth0/auth0-react";

/**
 * Site's terms of use page
 */
const TermsOfUsePage = () => {
    const { isAuthenticated} = useAuth0();


    useEffect(() => {
        setDocumentTitle('Terms of Use');
    }, []);

    const pageContent = (
        <div className="footer-pages in-height ml-6 mt-5">
            <h1>Terms of Use</h1>

            <p>Bridgetech Connect continues to expand internationally to meet the needs of our clients around the world.</p>

            <p>Responsibility for providing services to the client is defined in the letter of engagement/other contractual document between Bridgetech Connect and the client.</p>

            <h2>Website</h2>

            <p>The contents of this website do not constitute legal or other professional advice. Users should seek appropriate legal guidance before coming to any decision or either taking or refraining from taking any legal action. Bridgetech Connect will endeavour to keep the content of this website up to date but disclaims all liability for loss and/or damage that may result from its use.</p>

            <p>If you have a specific question, you should address it with one of our team members.</p>

            <h2>Third-party websites</h2>

            <p>We provide links to third-party websites and content for your convenience. However, including such links does not imply endorsement by us of any content contained on the websites accessible via those links. Bridgetech Connect is not responsible for the content of such third-party websites and hereby excludes all liability for any loss and/or damage that you may incur as a result of your access and/or use of such content. Please note that such third-party content may be subject to terms and conditions imposed by the third-party owner of the content.</p>

            <h2>Copyright</h2>

            <p>The copyright in the contents of this website belongs to Bridgetech Connect. Accordingly, Bridgetech Connect reserves all rights. Copying of part or all the contents of this website without the prior written permission of Bridgetech Connect is prohibited except that:</p>

            <ul>
                <li>you may print or download extracts of the printed text contained on this website ("the Extracts") for your own personal, non-commercial use and provided it is not incorporated (or to be incorporated or reproduced, whether in whole or in part) in any other work or publication</li>
                <li>you may recopy the Extracts to individual third parties for their own private use only but only if: (a) you acknowledge the Bridgetech Connect website as the source of the Extracts; (b) the titles, credits and URLs are not altered or removed; (c) you inform the individual third parties that these conditions and the disclaimer attached to this website (see <strong>Website</strong> above) apply to the Extracts and that they must comply with them.</li>
            </ul>

            <p>For the avoidance of doubt (but without limitation), no part of this website (or any of its contents) may be distributed, reproduced or otherwise communicated to the public, whether in electronic form or hard copy for any commercial purpose.</p>

            <p>Links to the Bridgetech Connect website may not be included in any other website without our prior written permission. Please see the <strong>Contact us</strong> section of this website for contact information.</p>

            <h2>Emails</h2>

            <p>Email messages sent from users of Bridgetech Connects email systems have a legal disclaimer statement appended automatically. </p>

            <p>When addressed to our clients, any opinions or advice contained in any email and any attachments are subject to the governing client engagement letter, our terms and conditions and/or related communications. Opinions, conclusions and other information in any email and any attachments that do not relate to the firm's business are neither given nor endorsed by it.</p>

            <p>The information in any email and any files transmitted with it is intended only for the person or entity to which it is addressed and may contain confidential and/or privileged material. Any unauthorised review, retransmission, dissemination or other use of, or taking of any action in reliance upon, this information by persons or entities other than the intended recipient is prohibited. If you received an email in error, please contact the sender and delete the material from any computer.</p>

            <p>We do not assure, warrant or guarantee the technical integrity of any communication. Emails sent to and from our email systems may be stored on discs/archive storage in accordance with our backup/archiving policy.</p>

            <p>We automatically monitor all inbound/outbound emails for viruses, profanity, offensive language, racist or sexual comments, virus hoaxes, chain mail and known spam mailers. We reserve the right to intercept, store, archive, delete or view such emails for security/audit purposes and where necessary, instigate appropriate proceedings against the parties involved.</p>

            <p>We confirm that outbound emails have been swept for the presence of computer viruses, but we do not guarantee such messages are virus-free. The onus is on the receiving recipients to check they are virus-free.</p>
        </div>
    );

    return isAuthenticated ? (
        <>{pageContent}</>
    ) : (
        <PublicPageLayout>
            <Card>{pageContent}</Card>
        </PublicPageLayout>
    );
};

export default TermsOfUsePage;
