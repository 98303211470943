import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
    DataTable,
    type DataTablePassThroughOptions,
    DataTableRowEditCompleteEvent,
} from 'primereact/datatable';
import { Complaint, Letter } from '@/stub';
import { Column, ColumnBodyOptions, ColumnEditorOptions } from "primereact/column";
import { Button } from "primereact/button";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { useQueryClient } from "@tanstack/react-query";
import { truncateString } from "@/Util/truncateString";
import PreviewFileModal from "@/components/Modals/PreviewFileModal";
import { snakeToNormalCase } from "@/helpers/general";
import { dayStart } from "@formkit/tempo";
import { clsx } from "clsx";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useGetComplaintLetters } from "@/Service/Api/ApiHooks/Letter/useGetComplaintLetters";
import DownloadButton from "@/components/partials/DownloadButton";
import { useLetterSentDate } from "@/Service/Api/ApiHooks/Letter/useLetterSentDate";
import {
    ComplaintDocumentSentDateSavedMessage
} from "@/Messages/Toast/ComplaintDocuments/ComplaintDocumentSentDateSavedMessage";
import { formatToApiDate } from "@/Util/formatToApiDate";

type TableColumnDefinition = {
    label?: string
    field?: string
    body?: React.ReactNode | ((data: Letter, options: ColumnBodyOptions) => React.ReactNode)
    editor?: (options: ColumnEditorOptions) => React.ReactNode
    rowEditor?: (data: Letter) => boolean
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .signalizing-field {
        padding: 0.15rem 0.5rem;
        border-radius: 6px;
        text-wrap: nowrap;

        &.future {
            background-color: var(--teal-200)
        }

        &.success {
            background-color: var(--green-200);
        }

        &.warning {
            background-color: var(--yellow-100);
        }

        &.danger {
            background-color: var(--red-200);
        }
    }
`;

export type ComplaintLettersDatatableProps = {
    complaint: Complaint
};

const today = new Date();

const stateBodyRenderGenerator = (letter: Letter) => {
    if (letter.sent_date) {
        return (
            <span className="signalizing-field success">
                        Sent: {formatToUKDate(letter.sent_date)}
            </span>
        );
    }

    const isOverdue = dayStart(letter.due_date) < today;
    if(!isOverdue) {
        return (
            <span
                className={clsx('signalizing-field', { warning: isOverdue })}
            >
                {'Due'}: {formatToUKDate(letter.due_date)}
            </span>
        );
    }
    if(isOverdue) {
        return (
            <span
                className={clsx('signalizing-field', { warning: isOverdue })}
            >
                {'Overdue'}: {formatToUKDate(letter.due_date)}
            </span>
        );
    }

    return (
        <span
            className="signalizing-field future"
        >
                    Planned: {formatToUKDate(letter.due_date)}
        </span>
    );
};

const calendar = (options: ColumnEditorOptions) => {
    return <BaseCalendar
        value={options.value}
        minDate={options.rowData?.created_at}
        maxDate={new Date()}
        onChange={(e) => options.editorCallback(e.value)}
    />;
};

/**
 * Files datatable
 */
const ComplaintLettersDatatable: React.FC<ComplaintLettersDatatableProps> = ({
    complaint
}: ComplaintLettersDatatableProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [previewFile, setPreviewFile] = useState<Letter | null>(null);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const setLetterSentDateMutation = useLetterSentDate();

    const {
        data: letters,
        isLoading: lettersLoading
    } = useGetComplaintLetters({
        requestParams: {
            complaint_id: complaint?.id
        }
    });

    const onPreviewClick = (file: Letter) => {
        setPreviewFile(file);
        setPreviewVisible(true);
    };

    const closePreview = useCallback(() => {
        setPreviewFile(null);
        setPreviewVisible(false);
    }, [setPreviewVisible]);

    const onRowEditComplete = async (e: DataTableRowEditCompleteEvent) => {
        await setLetterSentDateMutation.mutateAsync({
            letter_id: e.newData.id,
            UpdateLetterSentRequest: {
                sent_date: formatToApiDate(e.newData.sent_date),
            }
        }, {
            onSuccess: () => {
                addToastMessage(ComplaintDocumentSentDateSavedMessage);
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.letters._def
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.complaints._def
                    })
                ]);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'ID',
            field: 'id'
        },
        {
            label: 'File name',
            body: (data) => {
                return truncateString(data.document?.name ?? '');
            }
        },
        {
            label: 'Type',
            body: (data) => {
                return snakeToNormalCase(data.type);
            }
        },
        {
            label: 'State',
            field: 'sent_date',
            body: stateBodyRenderGenerator,
            editor: complaint && calendar
        },
        {
            rowEditor: (data) => !data.sent_date
        },
        {
            label: 'Actions',
            body: data => {
                return <div className='flex gap-2'>
                    <DownloadButton file={data.document} complaintId={data.complaint_id}/>
                    <Button label='Preview' onClick={() => onPreviewClick(data)}/>
                </div>;
            }
        },
    ];

    return (
        <StyledWrap>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={lettersLoading}
                        emptyMessage="No letters found."
                        value={letters}
                        editMode='row'
                        pt={dataTablePtOptions}
                        onRowEditComplete={onRowEditComplete}
                    >
                        {tableColumns.map((column, index) =>
                            <Column
                                key={`letters-column-${index}`}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                                editor={column.editor}
                                rowEditor={column.rowEditor}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
            {previewFile && <PreviewFileModal
                visible={previewVisible}
                closePreview={closePreview}
                file={previewFile.document}
                complaint_id={previewFile.complaint_id}
            />}
        </StyledWrap>
    );
};

export default ComplaintLettersDatatable;
