export const FilterOptions = {
    Product: 'Product',
    RootCause: 'Root Cause',
    DateRange: 'Date Range',
    Customer: 'Customer',
    Owner: 'Owner',
    Status: 'Status',
} as const;

export type FilterOptions = typeof FilterOptions[keyof typeof FilterOptions];
