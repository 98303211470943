import React, { useMemo, useState } from 'react';
import BaseAutocomplete, { type BaseAutocompleteProps } from '@/components/Core/Form/BaseAutocomplete';
import { useGetUsers } from "@/Service/Api/ApiHooks/Users/useGetUsers";
import { Button } from "primereact/button";

export type UserSelectProps = BaseAutocompleteProps;

const UserSelect: React.FC<UserSelectProps> = (props: UserSelectProps) => {
    const [searchTerm, setSearchTerm] = useState<string>();

    const {
        data: users
    } = useGetUsers({
        requestParams: {
            search: searchTerm
        },
        enabled: !!searchTerm,
        savePreviousData: true
    });

    const suggestions = useMemo(() => {
        if (!users?.data) {
            return [];
        }
        return users.data;
    }, [users]);

    return (
        <div className="flex">
            <BaseAutocomplete
                {...props}
                value={searchTerm ?? props.value}
                field="display_name"
                delay={700}
                suggestions={suggestions}
                forceSelection
                onClear={() => {
                    // This will reset the input value to the initial one
                    setSearchTerm(undefined);
                }}
                onSelect={(e) => {
                    if(props.onSelect){
                        props.onSelect(e);
                    }
                    setSearchTerm(undefined);
                }}
                completeMethod={(e) => {
                    setSearchTerm(e.query);
                }}
            />
            {props.onClear && (searchTerm ?? props.value) && <Button icon="pi pi-times" className="" onClick={() => {
                props.onClear();
                setSearchTerm(undefined);
            }}
            rounded text aria-label="Cancel" /> }
        </div>


    );
};

export default UserSelect;
