// Forms config files

import { newComplaintFormConfig } from './forms/new-complaint';

export const fieldsPlaceholders = {
    text: 'Enter text',
    date: 'DD/MM/YYYY',
    dateRange: 'yyyy/mm/dd - yyyy/mm/dd',
    boolean: 'Select option',
    dropdown: 'Select option',
    autoComplete: 'Search text',
    integer: 'Enter number',
    float: 'Enter number',
    integerZero: '0',
    floatZero: '0.00'
};

export const complaintFormConfig = (custom_form_data = false) => {
    return {
        tabs: [
            {
                key: 'customer',
                name: 'Customer',
                hasSubCategories: true,
                fields: newComplaintFormConfig(custom_form_data).customer.categories
            },
            {
                key: 'complaint',
                name: 'Complaint',
                fields: newComplaintFormConfig(custom_form_data).complaint.fields
            },
            {
                key: 'investigation',
                name: 'Investigation',
                fields: [],
                customComponent: true
            },
            {
                key: 'rootCause',
                name: 'Root Cause',
                fields: newComplaintFormConfig(custom_form_data).rootCause.fields,
                customComponent: true
            },
            {
                key: 'outcome',
                name: 'Outcome',
                fields: [],
                customComponent: true
            },
            {
                key: 'fos',
                name: 'FOS',
                fields: [],
                customComponent: true
            },
            // {
            //     key: 'calls',
            //     name: 'Calls',
            //     fields: [],
            //     customComponent: true
            // },
            // {
            //     key: 'notes',

            //     name: 'Notes',
            //     fields: [],
            //     customComponent: true
            // },
            {
                key: 'contacts',
                name: 'Contacts',
                fields: [],
                customComponent: true
            },
            {
                key: 'audit',
                name: 'Audit',
                fields: [],
                customComponent: true
            },
            {
                key: 'files',
                name: 'Files',
                fields: [],
                customComponent: true
            },
        ]
    };
};

