import React from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import { createGlobalStyle } from "styled-components";
import RootCauseForm, { RootCauseFormFormProps } from "@/components/Core/RootCause/RootCauseForm";

export type RootCauseModalProps = {
    dialogProps: DialogProps
    customerFormProps: RootCauseFormFormProps
};

const RootCauseModalStyle = createGlobalStyle`
    .customer-form {
        width: 100%;
    }
`;

const RootCauseCreateModal: React.FC<RootCauseModalProps> = ({ dialogProps, customerFormProps }: RootCauseModalProps) => {

    return (
        <>
            <RootCauseModalStyle/>
            <Dialog
                draggable={false}
                blockScroll
                header="Update Root Cause information"

                {...dialogProps}
            >
                <RootCauseForm
                    {...customerFormProps}
                />
            </Dialog>
        </>
    );
};

export default RootCauseCreateModal;
