import { useMutation } from "@tanstack/react-query";
import { ExportFullComplaintsListRequest } from "@/stub";
import { exportComplaintApiClient } from "@/Service/Api/ApiClients/ComplaintExportApiClient";
import { processRateLimiterErrors } from "@/Util/Api/processRateLimiterErrors";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";

export const useFullComplaintsListExport = () => {
    return useMutation({
        mutationFn: async (data: ExportFullComplaintsListRequest) => {
            try {
                return await exportComplaintApiClient.exportFullComplaintsList(data);
            } catch (error) {
                await processRateLimiterErrors(error);
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
