import { useGetRootCauses } from "@/Service/Api/ApiHooks/RootCause/useGetRootCauses";
import {
    RootCause,
    UpdateRootCauseOperationRequest,
} from "@/stub";
import React, { useMemo, useState } from "react";
import { OptionsTree } from "@/Model/OptionsTree";
import { DataTable, type DataTableStateEvent } from "primereact/datatable";
import AnglesSort from "@/components/Icons/AnglesSort";
import { Column, type ColumnBodyOptions } from "primereact/column";
import { Button } from "primereact/button";
import ContentHeader from "@/components/Blocks/ContentHeader";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import styled from "styled-components";
import { useDebounce } from "react-use";
import { Dialog } from "primereact/dialog";
import { useUpdateRootCause } from "@/Service/Api/ApiHooks/RootCause/useUpdateRootCause";
import { BespokeEditedMessage } from "@/Messages/Toast/Bespokes/BespokeEditedMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import RootCauseCreateModal from "@/components/Modals/RootCauseCreateModal";
import { useCreateRootCause } from "@/Service/Api/ApiHooks/RootCause/useCreateRootCause";
import { RootCauseInfoInitData } from "@/components/Core/RootCause/RootCauseForm";



type RootCauseTableData = {
    key: number;
    data: RootCause;
    label: string;
    parent_names: string;
    selectable: boolean;
    children: RootCause[];
    code: string | null;
};


type TableColumnDefinition = {
    label: string
    sortable?: boolean
    field: string
    sortField?: string
    body?: React.ReactNode | ((data: RootCauseTableData, options: ColumnBodyOptions) => React.ReactNode)
};

const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }
    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;

const flattenRootCauseTree = (rootCauses: RootCause[]): OptionsTree<RootCause>[] => {
    const result: OptionsTree<RootCause>[] = [];

    const flatten = (nodes: RootCause[], parentNames: string) => {
        for (const node of nodes) {
            const newNode = new OptionsTree<RootCause>(
                node.id,
                node,
                node.label,
                parentNames,
                node.selectable,
                []
            );
            result.push(newNode);
            if (node.children) {
                flatten(node.children, parentNames ? `${parentNames} > ${node.label}` : node.label);
            }
        }
    };

    flatten(rootCauses, '');
    return result;
};

const RootCausePage = () => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [searchInput, setSearchInput] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [updatedCause, setUpdatedCause] = useState<RootCause | null>(null);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState('');
    const { mutateAsync: createRootCause } = useCreateRootCause();
    const { mutateAsync, isPending } = useUpdateRootCause();

    const {
        data: rootCauses,
        isLoading,
    } = useGetRootCauses({
        requestParams: { selectable_lvl: 1 },
        select: (rootCauses: RootCause[]) => rootCauses?.[0]?.children ?? [],
        enabled: true
    });

    const rootCauseOptions = useMemo(() => {
        if (rootCauses) {
            const flattened = flattenRootCauseTree(rootCauses);
            if (debouncedValue) {
                return flattened.filter(option =>
                    option.label.toLowerCase().includes(debouncedValue.toLowerCase())
                );
            }
            return flattened;
        }
        return [];
    }, [rootCauses, debouncedValue]);


    const [,] = useDebounce(
        () => {
            setDebouncedValue(searchInput);
        },
        1000,
        [searchInput]
    );
    const onPagination = (e:DataTableStateEvent) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const handleCreateNewRootCause = async (data:RootCauseInfoInitData) => {
        await createRootCause({
            CreateRootCauseRequest: {
                term: data.code,
                name: data.label,
                parent_id:  data.parent_id,
                fca_root_cause_id: data.fca_root_cause_id,
            }
        }, {
            onSuccess: () => {
                addToastMessage(BespokeEditedMessage);
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.rootCauses.list({ selectable_lvl: 1 }).queryKey
                });
                setIsDialogVisible(false);
                setUpdatedCause(null);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const handleUpdateRootCause = async () => {

        if(updatedCause && updatedCause.parent_id) {
            const finalData: UpdateRootCauseOperationRequest = {
                root_cause_id: updatedCause.id,
                UpdateRootCauseRequest: {
                    name: updatedCause.label,
                },
            };

            await mutateAsync(finalData, {
                onSuccess: () => {
                    addToastMessage(BespokeEditedMessage);
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.rootCauses.list({ selectable_lvl: 1 }).queryKey
                    });
                    setUpdatedCause(null);
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            });
        }
    };

    const actionBodyTemplate = (date: RootCause) => {
        return (
            <Button onClick={() => setUpdatedCause(date)}>Update</Button>
        );
    };


    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'ID',
            field: 'key'
        },
        {
            label: 'Parent Names',
            field: 'parent_names',
        },
        {
            label: 'Label',
            field: 'label',
        },
        {
            field:'',
            label: 'Actions',
            body: cause => actionBodyTemplate(cause.data),
        },
    ];



    const footerContent = (
        <Button label="Update" severity="success" icon="pi pi-check" disabled={isPending} onClick={handleUpdateRootCause}  />
    );

    return (
        <div>
            <StyledWrap>
                <ContentHeader
                    title={'Root Causes'}
                    description={'Root Causes'}
                    rightSectionTemplate={
                        <div className="flex gap-3">
                            <BaseTextInput
                                value={searchInput}
                                placeholder="Search"
                                onChange={(e) => {
                                    setSearchInput(e.target.value);
                                }}
                            />
                            <Button onClick={() => setIsDialogVisible(true)} > Create New Root Cause</Button>
                        </div>

                    }
                />
                <div className="content-container">
                    <DataTable
                        value={rootCauseOptions.slice(first, first + rows)}
                        lazy
                        loading={isLoading}
                        totalRecords={rootCauseOptions.length}
                        selectionMode={'single'}
                        paginator
                        scrollable
                        first={first}
                        rows={rows}
                        rowsPerPageOptions={[10, 20, 50]}
                        sortIcon={(options) => {
                            return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted} />;
                        }}
                        sortField={'label'}
                        sortOrder={1}
                        onPage={onPagination}
                    >
                        {tableColumns.map(column =>
                            <Column
                                key={column.label}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                            />
                        )}
                    </DataTable>
                </div>
            </StyledWrap>

            <RootCauseCreateModal
                dialogProps={{
                    visible: isDialogVisible,
                    onHide: () => {
                        setIsDialogVisible(false);
                    }
                }}
                customerFormProps={{
                    initFormData: undefined,
                    onSubmit: handleCreateNewRootCause,
                    onSubmitButtonLabel: 'Create',
                    onSubmitButtonDisabled: false,
                    isProcessing: false,
                }}
            />

            <Dialog
                onHide={() => setUpdatedCause(null)}
                visible={!!updatedCause}
                draggable={false}
                blockScroll
                footer={footerContent}
                header="Create Root Cause"

            >
                <BaseTextInput
                    onChange={(e) => {
                        if(!updatedCause) return;
                        setUpdatedCause({
                            ...updatedCause,
                            label: e.target.value
                        });
                    }}
                    className="rounded-md w-full"
                    value={updatedCause?.label}
                    required
                    name="label"
                    label={'Label'}
                />
            </Dialog>
        </div>
    );
};

export default RootCausePage;
