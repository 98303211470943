/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelDocument } from './ChannelDocument';
import {
    ChannelDocumentFromJSON,
    ChannelDocumentFromJSONTyped,
    ChannelDocumentToJSON,
} from './ChannelDocument';
import type { ComplaintPoints } from './ComplaintPoints';
import {
    ComplaintPointsFromJSON,
    ComplaintPointsFromJSONTyped,
    ComplaintPointsToJSON,
} from './ComplaintPoints';

/**
 * 
 * @export
 * @interface ComplaintEvidence
 */
export interface ComplaintEvidence {
    /**
     * 
     * @type {number}
     * @memberof ComplaintEvidence
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ComplaintEvidence
     */
    type?: ComplaintEvidenceTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintEvidence
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ComplaintEvidence
     */
    summary?: string;
    /**
     * 
     * @type {Array<ComplaintPoints>}
     * @memberof ComplaintEvidence
     */
    points?: Array<ComplaintPoints>;
    /**
     * 
     * @type {ChannelDocument}
     * @memberof ComplaintEvidence
     */
    document?: ChannelDocument | null;
}


/**
 * @export
 */
export const ComplaintEvidenceTypeEnum = {
    TelephoneCallSales: 'telephone_call_sales',
    TelephoneCallService: 'telephone_call_service',
    CustomerCorrespondenceEmail: 'customer_correspondence_email',
    CustomerCorrespondenceLetter: 'customer_correspondence_letter',
    CustomerCorrespondenceOther: 'customer_correspondence_other',
    BusinessCorrespondenceEmail: 'business_correspondence_email',
    BusinessCorrespondenceLetter: 'business_correspondence_letter',
    BusinessCorrespondenceOther: 'business_correspondence_other',
    ProductDocumentationTermsAndCond: 'product_documentation_terms_and_cond',
    ProductDocumentationFeeSchedule: 'product_documentation_fee_schedule',
    ProductDocumentationFeaturesAndBenefits: 'product_documentation_features_and_benefits',
    BusinessSystems: 'business_systems'
} as const;
export type ComplaintEvidenceTypeEnum = typeof ComplaintEvidenceTypeEnum[keyof typeof ComplaintEvidenceTypeEnum];


/**
 * Check if a given object implements the ComplaintEvidence interface.
 */
export function instanceOfComplaintEvidence(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComplaintEvidenceFromJSON(json: any): ComplaintEvidence {
    return ComplaintEvidenceFromJSONTyped(json, false);
}

export function ComplaintEvidenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintEvidence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'points': !exists(json, 'points') ? undefined : ((json['points'] as Array<any>).map(ComplaintPointsFromJSON)),
        'document': !exists(json, 'document') ? undefined : ChannelDocumentFromJSON(json['document']),
    };
}

export function ComplaintEvidenceToJSON(value?: ComplaintEvidence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substring(0,10)),
        'summary': value.summary,
        'points': value.points === undefined ? undefined : ((value.points as Array<any>).map(ComplaintPointsToJSON)),
        'document': ChannelDocumentToJSON(value.document),
    };
}

