/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    body?: string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    complaint_id?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Notification
     */
    user: User;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    read_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    created_at: Date;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof Notification
     */
    data?: Array<Array<string>>;
}

/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'complaint_id': !exists(json, 'complaint_id') ? undefined : json['complaint_id'],
        'user': UserFromJSON(json['user']),
        'read_at': !exists(json, 'read_at') ? undefined : (new Date(json['read_at'])),
        'created_at': (new Date(json['created_at'])),
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'body': value.body,
        'complaint_id': value.complaint_id,
        'user': UserToJSON(value.user),
        'read_at': value.read_at === undefined ? undefined : (value.read_at.toISOString().substring(0,10)),
        'created_at': (value.created_at.toISOString().substring(0,10)),
        'data': value.data,
    };
}

