/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpsertComplaintInvestigationRequestEvidenceInner } from './UpsertComplaintInvestigationRequestEvidenceInner';
import {
    UpsertComplaintInvestigationRequestEvidenceInnerFromJSON,
    UpsertComplaintInvestigationRequestEvidenceInnerFromJSONTyped,
    UpsertComplaintInvestigationRequestEvidenceInnerToJSON,
} from './UpsertComplaintInvestigationRequestEvidenceInner';
import type { UpsertComplaintInvestigationRequestOutcomesInner } from './UpsertComplaintInvestigationRequestOutcomesInner';
import {
    UpsertComplaintInvestigationRequestOutcomesInnerFromJSON,
    UpsertComplaintInvestigationRequestOutcomesInnerFromJSONTyped,
    UpsertComplaintInvestigationRequestOutcomesInnerToJSON,
} from './UpsertComplaintInvestigationRequestOutcomesInner';

/**
 * 
 * @export
 * @interface UpsertComplaintInvestigationRequest
 */
export interface UpsertComplaintInvestigationRequest {
    /**
     * 
     * @type {Array<UpsertComplaintInvestigationRequestEvidenceInner>}
     * @memberof UpsertComplaintInvestigationRequest
     */
    evidence?: Array<UpsertComplaintInvestigationRequestEvidenceInner>;
    /**
     * 
     * @type {Array<UpsertComplaintInvestigationRequestOutcomesInner>}
     * @memberof UpsertComplaintInvestigationRequest
     */
    outcomes?: Array<UpsertComplaintInvestigationRequestOutcomesInner>;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintInvestigationRequest
     */
    investigation_summary?: string | null;
}

/**
 * Check if a given object implements the UpsertComplaintInvestigationRequest interface.
 */
export function instanceOfUpsertComplaintInvestigationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpsertComplaintInvestigationRequestFromJSON(json: any): UpsertComplaintInvestigationRequest {
    return UpsertComplaintInvestigationRequestFromJSONTyped(json, false);
}

export function UpsertComplaintInvestigationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertComplaintInvestigationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evidence': !exists(json, 'evidence') ? undefined : ((json['evidence'] as Array<any>).map(UpsertComplaintInvestigationRequestEvidenceInnerFromJSON)),
        'outcomes': !exists(json, 'outcomes') ? undefined : ((json['outcomes'] as Array<any>).map(UpsertComplaintInvestigationRequestOutcomesInnerFromJSON)),
        'investigation_summary': !exists(json, 'investigation_summary') ? undefined : json['investigation_summary'],
    };
}

export function UpsertComplaintInvestigationRequestToJSON(value?: UpsertComplaintInvestigationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evidence': value.evidence === undefined ? undefined : ((value.evidence as Array<any>).map(UpsertComplaintInvestigationRequestEvidenceInnerToJSON)),
        'outcomes': value.outcomes === undefined ? undefined : ((value.outcomes as Array<any>).map(UpsertComplaintInvestigationRequestOutcomesInnerToJSON)),
        'investigation_summary': value.investigation_summary,
    };
}

