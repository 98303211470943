import { useMutation } from '@tanstack/react-query';
import { type CreateComplaintChannelRequest } from '@/stub';
import type { UseFormSetError } from 'react-hook-form';
import { ComplaintChannelsApiClient } from '@/Service/Api/ApiClients/ComplaintChannelsApiClient';
import { processValidationErrors } from '@/Util/Api/processValidationErrors';

type useCreateComplaintChannelParams = {
    setError?: UseFormSetError<never>
}

export const useCreateComplaintChannel = ({ setError }: useCreateComplaintChannelParams = {}) => {
    return useMutation({
        mutationFn: async (data: CreateComplaintChannelRequest) => {
            try {
                return await ComplaintChannelsApiClient.createComplaintChannel(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    })
}
