import Footer from '../../../components/Blocks/Footer';
import { ForsetiLogoXs } from '../../../helpers/svg-icons';
import '../../../themes/default/styles/public-page-layout.scss';
import { Link } from "react-router-dom";
import React, { ReactNode } from 'react';

type PublicPageLayoutProps = {
    children?: ReactNode
};

const PublicPageLayout = ({ children }: PublicPageLayoutProps) => {
    return (
        <div className="public-page-layout">
            <div className="flex flex-column align-items-center justify-content-center h-full">
                <div>
                    <div className="flex justify-content-center align-items-center">
                        <Link to={"/"} className="site-logo uppercase-dark-text p-link mt-5">
                            <img src="img/logo-icon.png" />
                            <div className="logo-wrap flex justify-content-end">
                                <ForsetiLogoXs />
                            </div>
                        </Link>
                    </div>
                    {children}
                </div>

                <Footer />
            </div>
        </div>
    );
};

export default PublicPageLayout;
