/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface CloseComplaintActionRequest {
    complaint_id: number;
    action_id: number;
}

/**
 * 
 */
export class ComplaintActionsApi extends runtime.BaseAPI {

    /**
     * closeComplaintAction
     */
    async closeComplaintActionRaw(requestParameters: CloseComplaintActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling closeComplaintAction.');
        }

        if (requestParameters.action_id === null || requestParameters.action_id === undefined) {
            throw new runtime.RequiredError('action_id','Required parameter requestParameters.action_id was null or undefined when calling closeComplaintAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/actions/{action_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"action_id"}}`, encodeURIComponent(String(requestParameters.action_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * closeComplaintAction
     */
    async closeComplaintAction(requestParameters: CloseComplaintActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.closeComplaintActionRaw(requestParameters, initOverrides);
    }

}
