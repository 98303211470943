import { useMutation } from '@tanstack/react-query';
import type { UseFormSetError } from 'react-hook-form';
import { type UpdateFosInComplaintRequest } from '@/stub';
import { ComplaintFosApiClient } from '@/Service/Api/ApiClients/ComplaintFosApiClient';

type useUpdateComplaintFosParams = {
    setError?: UseFormSetError<never>
};

export const useUpdateComplaintFos = ({ setError }: useUpdateComplaintFosParams = {}) => {
    return useMutation({
        mutationFn: async (data: UpdateFosInComplaintRequest) => await ComplaintFosApiClient.updateFosInComplaint(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    Object.keys(errorBody.errors).map(key => {
                        setError(key, { message: errorBody.errors[key][0] });
                    });
                    throw new Error(errorBody.message);
                }
                )
            )
    });
};
