/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetActivityLogList200ResponseLinks
 */
export interface GetActivityLogList200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof GetActivityLogList200ResponseLinks
     */
    first?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivityLogList200ResponseLinks
     */
    last?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivityLogList200ResponseLinks
     */
    prev?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivityLogList200ResponseLinks
     */
    next?: string | null;
}

/**
 * Check if a given object implements the GetActivityLogList200ResponseLinks interface.
 */
export function instanceOfGetActivityLogList200ResponseLinks(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetActivityLogList200ResponseLinksFromJSON(json: any): GetActivityLogList200ResponseLinks {
    return GetActivityLogList200ResponseLinksFromJSONTyped(json, false);
}

export function GetActivityLogList200ResponseLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetActivityLogList200ResponseLinks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'prev': !exists(json, 'prev') ? undefined : json['prev'],
        'next': !exists(json, 'next') ? undefined : json['next'],
    };
}

export function GetActivityLogList200ResponseLinksToJSON(value?: GetActivityLogList200ResponseLinks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first': value.first,
        'last': value.last,
        'prev': value.prev,
        'next': value.next,
    };
}

