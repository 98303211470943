import { useQuery } from '@tanstack/react-query';
import { GetProductRequest } from '@/stub';
import { productApiClient } from '@/Service/Api/ApiClients/ProductApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetProductsParams = {
    requestParams: GetProductRequest
    enabled?: boolean
};

export const useGetProduct = ({ enabled = true, requestParams = {} }: useGetProductsParams) => useQuery({
    ...QueryKeys.products.list(requestParams),
    queryFn: async () => await productApiClient.getProduct(requestParams),
    enabled,
});
