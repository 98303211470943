import React from 'react';
import {
    Complaint,
    ComplaintInvestigation,
    ComplaintPoints,
    EvidenceConsideredType
} from '@/stub';
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import { FieldArrayWithId, useWatch } from "react-hook-form";
import BaseTextEditor from "@/components/Core/Form/BaseTextEditor";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { fieldsPlaceholders } from "@/config/forms";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import BaseMultiselect from "@/components/Core/Form/BaseMultiselect";
import AdvancedFileUpload from "@/components/Core/Form/FileUpload/AdvancedFileUpload";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { classNames } from "primereact/utils";

export type ComplaintEvidenceInputFormProps = BaseFormInputProps & {
    complaint: Complaint
    evidence: FieldArrayWithId<ComplaintInvestigation, "evidence", "id">
    index: number
    removeEvidence: (index: number) => void
    availablePoints: ComplaintPoints[]
    evidenceConsideredTypes: EvidenceConsideredType[]
};

type ProcessedComplaintPoint = {
    content: string
};

const multiselectItemTemplate = (option: ComplaintPoints): React.ReactNode => {
    return <div style={{ whiteSpace: 'pre-wrap', maxWidth: '200px', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: option?.content ?? '' }}/>;
};

const multiselectSelectedItemTemplate = (value: ProcessedComplaintPoint): React.ReactNode => {
    if (!value) {
        return <span>Select option</span>;
    }
    return <div className='overflow-hidden multiselect-selected-label' dangerouslySetInnerHTML={{ __html: value ?? '' }}/>;
};

const ComplaintEvidenceInputForm: React.FC<ComplaintEvidenceInputFormProps> = ({
    complaint,
    control,
    errorMessages,
    evidence,
    removeEvidence,
    index,
    availablePoints,
    evidenceConsideredTypes
}: ComplaintEvidenceInputFormProps) => {
    const controlledValue = control ? useWatch({
        control: control,
        name: `evidence[${index}].points`,
    }) : undefined;
    return <div className="mb-3">
        <div className="flex flex-column gap-3">
            <div className="flex flex-grow-1 flex-row gap-2 w-full align-items-center justify-content-center flex-column xl:flex-row">
                <div className="mt-4 w-2rem">#{index + 1}</div>
                <BaseDropdown
                    className='input'
                    control={control}
                    name={`evidence[${index}].type`}
                    label="Type"
                    disabled={isReadonlyComplaint(complaint)}
                    placeholder={fieldsPlaceholders.dropdown}
                    options={evidenceConsideredTypes}
                    errorMessages={errorMessages?.evidence?.[index]?.type?.message}
                />

                <BaseCalendar
                    className='input'
                    control={control}
                    label="Date"
                    name={`evidence[${index}].date`}
                    placeholder={fieldsPlaceholders.date}
                    disabled={isReadonlyComplaint(complaint)}
                    errorMessages={errorMessages?.evidence?.[index]?.date?.message}
                />

                <BaseMultiselect
                    className={classNames('w-full',{ 'input': controlledValue?.length === 0, 'xl:w-3': controlledValue?.length > 0 })}
                    control={control}
                    name={`evidence[${index}].points`}
                    label="Related complaint points"
                    optionLabel="content"
                    optionValue='content'
                    pt={{
                        root: {
                            className: 'w-12',
                        },
                    }}
                    placeholder={fieldsPlaceholders.dropdown}
                    options={availablePoints}
                    maxSelectedLabels={1}
                    itemTemplate={multiselectItemTemplate}
                    selectedItemTemplate={multiselectSelectedItemTemplate}
                    showClear
                    filter
                    disabled={isReadonlyComplaint(complaint)}
                    filterBy='content'
                    errorMessages={errorMessages?.evidence?.[index]?.points?.message}
                />

                {!isReadonlyComplaint(complaint) &&
                    <div className="ml-2 mt-5 w-2rem cursor-pointer" onClick={() => {
                        removeEvidence(index);
                    }}>
                        <i className="pi pi-times"/>
                    </div>}
            </div>
            <div>
                <BaseTextEditor
                    label='Summary'
                    textareaName={`evidence[${index}].summary`}
                    control={control}
                    height='130px'
                    menubar={false}
                    disabled={isReadonlyComplaint(complaint)}
                    errorMessages={errorMessages?.evidence?.[index]?.summary?.message}
                />
            </div>
            <div className='file-wrap'>
                <AdvancedFileUpload
                    className="mt-3"
                    name={`evidence[${index}].files`}
                    control={control}
                    label='Upload file'
                    multiple={false}
                    existingFiles={evidence.document ? [evidence.document] : []}
                    complaintId={complaint.id}
                    downloadExistingFiles
                    disabled={isReadonlyComplaint(complaint)}
                    errorMessages={errorMessages?.evidence?.[index]?.document?.message}
                />
            </div>
        </div>
    </div>;
};

export default ComplaintEvidenceInputForm;
