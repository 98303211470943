import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import {
    type Complaint,
    ComplaintFosAdjudicationOutcome,
    ComplaintFosCaseFileReview,
    ComplaintFosFinalDecisionOutcome,
    ComplaintFosFinalDecisionRequestedBy,
    type StoreComplaintFosRequest,
    type UpdateComplaintFosRequest
} from '@/stub';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseCheckbox from '@/components/Core/Form/BaseCheckbox';
import { Divider } from 'primereact/divider';
import BaseCalendar from '@/components/Core/Form/BaseCalendar';
import { fieldsPlaceholders } from '@/config/forms';
import BaseDropdown from '@/components/Core/Form/BaseDropdown';
import { capitalize } from '@/Util/capitalize';
import BaseTextEditor from '@/components/Core/Form/BaseTextEditor';
import { snakeToNormalCase } from '@/helpers/general';
import { z, type ZodObject } from 'zod';
import { useCreateComplaintFos } from '@/Service/Api/ApiHooks/ComplaintFos/useCreateComplaintFos';
import { useToastMessagesStore } from '@/Stores/ToastMessagesStore';
import { ComplaintFosCreatedMessage } from '@/Messages/Toast/ComplaintFos/ComplaintFosCreatedMessage';
import { useUpdateComplaintFos } from '@/Service/Api/ApiHooks/ComplaintFos/useUpdateComplaintFos';
import { ComplaintFosUpdatedMessage } from '@/Messages/Toast/ComplaintFos/ComplaintFosUpdatedMessage';
import { useGetComplaintFos } from '@/Service/Api/ApiHooks/ComplaintFos/useGetComplaintFos';
import { useQueryClient } from '@tanstack/react-query';
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import DownloadButton from "@/components/partials/DownloadButton";
import { useRefreshComplaintFosArchive } from "@/Service/Api/ApiHooks/ComplaintFos/useRefreshComplaintFosArchive";
import { downloadBlob } from "@/Util/downloadBlob";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { useFormStateStore } from "@/Stores/FormStore";
import { isEmpty } from "@/Util/isEmptyObject";
import BaseNumberInput from "@/components/Core/Form/BaseNumberInput";

const FosWrap = styled.div`
    .checkbox > label {
        font-weight: bolder;
    }
`;

export type FosProps = {
    complaint?: Complaint
};

const finalDecisionRequestedByOptions = Object.values(ComplaintFosFinalDecisionRequestedBy).map(option => ({
    value: option,
    label: capitalize(option)
}));

const finalDecisionOutcomeOptions = Object.values(ComplaintFosFinalDecisionOutcome).map(option => ({
    value: option,
    label: snakeToNormalCase(option)
}));

const caseFileReviewOptions = Object.values(ComplaintFosCaseFileReview).map(option => ({
    value: option,
    label: snakeToNormalCase(option)
}));

const adjudictionOutcomeOptions = Object.values(ComplaintFosAdjudicationOutcome).map(option => ({
    value: option,
    label: snakeToNormalCase(option)
}));

const FosSchema = z.object({
    id: z.number().optional(),
    is_fos: z.boolean({
        required_error: 'Case file requested needs to be checked'
    }).refine(value => value, 'Case file requested needs to be checked'),
    case_requested_date_received: z.date().optional(),
    case_requested_date_due: z.date().optional(),
    case_review: z.string()
        .refine((type) => Object.values(ComplaintFosCaseFileReview).includes(type))
        .optional(),
    case_review_summary: z.string().optional(),
    case_submitted: z.date().optional(),
    adjudication_date_received: z.date().optional(),
    adjudication_outcome: z.string()
        .refine((type) => Object.values(ComplaintFosAdjudicationOutcome).includes(type))
        .optional(),
    adjudication_outcome_summary: z.string().max(500).optional(),
    adjudication_date_request_final_decision: z.date().optional(),
    final_decision_requested: z.boolean().optional(),
    final_decision_date_requested: z.date().optional(),
    final_decision_requested_by: z.string()
        .refine((type) => Object.values(ComplaintFosFinalDecisionRequestedBy).includes(type))
        .optional(),
    escalation_reason: z.string().max(500).optional(),
    further_info_due_date: z.date().optional(),
    further_info_date_submitted: z.date().optional(),
    further_info_summary: z.string().max(500).optional(),
    final_decision_date_received: z.date().optional(),
    final_decision_outcome: z.string()
        .refine((type) => Object.values(ComplaintFosFinalDecisionOutcome).includes(type))
        .optional(),
    final_decision_outcome_summary: z.string().optional(),
    redress: z.number().optional(),
    other_remedial: z.boolean().optional(),
    remedial_summary: z.string().optional(),
    remedial_date_due: z.date().optional(),
    remedial_date_completed: z.date().optional(),
}).superRefine((data, ctx) => {
    if (data.is_fos) {
        if (!data.case_requested_date_received) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Date received is required when case file is requested',
                path: ['case_requested_date_received']
            });
        }
        if (!data.case_requested_date_due) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Due date is required when case file is requested',
                path: ['case_requested_date_due']
            });
        }
    }

    if (data.case_submitted) {
        if (!data.case_review) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Casefile review is required when Casefile submission date is entered',
                path: ['case_review']
            });
        }
        if (!data.case_review_summary) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Summary of review is required when Casefile submission date is entered',
                path: ['case_review_summary']
            });
        }
    }

    if (data.adjudication_date_received) {
        if (!data.adjudication_outcome) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Adjudication outcome is required when Adjudication date received is entered',
                path: ['adjudication_outcome']
            });
        }
        if (!data.adjudication_outcome_summary) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Outcome summary is required when Adjudication outcome is entered',
                path: ['adjudication_outcome_summary']
            });
        }
    }

    if (data.final_decision_requested) {
        if (!data.final_decision_date_requested) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Date requested is required when Final Decision requested',
                path: ['final_decision_date_requested']
            });
        }
        if (!data.final_decision_requested_by) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Requested by is required when Final Decision requested',
                path: ['final_decision_requested_by']
            });
        }
        if (!data.escalation_reason) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Reason is required when Final Decision requested',
                path: ['escalation_reason']
            });
        }
        if (!data.further_info_due_date) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Further information due date is required when final decision is requested',
                path: ['further_info_due_date']
            });
        }
        if (!data.further_info_date_submitted) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Further information date submitted is required when final decision is requested',
                path: ['further_info_date_submitted']
            });
        }
        if (data.further_info_date_submitted && !data.further_info_summary) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Summary of further information provided is required when date submitted for further information (FD) is entered',
                path: ['further_info_summary']
            });
        }
    }

    if (data.final_decision_outcome) {
        if (!data.final_decision_date_received) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Date Received is required when a Final Decision Outcome is entered',
                path: ['final_decision_date_received']
            });
        }
        if (!data.final_decision_outcome_summary) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Outcome summary is required when Final Decision outcome is entered',
                path: ['final_decision_outcome_summary']
            });
        }
    }

    if(data.other_remedial) {
        if (!data.remedial_summary) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Remedial summary is required when Other remedial action is selected',
                path: ['remedial_summary']
            });
        }
        if (!data.remedial_date_due) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Remedial action due date is required when Other remedial action is selected',
                path: ['remedial_date_due']
            });
        }
        if (!data.remedial_date_completed) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Remedial action completed date is required before the case can be closed',
                path: ['remedial_date_completed']
            });
        }
    }
    if (data.adjudication_outcome === "agree_with_customer" || data.final_decision_outcome === 'agree_with_customer') {
        if (!data.redress) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Redress is required when the outcome is "Agree with customer"',
                path: ['redress']
            });
        }
    }
});

type FosFormData = z.infer<typeof FosSchema>;

/**
 * Component which renders the FOS tab
 */
const Fos: React.FC<FosProps> = ({ complaint }: FosProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const setDirty = useFormStateStore((state) => state.setDirty);
    const { data: fosData } = useGetComplaintFos({
        requestParams: {
            complaint_id: complaint.id
        },
        enabled: complaint && complaint.is_fos
    });

    const {
        control,
        reset,
        handleSubmit,
        setError,
        formState: { errors, dirtyFields }
    } = useForm<FosFormData>({
        resolver: zodResolver(FosSchema),
        defaultValues: useMemo(() => {
            return { ...fosData, is_fos: complaint?.is_fos, final_decision_requested: complaint?.final_decision_requested ?? false };
        }, [fosData])
    });

    const createFosMutation = useCreateComplaintFos({ setError });
    const updateFosMutation = useUpdateComplaintFos({ setError });
    const refreshFosArchiveMutation = useRefreshComplaintFosArchive();

    const finalDecisionRequested = useWatch({
        control,
        name: 'final_decision_requested'
    });
    const caseFileRequested = useWatch({
        control,
        name: 'is_fos'
    });
    const otherRemedial = useWatch({
        control,
        name: 'other_remedial'
    });

    const validate = (data: FosFormData, schema: ZodObject<never>) => {
        return schema.safeParse(data).success;

    };

    const onSubmit = async (data: FosFormData) => {
        if (validate(data, FosSchema)) {
            const dataToSend: StoreComplaintFosRequest | UpdateComplaintFosRequest = {
                ...data,
                case_requested_date_received: formatToApiDate(data.case_requested_date_received),
                case_requested_date_due: formatToApiDate(data.case_requested_date_due),
                adjudication_date_received: formatToApiDate(data.adjudication_date_received),
                adjudication_date_request_final_decision: formatToApiDate(data.adjudication_date_request_final_decision),
                final_decision_date_requested: formatToApiDate(data.final_decision_date_requested),
                further_info_due_date: formatToApiDate(data.further_info_due_date),
                further_info_date_submitted: formatToApiDate(data.further_info_date_submitted),
                final_decision_date_received: formatToApiDate(data.final_decision_date_received),
                remedial_date_due: formatToApiDate(data.remedial_date_due),
                remedial_date_completed: formatToApiDate(data.remedial_date_completed)
            };
            if (complaint?.is_fos) {
                await updateFosMutation.mutateAsync({
                    fos_id: data.id,
                    complaint_id: complaint.id,
                    UpdateComplaintFosRequest: dataToSend
                }, {
                    onSuccess: () => {
                        void Promise.all([
                            queryClient.invalidateQueries({
                                queryKey: QueryKeys.complaints.detail(complaint?.id).queryKey
                            }),
                            queryClient.invalidateQueries({
                                queryKey: QueryKeys.fos.detail({ complaint_id: complaint.id }).queryKey
                            }),

                            queryClient.invalidateQueries({
                                queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                            })
                        ]);
                        addToastMessage(ComplaintFosUpdatedMessage);
                    },
                    onError: error => {
                        addToastMessage(CustomErrorMessage(error));
                    }
                });
            } else {
                await createFosMutation.mutateAsync({
                    complaint_id: complaint.id,
                    StoreComplaintFosRequest: dataToSend
                }, {
                    onSuccess: () => {
                        void Promise.all([
                            queryClient.invalidateQueries({
                                queryKey: QueryKeys.complaints.detail(complaint?.id).queryKey
                            }),
                            queryClient.invalidateQueries({
                                queryKey: QueryKeys.fos.detail({ complaint_id: complaint?.id }).queryKey
                            }),
                            queryClient.invalidateQueries({
                                queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                            })
                        ]);
                        addToastMessage(ComplaintFosCreatedMessage);
                    },
                    onError: error => {
                        addToastMessage(CustomErrorMessage(error));
                    }
                });
            }
        }
    };

    const refreshCaseFile = async (event) => {
        event.preventDefault();
        await refreshFosArchiveMutation.mutateAsync({
            fos_id: fosData?.id,
            complaint_id: complaint?.id,
        }, {
            onSuccess: (blob) => {
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.fos.detail({ complaint_id: complaint?.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                    })
                ]);
                downloadBlob(blob, fosData?.document?.name);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    useEffect(() => {
        if (fosData) {
            reset({ ...fosData, redress: parseFloat(fosData.redress) });
        }
    }, [fosData]);

    const isDirty = !isEmpty(dirtyFields);
    useEffect(() => {
        setDirty(isDirty);
    }, [isDirty]);


    return (
        <FosWrap>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formgrid grid my-3 mx-3 row-gap-3">
                    <div className="col-12">
                        <BaseCheckbox
                            className="checkbox"
                            control={control}
                            name="is_fos"
                            checked={caseFileRequested}
                            label="Case file requested"
                            disabled={isReadonlyComplaint(complaint)}
                            required
                            errorMessages={errors.is_fos?.message}
                        />
                    </div>

                    {
                        caseFileRequested &&
                        <>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    label="Date received"
                                    name='case_requested_date_received'
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.date}
                                    errorMessages={errors.case_requested_date_received?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    label="Due date"
                                    name='case_requested_date_due'
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.date}
                                    errorMessages={errors.case_requested_date_due?.message}
                                />
                            </div>
                            {complaint?.is_fos && <div className="col-12">
                                <div>
                                    <label className='block mb-2'>Case file</label>
                                    <div className='flex flex-column row-gap-1'>
                                        <span>{fosData?.document?.name}</span>
                                        <div className='flex gap-2'>
                                            <DownloadButton
                                                file={fosData?.document}
                                                complaintId={complaint?.id}
                                                text='Download'
                                                loading={updateFosMutation.isPending}
                                            />
                                            <Button
                                                label='Refresh file'
                                                icon='pi pi-refresh'
                                                disabled={isReadonlyComplaint(complaint)}
                                                onClick={refreshCaseFile}
                                                loading={refreshFosArchiveMutation.isPending || updateFosMutation.isPending}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className="col-12">
                                <BaseDropdown
                                    control={control}
                                    name="case_review"
                                    label="Case file review"
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.dropdown}
                                    options={caseFileReviewOptions}
                                    errorMessages={errors.case_review?.message}
                                />
                            </div>
                            <div className="col-6">
                                <BaseTextEditor
                                    label='Summary of review'
                                    textareaName='case_review_summary'
                                    control={control}
                                    menubar={false}
                                    disabled={isReadonlyComplaint(complaint)}
                                    errorMessages={errors?.case_review_summary?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    label="Case file submission"
                                    name='case_submitted'
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.date}
                                    errorMessages={errors.case_submitted?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    disabled={isReadonlyComplaint(complaint)}
                                    label="FOS Adjudication"
                                    name='adjudication_date_received'
                                    placeholder={fieldsPlaceholders.date}
                                    errorMessages={errors.adjudication_date_received?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseDropdown
                                    control={control}
                                    name="adjudication_outcome"
                                    label="Outcome"
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.dropdown}
                                    options={adjudictionOutcomeOptions}
                                    errorMessages={errors.adjudication_outcome?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    label="Date to request Final Decision"
                                    name='adjudication_date_request_final_decision'
                                    placeholder={fieldsPlaceholders.date}
                                    disabled={isReadonlyComplaint(complaint)}
                                    errorMessages={errors.adjudication_date_request_final_decision?.message}
                                />
                            </div>
                            <div className="col-6">
                                <BaseTextEditor
                                    label='Outcome Summary'
                                    textareaName='adjudication_outcome_summary'
                                    control={control}
                                    menubar={false}
                                    disabled={isReadonlyComplaint(complaint)}
                                    errorMessages={errors?.adjudication_outcome_summary?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseNumberInput
                                    control={control}
                                    name="redress"
                                    label="Redress"
                                    placeholder={fieldsPlaceholders.floatZero}
                                    mode='decimal'
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    type='decimal'
                                    errorMessages={errors.redress?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCheckbox
                                    className="checkbox"
                                    control={control}
                                    name="other_remedial"
                                    checked={otherRemedial}
                                    label="Other remedial"
                                    errorMessages={errors.other_remedial?.message}
                                />
                            </div>

                            {otherRemedial &&
                                <>
                                    <div className="col-12">
                                        <BaseTextEditor

                                            label='Remedial Summary summary'
                                            textareaName='remedial_summary'
                                            control={control}
                                            menubar={false}
                                            errorMessages={errors?.remedial_summary?.message}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <BaseCalendar
                                            control={control}
                                            label="Remedial Date due"
                                            name='remedial_date_due'
                                            placeholder={fieldsPlaceholders.date}
                                            errorMessages={errors.remedial_date_due?.message}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <BaseCalendar
                                            control={control}
                                            label="Remedial Date Completed"
                                            name='remedial_date_completed'
                                            placeholder={fieldsPlaceholders.date}
                                            errorMessages={errors.remedial_date_completed?.message}
                                        />
                                    </div>
                                </>
                            }
                        </>
                    }

                    <Divider/>

                    <div className="col-12">
                        <BaseCheckbox
                            className="checkbox"
                            control={control}
                            name="final_decision_requested"
                            checked={finalDecisionRequested}
                            disabled={isReadonlyComplaint(complaint)}
                            label="Final decision requested"
                            errorMessages={errors.final_decision_requested?.message}
                        />
                    </div>

                    {
                        finalDecisionRequested && <>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    disabled={isReadonlyComplaint(complaint)}
                                    label="Date requested"
                                    name='final_decision_date_requested'
                                    placeholder={fieldsPlaceholders.date}
                                    errorMessages={errors.final_decision_date_requested?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseDropdown
                                    control={control}
                                    name="final_decision_requested_by"
                                    label="Requested by"
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.dropdown}
                                    options={finalDecisionRequestedByOptions}
                                    errorMessages={errors.final_decision_requested_by?.message}
                                />
                            </div>
                            <div className="col-6">
                                <BaseTextEditor
                                    label='Reason'
                                    textareaName='escalation_reason'
                                    control={control}
                                    menubar={false}
                                    disabled={isReadonlyComplaint(complaint)}
                                    errorMessages={errors?.escalation_reason?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    label="Further information due date"
                                    name='further_info_due_date'
                                    placeholder={fieldsPlaceholders.date}
                                    disabled={isReadonlyComplaint(complaint)}
                                    errorMessages={errors.further_info_due_date?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    label="Further information date submitted"
                                    name='further_info_date_submitted'
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.date}
                                    errorMessages={errors.further_info_date_submitted?.message}
                                />
                            </div>
                            <div className="col-6">
                                <BaseTextEditor
                                    label='Further information summary'
                                    textareaName='further_info_summary'
                                    control={control}
                                    menubar={false}
                                    disabled={isReadonlyComplaint(complaint)}
                                    errorMessages={errors?.further_info_summary?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseCalendar
                                    control={control}
                                    label="Final Decision date received"
                                    name='final_decision_date_received'
                                    disabled={isReadonlyComplaint(complaint)}
                                    placeholder={fieldsPlaceholders.date}
                                    errorMessages={errors.final_decision_date_received?.message}
                                />
                            </div>
                            <div className="col-12">
                                <BaseDropdown
                                    control={control}
                                    name="final_decision_outcome"
                                    label="Final decision outcome"
                                    placeholder={fieldsPlaceholders.dropdown}
                                    options={finalDecisionOutcomeOptions}
                                    disabled={isReadonlyComplaint(complaint)}
                                    errorMessages={errors.final_decision_outcome?.message}
                                />
                            </div>
                            <div className="col-6">
                                <BaseTextEditor
                                    label='Outcome summary'
                                    textareaName='final_decision_outcome_summary'
                                    control={control}
                                    disabled={isReadonlyComplaint(complaint)}
                                    menubar={false}
                                    errorMessages={errors?.final_decision_outcome_summary?.message}
                                />
                            </div>
                        </>
                    }
                </div>
                <Divider/>

                <div className="flex">
                    <Button
                        type="submit"
                        label='Submit'
                        className="ml-auto"
                        icon='pi pi-check-circle'
                        iconPos="right"
                        disabled={isReadonlyComplaint(complaint)}
                        loading={createFosMutation.isPending || updateFosMutation.isPending}
                    />
                </div>
            </form>
        </FosWrap>
    );
};

export default Fos;
