import React, { useMemo, useState } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import { DataTable, type DataTablePassThroughOptions, type DataTableStateEvent } from 'primereact/datatable';
import { type Complaint, DocumentResource, GetComplaintDocumentsRequest, } from '@/stub';
import { produce } from 'immer';
import { useGetComplaintDocuments } from "@/Service/Api/ApiHooks/ComplaintDocuments/useGetComplaintDocuments";
import { Column, ColumnBodyOptions } from "primereact/column";
import DownloadButton from "@/components/partials/DownloadButton";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { truncateString } from "@/Util/truncateString";

type TableFilterOptions = {
    first: number
    rows: number
};

type TableColumnDefinition = {
    label?: string
    field?: string
    body?: React.ReactNode | ((data: DocumentResource, options: ColumnBodyOptions) => React.ReactNode)
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }
`;

export type FilesDatatableProps = {
    complaint: Complaint
};

/**
 * Files datatable
 */
const FilesDatatable: React.FC<FilesDatatableProps> = ({ complaint }: FilesDatatableProps) => {
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        rows: DT_ROWS_NO,
        first: 0
    });

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'File name',
            body: (data) => {
                return truncateString(data.name, 50);
            }
        },
        {
            label: 'Type',
            field: 'type',
        },
        {
            label: 'Date',
            body: (data) => {
                return formatToUKDate(data.created_at);
            }
        },
        {
            label: 'Actions',
            body: data => {
                return <div className='flex gap-2'>
                    <DownloadButton file={data} complaintId={complaint.id}/>
                </div>;
            }
        },
    ];

    const documentsRequestParams = useMemo<GetComplaintDocumentsRequest>(() => {
        return {
            page: (tableFilters.first / tableFilters.rows) + 1,
            limit: tableFilters.rows,
            complaint_id: complaint.id,
        };
    }, [tableFilters.first, tableFilters.rows, complaint.id]);

    const {
        data: documents,
        isLoading: loading
    } = useGetComplaintDocuments(documentsRequestParams);

    return (
        <StyledWrap>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={loading}
                        emptyMessage="No files found."
                        value={documents?.data}
                        totalRecords={documents?.meta.total}
                        removableSort
                        paginator
                        rows={tableFilters.rows}
                        rowsPerPageOptions={[10, 20, 50]}
                        first={tableFilters.first}
                        pt={dataTablePtOptions}
                        onPage={e => {
                            onPagination(e);
                        }}
                    >
                        {tableColumns.map(column =>
                            <Column
                                key={column.label}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
        </StyledWrap>
    );
};

export default FilesDatatable;
