import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { type GetComplaintChannelsRequest } from '@/stub';
import { ComplaintChannelsApiClient } from '../../ApiClients/ComplaintChannelsApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetComplaintChannelsParams = {
    requestParams?: GetComplaintChannelsRequest
    enabled?: boolean
    savePreviousData?: boolean
};

export const useGetComplaintChannels = ({
    requestParams,
    savePreviousData,
    enabled
}: useGetComplaintChannelsParams) => useQuery({
    ...QueryKeys.channels.list(requestParams),
    queryFn: async () => await ComplaintChannelsApiClient.getComplaintChannels(requestParams),
    placeholderData: savePreviousData ? keepPreviousData : undefined,
    enabled
});
