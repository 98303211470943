/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeleteSubscriptionNotificationRequest,
  GetNotifications200Response,
  Notification,
  UpdateSubscriptionNotificationRequest,
} from '../models/index';
import {
    DeleteSubscriptionNotificationRequestFromJSON,
    DeleteSubscriptionNotificationRequestToJSON,
    GetNotifications200ResponseFromJSON,
    GetNotifications200ResponseToJSON,
    NotificationFromJSON,
    NotificationToJSON,
    UpdateSubscriptionNotificationRequestFromJSON,
    UpdateSubscriptionNotificationRequestToJSON,
} from '../models/index';

export interface DeleteNotificationRequest {
    notification_id: string;
}

export interface GetNotificationRequest {
    notification_id: string;
}

export interface GetNotificationsRequest {
    filter_by?: GetNotificationsFilterByEnum;
    limit?: number;
    page?: number;
}

export interface MarkNotificationAsReadRequest {
    notification_id: string;
}

export interface SubscribeToNotificationsRequest {
    UpdateSubscriptionNotificationRequest?: UpdateSubscriptionNotificationRequest;
}

export interface UnsubscribeToNotificationsRequest {
    DeleteSubscriptionNotificationRequest?: DeleteSubscriptionNotificationRequest;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Delete Notification
     */
    async deleteNotificationRaw(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notification_id === null || requestParameters.notification_id === undefined) {
            throw new runtime.RequiredError('notification_id','Required parameter requestParameters.notification_id was null or undefined when calling deleteNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notification_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Notification
     */
    async deleteNotification(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Get Notification
     */
    async getNotificationRaw(requestParameters: GetNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.notification_id === null || requestParameters.notification_id === undefined) {
            throw new runtime.RequiredError('notification_id','Required parameter requestParameters.notification_id was null or undefined when calling getNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notification_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     * Get Notification
     */
    async getNotification(requestParameters: GetNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notification> {
        const response = await this.getNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Notifications
     */
    async getNotificationsRaw(requestParameters: GetNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNotifications200Response>> {
        const queryParameters: any = {};

        if (requestParameters.filter_by !== undefined) {
            queryParameters['filter_by'] = requestParameters.filter_by;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNotifications200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Notifications
     */
    async getNotifications(requestParameters: GetNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNotifications200Response> {
        const response = await this.getNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Unread Notifications Count
     */
    async getUnreadNotificationsCountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/unread-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Unread Notifications Count
     */
    async getUnreadNotificationsCount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getUnreadNotificationsCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Mark All Notifications As Read
     */
    async markAllNotificationsAsReadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/read-all`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark All Notifications As Read
     */
    async markAllNotificationsAsRead(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markAllNotificationsAsReadRaw(initOverrides);
    }

    /**
     * Mark Notification As Read
     */
    async markNotificationAsReadRaw(requestParameters: MarkNotificationAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.notification_id === null || requestParameters.notification_id === undefined) {
            throw new runtime.RequiredError('notification_id','Required parameter requestParameters.notification_id was null or undefined when calling markNotificationAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{notification_id}/read`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notification_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     * Mark Notification As Read
     */
    async markNotificationAsRead(requestParameters: MarkNotificationAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notification> {
        const response = await this.markNotificationAsReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * subscribe To Notifications. Register for Push Wap
     */
    async subscribeToNotificationsRaw(requestParameters: SubscribeToNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/subscribe`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubscriptionNotificationRequestToJSON(requestParameters.UpdateSubscriptionNotificationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * subscribe To Notifications. Register for Push Wap
     */
    async subscribeToNotifications(requestParameters: SubscribeToNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.subscribeToNotificationsRaw(requestParameters, initOverrides);
    }

    /**
     * unsubscribe To Notifications. Delete Push Wap
     */
    async unsubscribeToNotificationsRaw(requestParameters: UnsubscribeToNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/unsubscribe`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteSubscriptionNotificationRequestToJSON(requestParameters.DeleteSubscriptionNotificationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * unsubscribe To Notifications. Delete Push Wap
     */
    async unsubscribeToNotifications(requestParameters: UnsubscribeToNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsubscribeToNotificationsRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetNotificationsFilterByEnum = {
    Unread: 'unread',
    Read: 'read'
} as const;
export type GetNotificationsFilterByEnum = typeof GetNotificationsFilterByEnum[keyof typeof GetNotificationsFilterByEnum];
