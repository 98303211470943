import { useMutation } from '@tanstack/react-query';
import { customerApiClient } from '@/Service/Api/ApiClients/CustomerApiClient';
import {type CreateCustomerRequest} from '@/stub';
import {processValidationErrors} from "@/Util/Api/processValidationErrors";

interface useCreateCustomerParams {
}

export const useCreateCustomer = () => {
    return useMutation({
        mutationFn: async (data: CreateCustomerRequest) => {
            try {
                return await customerApiClient.createCustomer(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    })
}
