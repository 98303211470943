import { BESPOKE_TYPE_OPTIONS } from "@/config/constants";
import { capitalizeString, snakeToNormalCase } from "@/helpers/general";

export const formatType = (type: string) => {
    const foundOption = BESPOKE_TYPE_OPTIONS.find((item) => item.value === type);

    if (foundOption) {
        return 'Bespoke content for: ' + foundOption.label;
    } else {
        return capitalizeString(snakeToNormalCase(type));
    }
};
