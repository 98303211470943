/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RootCauseFinalReport
 */
export interface RootCauseFinalReport {
    /**
     * 
     * @type {string}
     * @memberof RootCauseFinalReport
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    closed?: number;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    avg_days_close?: number;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    closure_3d_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    closure_3d_8wk_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    closure_8wk_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    closure_not_upheld_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    closure_partial_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof RootCauseFinalReport
     */
    closure_uphold_percent?: number;
}

/**
 * Check if a given object implements the RootCauseFinalReport interface.
 */
export function instanceOfRootCauseFinalReport(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RootCauseFinalReportFromJSON(json: any): RootCauseFinalReport {
    return RootCauseFinalReportFromJSONTyped(json, false);
}

export function RootCauseFinalReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): RootCauseFinalReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
        'avg_days_close': !exists(json, 'avg_days_close') ? undefined : json['avg_days_close'],
        'closure_3d_percent': !exists(json, 'closure_3d_percent') ? undefined : json['closure_3d_percent'],
        'closure_3d_8wk_percent': !exists(json, 'closure_3d_8wk_percent') ? undefined : json['closure_3d_8wk_percent'],
        'closure_8wk_percent': !exists(json, 'closure_8wk_percent') ? undefined : json['closure_8wk_percent'],
        'closure_not_upheld_percent': !exists(json, 'closure_not_upheld_percent') ? undefined : json['closure_not_upheld_percent'],
        'closure_partial_percent': !exists(json, 'closure_partial_percent') ? undefined : json['closure_partial_percent'],
        'closure_uphold_percent': !exists(json, 'closure_uphold_percent') ? undefined : json['closure_uphold_percent'],
    };
}

export function RootCauseFinalReportToJSON(value?: RootCauseFinalReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'closed': value.closed,
        'avg_days_close': value.avg_days_close,
        'closure_3d_percent': value.closure_3d_percent,
        'closure_3d_8wk_percent': value.closure_3d_8wk_percent,
        'closure_8wk_percent': value.closure_8wk_percent,
        'closure_not_upheld_percent': value.closure_not_upheld_percent,
        'closure_partial_percent': value.closure_partial_percent,
        'closure_uphold_percent': value.closure_uphold_percent,
    };
}

