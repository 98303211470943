import React from 'react';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { type Complaint, CreateComplaintBespokeRequest, LetterType } from '@/stub';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import BaseTextEditor from '@/components/Core/Form/BaseTextEditor';
import { Divider } from 'primereact/divider';
import { useToastMessagesStore } from '@/Stores/ToastMessagesStore';
import { Dialog } from 'primereact/dialog';
import { useQueryClient } from '@tanstack/react-query';
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { useCreateBespoke } from "@/Service/Api/ApiHooks/ComplaintBespokes/useCreateBespoke";
import { BespokeAddedMessage } from "@/Messages/Toast/Bespokes/BespokeAddedMessage";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { snakeToNormalCase } from "@/helpers/general";

export type AddBespokeMessageModalProps = {
    complaint: Complaint
    visible: boolean
    setVisible: (visible: boolean) => void
};

type LetterTypeOption = {
    value: LetterType
    label: string
};

const AddBespokeMessageSchema = z.object({
    bespoke: z.string({
        required_error: 'Bespoke message is required',
        invalid_type_error: 'Bespoke message must be a string'
    }),
    letter_type: z.nativeEnum(LetterType, {
        required_error: 'Letter type is required',
        invalid_type_error: 'Letter type must be a string'
    }),
});

type AddBespokeMessageFormData = z.infer<typeof AddBespokeMessageSchema>;

const letterTypeOptions = (): LetterTypeOption[] => {
    const options: LetterTypeOption[] = [];
    Object.values(LetterType).forEach(item => {
        options.push({
            value: item,
            label: snakeToNormalCase(item)
        });
    });
    return options;
};

const AddBespokeMessageModal: React.FC<AddBespokeMessageModalProps> = ({
    visible,
    setVisible,
    complaint
}: AddBespokeMessageModalProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<AddBespokeMessageFormData>({
        resolver: zodResolver(AddBespokeMessageSchema)
    });

    const createBespokeMessageMutation = useCreateBespoke();

    const validate = (data: AddBespokeMessageFormData) => {
        return AddBespokeMessageSchema.safeParse(data).success;
    };

    const onSubmit = async (data: AddBespokeMessageFormData) => {
        if (validate(data)) {
            const dataToSend: CreateComplaintBespokeRequest = {
                complaint_id: complaint.id,
                UpsertBespokeRequest: data
            };
            await createBespokeMessageMutation.mutateAsync(dataToSend, {
                onSuccess: () => {
                    reset();
                    addToastMessage(BespokeAddedMessage);
                    void Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.bespokes.list({ complaint_id: complaint?.id }).queryKey
                        })
                    ]);
                    setVisible(false);
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            });
        }
    };

    const onHide = (): void => {
        setVisible(false);
    };

    const modalContent = (
        <form id="add-bespoke" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column row-gap-3">
                <Divider/>

                <BaseDropdown
                    control={control}
                    label="Letter type"
                    name='letter_type'
                    options={letterTypeOptions()}
                    required
                    errorMessages={errors?.letter_type?.message}
                />

                <BaseTextEditor
                    control={control}
                    label='Bespoke'
                    required
                    textareaName='bespoke'
                    errorMessages={errors?.bespoke?.message}
                />
            </div>
        </form>
    );

    const widgetFooter = (
        <>
            <Divider/>
            <div className="text-right">
                <Button
                    loading={createBespokeMessageMutation.isPending}
                    label="Add bespoke"
                    severity="success"
                    form='add-bespoke'
                    type='submit'
                />
            </div>
        </>
    );

    return (
        <Dialog
            id='bespoke-message-dialog'
            header='Add new bespoke message'
            className='bespoke-modal'
            visible={visible}
            onHide={onHide}
            footer={widgetFooter}
            blockScroll
            draggable={false}
        >
            <div className='w-full'>
                {modalContent}
            </div>
        </Dialog>
    );
};

export default AddBespokeMessageModal;
