/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RootCause } from './RootCause';
import {
    RootCauseFromJSON,
    RootCauseFromJSONTyped,
    RootCauseToJSON,
} from './RootCause';

/**
 * 
 * @export
 * @interface Decision
 */
export interface Decision {
    /**
     * 
     * @type {number}
     * @memberof Decision
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    decision_type: DecisionDecisionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    final_letter_type: DecisionFinalLetterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Decision
     */
    refund?: number;
    /**
     * 
     * @type {number}
     * @memberof Decision
     */
    interest?: number;
    /**
     * 
     * @type {number}
     * @memberof Decision
     */
    financial_loss?: number;
    /**
     * 
     * @type {number}
     * @memberof Decision
     */
    non_financial_loss?: number;
    /**
     * 
     * @type {number}
     * @memberof Decision
     */
    ex_gratia?: number;
    /**
     * 
     * @type {number}
     * @memberof Decision
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    approval_level?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Decision
     */
    approved_by?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Decision
     */
    approval_date?: Date;
    /**
     * 
     * @type {RootCause}
     * @memberof Decision
     */
    root_cause?: RootCause;
    /**
     * 
     * @type {boolean}
     * @memberof Decision
     */
    is_customer_accept_outcome?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Decision
     */
    created_at?: Date;
}


/**
 * @export
 */
export const DecisionDecisionTypeEnum = {
    Uphold: 'uphold',
    NotUpheld: 'not_upheld',
    Partial: 'partial'
} as const;
export type DecisionDecisionTypeEnum = typeof DecisionDecisionTypeEnum[keyof typeof DecisionDecisionTypeEnum];

/**
 * @export
 */
export const DecisionFinalLetterTypeEnum = {
    OnComplaintCreate: 'on_complaint_create',
    Contact: 'contact',
    Note: 'note',
    Document: 'document'
} as const;
export type DecisionFinalLetterTypeEnum = typeof DecisionFinalLetterTypeEnum[keyof typeof DecisionFinalLetterTypeEnum];


/**
 * Check if a given object implements the Decision interface.
 */
export function instanceOfDecision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "decision_type" in value;
    isInstance = isInstance && "final_letter_type" in value;

    return isInstance;
}

export function DecisionFromJSON(json: any): Decision {
    return DecisionFromJSONTyped(json, false);
}

export function DecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Decision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'decision_type': json['decision_type'],
        'final_letter_type': json['final_letter_type'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'refund': !exists(json, 'refund') ? undefined : json['refund'],
        'interest': !exists(json, 'interest') ? undefined : json['interest'],
        'financial_loss': !exists(json, 'financial_loss') ? undefined : json['financial_loss'],
        'non_financial_loss': !exists(json, 'non_financial_loss') ? undefined : json['non_financial_loss'],
        'ex_gratia': !exists(json, 'ex_gratia') ? undefined : json['ex_gratia'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'approval_level': !exists(json, 'approval_level') ? undefined : json['approval_level'],
        'approved_by': !exists(json, 'approved_by') ? undefined : json['approved_by'],
        'approval_date': !exists(json, 'approval_date') ? undefined : (new Date(json['approval_date'])),
        'root_cause': !exists(json, 'root_cause') ? undefined : RootCauseFromJSON(json['root_cause']),
        'is_customer_accept_outcome': !exists(json, 'is_customer_accept_outcome') ? undefined : json['is_customer_accept_outcome'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function DecisionToJSON(value?: Decision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'decision_type': value.decision_type,
        'final_letter_type': value.final_letter_type,
        'summary': value.summary,
        'refund': value.refund,
        'interest': value.interest,
        'financial_loss': value.financial_loss,
        'non_financial_loss': value.non_financial_loss,
        'ex_gratia': value.ex_gratia,
        'total': value.total,
        'approval_level': value.approval_level,
        'approved_by': value.approved_by,
        'approval_date': value.approval_date === undefined ? undefined : (value.approval_date.toISOString().substring(0,10)),
        'root_cause': RootCauseToJSON(value.root_cause),
        'is_customer_accept_outcome': value.is_customer_accept_outcome,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString().substring(0,10)),
    };
}

