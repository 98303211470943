/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcaRootCauseTree,
  RootCauseListMode,
} from '../models/index';
import {
    FcaRootCauseTreeFromJSON,
    FcaRootCauseTreeToJSON,
    RootCauseListModeFromJSON,
    RootCauseListModeToJSON,
} from '../models/index';

export interface GetFcaRootCauseInTreeStructureRequest {
    organisation_id?: number;
    selectable_lvl?: number;
}

export interface GetFcaRootCauseListModeRequest {
    organisation_id?: number;
}

/**
 * 
 */
export class FcaRootCauseApi extends runtime.BaseAPI {

    /**
     * Get List of FcaRootCause with Tree Structure
     */
    async getFcaRootCauseInTreeStructureRaw(requestParameters: GetFcaRootCauseInTreeStructureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FcaRootCauseTree>>> {
        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        if (requestParameters.selectable_lvl !== undefined) {
            queryParameters['selectable_lvl'] = requestParameters.selectable_lvl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fca-root-causes/tree-mode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FcaRootCauseTreeFromJSON));
    }

    /**
     * Get List of FcaRootCause with Tree Structure
     */
    async getFcaRootCauseInTreeStructure(requestParameters: GetFcaRootCauseInTreeStructureRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FcaRootCauseTree>> {
        const response = await this.getFcaRootCauseInTreeStructureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get List of FcaRootCause
     */
    async getFcaRootCauseListModeRaw(requestParameters: GetFcaRootCauseListModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RootCauseListMode>>> {
        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fca-root-causes/list-mode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RootCauseListModeFromJSON));
    }

    /**
     * Get List of FcaRootCause
     */
    async getFcaRootCauseListMode(requestParameters: GetFcaRootCauseListModeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RootCauseListMode>> {
        const response = await this.getFcaRootCauseListModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
