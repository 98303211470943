import { useEffect } from "react";
import { setDocumentTitle } from "@/helpers/general";
import DsarDatatable from "../components/Datatables/DsarDatatable";

/**
 * DSAR page
 */
const DsarPage = () => {
    useEffect(() => {
        setDocumentTitle('DSAR');
    }, []);

    return (
        <div className="page-content">
            <DsarDatatable/>
        </div>
    );
};

export default DsarPage;
