/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelType } from './ChannelType';
import {
    ChannelTypeFromJSON,
    ChannelTypeFromJSONTyped,
    ChannelTypeToJSON,
} from './ChannelType';

/**
 * 
 * @export
 * @interface StoreComplaintRequestChannel
 */
export interface StoreComplaintRequestChannel {
    /**
     * 
     * @type {string}
     * @memberof StoreComplaintRequestChannel
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreComplaintRequestChannel
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreComplaintRequestChannel
     */
    to?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreComplaintRequestChannel
     */
    description?: string | null;
    /**
     * 
     * @type {ChannelType}
     * @memberof StoreComplaintRequestChannel
     */
    channel_type: ChannelType;
}

/**
 * Check if a given object implements the StoreComplaintRequestChannel interface.
 */
export function instanceOfStoreComplaintRequestChannel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "channel_type" in value;

    return isInstance;
}

export function StoreComplaintRequestChannelFromJSON(json: any): StoreComplaintRequestChannel {
    return StoreComplaintRequestChannelFromJSONTyped(json, false);
}

export function StoreComplaintRequestChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreComplaintRequestChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : json['content'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'channel_type': ChannelTypeFromJSON(json['channel_type']),
    };
}

export function StoreComplaintRequestChannelToJSON(value?: StoreComplaintRequestChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'from': value.from,
        'to': value.to,
        'description': value.description,
        'channel_type': ChannelTypeToJSON(value.channel_type),
    };
}

