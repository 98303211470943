import React from 'react';
import styled from 'styled-components';
import { Action, ActionStateEnum } from "@/stub";
import { snakeToNormalCase } from "@/helpers/general";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { dayStart } from "@formkit/tempo";
import { clsx } from "clsx";

const ActionsWrap = styled.div`
    .item {
        gap: 10px;
        position: relative;

        .item-description {
            padding: 8px;
            gap: 5px;
            border-radius: 0px 10px 10px 10px;
            background: var(--gray-100);
            width: 100%;
            .li-desc{
                word-break: break-all;
            }
            .li-date, .li-title, .li-desc {
                font-size: 12px;
                font-style: normal;
                line-height: 150%;
            }

            .li-date, .li-desc {
                color: var(--gray-500);
                font-weight: 400;
            }

            .li-title {
                color: var(--gray-900);
                font-weight: 600;
            }

            .li-date {
                margin-right: auto;
            }
        }
    }

    .signalizing-field {
        padding: 0.15rem 0.5rem;
        border-radius: 6px;
        text-wrap: nowrap;

        &.future {
            background-color: var(--teal-200)
        }

        &.success {
            background-color: var(--green-200);
        }

        &.warning {
            background-color: var(--yellow-100);
        }

        &.danger {
            background-color: var(--red-200);
        }
    }
`;

export type ActionsProps = {
    actions: Action[],
    title: string
};

const today = new Date();
type DateBodyRenderGeneratorProps = {
    action: Action
};
const DateBodyRenderGenerator = ({ action }: DateBodyRenderGeneratorProps) => {
    if (action.state === ActionStateEnum.Closed) {
        return (
            <span className="signalizing-field success">Completed: {formatToUKDate(action.due_date)}</span>
        );
    }

    const isOverdue = dayStart(action.due_date) < today;
    if (isOverdue) {
        return (
            <span
                className={clsx('signalizing-field', { warning: isOverdue })}
            >
                {isOverdue ? 'Overdue' : 'Due'}: {formatToUKDate(action.due_date)}
            </span>
        );
    }

    return (
        <span
            className="signalizing-field future"
        >
                    Planned: {formatToUKDate(action.due_date)}
        </span>
    );
};

const Actions: React.FC<ActionsProps> = ({ actions, title }: ActionsProps) => {
    return (
        <ActionsWrap>
            <h5 className='flex mt-0 justify-content-center font-bold'>{title}</h5>
            <div className="flex flex-column align-item-center gap-2">
                {actions.map((action) => {
                    return (
                        <div className="item flex align-items-center" key={action.id}>
                            <i className='pi pi-calendar-times mx-auto'></i>

                            <div className="item-description flex flex-column justify-content-center">
                                <div className="li-date"><DateBodyRenderGenerator action={action}/></div>
                                <div className="li-title">{snakeToNormalCase(action.type)}</div>
                                <span className="li-desc" dangerouslySetInnerHTML={{ __html: action.description ?? '' }}/>
                            </div>
                        </div>
                    );
                })}
            </div>
        </ActionsWrap>
    );
};

export default Actions;
