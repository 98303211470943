/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ChannelFieldConfig } from './ChannelFieldConfig';
import {
    instanceOfChannelFieldConfig,
    ChannelFieldConfigFromJSON,
    ChannelFieldConfigFromJSONTyped,
    ChannelFieldConfigToJSON,
} from './ChannelFieldConfig';

/**
 * @type ChannelConfigDescription
 * 
 * @export
 */
export type ChannelConfigDescription = ChannelFieldConfig;

export function ChannelConfigDescriptionFromJSON(json: any): ChannelConfigDescription {
    return ChannelConfigDescriptionFromJSONTyped(json, false);
}

export function ChannelConfigDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelConfigDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ChannelFieldConfigFromJSONTyped(json, true) };
}

export function ChannelConfigDescriptionToJSON(value?: ChannelConfigDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfChannelFieldConfig(value)) {
        return ChannelFieldConfigToJSON(value as ChannelFieldConfig);
    }

    return {};
}

