/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportKPISummaryReportRequest
 */
export interface ExportKPISummaryReportRequest {
    /**
     * date_format:Y-m-d
     * @type {string}
     * @memberof ExportKPISummaryReportRequest
     */
    from: string;
    /**
     * date_format:Y-m-d
     * @type {string}
     * @memberof ExportKPISummaryReportRequest
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportKPISummaryReportRequest
     */
    organisation_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportKPISummaryReportRequest
     */
    search?: string;
}

/**
 * Check if a given object implements the ExportKPISummaryReportRequest interface.
 */
export function instanceOfExportKPISummaryReportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "from" in value;

    return isInstance;
}

export function ExportKPISummaryReportRequestFromJSON(json: any): ExportKPISummaryReportRequest {
    return ExportKPISummaryReportRequestFromJSONTyped(json, false);
}

export function ExportKPISummaryReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportKPISummaryReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'search': !exists(json, 'search') ? undefined : json['search'],
    };
}

export function ExportKPISummaryReportRequestToJSON(value?: ExportKPISummaryReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
        'organisation_id': value.organisation_id,
        'search': value.search,
    };
}

