import React, { useId, useMemo } from "react";
import { Controller, useWatch } from "react-hook-form";
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteProps } from "primereact/autocomplete";
import { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import ErrorsBlock from "@/components/Core/Form/ErrorsBlock";
import { fieldsPlaceholders } from "@/config/forms";
export type BaseAutocompleteProps = AutoCompleteProps & BaseFormInputProps & {
    preprocessChange?: (event: AutoCompleteChangeEvent) => AutoCompleteChangeEvent
};

const BaseAutocomplete: React.FC<BaseAutocompleteProps> = ({
    control,
    label,
    errorMessages,
    className,
    onChange,
    preprocessChange,
    ...props
}: BaseAutocompleteProps) => {
    const localId = useId();

    const controlledValue = control ? useWatch({
        control: control,
        name: props.name ?? 'undefined',
    }) : undefined;

    const selectedValue = useMemo(() => {
        if (control) {
            return controlledValue;
        }
        return props.value;
    }, [controlledValue, control, props.value]);


    return (
        <div className={className}>
            {
                label && <label className="block mb-2" htmlFor={localId}>{label}{props.required ? '*' : ''}</label>
            }
            {
                control && props.name
                && <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <AutoComplete
                            id={localId}
                            name={field.name}
                            invalid={fieldState.invalid}
                            suggestions={props.suggestions}
                            value={selectedValue}
                            disabled={field.disabled}
                            placeholder={props.placeholder ?? fieldsPlaceholders.text}
                            onChange={(e) => {
                                let event: AutoCompleteChangeEvent = e;
                                if (preprocessChange) {
                                    event = preprocessChange(event);
                                }
                                if (onChange) {
                                    onChange(event);
                                }
                                field.onChange(event.value);
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            {...props}
                        />
                    )}
                />
            }
            {
                !control
                && <AutoComplete
                    id={localId}
                    suggestions={props.suggestions}
                    onChange={(e) => {
                        let event: AutoCompleteChangeEvent = e;
                        if (preprocessChange) {
                            event = preprocessChange(event);
                        }
                        if (onChange) {
                            onChange(event);
                        }
                    }}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseAutocomplete;
