/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Channel,
  ChannelConfig,
  GetComplaintChannels200Response,
} from '../models/index';
import {
    ChannelFromJSON,
    ChannelToJSON,
    ChannelConfigFromJSON,
    ChannelConfigToJSON,
    GetComplaintChannels200ResponseFromJSON,
    GetComplaintChannels200ResponseToJSON,
} from '../models/index';

export interface CreateComplaintChannelRequest {
    complaint_id: number;
    files?: Array<Blob> | null;
    content?: string | null;
    from?: string | null;
    to?: string | null;
    description?: string | null;
    contact_type?: CreateComplaintChannelContactTypeEnum;
    channel_type?: CreateComplaintChannelChannelTypeEnum;
    due_date?: string;
    created_at?: string;
    organisation_id?: number;
}

export interface GetComplaintChannelRequest {
    complaint_id: number;
    channel_id: number;
}

export interface GetComplaintChannelsRequest {
    complaint_id: number;
    organisation_id?: number;
    page?: number;
    limit?: number;
}

export interface UpdateComplaintChannelRequest {
    complaint_id: number;
    channel_id: number;
    file?: Blob | null;
    content?: string | null;
    from?: string | null;
    to?: string | null;
    description?: string | null;
    channel_type?: UpdateComplaintChannelChannelTypeEnum;
    due_date?: string | null;
    created_at?: string;
    organisation_id?: number;
}

/**
 * 
 */
export class ComplaintChannelsApi extends runtime.BaseAPI {

    /**
     * create Complaint Channel
     */
    async createComplaintChannelRaw(requestParameters: CreateComplaintChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Channel>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling createComplaintChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files[]', element as any);
            })
        }

        if (requestParameters.content !== undefined) {
            formParams.append('content', requestParameters.content as any);
        }

        if (requestParameters.from !== undefined) {
            formParams.append('from', requestParameters.from as any);
        }

        if (requestParameters.to !== undefined) {
            formParams.append('to', requestParameters.to as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.contact_type !== undefined) {
            formParams.append('contact_type', requestParameters.contact_type as any);
        }

        if (requestParameters.channel_type !== undefined) {
            formParams.append('channel_type', requestParameters.channel_type as any);
        }

        if (requestParameters.due_date !== undefined) {
            formParams.append('due_date', requestParameters.due_date as any);
        }

        if (requestParameters.created_at !== undefined) {
            formParams.append('created_at', requestParameters.created_at as any);
        }

        if (requestParameters.organisation_id !== undefined) {
            formParams.append('organisation_id', requestParameters.organisation_id as any);
        }

        const response = await this.request({
            path: `/complaints/{complaint_id}/channels`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelFromJSON(jsonValue));
    }

    /**
     * create Complaint Channel
     */
    async createComplaintChannel(requestParameters: CreateComplaintChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Channel> {
        const response = await this.createComplaintChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get Channel Config
     */
    async getChannelConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChannelConfig>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channel-config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChannelConfigFromJSON));
    }

    /**
     * get Channel Config
     */
    async getChannelConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChannelConfig>> {
        const response = await this.getChannelConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * get Complaint Channel
     */
    async getComplaintChannelRaw(requestParameters: GetComplaintChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Channel>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintChannel.');
        }

        if (requestParameters.channel_id === null || requestParameters.channel_id === undefined) {
            throw new runtime.RequiredError('channel_id','Required parameter requestParameters.channel_id was null or undefined when calling getComplaintChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/channels/{channel_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"channel_id"}}`, encodeURIComponent(String(requestParameters.channel_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelFromJSON(jsonValue));
    }

    /**
     * get Complaint Channel
     */
    async getComplaintChannel(requestParameters: GetComplaintChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Channel> {
        const response = await this.getComplaintChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get list of Complaint Channels
     */
    async getComplaintChannelsRaw(requestParameters: GetComplaintChannelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComplaintChannels200Response>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintChannels.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/channels`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComplaintChannels200ResponseFromJSON(jsonValue));
    }

    /**
     * get list of Complaint Channels
     */
    async getComplaintChannels(requestParameters: GetComplaintChannelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComplaintChannels200Response> {
        const response = await this.getComplaintChannelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateComplaintChannel
     */
    async updateComplaintChannelRaw(requestParameters: UpdateComplaintChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Channel>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateComplaintChannel.');
        }

        if (requestParameters.channel_id === null || requestParameters.channel_id === undefined) {
            throw new runtime.RequiredError('channel_id','Required parameter requestParameters.channel_id was null or undefined when calling updateComplaintChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.content !== undefined) {
            formParams.append('content', requestParameters.content as any);
        }

        if (requestParameters.from !== undefined) {
            formParams.append('from', requestParameters.from as any);
        }

        if (requestParameters.to !== undefined) {
            formParams.append('to', requestParameters.to as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.channel_type !== undefined) {
            formParams.append('channel_type', requestParameters.channel_type as any);
        }

        if (requestParameters.due_date !== undefined) {
            formParams.append('due_date', requestParameters.due_date as any);
        }

        if (requestParameters.created_at !== undefined) {
            formParams.append('created_at', requestParameters.created_at as any);
        }

        if (requestParameters.organisation_id !== undefined) {
            formParams.append('organisation_id', requestParameters.organisation_id as any);
        }

        const response = await this.request({
            path: `/complaints/{complaint_id}/channels/{channel_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"channel_id"}}`, encodeURIComponent(String(requestParameters.channel_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelFromJSON(jsonValue));
    }

    /**
     * updateComplaintChannel
     */
    async updateComplaintChannel(requestParameters: UpdateComplaintChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Channel> {
        const response = await this.updateComplaintChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CreateComplaintChannelContactTypeEnum = {
    OnComplaintCreate: 'on_complaint_create',
    Contact: 'contact',
    Note: 'note',
    Document: 'document'
} as const;
export type CreateComplaintChannelContactTypeEnum = typeof CreateComplaintChannelContactTypeEnum[keyof typeof CreateComplaintChannelContactTypeEnum];
/**
 * @export
 */
export const CreateComplaintChannelChannelTypeEnum = {
    Email: 'email',
    Letter: 'letter',
    PhoneCall: 'phone_call',
    Website: 'website',
    SocialMedia: 'social_media',
    Fos: 'fos',
    ReferredComplaints: 'referred_complaints',
    Intercom: 'intercom',
    ThirdParty: 'third_party',
    Aggregator: 'aggregator'
} as const;
export type CreateComplaintChannelChannelTypeEnum = typeof CreateComplaintChannelChannelTypeEnum[keyof typeof CreateComplaintChannelChannelTypeEnum];
/**
 * @export
 */
export const UpdateComplaintChannelChannelTypeEnum = {
    Email: 'email',
    Letter: 'letter',
    PhoneCall: 'phone_call',
    Website: 'website',
    SocialMedia: 'social_media',
    Fos: 'fos',
    ReferredComplaints: 'referred_complaints',
    Intercom: 'intercom',
    ThirdParty: 'third_party',
    Aggregator: 'aggregator'
} as const;
export type UpdateComplaintChannelChannelTypeEnum = typeof UpdateComplaintChannelChannelTypeEnum[keyof typeof UpdateComplaintChannelChannelTypeEnum];
