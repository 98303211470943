/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComplaintPoints } from './ComplaintPoints';
import {
    ComplaintPointsFromJSON,
    ComplaintPointsFromJSONTyped,
    ComplaintPointsToJSON,
} from './ComplaintPoints';

/**
 * 
 * @export
 * @interface ComplaintOutcome
 */
export interface ComplaintOutcome {
    /**
     * 
     * @type {number}
     * @memberof ComplaintOutcome
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOutcome
     */
    type?: ComplaintOutcomeTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintOutcome
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOutcome
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof ComplaintOutcome
     */
    remediation?: number | null;
    /**
     * 
     * @type {Array<ComplaintPoints>}
     * @memberof ComplaintOutcome
     */
    points?: Array<ComplaintPoints>;
}


/**
 * @export
 */
export const ComplaintOutcomeTypeEnum = {
    Uphold: 'uphold',
    NotUpheld: 'not-upheld'
} as const;
export type ComplaintOutcomeTypeEnum = typeof ComplaintOutcomeTypeEnum[keyof typeof ComplaintOutcomeTypeEnum];


/**
 * Check if a given object implements the ComplaintOutcome interface.
 */
export function instanceOfComplaintOutcome(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComplaintOutcomeFromJSON(json: any): ComplaintOutcome {
    return ComplaintOutcomeFromJSONTyped(json, false);
}

export function ComplaintOutcomeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintOutcome {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'content': !exists(json, 'content') ? undefined : json['content'],
        'remediation': !exists(json, 'remediation') ? undefined : json['remediation'],
        'points': !exists(json, 'points') ? undefined : ((json['points'] as Array<any>).map(ComplaintPointsFromJSON)),
    };
}

export function ComplaintOutcomeToJSON(value?: ComplaintOutcome | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substring(0,10)),
        'content': value.content,
        'remediation': value.remediation,
        'points': value.points === undefined ? undefined : ((value.points as Array<any>).map(ComplaintPointsToJSON)),
    };
}

