/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionUser } from './ActionUser';
import {
    ActionUserFromJSON,
    ActionUserFromJSONTyped,
    ActionUserToJSON,
} from './ActionUser';
import type { ChannelAction } from './ChannelAction';
import {
    ChannelActionFromJSON,
    ChannelActionFromJSONTyped,
    ChannelActionToJSON,
} from './ChannelAction';
import type { ChannelCustomer } from './ChannelCustomer';
import {
    ChannelCustomerFromJSON,
    ChannelCustomerFromJSONTyped,
    ChannelCustomerToJSON,
} from './ChannelCustomer';
import type { ChannelDocument } from './ChannelDocument';
import {
    ChannelDocumentFromJSON,
    ChannelDocumentFromJSONTyped,
    ChannelDocumentToJSON,
} from './ChannelDocument';

/**
 * 
 * @export
 * @interface Channel
 */
export interface Channel {
    /**
     * 
     * @type {number}
     * @memberof Channel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    contact_type: ChannelContactTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    channel_type?: ChannelChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    to?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    content?: string | null;
    /**
     * 
     * @type {ChannelCustomer}
     * @memberof Channel
     */
    customer?: ChannelCustomer | null;
    /**
     * 
     * @type {ActionUser}
     * @memberof Channel
     */
    user?: ActionUser | null;
    /**
     * 
     * @type {Date}
     * @memberof Channel
     */
    created_at: Date;
    /**
     * 
     * @type {ChannelAction}
     * @memberof Channel
     */
    action?: ChannelAction | null;
    /**
     * 
     * @type {ChannelDocument}
     * @memberof Channel
     */
    document?: ChannelDocument | null;
}


/**
 * @export
 */
export const ChannelContactTypeEnum = {
    OnComplaintCreate: 'on_complaint_create',
    Contact: 'contact',
    Note: 'note',
    Document: 'document'
} as const;
export type ChannelContactTypeEnum = typeof ChannelContactTypeEnum[keyof typeof ChannelContactTypeEnum];

/**
 * @export
 */
export const ChannelChannelTypeEnum = {
    Email: 'email',
    Letter: 'letter',
    PhoneCall: 'phone_call',
    Website: 'website',
    SocialMedia: 'social_media',
    Fos: 'fos',
    ReferredComplaints: 'referred_complaints',
    Intercom: 'intercom',
    ThirdParty: 'third_party',
    Aggregator: 'aggregator'
} as const;
export type ChannelChannelTypeEnum = typeof ChannelChannelTypeEnum[keyof typeof ChannelChannelTypeEnum];


/**
 * Check if a given object implements the Channel interface.
 */
export function instanceOfChannel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "contact_type" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function ChannelFromJSON(json: any): Channel {
    return ChannelFromJSONTyped(json, false);
}

export function ChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Channel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contact_type': json['contact_type'],
        'channel_type': !exists(json, 'channel_type') ? undefined : json['channel_type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'customer': !exists(json, 'customer') ? undefined : ChannelCustomerFromJSON(json['customer']),
        'user': !exists(json, 'user') ? undefined : ActionUserFromJSON(json['user']),
        'created_at': (new Date(json['created_at'])),
        'action': !exists(json, 'action') ? undefined : ChannelActionFromJSON(json['action']),
        'document': !exists(json, 'document') ? undefined : ChannelDocumentFromJSON(json['document']),
    };
}

export function ChannelToJSON(value?: Channel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'contact_type': value.contact_type,
        'channel_type': value.channel_type,
        'description': value.description,
        'from': value.from,
        'to': value.to,
        'content': value.content,
        'customer': ChannelCustomerToJSON(value.customer),
        'user': ActionUserToJSON(value.user),
        'created_at': (value.created_at.toISOString().substring(0,10)),
        'action': ChannelActionToJSON(value.action),
        'document': ChannelDocumentToJSON(value.document),
    };
}

