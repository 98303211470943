import { Column, ColumnBodyOptions } from 'primereact/column';
import React, { useState, useMemo } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import {
    DataTable,
    type DataTablePassThroughOptions,
    type DataTableStateEvent
} from 'primereact/datatable';
import {
    GetCustomersRequest,
    GetCustomersSortByEnum,
    GetCustomersSortOrderEnum,
    GetUsersFromOrganisationRequest, UpdateUserRolesRequestAdditionalRolesEnum,
    UpdateUserRolesRequestMainRoleEnum,
    User
} from '@/stub';
import { SortOrder } from 'primereact/api';
import { produce } from 'immer';
import AnglesSort from '@/components/Icons/AnglesSort';
import { Button } from "primereact/button";
import { url } from "@/helpers/general";
import { useNavigate } from "react-router-dom";
import { useOrgId } from "@/Hooks/useOrgId";
import { useGetUsers } from "@/Service/Api/ApiHooks/Users/useGetUsers";
import { Tag } from "primereact/tag";

type TableFilterOptions = {
    first: number
    rows: number
    sortField: string
    sortOrder: SortOrder
    search: string
};

type TableColumnDefinition = {
    label: string
    sortable?: boolean
    field?: string
    sortField?: string
    headerTooltip?: string
    body?: React.ReactNode | ((data: User, options: ColumnBodyOptions) => React.ReactNode)
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .content-header {
        padding: 1rem;
        border-bottom: var(--gray-300) 1px solid;

        &__title {
            margin: 0.25rem 0;
            font-weight: 600;
            font-size: 1.5rem;
        }

        &__description {
            font-size: 0.9rem;
            color: var(--gray-500);
        }
    }

    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .datatable-container {
        margin-top: 2rem;
        font-size: 0.8rem;
    }

    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .datatable-cell {
        &__clean-text {
            margin: 0;
        }
    }

    .angles-sort {
        margin-left: 0.3rem;

        &__icon {
            color: var(--primary-200);
            font-size: 0.8rem;
            font-weight: 600;

            &.active {
                color: var(--primary-500);
            }
        }
    }
`;

const roleMappings: { [key: string]: string } = {
    ...Object.entries(UpdateUserRolesRequestMainRoleEnum).reduce((acc, [key, value]) => {
        acc[value] = key.replace(/([A-Z])/g, ' $1').trim();
        return acc;
    }, {} as { [key: string]: string }),
    ...Object.entries(UpdateUserRolesRequestAdditionalRolesEnum).reduce((acc, [key, value]) => {
        acc[value] = key.replace(/([A-Z])/g, ' $1').trim();
        return acc;
    }, {} as { [key: string]: string }),
};

export const formatRole = (role: string): string => {
    return roleMappings[role] || role.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
};

export type OrganisationUserDatableProps = {
    requestParams?: Partial<GetUsersFromOrganisationRequest>
};

/**
 * OrganisationUserDatable datatable
 */

const OrganisationUserDatable = ({ requestParams }: OrganisationUserDatableProps) => {
    const orgId = useOrgId();
    const navigate = useNavigate();
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        search: '',
        sortField: GetCustomersSortByEnum.IdNumber,
        sortOrder: SortOrder.DESC,
        rows: DT_ROWS_NO,
        first: 0
    });

    const customersRequest = useMemo<GetCustomersRequest>(() => {
        return {
            ...requestParams,
            sort_by: tableFilters.sortField as GetCustomersSortByEnum ?? undefined,
            sort_order: tableFilters.sortOrder === SortOrder.ASC ? GetCustomersSortOrderEnum.Asc : tableFilters.sortOrder === SortOrder.DESC ? GetCustomersSortOrderEnum.Desc : undefined,
            page: (tableFilters.first / tableFilters.rows) + 1,
            limit: tableFilters.rows
        };
    }, [tableFilters.first, tableFilters.rows, tableFilters.sortField, tableFilters.sortOrder,requestParams]);

    const {
        data: usersData,
        isLoading: customersLoading
    } = useGetUsers({
        requestParams: customersRequest
    });

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
    };

    const onSort = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            draft.sortField = e.sortField;
            draft.sortOrder = e.sortOrder ?? SortOrder.UNSORTED;
        }));
    };



    const navigateToUser = (user: User) => {
        navigate(url('organisation-user', { orgId: orgId, userId: user.id }));
    };

    /**
     * Actions column template
     *
     * @param {User} user object containing row data
     */
    const actionsBodyTemplate = (user: User) => {
        return (
            <div className="flex gap-2">
                <Button
                    onClick={() => navigateToUser(user)}
                    label='View'
                />
            </div>
        );
    };



    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'First Name',
            field: 'firstname',
            body: (user) => {
                return <>
                    {user.firstname ?? 'N/A'}
                </>;

            }
        },
        {
            label: 'Last Name',
            field: 'surname',

            body: (user) => {
                return <>
                    {user.surname ?? 'N/A'}
                </>;

            }
        },
        {
            label: 'Display Name',
            field: 'display_name',
            body: (user) => {
                return <>
                    {user.display_name ?? 'N/A'}
                </>;

            }
        },
        {
            label: 'Email',
            field: 'email',
        },
        {
            label: 'Roles',
            body: (user) => {
                return <div className="flex flex-column gap-1">
                    <p>{formatRole(user?.main_role) ?? 'N/A'}</p>
                    {user.additional_roles?.map((el:string) =>
                        <Tag key={el} value={formatRole(el)} severity='info'/>
                    )
                    }
                </div>;

            }
        },
        {
            label: 'Actions',
            body: actionsBodyTemplate
        }
    ];


    return (
        <StyledWrap>

            <div className="content-container">
                <DataTable
                    lazy
                    loading={customersLoading}
                    emptyMessage="No customers found."
                    value={usersData?.data}
                    totalRecords={usersData?.meta.total}
                    paginator
                    rows={tableFilters.rows}
                    rowsPerPageOptions={[10, 20, 50]}
                    first={tableFilters.first}
                    pt={dataTablePtOptions}
                    sortIcon={(options) => {
                        return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                    }}
                    sortField={tableFilters.sortField}
                    sortOrder={tableFilters.sortOrder}
                    onPage={e => {
                        onPagination(e);
                    }}
                    onSort={e => {
                        onSort(e);
                    }}
                >
                    {tableColumns.map((column, index) =>
                        <Column
                            key={`customers-column-${index}`}
                            field={column.field}
                            body={column.body}
                            header={column.label}
                            sortable={column.sortable}
                            sortField={column.sortField}
                            headerTooltip={column.headerTooltip}
                        />
                    )}
                </DataTable>
            </div>
        </StyledWrap>
    );
};

export default OrganisationUserDatable;
