/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FosReportResource
 */
export interface FosReportResource {
    /**
     * 
     * @type {string}
     * @memberof FosReportResource
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof FosReportResource
     */
    adjudication_open?: number;
    /**
     * 
     * @type {number}
     * @memberof FosReportResource
     */
    final_decision_open?: number;
    /**
     * 
     * @type {number}
     * @memberof FosReportResource
     */
    fos_breach_vol?: number;
    /**
     * 
     * @type {number}
     * @memberof FosReportResource
     */
    final_fos_breach_vol?: number;
    /**
     * 
     * @type {number}
     * @memberof FosReportResource
     */
    other_remedial_breach_vol?: number;
}

/**
 * Check if a given object implements the FosReportResource interface.
 */
export function instanceOfFosReportResource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FosReportResourceFromJSON(json: any): FosReportResource {
    return FosReportResourceFromJSONTyped(json, false);
}

export function FosReportResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FosReportResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'adjudication_open': !exists(json, 'adjudication_open') ? undefined : json['adjudication_open'],
        'final_decision_open': !exists(json, 'final_decision_open') ? undefined : json['final_decision_open'],
        'fos_breach_vol': !exists(json, 'fos_breach_vol') ? undefined : json['fos_breach_vol'],
        'final_fos_breach_vol': !exists(json, 'final_fos_breach_vol') ? undefined : json['final_fos_breach_vol'],
        'other_remedial_breach_vol': !exists(json, 'other_remedial_breach_vol') ? undefined : json['other_remedial_breach_vol'],
    };
}

export function FosReportResourceToJSON(value?: FosReportResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'adjudication_open': value.adjudication_open,
        'final_decision_open': value.final_decision_open,
        'fos_breach_vol': value.fos_breach_vol,
        'final_fos_breach_vol': value.final_fos_breach_vol,
        'other_remedial_breach_vol': value.other_remedial_breach_vol,
    };
}

