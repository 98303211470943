/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetComplaintDocuments200Response,
} from '../models/index';
import {
    GetComplaintDocuments200ResponseFromJSON,
    GetComplaintDocuments200ResponseToJSON,
} from '../models/index';

export interface DownloadComplaintDocumentRequest {
    complaint_id: number;
    document_id: number;
    organisation_id?: number;
}

export interface GetComplaintDocumentsRequest {
    complaint_id: number;
    organisation_id?: number;
    page?: number;
    limit?: number;
}

export interface UpdateComplaintDocumentRequest {
    complaint_id: number;
    file?: Blob | null;
    documentable_type?: UpdateComplaintDocumentDocumentableTypeEnum;
    documentable_id?: number;
}

/**
 * 
 */
export class ComplaintDocumentsApi extends runtime.BaseAPI {

    /**
     * get Complaint Channel
     */
    async downloadComplaintDocumentRaw(requestParameters: DownloadComplaintDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling downloadComplaintDocument.');
        }

        if (requestParameters.document_id === null || requestParameters.document_id === undefined) {
            throw new runtime.RequiredError('document_id','Required parameter requestParameters.document_id was null or undefined when calling downloadComplaintDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/documents/{document_id}/download`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.document_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * get Complaint Channel
     */
    async downloadComplaintDocument(requestParameters: DownloadComplaintDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadComplaintDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get list of Complaint Documents
     */
    async getComplaintDocumentsRaw(requestParameters: GetComplaintDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComplaintDocuments200Response>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/documents`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComplaintDocuments200ResponseFromJSON(jsonValue));
    }

    /**
     * get list of Complaint Documents
     */
    async getComplaintDocuments(requestParameters: GetComplaintDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComplaintDocuments200Response> {
        const response = await this.getComplaintDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update Complaint Documents
     */
    async updateComplaintDocumentRaw(requestParameters: UpdateComplaintDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateComplaintDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.documentable_type !== undefined) {
            formParams.append('documentable_type', requestParameters.documentable_type as any);
        }

        if (requestParameters.documentable_id !== undefined) {
            formParams.append('documentable_id', requestParameters.documentable_id as any);
        }

        const response = await this.request({
            path: `/complaints/{complaint_id}/documents/upload`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update Complaint Documents
     */
    async updateComplaintDocument(requestParameters: UpdateComplaintDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateComplaintDocumentRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const UpdateComplaintDocumentDocumentableTypeEnum = {
    Channel: 'Channel',
    ComplaintFos: 'ComplaintFos',
    Letter: 'Letter',
    Evidence: 'Evidence'
} as const;
export type UpdateComplaintDocumentDocumentableTypeEnum = typeof UpdateComplaintDocumentDocumentableTypeEnum[keyof typeof UpdateComplaintDocumentDocumentableTypeEnum];
