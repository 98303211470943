import ComplaintInfoForm, { type ComplaintInfoFormData } from '@/components/Core/Complaint/ComplaintInfoForm';
import { type Complaint, type UpdateComplaintOperationRequest, type UpdateComplaintRequest } from '@/stub';
import { useUpdateComplaint } from '@/Service/Api/ApiHooks/Complaint/useUpdateComplaint';
import { useQueryClient } from '@tanstack/react-query';
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { ComplaintUpdatedMessage } from "@/Messages/Toast/Complaint/ComplaintUpdatedMessage";
import { useState } from "react";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { useParams } from "react-router-dom";

type ComplaintInfoProps = {
    complaint?: Complaint
};

const ComplaintInfo = ({ complaint }: ComplaintInfoProps) => {
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const queryClient = useQueryClient();
    const { complaintId } = useParams();
    const [complaintFormData, setComplaintFormData] = useState<UpdateComplaintRequest>();
    const updateComplaintMutation = useUpdateComplaint({});


    const onSubmit = (updateComplaintFormData: UpdateComplaintRequest) => {
        const dataToSend: UpdateComplaintOperationRequest = {
            complaint_id: complaint?.id,
            UpdateComplaintRequest: {
                ...updateComplaintFormData,
                raised_date: formatToApiDate(updateComplaintFormData.raised_date)
            }
        };
        updateComplaintMutation.mutate(dataToSend, {
            onSuccess: () => {
                addToastMessage(ComplaintUpdatedMessage);
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.complaints._def
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.complaints.detail(Number(complaintId)).queryKey,
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                    })
                ]);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    return <div>
        <ComplaintInfoForm
            className="mt-3"
            viewMode
            complaint={complaint}
            isProcessing={updateComplaintMutation.isPending}
            onSubmit={(complaintFormData: ComplaintInfoFormData) => {
                setComplaintFormData(complaintFormData);
                onSubmit(complaintFormData);
            }}
        />
    </div>;
};

export default ComplaintInfo;
