/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateRootCauseRequest,
  RootCause,
  RootCauseListMode,
  UpdateRootCauseRequest,
} from '../models/index';
import {
    CreateRootCauseRequestFromJSON,
    CreateRootCauseRequestToJSON,
    RootCauseFromJSON,
    RootCauseToJSON,
    RootCauseListModeFromJSON,
    RootCauseListModeToJSON,
    UpdateRootCauseRequestFromJSON,
    UpdateRootCauseRequestToJSON,
} from '../models/index';

export interface CreateRootCauseOperationRequest {
    CreateRootCauseRequest?: CreateRootCauseRequest;
}

export interface GetRootCauseInTreeStructureRequest {
    organisation_id?: number;
    selectable_lvl?: number;
}

export interface GetRootCauseListModeRequest {
    organisation_id?: number;
}

export interface UpdateRootCauseOperationRequest {
    root_cause_id: number;
    UpdateRootCauseRequest?: UpdateRootCauseRequest;
}

/**
 * 
 */
export class RootCauseApi extends runtime.BaseAPI {

    /**
     * Create a new Root Cause
     */
    async createRootCauseRaw(requestParameters: CreateRootCauseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RootCause>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/root-causes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRootCauseRequestToJSON(requestParameters.CreateRootCauseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RootCauseFromJSON(jsonValue));
    }

    /**
     * Create a new Root Cause
     */
    async createRootCause(requestParameters: CreateRootCauseOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RootCause> {
        const response = await this.createRootCauseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get List of RootCause with Tree Structure
     */
    async getRootCauseInTreeStructureRaw(requestParameters: GetRootCauseInTreeStructureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RootCause>>> {
        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        if (requestParameters.selectable_lvl !== undefined) {
            queryParameters['selectable_lvl'] = requestParameters.selectable_lvl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/root-causes/tree-mode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RootCauseFromJSON));
    }

    /**
     * Get List of RootCause with Tree Structure
     */
    async getRootCauseInTreeStructure(requestParameters: GetRootCauseInTreeStructureRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RootCause>> {
        const response = await this.getRootCauseInTreeStructureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get List of RootCause
     */
    async getRootCauseListModeRaw(requestParameters: GetRootCauseListModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RootCauseListMode>>> {
        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/root-causes/list-mode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RootCauseListModeFromJSON));
    }

    /**
     * Get List of RootCause
     */
    async getRootCauseListMode(requestParameters: GetRootCauseListModeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RootCauseListMode>> {
        const response = await this.getRootCauseListModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a new Root Cause
     */
    async updateRootCauseRaw(requestParameters: UpdateRootCauseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RootCause>> {
        if (requestParameters.root_cause_id === null || requestParameters.root_cause_id === undefined) {
            throw new runtime.RequiredError('root_cause_id','Required parameter requestParameters.root_cause_id was null or undefined when calling updateRootCause.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/root-causes/{root_cause_id}`.replace(`{${"root_cause_id"}}`, encodeURIComponent(String(requestParameters.root_cause_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRootCauseRequestToJSON(requestParameters.UpdateRootCauseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RootCauseFromJSON(jsonValue));
    }

    /**
     * Update a new Root Cause
     */
    async updateRootCause(requestParameters: UpdateRootCauseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RootCause> {
        const response = await this.updateRootCauseRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
