import { useQuery } from '@tanstack/react-query';
import { organisationsApiClient } from '@/Service/Api/ApiClients/OrganisationsApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { GetOrganisationsRequest } from "@/stub";

export type useGetOrganisationsParams = {
    requestParams?: GetOrganisationsRequest
    enabled?: boolean
};
export const useGetOrganisations = ({ requestParams, enabled = true }: useGetOrganisationsParams = {}) => useQuery({
    ...QueryKeys.organisations.list(requestParams),
    queryFn: async () => await organisationsApiClient.getOrganisations(requestParams),
    enabled
});
