import { useQuery } from '@tanstack/react-query';
import { GetLettersRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { letterApiClient } from "@/Service/Api/ApiClients/LetterApiClient";

export type UseGetLettersParams = {
    requestParams: GetLettersRequest,
    enabled?: boolean,
};

export const useGetLetters = ({ requestParams, enabled }: UseGetLettersParams) => useQuery({
    ...QueryKeys.letters.list(requestParams),
    queryFn: async () => await letterApiClient.getLetters(requestParams),
    enabled
});
