/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ComplaintFosFinalDecisionRequestedBy = {
    Customer: 'customer',
    Business: 'business'
} as const;
export type ComplaintFosFinalDecisionRequestedBy = typeof ComplaintFosFinalDecisionRequestedBy[keyof typeof ComplaintFosFinalDecisionRequestedBy];


export function ComplaintFosFinalDecisionRequestedByFromJSON(json: any): ComplaintFosFinalDecisionRequestedBy {
    return ComplaintFosFinalDecisionRequestedByFromJSONTyped(json, false);
}

export function ComplaintFosFinalDecisionRequestedByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintFosFinalDecisionRequestedBy {
    return json as ComplaintFosFinalDecisionRequestedBy;
}

export function ComplaintFosFinalDecisionRequestedByToJSON(value?: ComplaintFosFinalDecisionRequestedBy | null): any {
    return value as any;
}

