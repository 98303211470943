/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetActivityLogList200ResponseMetaLinksInner } from './GetActivityLogList200ResponseMetaLinksInner';
import {
    GetActivityLogList200ResponseMetaLinksInnerFromJSON,
    GetActivityLogList200ResponseMetaLinksInnerFromJSONTyped,
    GetActivityLogList200ResponseMetaLinksInnerToJSON,
} from './GetActivityLogList200ResponseMetaLinksInner';

/**
 * 
 * @export
 * @interface GetActivityLogList200ResponseMeta
 */
export interface GetActivityLogList200ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof GetActivityLogList200ResponseMeta
     */
    current_page: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityLogList200ResponseMeta
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityLogList200ResponseMeta
     */
    last_page: number;
    /**
     * 
     * @type {Array<GetActivityLogList200ResponseMetaLinksInner>}
     * @memberof GetActivityLogList200ResponseMeta
     */
    links: Array<GetActivityLogList200ResponseMetaLinksInner>;
    /**
     * 
     * @type {string}
     * @memberof GetActivityLogList200ResponseMeta
     */
    path: string;
    /**
     * 
     * @type {number}
     * @memberof GetActivityLogList200ResponseMeta
     */
    per_page: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityLogList200ResponseMeta
     */
    to: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityLogList200ResponseMeta
     */
    total: number;
}

/**
 * Check if a given object implements the GetActivityLogList200ResponseMeta interface.
 */
export function instanceOfGetActivityLogList200ResponseMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "current_page" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "last_page" in value;
    isInstance = isInstance && "links" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "per_page" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function GetActivityLogList200ResponseMetaFromJSON(json: any): GetActivityLogList200ResponseMeta {
    return GetActivityLogList200ResponseMetaFromJSONTyped(json, false);
}

export function GetActivityLogList200ResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetActivityLogList200ResponseMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_page': json['current_page'],
        'from': json['from'],
        'last_page': json['last_page'],
        'links': ((json['links'] as Array<any>).map(GetActivityLogList200ResponseMetaLinksInnerFromJSON)),
        'path': json['path'],
        'per_page': json['per_page'],
        'to': json['to'],
        'total': json['total'],
    };
}

export function GetActivityLogList200ResponseMetaToJSON(value?: GetActivityLogList200ResponseMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_page': value.current_page,
        'from': value.from,
        'last_page': value.last_page,
        'links': ((value.links as Array<any>).map(GetActivityLogList200ResponseMetaLinksInnerToJSON)),
        'path': value.path,
        'per_page': value.per_page,
        'to': value.to,
        'total': value.total,
    };
}

