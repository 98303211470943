import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import { Complaints } from '@/Service/Api/QueryKeys/Complaints';
import { Channels } from "@/Service/Api/QueryKeys/Channels";
import { ActivityLogComplaints } from "@/Service/Api/QueryKeys/ActivityLogComplaints";
import { Customers } from "@/Service/Api/QueryKeys/Customers";
import { Investigation } from "@/Service/Api/QueryKeys/Investigation";
import { AuthUser } from "@/Service/Api/QueryKeys/AuthUser";
import { Decision } from "@/Service/Api/QueryKeys/Decision";
import { Documents } from "@/Service/Api/QueryKeys/Documents";
import { Fos } from "@/Service/Api/QueryKeys/Fos";
import { Organisations } from "@/Service/Api/QueryKeys/Organisations";
import { Products } from "@/Service/Api/QueryKeys/Products";
import { ProductCategories } from "@/Service/Api/QueryKeys/ProductCategories";
import { Reports } from "@/Service/Api/QueryKeys/Reports";
import { RootCauses } from "@/Service/Api/QueryKeys/RootCauses";
import { VulnerableCustomerCategories } from "@/Service/Api/QueryKeys/VulnerableCustomerCategories";
import { Letters } from "@/Service/Api/QueryKeys/Letters";
import { Notifications } from "@/Service/Api/QueryKeys/Notifications";
import { Users } from "@/Service/Api/QueryKeys/Users";
import { Bespokes } from "@/Service/Api/QueryKeys/Bespokes";
import { SearchAddress } from "@/Service/Api/QueryKeys/SearchAddress";
import { ActivityLog } from "@/Service/Api/QueryKeys/ActivityLog";
import { FcaRootCauses } from "@/Service/Api/QueryKeys/FcaRootCauses";

export const QueryKeys = mergeQueryKeys(
    Complaints,
    Channels,
    ActivityLogComplaints,
    Customers,
    Investigation,
    AuthUser,
    Decision,
    Documents,
    Fos,
    Organisations,
    Products,
    ProductCategories,
    Reports,
    RootCauses,
    FcaRootCauses,
    VulnerableCustomerCategories,
    Letters,
    Notifications,
    Users,
    Bespokes,
    SearchAddress,
    ActivityLog,
);
