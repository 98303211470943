/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ComplaintFosFinalDecisionOutcome = {
    Business: 'agree_with_business',
    Customer: 'agree_with_customer'
} as const;
export type ComplaintFosFinalDecisionOutcome = typeof ComplaintFosFinalDecisionOutcome[keyof typeof ComplaintFosFinalDecisionOutcome];


export function ComplaintFosFinalDecisionOutcomeFromJSON(json: any): ComplaintFosFinalDecisionOutcome {
    return ComplaintFosFinalDecisionOutcomeFromJSONTyped(json, false);
}

export function ComplaintFosFinalDecisionOutcomeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintFosFinalDecisionOutcome {
    return json as ComplaintFosFinalDecisionOutcome;
}

export function ComplaintFosFinalDecisionOutcomeToJSON(value?: ComplaintFosFinalDecisionOutcome | null): any {
    return value as any;
}

