export const downloadBlob = (content: Blob, filename?: string) => {

    const url = window.URL.createObjectURL(content);
    const link = window.document.createElement('a');
    link.href = url;
    if(filename){
        link.setAttribute('download', filename);
    }
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
