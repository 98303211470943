import { useMutation } from '@tanstack/react-query';
import { CreateComplaintBespokeRequest } from '@/stub';
import { processValidationErrors } from '@/Util/Api/processValidationErrors';
import { BespokeMessagesApiClient } from "@/Service/Api/ApiClients/BespokeMessagesApiClient";

export const useCreateBespoke = () => {
    return useMutation({
        mutationFn: async (data: CreateComplaintBespokeRequest) => {
            try {
                return await BespokeMessagesApiClient.createComplaintBespoke(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
