/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerVulnerableCategory } from './CustomerVulnerableCategory';
import {
    CustomerVulnerableCategoryFromJSON,
    CustomerVulnerableCategoryFromJSONTyped,
    CustomerVulnerableCategoryToJSON,
} from './CustomerVulnerableCategory';

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    vulnerable_category_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id_number: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    is_hec: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    is_vulnerable: boolean;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    display_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    type: CustomerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    postcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    country?: string | null;
    /**
     * 
     * @type {CustomerVulnerableCategory}
     * @memberof Customer
     */
    vulnerable_category?: CustomerVulnerableCategory | null;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    opened_complaints_count?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    closed_complaints_count?: number | null;
}


/**
 * @export
 */
export const CustomerTypeEnum = {
    Person: 'person',
    Organisation: 'organisation'
} as const;
export type CustomerTypeEnum = typeof CustomerTypeEnum[keyof typeof CustomerTypeEnum];


/**
 * Check if a given object implements the Customer interface.
 */
export function instanceOfCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "id_number" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "is_hec" in value;
    isInstance = isInstance && "is_vulnerable" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'vulnerable_category_id': !exists(json, 'vulnerable_category_id') ? undefined : json['vulnerable_category_id'],
        'id_number': json['id_number'],
        'email': json['email'],
        'is_hec': json['is_hec'],
        'is_vulnerable': json['is_vulnerable'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': json['type'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'vulnerable_category': !exists(json, 'vulnerable_category') ? undefined : CustomerVulnerableCategoryFromJSON(json['vulnerable_category']),
        'opened_complaints_count': !exists(json, 'opened_complaints_count') ? undefined : json['opened_complaints_count'],
        'closed_complaints_count': !exists(json, 'closed_complaints_count') ? undefined : json['closed_complaints_count'],
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vulnerable_category_id': value.vulnerable_category_id,
        'id_number': value.id_number,
        'email': value.email,
        'is_hec': value.is_hec,
        'is_vulnerable': value.is_vulnerable,
        'title': value.title,
        'firstname': value.firstname,
        'middlename': value.middlename,
        'surname': value.surname,
        'display_name': value.display_name,
        'name': value.name,
        'type': value.type,
        'phone': value.phone,
        'address1': value.address1,
        'address2': value.address2,
        'postcode': value.postcode,
        'county': value.county,
        'city': value.city,
        'country': value.country,
        'vulnerable_category': CustomerVulnerableCategoryToJSON(value.vulnerable_category),
        'opened_complaints_count': value.opened_complaints_count,
        'closed_complaints_count': value.closed_complaints_count,
    };
}

