import { type DefaultError, useQuery, type UseQueryResult } from '@tanstack/react-query';
import { productCategoryApiClient } from '@/Service/Api/ApiClients/ProductCategoryApiClient';
import { type Category, type GetCategoryWithProductsRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetProductCategoriesParams = {
    requestParams?: GetCategoryWithProductsRequest
    enabled?: boolean
    select?
};

export const useGetProductCategories = ({
    enabled = true,
    select,
    requestParams = {}
}: useGetProductCategoriesParams): UseQueryResult<Category[], DefaultError> => useQuery({
    ...QueryKeys.productCategories.list(requestParams),
    queryFn: async () => await productCategoryApiClient.getCategoryWithProducts(requestParams),
    enabled,
    select
});
