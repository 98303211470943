import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Stepper, type StepperRefAttributes } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import {
    ComplaintCustomerContactType,
    ContactChannelType,
    type CreateComplaintRequest,
    type StoreComplaintRequestCustomersInner
} from '@/stub';
import { useCreateComplaint } from '@/Service/Api/ApiHooks/Complaint/useCreateComplaint';
import ComplaintContactsForm, {
    CustomerContactTypes,
    type FinalizedComplaintContactsData
} from '@/components/Core/Complaint/ComplaintContactsForm';
import ComplaintInfoForm, { type ComplaintInfoFormData } from '@/components/Core/Complaint/ComplaintInfoForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ComplaintCreatedMessage } from '@/Messages/Toast/Complaint/ComplaintCreatedMessage';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/Service/Api/QueryKeys/QueryKeys';
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { url } from "@/helpers/general";
import { useOrgId } from "@/Hooks/useOrgId";
import { useAddToast } from "@/Hooks/useAddToast";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { useUpdateComplaintChannel } from "@/Service/Api/ApiHooks/ComplaintChannel/useUpdateComplaintChannel";
import { useGetCustomer } from "@/Service/Api/ApiHooks/Customer/useGetCustomer";

type StepperTab = {
    name: string
    component?: React.ReactNode
};

const CreateComplaintPage: React.FC = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const orgId = useOrgId();
    const addToast = useAddToast();
    const [params] = useSearchParams();
    const stepperRef = useRef<StepperRefAttributes>(null);

    const [complaintContactsFormData, setComplaintContactsFormData] = useState<FinalizedComplaintContactsData>();
    const [complaintFormData, setComplaintFormData] = useState<ComplaintInfoFormData>();

    const createComplaintMutation = useCreateComplaint();
    const updateComplaintChannelMutation = useUpdateComplaintChannel({});

    const { data: customer } = useGetCustomer({
        requestParams: {
            customer_id: params.get('customer_id')
        },
        enabled: !!params.get('customer_id')
    });

    const tabs: StepperTab[] = [
        {
            name: 'Customer',
            component: <ComplaintContactsForm
                complaintContactsData={complaintContactsFormData}
                onSubmit={(customerFormData) => {
                    setComplaintContactsFormData(customerFormData);
                    stepperRef.current?.nextCallback();
                }}
            />
        },
        {
            name: 'Complaint',
            component: <ComplaintInfoForm
                lastStep
                isProcessing={createComplaintMutation.isPending || updateComplaintChannelMutation.isPending}
                errorBag={createComplaintMutation.isError ? [createComplaintMutation.error] : undefined}
                onBack={() => {
                    stepperRef.current?.prevCallback();
                }}
                onSubmit={(complaintFormData: ComplaintInfoFormData) => {
                    setComplaintFormData(complaintFormData);
                    onSubmit(complaintFormData);
                }}
            />
        }
    ];

    const onSubmit = (newComplaintFormData: ComplaintInfoFormData) => {
        if (complaintContactsFormData) {
            const {
                primaryCorrespondence,
                ...complaintContacts
            } = complaintContactsFormData;
            const customers: StoreComplaintRequestCustomersInner[] = [];
            (Object.keys(complaintContacts) as ComplaintCustomerContactType[]).forEach(contactType => {
                const complaintContact = complaintContacts[contactType];
                if (complaintContact) {
                    customers.push({
                        is_primary: contactType === primaryCorrespondence.contactType,
                        customer_id: complaintContact.id,
                        type: contactType
                    });
                }
            });
            const createComplaintRequest: CreateComplaintRequest = {
                StoreComplaintRequest: {
                    customers,
                    raised_date: formatToApiDate(newComplaintFormData.raised_date),
                    product_id: newComplaintFormData.product_id,
                    root_cause_id: newComplaintFormData.root_cause_id,
                    summary: newComplaintFormData.summary,
                    channel: newComplaintFormData.channel
                }
            };
            createComplaintMutation.mutate(
                createComplaintRequest,
                {
                    onSuccess: async (complaint) => {
                        if (newComplaintFormData.channel?.files?.[0]) {
                            await updateComplaintChannelMutation.mutateAsync({
                                complaint_id: complaint.id,
                                channel_id: complaint?.channel.id,
                                contact_type: ContactChannelType.OnComplaintCreate,
                                file: newComplaintFormData.channel?.files[0] ?? ''
                            });
                        }

                        await queryClient.invalidateQueries({
                            queryKey: QueryKeys.complaints._def
                        });
                        addToast(ComplaintCreatedMessage);
                        navigate(url('inbox', { orgId }));
                    },
                    onError: (error) => {
                        addToast(CustomErrorMessage(error));
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (customer) {
            setComplaintContactsFormData((prev) => ({
                ...prev,
                customer: customer,
                primaryCorrespondence: {
                    contactType: ComplaintCustomerContactType.Customer,
                    label: CustomerContactTypes.customer.label
                }
            }));
            stepperRef.current?.nextCallback();
        }
    }, [customer]);

    return (
        <div>
            <div className="page-header flex justify-content-between">
                <div>
                    <h3 className="page-title">New Complaint</h3>
                    <span className="page-subtitle">Log a complaint</span>
                </div>
            </div>

            <div className="page-item">
                <Card>
                    <Stepper linear ref={stepperRef}>
                        {tabs.map((tab, index) =>
                            <StepperPanel key={index} header={tab.name}>
                                {tab.component}
                            </StepperPanel>
                        )}
                    </Stepper>
                </Card>
            </div>
        </div>
    );
};

export default withAuthenticationRequired(CreateComplaintPage);
