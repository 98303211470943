import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { productApiClient } from "@/Service/Api/ApiClients/ProductApiClient";

export const useGetProductsInfinite = ({ enabled = true, requestParams = {} }) => useInfiniteQuery({
    ...QueryKeys.products.list(requestParams),
    queryFn: async ({ pageParam = 1 }) => {
        return await productApiClient.getProducts({ ...requestParams, page: pageParam });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPageParam === lastPage.meta.last_page) {
            return undefined;
        }
        return ++lastPageParam;
    },
    enabled,
});
