// New complaint form config

import moment from "moment";
import {COMPLAINT_CHANNEL_OPTIONS, CUSTOMER_TITLE_OPTIONS, MARSHMALLOW_COMPLAINT_CHANNEL_OPTIONS, PERSON_ORGANISATION_OPTIONS} from "../constants";

export const newComplaintFormConfig = (custom_form_data = false) => {
    return {
        customer: {
            key: 'customer',
            categories: [
                {
                    key: 'customer',
                    name: 'Customer',
                    fields: [
                        {
                            name: 'title',
                            label: 'Title',
                            type: 'dropdown',
                            dropdownOptions: CUSTOMER_TITLE_OPTIONS,
                            extraOptions: {
                                checkboxOptions: {
                                    name: 'is_hec',
                                    bottomMargin: 0
                                }
                            },
                            validation: [],
                        },
                        {
                            name: 'is_hec',
                            label: 'Is HEC',
                            type: 'checkbox',
                            isExtraCheckbox: true,
                            validation: [],
                        },
                        {
                            name: 'id_number',
                            label: 'Customer ID',
                            type: 'text',
                            validation: [{name: 'required'}],
                        },
                        {
                            name: 'firstname',
                            label: 'First Name',
                            type: 'text',
                            validation: [{name: 'required'}],
                        },
                        {
                            name: 'middlename',
                            label: 'Middle Name',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'surname',
                            label: 'Surname',
                            type: 'text',
                            validation: [{name: 'required'}],
                        },
                        {
                            name: 'phone',
                            label: 'Phone Number',
                            type: 'text',
                            validation: [{name: 'phoneNumber'}],
                        },
                        {
                            name: 'email',
                            label: 'Email Address',
                            type: 'text',
                            validation: [{name: 'email'}, {name: 'required'}],
                        },
                        {
                            name: 'address1',
                            label: 'Address Line 1',
                            type: 'autocomplete',
                            extraOptions: {
                                autocompleteType: 'loquateSearchAddress',
                                updateFields: {
                                    address1: 'address1', // frontend field name; backend field name
                                    address2: 'address2',
                                    city: 'city',
                                    country: 'country',
                                    county: 'county',
                                    postcode: 'postcode'
                                }
                            },
                            validation: [],
                        },
                        {
                            name: 'address2',
                            label: 'Address Line 2',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'postcode',
                            label: 'Post Code',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'county',
                            label: 'County',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'city',
                            label: 'City',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'country',
                            label: 'Country',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'is_vulnerable',
                            label: 'Vulnerable Customer',
                            type: 'checkbox',
                            validation: [],
                        },
                        {
                            name: 'vulnerable_category_id[0]',
                            label: 'Drivers',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'customerVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 0,
                                    maxIndex: 2
                                }
                            },
                            dependency: {
                                type: 'visibility',
                                sourceField: 'is_vulnerable',
                                matchValue: true
                            },
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'customer',
                                        name: 'is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        {
                            name: 'vulnerable_category_id[1]',
                            label: 'Characteristics',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'customerVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 1,
                                    maxIndex: 2
                                }
                            },
                            dependency: {
                                type: 'visibility',
                                sourceField: 'is_vulnerable',
                                matchValue: true
                            },
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'customer',
                                        name: 'is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        {
                            name: 'vulnerable_category_id[2]',
                            label: 'Adjustments',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'customerVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 2,
                                    maxIndex: 2
                                }
                            },
                            dependency: {
                                type: 'visibility',
                                sourceField: 'is_vulnerable',
                                matchValue: true
                            },
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'customer',
                                        name: 'is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                    ]
                },
                {
                    key: 'third_party',
                    name: 'Third Party',
                    fields: [
                        {
                            name: 'type',
                            label: 'Person/Organisation',
                            type: 'dropdown',
                            dropdownOptions: PERSON_ORGANISATION_OPTIONS,
                            dependencySource: true,
                            validation: [],
                        },
                        // person
                        {
                            name: 'person_title',
                            label: 'Title',
                            type: 'dropdown',
                            dropdownOptions: CUSTOMER_TITLE_OPTIONS,
                            extraOptions: {
                                checkboxOptions: {
                                    name: 'person_is_hec',
                                    bottomMargin: 0
                                }
                            },
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_is_hec',
                            label: 'Is HEC',
                            type: 'checkbox',
                            isExtraCheckbox: true,
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person',
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_id_number',
                            label: 'ID Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: []
                            // validation: [{
                            //     name: 'required_if',
                            //     params: {
                            //         dependsOnField: {
                            //             category: 'third_party',
                            //             name: 'type',
                            //             value: 'person'
                            //         }
                            //     }
                            // }],
                        },
                        {
                            name: 'person_firstname',
                            label: 'First Name',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'type',
                                        value: 'person'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'person_middlename',
                            label: 'Middle Name',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_surname',
                            label: 'Surname',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'type',
                                        value: 'person'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'person_phone',
                            label: 'Phone Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{name: 'phoneNumber'}],
                        },
                        {
                            name: 'person_email',
                            label: 'Email Address',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{name: 'email'}, {
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'type',
                                        value: 'person'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'person_address1',
                            label: 'Address Line 1',
                            type: 'autocomplete',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            extraOptions: {
                                autocompleteType: 'loquateSearchAddress',
                                updateFields: {
                                    person_address1: 'address1', // frontend field name; backend field name
                                    person_address2: 'address2',
                                    person_city: 'city',
                                    person_country: 'country',
                                    person_county: 'county',
                                    person_postcode: 'postcode'
                                }
                            },
                            validation: [],
                        },
                        {
                            name: 'person_address2',
                            label: 'Address Line 2',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_postcode',
                            label: 'Post Code',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_county',
                            label: 'County',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_city',
                            label: 'City',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_country',
                            label: 'Country',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_is_vulnerable',
                            label: 'Vulnerable Customer',
                            type: 'checkbox',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'vulnerable_category_id[0]',
                            label: 'Drivers',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'thirdPartyVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 0,
                                    maxIndex: 2
                                }
                            },
                            dependencies: [
                                {
                                    type: 'visibility',
                                    sourceField: 'person_is_vulnerable',
                                    mandatory: true,
                                    matchValue: true
                                },
                                {
                                    type: 'visibility',
                                    sourceField: 'type',
                                    mandatory: true,
                                    matchValue: 'person'
                                }
                            ],
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'person_is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        {
                            name: 'vulnerable_category_id[1]',
                            label: 'Characteristics',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'thirdPartyVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 1,
                                    maxIndex: 2
                                }
                            },
                            dependencies: [
                                {
                                    type: 'visibility',
                                    sourceField: 'person_is_vulnerable',
                                    mandatory: true,
                                    matchValue: true
                                },
                                {
                                    type: 'visibility',
                                    mandatory: true,
                                    sourceField: 'type',
                                    matchValue: 'person'
                                }
                            ],
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'person_is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        {
                            name: 'vulnerable_category_id[2]',
                            label: 'Adjustments',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'thirdPartyVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 2,
                                    maxIndex: 2
                                }
                            },
                            dependencies: [
                                {
                                    type: 'visibility',
                                    sourceField: 'person_is_vulnerable',
                                    mandatory: true,
                                    matchValue: true
                                },
                                {
                                    type: 'visibility',
                                    mandatory: true,
                                    sourceField: 'type',
                                    matchValue: 'person'
                                }
                            ],
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'person_is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        // organisation
                        {
                            name: 'organisation_name',
                            label: 'Name',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'type',
                                        value: 'organisation'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'organisation_id_number',
                            label: 'ID Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'type',
                                        value: 'organisation'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'organisation_phone',
                            label: 'Phone Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [{name: 'phoneNumber'}],
                        },
                        {
                            name: 'organisation_email',
                            label: 'Email Address',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [{name: 'email'}, {
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'third_party',
                                        name: 'type',
                                        value: 'organisation'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'organisation_address1',
                            label: 'Address Line 1',
                            type: 'autocomplete',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            extraOptions: {
                                autocompleteType: 'loquateSearchAddress',
                                updateFields: {
                                    organisation_address1: 'address1', // frontend field name; backend field name
                                    organisation_address2: 'address2',
                                    organisation_city: 'city',
                                    organisation_country: 'country',
                                    organisation_county: 'county',
                                    organisation_postcode: 'postcode'
                                }
                            },
                            validation: [],
                        },
                        {
                            name: 'organisation_address2',
                            label: 'Address Line 2',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_postcode',
                            label: 'Post Code',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_county',
                            label: 'County',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_city',
                            label: 'City',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_country',
                            label: 'Country',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                    ]
                },
                {
                    key: 'solicitor',
                    name: 'Solicitor',
                    fields: [
                        {
                            name: 'type',
                            label: 'Person/Organisation',
                            type: 'dropdown',
                            dropdownOptions: PERSON_ORGANISATION_OPTIONS,
                            dependencySource: true,
                            validation: [],
                        },
                        // person
                        {
                            name: 'person_title',
                            label: 'Title',
                            type: 'dropdown',
                            dropdownOptions: CUSTOMER_TITLE_OPTIONS,
                            extraOptions: {
                                checkboxOptions: {
                                    name: 'person_is_hec',
                                    bottomMargin: 0
                                }
                            },
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_is_hec',
                            label: 'Is HEC',
                            type: 'checkbox',
                            isExtraCheckbox: true,
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person',
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_id_number',
                            label: 'ID Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'type',
                                        value: 'person'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'person_firstname',
                            label: 'First Name',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'type',
                                        value: 'person'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'person_middlename',
                            label: 'Middle Name',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_surname',
                            label: 'Surname',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'type',
                                        value: 'person'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'person_phone',
                            label: 'Phone Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{name: 'phoneNumber'}],
                        },
                        {
                            name: 'person_email',
                            label: 'Email Address',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [{name: 'email'}, {
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'type',
                                        value: 'person'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'person_address1',
                            label: 'Address Line 1',
                            type: 'autocomplete',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            extraOptions: {
                                autocompleteType: 'loquateSearchAddress',
                                updateFields: {
                                    person_address1: 'address1', // frontend field name; backend field name
                                    person_address2: 'address2',
                                    person_city: 'city',
                                    person_country: 'country',
                                    person_county: 'county',
                                    person_postcode: 'postcode'
                                }
                            },
                            validation: [],
                        },
                        {
                            name: 'person_address2',
                            label: 'Address Line 2',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_postcode',
                            label: 'Post Code',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_county',
                            label: 'County',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_city',
                            label: 'City',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_country',
                            label: 'Country',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'person_is_vulnerable',
                            label: 'Vulnerable Customer',
                            type: 'checkbox',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'person'
                            },
                            subcategory: 'person',
                            validation: [],
                        },
                        {
                            name: 'vulnerable_category_id[0]',
                            label: 'Drivers',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'solicitorVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 0,
                                    maxIndex: 2
                                }
                            },
                            dependencies: [
                                {
                                    type: 'visibility',
                                    sourceField: 'person_is_vulnerable',
                                    mandatory: true,
                                    matchValue: true
                                },
                                {
                                    type: 'visibility',
                                    sourceField: 'type',
                                    mandatory: true,
                                    matchValue: 'person'
                                }
                            ],
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'person_is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        {
                            name: 'vulnerable_category_id[1]',
                            label: 'Characteristics',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'solicitorVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 1,
                                    maxIndex: 2
                                }
                            },
                            dependencies: [
                                {
                                    type: 'visibility',
                                    sourceField: 'person_is_vulnerable',
                                    mandatory: true,
                                    matchValue: true
                                },
                                {
                                    type: 'visibility',
                                    sourceField: 'type',
                                    mandatory: true,
                                    matchValue: 'person'
                                }
                            ],
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'person_is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        {
                            name: 'vulnerable_category_id[2]',
                            label: 'Adjustments',
                            type: 'dropdown',
                            extraOptions: {
                                apiDropdownOptions: {
                                    apiName: 'vulnerable_customer_categories',
                                    stateName: 'solicitorVulnerableCategories',
                                    inputName: 'vulnerable_category_id',
                                    index: 2,
                                    maxIndex: 2
                                }
                            },
                            dependencies: [
                                {
                                    type: 'visibility',
                                    sourceField: 'person_is_vulnerable',
                                    mandatory: true,
                                    matchValue: true
                                },
                                {
                                    type: 'visibility',
                                    sourceField: 'type',
                                    mandatory: true,
                                    matchValue: 'person'
                                }
                            ],
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'person_is_vulnerable',
                                        value: true
                                    }
                                }
                            }],
                        },
                        // organisation
                        {
                            name: 'organisation_name',
                            label: 'Name',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'type',
                                        value: 'organisation'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'organisation_id_number',
                            label: 'ID Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            validation: [{
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'type',
                                        value: 'organisation'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'organisation_phone',
                            label: 'Phone Number',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [{name: 'phoneNumber'}],
                        },
                        {
                            name: 'organisation_email',
                            label: 'Email Address',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [{name: 'email'}, {
                                name: 'required_if',
                                params: {
                                    dependsOnField: {
                                        category: 'solicitor',
                                        name: 'type',
                                        value: 'organisation'
                                    }
                                }
                            }],
                        },
                        {
                            name: 'organisation_address1',
                            label: 'Address Line 1',
                            type: 'autocomplete',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            extraOptions: {
                                autocompleteType: 'loquateSearchAddress',
                                updateFields: {
                                    organisation_address1: 'address1', // frontend field name; backend field name
                                    organisation_address2: 'address2',
                                    organisation_city: 'city',
                                    organisation_country: 'country',
                                    organisation_county: 'county',
                                    organisation_postcode: 'postcode'
                                }
                            },
                            validation: [],
                        },
                        {
                            name: 'organisation_address2',
                            label: 'Address Line 2',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_postcode',
                            label: 'Post Code',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_county',
                            label: 'County',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_city',
                            label: 'City',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                        {
                            name: 'organisation_country',
                            label: 'Country',
                            type: 'text',
                            dependency: {
                                type: 'visibility',
                                sourceField: 'type',
                                matchValue: 'organisation'
                            },
                            subcategory: 'organisation',
                            validation: [],
                        },
                    ]
                },
                {
                    key: 'company',
                    name: 'Company',
                    fields: [
                        {
                            name: 'name',
                            label: 'Name',
                            type: 'text',
                            validation: [{
                                name: 'required_with',
                                params: {
                                    dependsOnField: {
                                        category: 'company',
                                        name: 'id_number',
                                    }
                                }
                            }, {
                                name: 'required_with',
                                params: {
                                    dependsOnField: {
                                        category: 'company',
                                        name: 'email',
                                    }
                                }
                            }],
                        },
                        {
                            name: 'id_number',
                            label: 'ID Number',
                            type: 'text',
                            validation: [{
                                name: 'required_with',
                                params: {
                                    dependsOnField: {
                                        category: 'company',
                                        name: 'name',
                                    }
                                }
                            }, {
                                name: 'required_with',
                                params: {
                                    dependsOnField: {
                                        category: 'company',
                                        name: 'email',
                                    }
                                }
                            }],
                        },
                        {
                            name: 'phone',
                            label: 'Phone Number',
                            type: 'text',
                            validation: [{name: 'phoneNumber'}],
                        },
                        {
                            name: 'email',
                            label: 'Email Address',
                            type: 'text',
                            validation: [{name: 'email'}, {
                                name: 'required_with',
                                params: {
                                    dependsOnField: {
                                        category: 'company',
                                        name: 'id_number',
                                    }
                                }
                            }, {
                                name: 'required_with',
                                params: {
                                    dependsOnField: {
                                        category: 'company',
                                        name: 'name',
                                    }
                                }
                            }],
                        },
                        {
                            name: 'address1',
                            label: 'Address Line 1',
                            type: 'autocomplete',
                            extraOptions: {
                                autocompleteType: 'loquateSearchAddress',
                                updateFields: {
                                    address1: 'address1', // frontend field name; backend field name
                                    address2: 'address2',
                                    city: 'city',
                                    country: 'country',
                                    county: 'county',
                                    postcode: 'postcode'
                                }
                            },
                            validation: [],
                        },
                        {
                            name: 'address2',
                            label: 'Address Line 2',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'postcode',
                            label: 'Post Code',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'county',
                            label: 'County',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'city',
                            label: 'City',
                            type: 'text',
                            validation: [],
                        },
                        {
                            name: 'country',
                            label: 'Country',
                            type: 'text',
                            validation: [],
                        },
                    ]
                },
            ]
        },
        complaint: {
            key: 'complaint',
            fields: custom_form_data ? [
                    {
                        name: 'raised_date',
                        label: 'Date Received Complaint',
                        type: 'date',
                        extraOptions: {
                            maxDate: moment().toDate()
                        },
                        validation: [{name: 'required'}],
                        disabled: true
                    },
                    {
                        name: 'category_id',
                        label: 'Product Type',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'categories',
                                stateName: 'productOptions',
                                resetFields: ['product_id'],
                                index: 0,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'product_id',
                        label: 'Product',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'categories',
                                stateName: 'productOptions',
                                index: 1,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'summary',
                        label: 'Complaint Summary',
                        type: 'textarea',
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'root_cause_id[0]',
                        label: 'Root Cause 1',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'root_causes',
                                stateName: 'rootCauses',
                                inputName: 'root_cause_id',
                                index: 0,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'root_cause_id[1]',
                        label: 'Root Cause 2',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'root_causes',
                                stateName: 'rootCauses',
                                inputName: 'root_cause_id',
                                index: 1,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'root_cause_id[2]',
                        label: 'Root Cause 3',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'root_causes',
                                stateName: 'rootCauses',
                                inputName: 'root_cause_id',
                                index: 2,
                                maxIndex: 2
                            }
                        },
                        validation: [],
                    },
                    {
                        name: 'source',
                        label: 'Channel',
                        type: 'dropdown',
                        dropdownOptions: MARSHMALLOW_COMPLAINT_CHANNEL_OPTIONS,
                        dependencySource: true,
                        extraOptions: {
                            erasableOnFieldChange: 'source_info'
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'source_info',
                        label: 'Source Information?',
                        type: 'dropdown',
                        extraOptions: {
                            configDropdownOptions: MARSHMALLOW_COMPLAINT_CHANNEL_OPTIONS
                        },
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: ['third_party', 'aggregator']
                        },
                        validation: [],
                    },
                    {
                        name: 'source_info',
                        label: 'Extra Informations',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'referred-complaints'
                        },
                        validation: [],
                    },

                    // phone call
                    {
                        name: 'phone_call_phone_number',
                        label: 'Phone number',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'phone_call'
                        },
                        subcategory: 'phone_call',
                        apiName: 'sender',
                        validation: [{name: 'phoneNumber'}]
                    },
                    {
                        name: 'phone_call_content_file',
                        label: 'Upload file',
                        type: 'upload',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'phone_call'
                        },
                        extraOptions: {
                            multiple: false,
                            noDeleteBtn: true
                        },
                        subcategory: 'phone_call',
                        apiName: 'complaint_documents',
                        validation: [],
                    },

                    // letter
                    {
                        name: 'letter_from_address',
                        label: 'From address',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'letter'
                        },
                        subcategory: 'letter',
                        apiName: 'sender',
                        validation: []
                    },
                    {
                        name: 'letter_to_address',
                        label: 'To address',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'letter'
                        },
                        subcategory: 'letter',
                        apiName: 'recipient',
                        validation: []
                    },
                    {
                        name: 'letter_content_file',
                        label: 'Upload file',
                        type: 'upload',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'letter'
                        },
                        extraOptions: {
                            multiple: false,
                            noDeleteBtn: true
                        },
                        subcategory: 'letter',
                        apiName: 'complaint_documents',
                        validation: [],
                    },

                    // email
                    {
                        name: 'email_sender_email',
                        label: 'Sender email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'email'
                        },
                        subcategory: 'email',
                        apiName: 'sender',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'email_recipient_email',
                        label: 'Recipient email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'email'
                        },
                        subcategory: 'email',
                        apiName: 'recipient',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'email_content_file',
                        label: 'Upload file',
                        type: 'upload',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'email'
                        },
                        extraOptions: {
                            multiple: false,
                            noDeleteBtn: true
                        },
                        subcategory: 'email',
                        apiName: 'complaint_documents',
                        validation: [],
                    },

                    // website
                    {
                        name: 'website_sender_email',
                        label: 'Sender email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'website'
                        },
                        subcategory: 'website',
                        apiName: 'sender',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'website_recipient_email',
                        label: 'Recipient email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'website'
                        },
                        subcategory: 'website',
                        apiName: 'recipient',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'website_content_file',
                        label: 'Upload file',
                        type: 'upload',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'website'
                        },
                        extraOptions: {
                            multiple: false,
                            noDeleteBtn: true
                        },
                        subcategory: 'website',
                        apiName: 'complaint_documents',
                        validation: [],
                    },

                    // social media
                    {
                        name: 'social_media_sender',
                        label: 'Sender',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'social_media'
                        },
                        subcategory: 'social_media',
                        apiName: 'sender',
                        validation: []
                    },
                    {
                        name: 'social_media_recipient',
                        label: 'Recipient',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'social_media'
                        },
                        subcategory: 'social_media',
                        apiName: 'recipient',
                        validation: []
                    },
                    {
                        name: 'social_media_content_file',
                        label: 'Upload file',
                        type: 'upload',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'social_media'
                        },
                        extraOptions: {
                            multiple: false,
                            noDeleteBtn: true
                        },
                        subcategory: 'social_media',
                        apiName: 'complaint_documents',
                        validation: [],
                    },

                    // intercom
                    {
                        name: 'intercom_content_file',
                        label: 'Intercom chat',
                        type: 'upload',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'intercom'
                        },
                        extraOptions: {
                            multiple: false,
                            noDeleteBtn: true
                        },
                        subcategory: 'intercom',
                        apiName: 'complaint_documents',
                        validation: [],
                    },
                ] :
                [
                    {
                        name: 'raised_date',
                        label: 'Date Received Complaint',
                        type: 'date',
                        extraOptions: {
                            maxDate: moment().toDate()
                        },
                        validation: [{name: 'required'}],
                        disabled: true
                    },
                    {
                        name: 'category_id',
                        label: 'Product Type',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'categories',
                                stateName: 'productOptions',
                                resetFields: ['product_id'],
                                index: 0,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'product_id',
                        label: 'Product',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'categories',
                                stateName: 'productOptions',
                                index: 1,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'summary',
                        label: 'Complaint Summary',
                        type: 'textarea',
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'root_cause_id[0]',
                        label: 'Root Cause 1',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'root_causes',
                                stateName: 'rootCauses',
                                inputName: 'root_cause_id',
                                index: 0,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'root_cause_id[1]',
                        label: 'Root Cause 2',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'root_causes',
                                stateName: 'rootCauses',
                                inputName: 'root_cause_id',
                                index: 1,
                                maxIndex: 2
                            }
                        },
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'root_cause_id[2]',
                        label: 'Root Cause 3',
                        type: 'dropdown',
                        extraOptions: {
                            apiDropdownOptions: {
                                apiName: 'root_causes',
                                stateName: 'rootCauses',
                                inputName: 'root_cause_id',
                                index: 2,
                                maxIndex: 2
                            }
                        },
                        validation: [],
                    },
                    {
                        name: 'source',
                        label: 'Channel',
                        type: 'dropdown',
                        dropdownOptions: COMPLAINT_CHANNEL_OPTIONS,
                        dependencySource: true,
                        validation: [{name: 'required'}],
                    },
                    {
                        name: 'source_info',
                        label: 'Extra Informations',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'referred-complaints'
                        },
                        validation: [],
                    },
                    // phone call
                    {
                        name: 'phone_call_phone_number',
                        label: 'Phone number',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'phone_call'
                        },
                        subcategory: 'phone_call',
                        apiName: 'sender',
                        validation: [{name: 'phoneNumber'}]
                    },
                    {
                        name: 'phone_call_conversation',
                        label: 'Conversation',
                        type: 'textarea',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'phone_call'
                        },
                        subcategory: 'phone_call',
                        apiName: 'content',
                        validation: [],
                    },

                    // letter
                    {
                        name: 'letter_from_address',
                        label: 'From address',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'letter'
                        },
                        subcategory: 'letter',
                        apiName: 'sender',
                        validation: []
                    },
                    {
                        name: 'letter_to_address',
                        label: 'To address',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'letter'
                        },
                        subcategory: 'letter',
                        apiName: 'recipient',
                        validation: []
                    },
                    {
                        name: 'letter_content',
                        label: 'Content',
                        type: 'textarea',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'letter'
                        },
                        subcategory: 'letter',
                        apiName: 'content',
                        validation: [],
                    },

                    // email
                    {
                        name: 'email_sender_email',
                        label: 'Sender email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'email'
                        },
                        subcategory: 'email',
                        apiName: 'sender',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'email_recipient_email',
                        label: 'Recipient email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'email'
                        },
                        subcategory: 'email',
                        apiName: 'recipient',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'email_content',
                        label: 'Email text',
                        type: 'textarea',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'email'
                        },
                        subcategory: 'email',
                        apiName: 'content',
                        validation: [],
                    },

                    // website
                    {
                        name: 'website_sender_email',
                        label: 'Sender email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'website'
                        },
                        subcategory: 'website',
                        apiName: 'sender',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'website_recipient_email',
                        label: 'Recipient email',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'website'
                        },
                        subcategory: 'website',
                        apiName: 'recipient',
                        validation: [{name: 'email'}]
                    },
                    {
                        name: 'website_content',
                        label: 'Email text',
                        type: 'textarea',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'website'
                        },
                        subcategory: 'website',
                        apiName: 'content',
                        validation: [],
                    },

                    // social media
                    {
                        name: 'social_media_sender',
                        label: 'Sender',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'social_media'
                        },
                        subcategory: 'social_media',
                        apiName: 'sender',
                        validation: []
                    },
                    {
                        name: 'social_media_recipient',
                        label: 'Recipient',
                        type: 'text',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'social_media'
                        },
                        subcategory: 'social_media',
                        apiName: 'recipient',
                        validation: []
                    },
                    {
                        name: 'social_media_content',
                        label: 'Content',
                        type: 'textarea',
                        dependency: {
                            type: 'visibility',
                            sourceField: 'source',
                            matchValue: 'social_media'
                        },
                        subcategory: 'social_media',
                        apiName: 'content',
                        validation: [],
                    }
                ]
        },
        rootCause: {
            key: 'rootCause',
            fields: [
                {
                    name: 'root_cause_final_id[0]',
                    label: 'Final Root Cause 1',
                    type: 'dropdown',
                    extraOptions: {
                        apiDropdownOptions: {
                            apiName: 'root_causes',
                            stateName: 'rootCausesFinal',
                            inputName: 'root_cause_final_id',
                            index: 0,
                            maxIndex: 2
                        }
                    },
                    validation: [{name: 'required'}]
                },
                {
                    name: 'root_cause_final_id[1]',
                    label: 'Final Root Cause 2',
                    type: 'dropdown',
                    extraOptions: {
                        apiDropdownOptions: {
                            apiName: 'root_causes',
                            stateName: 'rootCausesFinal',
                            inputName: 'root_cause_final_id',
                            index: 1,
                            maxIndex: 2
                        }
                    },
                    validation: [{name: 'required'}]
                },
                {
                    name: 'root_cause_final_id[2]',
                    label: 'Final Root Cause 3',
                    type: 'dropdown',
                    extraOptions: {
                        apiDropdownOptions: {
                            apiName: 'root_causes',
                            stateName: 'rootCausesFinal',
                            inputName: 'root_cause_final_id',
                            index: 2,
                            maxIndex: 2
                        }
                    },
                    validation: []
                },
            ]
        },
        finish: {
            key: 'finish',
            fields: [
                {
                    name: 'actions_taken',
                    label: 'Actions taken to resolve',
                    type: 'textarea',
                    validation: [],
                },
                {
                    name: 'additional_notes',
                    label: 'Additional notes or actions',
                    type: 'textarea',
                    validation: [],
                },
                {
                    name: 'files',
                    label: 'Upload supporting documentation',
                    type: 'upload',
                    validation: [],
                },
                {
                    name: 'outcome_id',
                    label: 'Outcomes',
                    type: 'dropdown',
                    dropdownOptionsAPI: 'outcomes',
                    dependencySource: true,
                    validation: [],
                },
                {
                    name: 'closing_reasons',
                    label: 'Closing reasons',
                    type: 'textarea',
                    dependency: {
                        type: 'visibility',
                        sourceField: 'outcome_id',
                        matchType: '3wd',
                        matchValue: 'none'
                    },
                    validation: [],
                },
            ]
        }
    }
};
