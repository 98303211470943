export type PrimitiveValue = {
    type: 'string' | 'number' | 'boolean' | 'undefined' | 'null'
    value: string | undefined | null
};

export const storageSavePrimitive = (key: string, value: string | number | boolean | undefined | null) => {
    let stringValue: string | null;
    if (typeof value !== 'string')
        stringValue = String(value);
    else {
        stringValue = value;
    }
    window.localStorage.setItem(key, JSON.stringify({
        type: typeof value,
        value: stringValue
    }));
};

export const storageGetPrimitive = <T extends string | number | boolean | undefined>(key: string): T | null => {
    const item = window.localStorage.getItem(key);
    if (item === null)
        return item;
    const primitiveValue: PrimitiveValue = JSON.parse(item);
    switch (primitiveValue.type) {
        case 'string':
            return primitiveValue.value as T;
        case 'number':
            return Number(primitiveValue.value) as T;
        case 'boolean':
            return primitiveValue.value as T;
        case 'undefined':
            return undefined as T;
        case 'null':
            return null;
    }
};

export const storageRemove = (key: string) => {
    window.localStorage.removeItem(key);
};
