/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelConfigDescription } from './ChannelConfigDescription';
import {
    ChannelConfigDescriptionFromJSON,
    ChannelConfigDescriptionFromJSONTyped,
    ChannelConfigDescriptionToJSON,
} from './ChannelConfigDescription';

/**
 * 
 * @export
 * @interface ChannelConfig
 */
export interface ChannelConfig {
    /**
     * 
     * @type {string}
     * @memberof ChannelConfig
     */
    channel_type: ChannelConfigChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChannelConfig
     */
    label: string;
    /**
     * 
     * @type {ChannelConfigDescription}
     * @memberof ChannelConfig
     */
    description?: ChannelConfigDescription | null;
    /**
     * 
     * @type {ChannelConfigDescription}
     * @memberof ChannelConfig
     */
    from?: ChannelConfigDescription | null;
    /**
     * 
     * @type {ChannelConfigDescription}
     * @memberof ChannelConfig
     */
    to?: ChannelConfigDescription | null;
    /**
     * 
     * @type {ChannelConfigDescription}
     * @memberof ChannelConfig
     */
    content?: ChannelConfigDescription | null;
    /**
     * 
     * @type {ChannelConfigDescription}
     * @memberof ChannelConfig
     */
    file?: ChannelConfigDescription | null;
}


/**
 * @export
 */
export const ChannelConfigChannelTypeEnum = {
    Email: 'email',
    Letter: 'letter',
    PhoneCall: 'phone_call',
    Website: 'website',
    SocialMedia: 'social_media',
    Fos: 'fos',
    ReferredComplaints: 'referred_complaints',
    Intercom: 'intercom',
    ThirdParty: 'third_party',
    Aggregator: 'aggregator'
} as const;
export type ChannelConfigChannelTypeEnum = typeof ChannelConfigChannelTypeEnum[keyof typeof ChannelConfigChannelTypeEnum];


/**
 * Check if a given object implements the ChannelConfig interface.
 */
export function instanceOfChannelConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "channel_type" in value;
    isInstance = isInstance && "label" in value;

    return isInstance;
}

export function ChannelConfigFromJSON(json: any): ChannelConfig {
    return ChannelConfigFromJSONTyped(json, false);
}

export function ChannelConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel_type': json['channel_type'],
        'label': json['label'],
        'description': !exists(json, 'description') ? undefined : ChannelConfigDescriptionFromJSON(json['description']),
        'from': !exists(json, 'from') ? undefined : ChannelConfigDescriptionFromJSON(json['from']),
        'to': !exists(json, 'to') ? undefined : ChannelConfigDescriptionFromJSON(json['to']),
        'content': !exists(json, 'content') ? undefined : ChannelConfigDescriptionFromJSON(json['content']),
        'file': !exists(json, 'file') ? undefined : ChannelConfigDescriptionFromJSON(json['file']),
    };
}

export function ChannelConfigToJSON(value?: ChannelConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_type': value.channel_type,
        'label': value.label,
        'description': ChannelConfigDescriptionToJSON(value.description),
        'from': ChannelConfigDescriptionToJSON(value.from),
        'to': ChannelConfigDescriptionToJSON(value.to),
        'content': ChannelConfigDescriptionToJSON(value.content),
        'file': ChannelConfigDescriptionToJSON(value.file),
    };
}

