import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { setDocumentTitle } from "@/helpers/general";
import { GetUsersFromOrganisationRequest } from "@/stub";
import ContentHeader from "@/components/Blocks/ContentHeader";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import OrganisationAuditDatable from "@/components/Datatables/OrganisationAuditDatable";
import AuditFilterList, { AuditFilterOption } from "@/components/Filters/Audit/AuditFilterList";
import { FilterOptions } from "@/Enums/FilterOptions";
import { AuditFilterOptions } from "@/Enums/AuditFilterOptions";
import useGetAuditFiltersQuery from "@/Hooks/useGetAuditFiltersQuery";
import { formatToApiDate } from "@/Util/formatToApiDate";


const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;

const allAvailableInboxPresets: AuditFilterOption[] = [
    {
        type: FilterOptions.DateRange,
        value: 'date_range'
    },
    {
        type: FilterOptions.Owner,
        value: 'owner'
    },
    {
        type: AuditFilterOptions.Complaint,
        value: 'complaint'
    },
    {
        type: AuditFilterOptions.Type,
        value: 'type'
    },
    {
        type: AuditFilterOptions.Action,
        value: 'action'
    },
];



export type OrganisationAuditPageProps = {
    className?: string
};

/**
 * Organisation Users Page
 */
const OrganisationAuditPage: React.FC = ({ className }: OrganisationAuditPageProps) => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>(searchInput);

    useDebounce(
        () => {
            setDebouncedSearchInput(searchInput);
        },
        600,
        [searchInput]
    );
    useEffect(() => {
        setDocumentTitle('Organisation Users');
    }, []);

    const {
        ownerQuery,
        dataRangeQueryTo,
        dataRangeQueryFrom,
        typeQuery,
        complaintIdQuery,
        actionQuery,
    } = useGetAuditFiltersQuery();

    const requestParams = useMemo<GetUsersFromOrganisationRequest>(() => {
        const fromDate = dataRangeQueryFrom ?  new Date(dataRangeQueryFrom) : undefined;
        const toDate = dataRangeQueryTo ? new Date(dataRangeQueryTo) : undefined;
        return {
            event:actionQuery ?? undefined,
            complaint_id: complaintIdQuery ?? undefined,
            user_id: ownerQuery ?? undefined,
            date_from: formatToApiDate(fromDate),
            date_to: formatToApiDate(toDate),
            search: debouncedSearchInput ?? undefined,
            log_name: typeQuery ?? undefined,
        };
    }, [debouncedSearchInput,actionQuery,typeQuery,dataRangeQueryFrom,dataRangeQueryTo,ownerQuery,complaintIdQuery]);

    return (
        <div>
            <StyledWrap className={className}>
                <ContentHeader
                    title={'Audit'}
                    description={'Forseti Complaints Manager'}
                    rightSectionTemplate={
                        <BaseTextInput
                            value={searchInput || ''}
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                        />
                    }
                />


                <div className="content-container">
                    <div className="page-actions">
                        <AuditFilterList className="flex gap-2 justify-content-start"
                            filterOptions={allAvailableInboxPresets}/>
                    </div>
                    <OrganisationAuditDatable requestParams={requestParams}/>
                </div>
            </StyledWrap>
        </div>
    );
};

export default withAuthenticationRequired(OrganisationAuditPage);
