import { useMutation } from '@tanstack/react-query';
import { type UpdateComplaintCustomersRequest } from '@/stub';
import type { UseFormSetError } from 'react-hook-form';
import { complaintCustomersApiClient } from '@/Service/Api/ApiClients/ComplaintCustomersApiClient';

type useUpdateComplaintCustomerParams = {
    setError?: UseFormSetError<never>
}

export const useUpdateComplaintCustomer = ({ setError }: useUpdateComplaintCustomerParams = {}) => {
    return useMutation({
        mutationFn: async (data: UpdateComplaintCustomersRequest) => await complaintCustomersApiClient.updateComplaintCustomers(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                        Object.keys(errorBody.errors).map(key => {
                            setError(key, { message: errorBody.errors[key][0] });
                        });
                        throw new Error(errorBody.message)
                    }
                )
            )
    })
}
