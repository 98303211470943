import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { usersApiClient } from "@/Service/Api/ApiClients/UsersApiClient";
import { GetUsersFromOrganisationRequest } from "@/stub";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetUsersParams = {
    requestParams?: GetUsersFromOrganisationRequest
    enabled?: boolean
    savePreviousData?: boolean
};

export const useGetUsers = ({ requestParams, enabled, savePreviousData }: useGetUsersParams = {}) => useQuery({
    ...QueryKeys.users.list(requestParams),
    queryFn: async () => await usersApiClient.getUsersFromOrganisation(requestParams),
    enabled,
    gcTime: 0,
    placeholderData: savePreviousData ? keepPreviousData : undefined
});
