import { useEffect, useMemo, useState } from 'react';
import { ChatSquareTextIcon, MessageIcon, NoteIcon } from '@/helpers/svg-icons';
import { capitalizeString, hasPermission, snakeToNormalCase } from '@/helpers/general';
import { COMPLAINT_ALLOWED_EXTENSIONS, FILE_UPLOAD_MAX_SIZE } from '@/config/constants';
import { complaintFormConfig } from '@/config/forms';
import FileUploadTemplate from '../../templates/forms/FileUploadTemplate';
import { getUserFullName } from '@/helpers/user';
import Investigation from '../tabs/Investigation';
import SmallMessage from '../SmallMsg';
import Contacts from '../tabs/Contacts';
import Outcome from '../tabs/Outcome';
import Files from '../tabs/Files';
import Audit from '../tabs/Audit';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import styled from 'styled-components';
import Actions from '../cards/Actions';
import Fos from '../tabs/Fos';
import { useNavigate, useParams } from 'react-router-dom';
import AddNoteModal from '@/components/Modals/AddNoteModal';
import { useAuthStore } from '@/Stores/AuthStore';
import { useGetComplaint } from '@/Service/Api/ApiHooks/Complaint/useGetComplaint';
import Customer from '@/components/partials/tabs/Customer';
import { type FileUploadHandlerEvent } from 'primereact/fileupload';
import ComplaintInfo from '@/components/partials/tabs/ComplaintInfo';
import { ActionStateEnum, ActionType, ContactChannelType } from '@/stub';
import { formatToUKDate } from '@/Util/formatToUKDate';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import AddContactModal from "@/components/Modals/AddContactModal";
import Channel from "@/components/partials/tabs/Channel";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { ComplaintDocumentUploadedMessage } from "@/Messages/Toast/ComplaintDocuments/ComplaintDocumentUploadedMessage";
import { useCreateComplaintChannel } from "@/Service/Api/ApiHooks/ComplaintChannel/useCreateComplaintChannel";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { useEscalateComplaint } from "@/Service/Api/ApiHooks/Complaint/useEscalateComplaint";
import { ComplaintEscalatedMessage } from "@/Messages/Toast/Complaint/ComplaintEscalatedMessage";
import AddBespokeMessageModal from "@/components/Modals/AddBespokeMessageModal";
import Letters from "@/components/partials/tabs/Letters";
import { Divider } from "primereact/divider";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { useFormStateStore } from "@/Stores/FormStore";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ContactsTable from "@/components/partials/tabs/ContactsTable";

const ComplaintWrap = styled.div`
    .header-info-wrap {
        color: var(--gray-500);
        font-style: normal;
        line-height: 150%;

        .hi-title {
            font-size: 12px;
            font-weight: 700;
        }

        .hi-subtitle {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .main-buttons {
        display: flex;

        .p-button {
            margin-right: 10px;
        }
    }

    .tc-header {
        position: relative;

        .tc-buttons {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .list-fields {
        color: var(--gray-500);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .field-item {
            div:first-child {
                width: 180px;
            }

            div:nth-child(2) {
                white-space: pre-wrap;
            }
        }
    }

    .back-to-btn {
        cursor: pointer;
        font-size: 14px;

        > i.pi {
            font-size: 12px;
        }
    }

    .main-correspondence-wrap {
        position: absolute;
        left: 385px;
        top: -1px;

        @media only screen and (max-width: 1460px) {
            position: inherit;
        }

        .inline-input-wrap {
            > label {
                width: 170px;
                margin-top: 7px;
            }

            .input-wrap .p-dropdown,
            .input-wrap .p-inputtext {
                width: 180px;
            }
        }
    }
`;

const tabs = [
    {
        key: 'customer',
        name: 'Customer',
    },
    {
        key: 'complaint',
        name: 'Complaint',
    },
    {
        key: 'channel',
        name: 'Channel',
    },
    {
        key: 'investigation',
        name: 'Investigation',

    },
    {
        key: 'outcome',
        name: 'Outcome',
    },
    {
        key: 'fos',
        name: 'FOS',
    },

    {
        key: 'contacts',
        name: 'Contacts',
    },
    {
        key: 'audit',
        name: 'Audit',
    },
    {
        key: 'files',
        name: 'Files',
    },
    {
        key: 'letters',
        name: 'Letters',
    }
];

const ComplaintView = () => {
    const { complaintId } = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const isDirty = useFormStateStore((state) => state.isDirty);
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const authUser = useAuthStore((state) => state.authUser);

    const createComplaintChannelMutation = useCreateComplaintChannel();
    const escalateComplaintMutation = useEscalateComplaint();

    const { data: complaint } = useGetComplaint({
        requestParameters: {
            complaint_id: Number(complaintId as string)
        }
    });

    // Tabs
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    // Modals
    const [showContactModal, setShowContactModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showBespokeMessageModal, setShowBespokeMessageModal] = useState(false);
    const resetDirty = useFormStateStore((state) => state.resetDirty);
    /**
     * Generate tab content
     *
     * @param {object} tab object data
     */
    const generateTabContent = (tab) => {
        switch (tab.key) {
            case 'customer':
                return (
                    <div className="tab-content">
                        <div className='tc-header mt-2'>
                            <Customer
                                complaint={complaint}
                            />
                        </div>
                    </div>
                );
            case 'complaint':
                return (
                    <div>
                        <ComplaintInfo
                            complaint={complaint}
                        />
                    </div>
                );
            case 'channel':
                return (
                    <div>
                        <Channel
                            complaint={complaint}
                        />
                    </div>
                );
            case 'investigation':
                return (
                    <div>
                        <Investigation
                            complaint={complaint}
                        />
                    </div>
                );

            case 'outcome':
                return (
                    <Outcome
                        complaint={complaint}
                    />
                );

            case 'fos':
                if (hasPermission(authUser?.permissions, 'write:fos')) {
                    return (
                        <Fos
                            complaint={complaint}
                        />
                    );
                }
                break;

            case 'contacts':
                return (
                    <ContactsTable
                        complaint={complaint}
                    />
                );

            case 'audit':
                return (
                    <div>
                        <Audit
                            complaint={complaint}
                        />
                    </div>
                );

            case 'files':
                return (
                    <div className="custom-content">
                        <Files
                            complaint={complaint}
                        />
                    </div>
                );
            case 'letters':
                return (
                    <div className="custom-content">
                        <Letters
                            complaint={complaint}
                        />
                    </div>
                );

            default:
                return (
                    <div></div>
                );
        }
    };

    const nextAction = useMemo(() => {
        const action = complaint?.actions.find((action) => action.state !== ActionStateEnum.Closed);
        if (action) {
            return `${snakeToNormalCase(action.type)} - ${formatToUKDate(action.due_date)}`;
        }
        return 'No action';
    }, [complaint]);

    const onEscalate = async () => {
        await escalateComplaintMutation.mutateAsync({
            complaint_id: complaint.id
        }, {
            onSuccess: () => {
                addToastMessage(ComplaintEscalatedMessage);
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.complaints._def
                    }),
                ]);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    useEffect(() => {
        return () => {
            resetDirty();
        };
    }, []);

    return (
        complaint && <ComplaintWrap className={complaint ? '' : 'hidden'}>
            <div className="page-header flex justify-content-between align-items-center">
                <div>
                    <div>
                        <Button className="p-0" link onClick={() => navigate(-1)}>
                            <i className="pi pi-chevron-left mr-1 fake-link"></i>
                            <span className="fake-link">Back to list</span>
                        </Button>
                        <span className="page-subtitle ml-4">#{complaint?.ref_number}</span>
                    </div>
                    <div className="flex align-items-center">
                        <h3 className="page-title">{getUserFullName(complaint?.main_customer)}</h3>
                        <SmallMessage message={(complaint?.days_open || 0) + ' days old'}/>
                    </div>
                </div>

                <div>
                    {hasPermission(authUser?.permissions, 'escalate:complaints') &&
                        !complaint?.is_escalated && (
                        <Button
                            className="p-button-outlined p-button-sm ml-2"
                            label="Escalate"
                            loading={escalateComplaintMutation.isPending}
                            disabled={isReadonlyComplaint(complaint)}
                            onClick={onEscalate}
                        />
                    )}
                </div>
            </div>

            <div className="page-item">
                <Card>
                    <div className="grid grid-nogutter">
                        <div className="header-info-wrap col-3 mb-3">
                            <div className="hi-title">Date Received Complaint</div>
                            <div
                                className="hi-subtitle">{formatToUKDate(complaint?.raised_date)}</div>
                        </div>

                        <div className="header-info-wrap col-3 mb-3">
                            <div className="hi-title">Created at</div>
                            <div
                                className="hi-subtitle">{formatToUKDate(complaint?.created_at, true)}</div>
                        </div>

                        <div className="header-info-wrap col-3">
                            <div className="hi-title">Status</div>
                            <div className="hi-subtitle">{complaint?.status}</div>
                        </div>

                        <div className="header-info-wrap col-3">
                            <div className="hi-title">Assigned to</div>
                            <div className="hi-subtitle">
                                {complaint?.owner?.display_name ?? ''}
                            </div>
                        </div>

                        <div className="header-info-wrap col-3">
                            <div className="hi-title">Escalated</div>
                            <div className="hi-subtitle fake-link">{complaint?.is_escalated ? 'Yes' : 'No'}</div>
                        </div>

                        <div className="header-info-wrap col-3">
                            <div className="hi-title">Next action</div>
                            <div className="hi-subtitle flex fake-link">
                                <span>{nextAction}</span>
                            </div>
                        </div>

                        <div className="header-info-wrap col-3">
                            <div className="hi-title">Product</div>
                            <div className="hi-subtitle">{complaint?.product.description || ''}</div>
                        </div>

                        <div className="header-info-wrap col-3">
                            <div className="hi-title">FOS</div>
                            <div className="hi-subtitle fake-link">{complaint?.is_fos ? 'Yes' : 'No'}</div>
                        </div>

                        {complaint?.decision && <div className="header-info-wrap col-3">
                            <div className="hi-title">Outcome</div>
                            <div className="hi-subtitle fake-link">
                                {capitalizeString(complaint.decision.decision_type)}
                            </div>
                        </div>
                        }
                    </div>
                </Card>
            </div>

            <div className="mx-4 main-buttons">
                <Button
                    className="p-button-outlined p-button-sm"
                    label="Add contact"
                    disabled={isReadonlyComplaint(complaint)}
                    icon={<ChatSquareTextIcon/>}
                    onClick={() => {
                        setShowContactModal(true);
                    }}
                />
                <Button
                    className="p-button-outlined p-button-sm"
                    label="Add note"
                    disabled={isReadonlyComplaint(complaint)}
                    icon={<NoteIcon/>}
                    onClick={() => {
                        setShowNoteModal(true);
                    }}
                />
                <FileUploadTemplate
                    disabled={isReadonlyComplaint(complaint)}
                    name="complaint-upload-btn"
                    className="complaint-fileupload"
                    contentClassName="mb-5 p-button-sm p-button-outlined"
                    mode="basic"
                    accept={COMPLAINT_ALLOWED_EXTENSIONS.join(',')}
                    maxFileSize={FILE_UPLOAD_MAX_SIZE}
                    uploadHandler={(e: FileUploadHandlerEvent) => {
                        createComplaintChannelMutation.mutate({
                            complaint_id: complaint?.id,
                            files: e.files,
                            contact_type: ContactChannelType.Document,
                            organisation_id: authUser?.organisation_id
                        }, {
                            onSuccess: () => {
                                e.options.clear();
                                setActiveTabIndex(complaintFormConfig()?.tabs?.findIndex((tab) => tab.key === 'files'));
                                addToastMessage(ComplaintDocumentUploadedMessage);

                                void Promise.all([
                                    queryClient.invalidateQueries({
                                        queryKey: QueryKeys.channels.list({ complaint_id: complaint?.id }).queryKey
                                    }),
                                    queryClient.invalidateQueries({
                                        queryKey: QueryKeys.documents.list({ complaint_id: complaint?.id }).queryKey
                                    }),
                                    queryClient.invalidateQueries({
                                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                                    })
                                ]);
                            },
                            onError: error => {
                                e.options.clear();
                                addToastMessage(CustomErrorMessage(error));
                            }
                        });
                    }}
                    loading={createComplaintChannelMutation.isPending}
                    multiple={false}
                    chooseOptions={{ label: 'Upload file', icon: 'pi pi-cloud-upload' }}
                    auto
                />
                <Button
                    className="p-button-outlined p-button-sm"
                    label="Bespoke message"
                    disabled={isReadonlyComplaint(complaint)}
                    icon={<MessageIcon/>}
                    onClick={() => {
                        setShowBespokeMessageModal(true);
                    }}
                />
            </div>

            <div className="page-item">
                <div className="grid grid-nogutter">
                    <div className="col-9 mr-4 mb-4">
                        <Card className="p-tight">
                            <TabView
                                id={'tabpanels'}
                                activeIndex={activeTabIndex}
                                onTabChange={(e) => {
                                    if(isDirty) {
                                        confirmDialog({
                                            message: 'You have unsaved changes, do you really want to leave?',
                                            header: 'Confirmation',
                                            icon: 'pi pi-exclamation-triangle',
                                            defaultFocus: 'accept',
                                            accept: () => {
                                                setActiveTabIndex(e.index);
                                                resetDirty();
                                            },
                                            reject: () => {
                                                setActiveTabIndex(activeTabIndex);
                                            }
                                        });
                                    } else {
                                        setActiveTabIndex(e.index);
                                    }
                                }}
                                className="main-tabs no-control-btns"
                                scrollable
                            >
                                {tabs.map((tab) => {
                                    if (tab.key !== 'fos' || (tab.key === 'fos' && hasPermission(authUser?.permissions, 'write:fos'))) {
                                        return (
                                            <TabPanel header={tab.name} key={tab.key}>
                                                {generateTabContent(tab)}
                                            </TabPanel>
                                        );
                                    }
                                })}
                            </TabView>
                        </Card>
                    </div>

                    <div className="col">
                        <Card>
                            {complaint?.id && <div>
                                <Actions actions={complaint.actions.filter(action => action.state !== ActionStateEnum.Closed)} title='Next Actions'/>
                                <Divider/>
                                <Actions actions={complaint.actions.filter(action => action.state === ActionStateEnum.Closed)} title='Closed Actions'/>
                            </div>}
                        </Card>
                    </div>
                </div>
            </div>

            <ConfirmDialog />
            {showContactModal && <AddContactModal
                complaint={complaint}
                visible={showContactModal}
                setVisible={setShowContactModal}
            />}

            {showNoteModal && <AddNoteModal
                complaint={complaint}
                visible={showNoteModal}
                setVisible={setShowNoteModal}
            />}

            {showBespokeMessageModal && <AddBespokeMessageModal
                complaint={complaint}
                visible={showBespokeMessageModal}
                setVisible={setShowBespokeMessageModal}
            />}
        </ComplaintWrap>
    );
};

export default ComplaintView;
