import { Column, ColumnBodyOptions } from 'primereact/column';
import React, { useState, useMemo, useRef } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import {
    DataTable,
    type DataTablePassThroughOptions,
    type DataTableStateEvent
} from 'primereact/datatable';
import {
    Customer, CustomerTypeEnum,
    GetCustomersRequest, GetCustomersSortByEnum, GetCustomersSortOrderEnum
} from '@/stub';
import { SortOrder } from 'primereact/api';
import { produce } from 'immer';
import AnglesSort from '@/components/Icons/AnglesSort';
import { useGetCustomers } from "@/Service/Api/ApiHooks/Customer/useGetCustomers";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import CustomerModal from "@/components/Modals/CustomerModal";
import type { CustomerInfoFormData } from "@/components/Core/Customer/CustomerForm";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useUpdateCustomer } from "@/Service/Api/ApiHooks/Customer/useUpdateCustomer";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { useQueryClient } from "@tanstack/react-query";
import { ComplaintCustomerUpdatedMessage } from "@/Messages/Toast/ComplaintCustomers/ComplaintCustomerUpdatedMessage";
import { capitalize } from "@/Util/capitalize";
import { authUserCan } from "@/Util/permissionChecks";
import { Toast } from "primereact/toast";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { url } from "@/helpers/general";
import { Link, useNavigate } from "react-router-dom";
import { useOrgId } from "@/Hooks/useOrgId";
import { useImpersonatedOrgId } from "@/Hooks/useImpersonatedOrgId";

type TableFilterOptions = {
    first: number
    rows: number
    sortField: string
    sortOrder: SortOrder
    search: string
};

type TableColumnDefinition = {
    label: string
    sortable?: boolean
    field?: string
    sortField?: string
    headerTooltip?: string
    body?: React.ReactNode | ((data: Customer, options: ColumnBodyOptions) => React.ReactNode)
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .content-header {
        padding: 1rem;
        border-bottom: var(--gray-300) 1px solid;

        &__title {
            margin: 0.25rem 0;
            font-weight: 600;
            font-size: 1.5rem;
        }

        &__description {
            font-size: 0.9rem;
            color: var(--gray-500);
        }
    }

    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .datatable-container {
        margin-top: 2rem;
        font-size: 0.8rem;
    }

    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .datatable-cell {
        &__clean-text {
            margin: 0;
        }
    }

    .angles-sort {
        margin-left: 0.3rem;

        &__icon {
            color: var(--primary-200);
            font-size: 0.8rem;
            font-weight: 600;

            &.active {
                color: var(--primary-500);
            }
        }
    }
`;

export type CustomersDatatableProps = {
    requestParams?: Partial<GetCustomersRequest>
};

/**
 * DSAR datatable
 */
const CustomersDatatable = ({ requestParams }: CustomersDatatableProps) => {
    const queryClient = useQueryClient();
    const toast = useRef<Toast>(null);
    const orgId = useOrgId();
    const navigate = useNavigate();
    const impersonatedOrgId = useImpersonatedOrgId();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const updateCustomerMutation = useUpdateCustomer();
    const [viewCustomer, setViewCustomer] = useState<Customer | null>(null);
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        search: '',
        sortField: GetCustomersSortByEnum.IdNumber,
        sortOrder: SortOrder.DESC,
        rows: DT_ROWS_NO,
        first: 0
    });

    const customersRequest = useMemo<GetCustomersRequest>(() => {
        return {
            ...requestParams,
            sort_by: tableFilters.sortField as GetCustomersSortByEnum ?? undefined,
            sort_order: tableFilters.sortOrder === SortOrder.ASC ? GetCustomersSortOrderEnum.Asc : tableFilters.sortOrder === SortOrder.DESC ? GetCustomersSortOrderEnum.Desc : undefined,
            page: (tableFilters.first / tableFilters.rows) + 1,
            limit: tableFilters.rows
        };
    }, [requestParams, tableFilters.first, tableFilters.rows, tableFilters.sortField, tableFilters.sortOrder]);

    const {
        data: customersData,
        isLoading: customersLoading
    } = useGetCustomers({
        requestParams: customersRequest
    });

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
    };

    const onSort = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            draft.sortField = e.sortField;
            draft.sortOrder = e.sortOrder ?? SortOrder.UNSORTED;
        }));
    };

    const onViewClick = (customer: Customer) => {
        setViewCustomer(customer);
    };

    const onOpenComplaintClick = (event, customer: Customer) => {
        if(!!customer.opened_complaints_count){
            confirmDialog(event, customer);
        }else{
            navigateToNewComplaint(customer);
        }
    };

    /**
     * Actions column template
     *
     * @param {Customer} customer object containing row data
     */
    const actionsBodyTemplate = (customer: Customer) => {
        return (
            <div className="flex gap-2">
                <Button
                    label='View'
                    onClick={() => onViewClick(customer)}
                />
                {customer.type === CustomerTypeEnum.Person && <Button
                    disabled={!!impersonatedOrgId}
                    label='Open Complaint'
                    onClick={(event) => {
                        onOpenComplaintClick(event, customer);
                    }}
                />}
            </div>
        );
    };

    const navigateToNewComplaint = (customer: Customer) => {
        navigate(url('complaints.create', { orgId: orgId }) + `?customer_id=${customer.id}`, { replace: true });
    };

    const confirmDialog = (event, customer: Customer) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'The customer already has opened complaints. Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'reject',
            accept() {
                toast.current?.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
                navigateToNewComplaint(customer);
            },
            reject(){
                toast.current?.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
            }
        });
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'ID',
            field: 'id_number',
            sortable: true,
            sortField: 'id_number'
        },
        {
            label: 'Type',
            field: 'type',
            sortable: true,
            sortField: 'type'
        },
        {
            label: 'Title',
            field: 'title',
            sortable: true,
            sortField: 'title',
            body: customer => capitalize(customer.title)
        },
        {
            label: 'Name',
            field: 'name',
            sortable: true,
            sortField: 'name'
        },
        {
            label: 'Email',
            field: 'email',
            sortable: true,
            sortField: 'email'
        },
        {
            label: 'Complaints Count',
            headerTooltip: 'Open Complaints / Closed Complaints',
            body: (customer) => {
                return <>
                    {
                        customer.opened_complaints_count || customer.closed_complaints_count ?
                            <Link to={url('inbox', { orgId: orgId }) + `?presets=All&customer_id=${customer.id}`} replace>
                                <span>{`${customer.opened_complaints_count}/${customer.closed_complaints_count}`}</span>
                            </Link>
                            :
                            <span>{`${customer.opened_complaints_count}/${customer.closed_complaints_count}`}</span>
                    }
                </>
                ;
            }
        },
        {
            label: 'Flags',
            field: 'is_hec',
            body: (customer) => {
                return (
                    <div className="flex gap-2 mb-1">
                        {customer.is_hec && <Tag value='High Escalation' severity='danger'/>}
                        {customer.is_vulnerable && <Tag value='Vulnerable' severity='info'/>}
                    </div>

                );
            }
        },
        {
            label: 'Actions',
            body: actionsBodyTemplate
        }
    ];

    const onCustomerUpdate = async (customerInfoFormData: CustomerInfoFormData) => {
        await updateCustomerMutation.mutateAsync({
            customer_id: customerInfoFormData.id,
            UpdateCustomerRequest: customerInfoFormData
        }, {
            onSuccess: () => {
                addToastMessage(ComplaintCustomerUpdatedMessage);
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.customers._def
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.complaints._def
                    })
                ]);
                setViewCustomer(null);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    return (
        <StyledWrap>
            <div className="datatable-container">
                <DataTable
                    lazy
                    loading={customersLoading}
                    emptyMessage="No customers found."
                    value={customersData?.data}
                    totalRecords={customersData?.meta.total}
                    paginator
                    rows={tableFilters.rows}
                    rowsPerPageOptions={[10, 20, 50]}
                    first={tableFilters.first}
                    pt={dataTablePtOptions}
                    sortIcon={(options) => {
                        return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                    }}
                    sortField={tableFilters.sortField}
                    sortOrder={tableFilters.sortOrder}
                    onPage={e => {
                        onPagination(e);
                    }}
                    onSort={e => {
                        onSort(e);
                    }}
                >
                    {tableColumns.map((column, index) =>
                        <Column
                            key={`customers-column-${index}`}
                            field={column.field}
                            body={column.body}
                            header={column.label}
                            sortable={column.sortable}
                            sortField={column.sortField}
                            headerTooltip={column.headerTooltip}
                        />
                    )}
                </DataTable>
            </div>
            {viewCustomer && <CustomerModal
                dialogProps={{
                    visible: !!viewCustomer,
                    onHide: () => {setViewCustomer(null);}
                }}
                customerFormProps={{
                    initFormData: viewCustomer,
                    onSubmit: onCustomerUpdate,
                    onSubmitButtonLabel: 'Update',
                    onSubmitButtonDisabled: !authUserCan('edit:org_customers'),
                    isProcessing: updateCustomerMutation.isPending,
                    errorBag: updateCustomerMutation.isError ? [updateCustomerMutation.error] : undefined
                }}
            />}
            <Toast ref={toast} />
            <ConfirmPopup />
        </StyledWrap>
    );
};

export default CustomersDatatable;
