import React, { useMemo } from 'react';
import BaseTreeSelect, { type BaseTreeSelectProps } from '@/components/Core/Form/BaseTreeSelect';
import type { RootCause } from '@/stub';
import { useGetRootCauses } from '@/Service/Api/ApiHooks/RootCause/useGetRootCauses';
import { OptionsTree } from "@/Model/OptionsTree";

export type BaseRootCauseSelectProps = BaseTreeSelectProps & {
    queryEnabled?: boolean
    isSelectedRoot?: boolean
    selectableLvl?: number
};

const buildRootCauseTree = (rootCauses: RootCause[]): OptionsTree<RootCause>[] => {
    if (rootCauses?.length > 0) {
        return rootCauses.map((rootCause) => {
            return new OptionsTree<RootCause>(
                rootCause.id,
                rootCause,
                rootCause.label,
                rootCause.parent_names,
                rootCause.selectable,
                buildRootCauseTree(rootCause.children ?? []),
            );
        });
    }
    return [];
};

const BaseRootCauseSelect: React.FC<BaseRootCauseSelectProps> = ({ queryEnabled = false, isSelectedRoot = false, selectableLvl = 1, ...props }) => {
    const {
        data: rootCauses,
        refetch
    } = useGetRootCauses({
        requestParams: { selectable_lvl: !queryEnabled ? selectableLvl : undefined },
        select: (rootCauses: RootCause[]) => isSelectedRoot ? rootCauses ?? [] : rootCauses?.[0]?.children ?? [],
        enabled: queryEnabled || !!props.value
    });

    const rootCauseOptions = useMemo(() => {
        if (rootCauses) {
            return buildRootCauseTree(rootCauses);
        }
        return [];
    }, [rootCauses]);

    return (
        <BaseTreeSelect
            filter
            options={rootCauseOptions}
            variant='filled'
            onClick={(e) => {
                if(!queryEnabled && !rootCauses){
                    refetch();
                }
                if(props.onClick){
                    props.onClick(e);
                }
            }}
            {...props}
        />
    );
};

export default BaseRootCauseSelect;
