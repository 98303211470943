import React from 'react';
import { Button } from 'primereact/button';
import { fieldsPlaceholders } from '@/config/forms';
import { COMPLAINT_ALLOWED_EXTENSIONS, FILE_UPLOAD_MAX_SIZE } from '@/config/constants';
import { useCreateComplaintChannel } from '@/Service/Api/ApiHooks/ComplaintChannel/useCreateComplaintChannel';
import { useForm } from 'react-hook-form';
import { type Complaint, CreateComplaintChannelContactTypeEnum, type CreateComplaintChannelRequest } from '@/stub';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import BaseCalendar from '@/components/Core/Form/BaseCalendar';
import BaseTextEditor from '@/components/Core/Form/BaseTextEditor';
import { Divider } from 'primereact/divider';
import { ComplaintNoteAddedMessage } from '@/Messages/Toast/ComplaintNotes/ComplaintNoteAddedMessage';
import { useToastMessagesStore } from '@/Stores/ToastMessagesStore';
import { Dialog } from 'primereact/dialog';
import { useQueryClient } from '@tanstack/react-query';
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import AdvancedFileUpload from "@/components/Core/Form/FileUpload/AdvancedFileUpload";
import { formatToApiDate } from "@/Util/formatToApiDate";

export type AddNoteModalProps = {
    complaint?: Complaint
    visible: boolean
    setVisible: (visible: boolean) => void
};

const FileSchema = z.instanceof(File)
    .refine((file?: File) => !file || file.size <= FILE_UPLOAD_MAX_SIZE, `Max upload size is ${FILE_UPLOAD_MAX_SIZE / 1_000_000}`)
    .refine((file?: File) => !file || COMPLAINT_ALLOWED_EXTENSIONS.includes(file.type ?? ''), 'File format not supported');

const AddNoteSchema = z.object({
    due_date: z.date().nullable().optional(),
    content: z.string().optional(),
    contact_type: z.string(),
    files: z.array(FileSchema).optional()
});

type AddNoteFormData = z.infer<typeof AddNoteSchema>;
const minDate = new Date();

const AddNoteModal: React.FC<AddNoteModalProps> = ({ visible, setVisible, complaint }: AddNoteModalProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors }
    } = useForm<AddNoteFormData>({
        resolver: zodResolver(AddNoteSchema),
        defaultValues: {
            content: '',
            due_date: minDate,
            contact_type: CreateComplaintChannelContactTypeEnum.Note
        }
    });

    const createComplaintChannelMutation = useCreateComplaintChannel({ setError });

    const validate = (data: AddNoteFormData) => {
        return AddNoteSchema.safeParse(data).success;
    };

    const onSubmit = async (data: AddNoteFormData) => {
        if (validate(data)) {
            const dataToSend: CreateComplaintChannelRequest = {
                ...data,
                complaint_id: complaint.id,
                due_date: formatToApiDate(data.due_date),
                files: data.files
            };
            await createComplaintChannelMutation.mutateAsync(dataToSend, {
                onSuccess: () => {
                    reset();
                    addToastMessage(ComplaintNoteAddedMessage);
                    void Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.complaints._def
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.channels.list({ complaint_id: complaint?.id }).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.documents.list({ complaint_id: complaint?.id }).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                        })
                    ]);
                    setVisible(false);
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            });
        }
    };

    const onHide = (): void => {
        setVisible(false);
    };

    const modalContent = (
        <form id="add-note" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column row-gap-3">
                <Divider/>

                <BaseCalendar
                    control={control}
                    label="Next action date"
                    name='due_date'
                    placeholder={fieldsPlaceholders.date}
                    minDate={minDate}
                    errorMessages={errors?.due_date?.message}
                />

                <BaseTextEditor
                    label='Content'
                    textareaName='content'
                    control={control}
                    menubar={false}
                    errorMessages={errors?.content?.message}
                />

                <AdvancedFileUpload
                    className="mt-3"
                    name="files"
                    control={control}
                    label='Files upload:'
                    multiple={true}
                    errorMessages={errors.files?.message}
                />
            </div>
        </form>
    )
    ;

    const widgetFooter = (
        <>
            <Divider/>
            <div className="text-right">
                <Button
                    loading={createComplaintChannelMutation.isPending}
                    label="Add note"
                    severity="success"
                    form='add-note'
                    type='submit'
                />
            </div>
        </>
    );

    return (
        <Dialog
            id='add-note-dialog'
            header='Add a new note'
            className='note-modal'
            visible={visible}
            onHide={onHide}
            footer={widgetFooter}
            blockScroll
            draggable={false}
        >
            <div className='w-full'>
                {modalContent}
            </div>
        </Dialog>
    );
};

export default AddNoteModal;
