/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetLetters200Response,
  Letter,
  LetterType,
  UpdateLetterSentBatchRequest,
  UpdateLetterSentRequest,
} from '../models/index';
import {
    GetLetters200ResponseFromJSON,
    GetLetters200ResponseToJSON,
    LetterFromJSON,
    LetterToJSON,
    LetterTypeFromJSON,
    LetterTypeToJSON,
    UpdateLetterSentBatchRequestFromJSON,
    UpdateLetterSentBatchRequestToJSON,
    UpdateLetterSentRequestFromJSON,
    UpdateLetterSentRequestToJSON,
} from '../models/index';

export interface GetComplaintLettersRequest {
    complaint_id: number;
    organisation_id?: number;
}

export interface GetLettersRequest {
    type?: LetterType;
    sort_by?: GetLettersSortByEnum;
    sort_order?: GetLettersSortOrderEnum;
    page?: number;
    limit?: number;
    organisation_id?: number;
    due_date_from?: string;
    due_date_to?: string;
    states?: Array<GetLettersStatesEnum>;
}

export interface SentBatchLetterRequest {
    UpdateLetterSentBatchRequest?: UpdateLetterSentBatchRequest;
}

export interface SentLetterRequest {
    letter_id: number;
    UpdateLetterSentRequest?: UpdateLetterSentRequest;
}

/**
 * 
 */
export class LettersApi extends runtime.BaseAPI {

    /**
     * get list of Complaint Letters
     */
    async getComplaintLettersRaw(requestParameters: GetComplaintLettersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Letter>>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintLetters.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/letters`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LetterFromJSON));
    }

    /**
     * get list of Complaint Letters
     */
    async getComplaintLetters(requestParameters: GetComplaintLettersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Letter>> {
        const response = await this.getComplaintLettersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Letters
     */
    async getLettersRaw(requestParameters: GetLettersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLetters200Response>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        if (requestParameters.due_date_from !== undefined) {
            queryParameters['due_date_from'] = requestParameters.due_date_from;
        }

        if (requestParameters.due_date_to !== undefined) {
            queryParameters['due_date_to'] = requestParameters.due_date_to;
        }

        if (requestParameters.states) {
            queryParameters['states[]'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/letters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLetters200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of Letters
     */
    async getLetters(requestParameters: GetLettersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLetters200Response> {
        const response = await this.getLettersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sent Batch Letter
     */
    async sentBatchLetterRaw(requestParameters: SentBatchLetterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/letters/sent-batch`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLetterSentBatchRequestToJSON(requestParameters.UpdateLetterSentBatchRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Sent Batch Letter
     */
    async sentBatchLetter(requestParameters: SentBatchLetterRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.sentBatchLetterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sent Letter
     */
    async sentLetterRaw(requestParameters: SentLetterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Letter>> {
        if (requestParameters.letter_id === null || requestParameters.letter_id === undefined) {
            throw new runtime.RequiredError('letter_id','Required parameter requestParameters.letter_id was null or undefined when calling sentLetter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/letters/{letter_id}/sent`.replace(`{${"letter_id"}}`, encodeURIComponent(String(requestParameters.letter_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLetterSentRequestToJSON(requestParameters.UpdateLetterSentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LetterFromJSON(jsonValue));
    }

    /**
     * Sent Letter
     */
    async sentLetter(requestParameters: SentLetterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Letter> {
        const response = await this.sentLetterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetLettersSortByEnum = {
    Id: 'id',
    SentDate: 'sent_date',
    CreatedAt: 'created_at',
    Type: 'type',
    DueDate: 'due_date',
    State: 'state'
} as const;
export type GetLettersSortByEnum = typeof GetLettersSortByEnum[keyof typeof GetLettersSortByEnum];
/**
 * @export
 */
export const GetLettersSortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetLettersSortOrderEnum = typeof GetLettersSortOrderEnum[keyof typeof GetLettersSortOrderEnum];
/**
 * @export
 */
export const GetLettersStatesEnum = {
    DueTo: 'App\\Models\\ActionState\\DueTo',
    PastDue: 'App\\Models\\ActionState\\PastDue',
    Closed: 'App\\Models\\ActionState\\Closed'
} as const;
export type GetLettersStatesEnum = typeof GetLettersStatesEnum[keyof typeof GetLettersStatesEnum];
