import React, { useEffect } from 'react';
import '../styles/datatables.scss';
import { setDocumentTitle } from '@/helpers/general';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import KpiRootCauseDatatable from "@/components/Datatables/KpiRootCauseDatatable";

/**
 * KPI Summary page
 */
const KpiSummaryPage: React.FC = () => {
    useEffect(() => {
        setDocumentTitle('KPI Root Cause');
    }, []);

    return (
        <>
            <div className="page-content">
                <KpiRootCauseDatatable/>
            </div>
        </>
    );
};

export default withAuthenticationRequired(KpiSummaryPage);
