/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetActivityLogList200ResponseLinks } from './GetActivityLogList200ResponseLinks';
import {
    GetActivityLogList200ResponseLinksFromJSON,
    GetActivityLogList200ResponseLinksFromJSONTyped,
    GetActivityLogList200ResponseLinksToJSON,
} from './GetActivityLogList200ResponseLinks';
import type { GetActivityLogList200ResponseMeta } from './GetActivityLogList200ResponseMeta';
import {
    GetActivityLogList200ResponseMetaFromJSON,
    GetActivityLogList200ResponseMetaFromJSONTyped,
    GetActivityLogList200ResponseMetaToJSON,
} from './GetActivityLogList200ResponseMeta';
import type { Letter } from './Letter';
import {
    LetterFromJSON,
    LetterFromJSONTyped,
    LetterToJSON,
} from './Letter';

/**
 * 
 * @export
 * @interface GetLetters200Response
 */
export interface GetLetters200Response {
    /**
     * 
     * @type {Array<Letter>}
     * @memberof GetLetters200Response
     */
    data: Array<Letter>;
    /**
     * 
     * @type {GetActivityLogList200ResponseLinks}
     * @memberof GetLetters200Response
     */
    links: GetActivityLogList200ResponseLinks;
    /**
     * 
     * @type {GetActivityLogList200ResponseMeta}
     * @memberof GetLetters200Response
     */
    meta: GetActivityLogList200ResponseMeta;
}

/**
 * Check if a given object implements the GetLetters200Response interface.
 */
export function instanceOfGetLetters200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "links" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetLetters200ResponseFromJSON(json: any): GetLetters200Response {
    return GetLetters200ResponseFromJSONTyped(json, false);
}

export function GetLetters200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLetters200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(LetterFromJSON)),
        'links': GetActivityLogList200ResponseLinksFromJSON(json['links']),
        'meta': GetActivityLogList200ResponseMetaFromJSON(json['meta']),
    };
}

export function GetLetters200ResponseToJSON(value?: GetLetters200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(LetterToJSON)),
        'links': GetActivityLogList200ResponseLinksToJSON(value.links),
        'meta': GetActivityLogList200ResponseMetaToJSON(value.meta),
    };
}

