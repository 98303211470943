import React, { useEffect } from 'react';
import { Card } from 'primereact/card';
import PublicPageLayout from '../themes/default/layouts/PublicPageLayout';
import { setDocumentTitle } from '../helpers/general';
import {useAuth0} from "@auth0/auth0-react";

/**
 * Site's privacy policy page
 */
const PrivacyPolicyPage = () => {
    const { isAuthenticated} = useAuth0();

    useEffect(() => {
        setDocumentTitle('Privacy Policy');
    }, []);

    const pageContent = (
        <div className="footer-pages min-height ml-6 mt-5">
            <h1>Privacy Policy</h1>

            <h2>INTRODUCTION</h2>

            <p>Bridgetech Connect is committed to protecting the data of everyone  we deal with. Our Privacy Policy comprises separate sections for your convenience. Each section sets out important information about how Bridgetech Connect may collect and use your data depending upon the categories of individuals whose data we process (such reference to "data" means all forms of personal data).</p>
            <p>This policy applies to our clients, prospective clients, individuals we market directly to and others browsing our website. It applies globally and is based on UK and European data protection principles. We will update this policy from time to time.</p>

            <h2>WHO WE ARE</h2>

            <p>Bridgetech Connect is an international legal business that operates through separately constituted and regulated entities providing specialist software solutions in accordance with the relevant laws of the jurisdictions in which the different entities operate.</p>
            <h2>WHAT DATA DO WE COLLECT AND WHERE DO WE GET IT FROM?</h2>

            <p>Bridgetech Connect will collect, use and store your data for various reasons in connection with your relationship with us and we collect your data from a range of sources.</p>
            <p>The following table lists the main categories of data we collect and the sources from which we collect it. The table is not exhaustive and there may be other data and other sources that Bridgetech Connect collects, uses and stores in the context of our relationship.</p>

            <table>
                <tbody>
                    <tr>
                        <td><strong>Type of data</strong></td>
                        <td><strong>Source of data</strong></td>
                    </tr>

                    <tr>
                        <td><strong>Contact information</strong>, including your name, address, telephone number(s), email, your organisation details (including job title, employer, place of work and organisation contact details) and your gender</td>
                        <td>You, our client and/or our client's insured, intermediaries, those involved in a matter and/or publicly available resources</td>
                    </tr>

                    <tr>
                        <td><strong>Regulatory information</strong>, including your date of birth, identity information, details of whether you are a politically exposed person (PEP)</td>
                        <td>You, our client and/or our client's insured, intermediaries, those involved in a matter and/or publicly available resources</td>
                    </tr>

                    <tr>
                        <td><strong>Matter information</strong>, including policy number/policy inception date, claim number, loss details/claim, complaint information and any information provided to us in relation to the purpose upon which we are acting</td>
                        <td>You, our client and/or our client's insured, intermediaries, those involved in a matter and/or publicly available resources</td>
                    </tr>

                    <tr>
                        <td><strong>Billing information</strong>, including bank account details and payment/billing instructions</td>
                        <td>You, our client and/or our client's insured, intermediaries, those involved in a matter and/or publicly available resources</td>
                    </tr>

                    <tr>
                        <td><strong>Marketing preferences</strong>, including marketing communication preferences, legal and industry sector interests</td>
                        <td>You or your employer (if they are our actual or prospective client)</td>
                    </tr>

                    <tr>
                        <td><strong>Device usage and browsing information</strong>, including IP addresses, online identifiers and information automatically generated through your use of our website and digital platforms/media</td>
                        <td>You and our website/digital platforms</td>
                    </tr>

                    <tr>
                        <td><strong>Visitors to our offices</strong>, including any of the above types of data, CCTV recordings, building access records, health-related information, including dietary and access requirements</td>
                        <td>You or your employer (if they are our actual or prospective client)</td>
                    </tr>
                </tbody>
            </table>

            <h2>HOW DO WE USE DATA?</h2>

            <p>Bridgetech Connect uses your data for various purposes to provide our services as an international specialist software solutions provider.</p>
            <p>We have set out in the following table the main purposes for which your data may be processed and the "lawful basis" for the processing, i.e. the legal reason we are able to process your data.</p>

            <h3>Personal data</h3>

            <table>
                <tbody>
                    <tr>
                        <td><strong>What we use data for</strong></td>
                        <td><strong>Our reasons/lawful basis</strong></td>
                    </tr>

                    <tr>
                        <td>Providing advice and/or complaint management services (which may include the provision of management information to our client or as requested as a service in its own right to our client directly)</td>
                        <td>
                            <p>To perform our contract with you</p>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Corresponding with you or to others electronically and/or physically, e.g. by letter, fax, telephone (including within any recording or transcription), email, secure sharing portal or SMS, which may include the use of third-party suppliers (such as electronic signature providers) to assist in delivering the services to you</td>
                        <td>
                            <p>To perform our contract with you</p>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Managing and running checks within our anti-fraud systems and sharing information with our clients and other appropriate organisations for the purpose of preventing, detecting or prosecuting fraud (which may involve reference to credit reference agencies (please see <a href="https://www.transunion.co.uk/crain" target="_blank">https://www.transunion.co.uk/crain</a> and <a href="https://www.transunion.co.uk/legal-information/bureau-privacy-notice" target="_blank">https://www.transunion.co.uk/legal-information/bureau-privacy-notice</a>)</td>
                        <td>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Managing and running checks within our internal databases, which relate to complaint management services</td>
                        <td>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Complying with our legal obligations or making disclosures to government, regulatory or other public bodies where the disclosure is appropriate and/or permitted by law</td>
                        <td>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Managing our professional relationships with our clients and third parties</td>
                        <td>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Ensuring business policies are adhered to, e.g. policies covering security and internet use</td>
                        <td>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Operational reasons, such as improving efficiency, training and quality control, including obtaining your feedback on the provision of our services</td>
                        <td>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Providing training and updates</td>
                        <td>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Sharing information within Bridgetech Connect (for example, where one of our entities is advising or providing services to another entity or where we are checking for legal or commercial conflicts)</td>
                        <td>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Preventing unauthorised access and modifications to systems</td>
                        <td>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Protecting the security of systems, website pages and data used to provide the services</td>
                        <td>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>We may also use your personal data to ensure the security of systems and data to a standard that goes beyond our legal obligations and in those cases, our reasons are for legitimate interests, i.e. to protect systems, website pages (such as via the use of Google ReCAPTCHA) and data and to prevent and detect criminal activity that could be damaging for you and/or us</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Marketing (by post, email and/or via our user account area for which you are always able to opt-out/unsubscribe with no detriment), development and/or tendering in relation to our products and services</td>
                        <td>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Providing access to our files for audit, review or other quality assurance checks by our clients, regulators, auditors, professional advisors, crime and law enforcement agencies and governmental bodies (for example, the Garda National Bureau of Criminal Investigation/An Garda Siochana in Ireland) and/or certification bodies (for example, The Solicitors Regulation Authority in the UK)</td>
                        <td>
                            <p>To comply with legal and regulatory obligations</p>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Day-to-day operations of our business. For this, we may use third-party service providers (for example, access in our premises via third-party systems, procurement services, recruitment consultants, general office services, library services, translation services, website service providers and/or IT service providers)</td>
                        <td>
                            <p>For a legitimate interest</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3>Special categories of data</h3>

            <p>Certain categories of data are considered "special categories of data" and are subject to additional safeguards. The special categories of personal data which we process may relate to:</p>

            <ul>
                <li>racial or ethnic origin</li>
                <li>political opinions</li>
                <li>religious or philosophical beliefs</li>
                <li>trade union membership</li>
                <li>genetic and/or biometric data</li>
                <li>physical and mental health</li>
                <li>sex life and/or sexual orientation.</li>
            </ul>

            <p>For the special categories of personal data set out above, additional lawful bases apply as set out in the following table. Again, the information in the table is not exhaustive and Bridgetech Connect may undertake additional processing of data based on the lawful bases set out below.</p>

            <table>
                <tbody>
                    <tr>
                        <td><strong>What we use your special category personal data for</strong></td>
                        <td><strong>Our reasons/lawful basis</strong></td>
                    </tr>

                    <tr>
                        <td>Providing complaint management services to our client</td>
                        <td>
                            <p>As necessary for the establishment, exercise or defence of legal claims</p>
                            <p>As necessary for reasons of substantial public interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Obtaining legal advice, establishing, defending and enforcing our legal rights and obligations in connection with, any court or legal proceedings</td>
                        <td>
                            <p>As necessary for the establishment, exercise or defence of legal claims</p>
                            <p>As necessary for reasons of substantial public interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Complying with all relevant legal and regulatory requirements (such as anti-money laundering and client verification checks)</td>
                        <td>
                            <p>As necessary for reasons of substantial public interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Responding to binding requests or search warrants or orders from courts, governmental, regulatory and/or enforcement bodies and authorities or sharing information (on a voluntary basis) with the same</td>
                        <td>
                            <p>As necessary for the establishment, exercise or defence of legal claims</p>
                            <p>As necessary for reasons of substantial public interest</p>
                        </td>
                    </tr>

                    <tr>
                        <td>Hosting you at our offices</td>
                        <td>
                            <p>You have given your explicit consent to the processing of your dietary and access requirements.</p>
                            <p>It is necessary to protect somebody's vital interests or they are incapable of giving consent in case of any accidents and emergencies at our offices.</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>In addition to the above, there may be occasions where we process personal data relating to criminal convictions and offences. We process criminal offence data where necessary in relation to legal claims to prevent or detect unlawful acts, to comply with regulatory requirements relating to unlawful acts and dishonesty and to prevent fraud/money laundering.</p>

            <h3>Marketing</h3>

            <p>Where you receive a marketing email, event invitation or other direct mailing from us, we may collect information about you in the following ways:</p>

            <ul>
                <p>Opening emails: if you open the email either by downloading images in the email or clicking on a link, we log such activity on our database so we can personalise future emails to you.</p>
                <p>View as a web page: if you click on the "view it as a web page" link, a tracking code is generated so that the web page is personalised in the same way as the email.</p>
                <p>Links to web pages: if you click on any web link, a tracking code is generated, which we use to log such activity on our database.</p>
                <p>Unsubscribe: if you unsubscribe from any direct marketing, invitation or alert, we will continue to store your data on a "marketing suppression list" so as to record your preference.</p>
                <p>Event RSVP buttons: in our event invitations and confirmations, we provide buttons to allow you to accept, decline, cancel (and register if you are not the original recipient of the invitation) for that event. Clicking on these buttons will generate a tracking code so we can record your choice in our database to help us manage the event.</p>
            </ul>

            <h3>Improving our services</h3>

            <p>In order for us to improve our services, we endeavour to use dummy or anonymised data wherever possible. Where that is not possible, we may use personal data to test the improvement of a system(s) or development of a new system(s) in pursuit of our legitimate business interests (but only if these are not overridden by a person's interests, rights or freedoms). That being said, we will only do so in a safe and controlled manner in accordance with our obligations under data protection legislation.</p>
            <p>In the event that you object to the use of your personal data as part of our testing only, you are able to object (by emailing <a href="mailto:info@forseticomplaints.com">info@forseticomplaints.com</a>) whereby we will add your name to an opt-out list and your personal data will not be used as part of any test.</p>

            <h2>WHEN DO WE SHARE DATA?</h2>

            <p>Bridgetech Connect may, as follows, share your data with the following main parties in certain circumstances and where it is necessary to achieve the purposes detailed above:</p>

            <ul>
                <li>our business contacts</li>
                <li>our regulators, auditors, professional advisers, crime and law enforcement agencies, governmental bodies (for example, the Garda National Bureau of Criminal Investigation/An Garda Siochana in Ireland) and/or certification bodies (for example, The Solicitors Regulation Authority in the UK and in relation to our ISO27001, ISO9001 and Cyber Essentials Plus certifications).</li>
            </ul>

            <p>The list above is not exhaustive.</p>

            <h3>Transferring data out of the United Kingdom (UK) and European Economic Area (EEA)</h3>

            <p>As we are a global specialist software solutions provider, data may be transferred outside of the UK and the EEA. For example, this may be in relation to an international legal claim or transaction or where we are sharing information with our colleagues or third-party service providers who operate outside of the UK or the EEA.</p>
            <p>Where we transfer your personal data outside the UK or the EEA, we do so on the basis of an adequacy decision or (where this is not available) legally approved standard data protection clauses. In the event we cannot or choose not to continue to rely on either of those mechanisms at any time, we will not transfer your data outside the UK or the EEA unless we can do so based on an alternative mechanism or exception provided by applicable data protection law.</p>

            <h2>HOW DO WE KEEP DATA SECURE?</h2>

            <p>We have appropriate technical and organisational measures in place to protect data. We limit data access to those who have a genuine business need to access it. Those processing data will do so only in an authorised manner and are subject to a duty of confidentiality. We continually test our systems and are ISO 27001 and Cyber Essentials PLUS certified, which means we follow industry standards for information security.</p>
            <p>We also have procedures to deal with any suspected data breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</p>

            <h2>HOW LONG WILL WE KEEP DATA?</h2>

            <p>Bridgetech Connect's policy is to retain data only for as long as needed to fulfil the purpose(s) for which it was collected or otherwise as required under applicable jurisdictional laws and/or regulations and/or business continuity purposes. Under some circumstances, we may anonymise your data so that it can no longer be associated with you. We reserve the right to retain and use such anonymous data for any legitimate business purpose without further notice to you.</p>
            <p>Bridgetech Connect will typically retain data for periods set out below subject to any exceptional circumstances and/or to comply with jurisdictional laws or regulations and/or business continuity purposes.</p>

            <table>
                <tbody>
                    <tr>
                        <td colSpan="2">Subject to the exceptions below and unless there is any other valid legal, regulatory, client or business reason to retain it beyond that timescale, all matter files will usually be destroyed at approximately seven years after the matter has been closed.</td>
                    </tr>

                    <tr>
                        <td><strong>Claims files</strong></td>
                        <td><strong>Retention period</strong></td>
                    </tr>

                    <tr>
                        <td>Complaint file data</td>
                        <td>Five years after the completion of the complaint file</td>
                    </tr>

                    <tr>
                        <td><strong>Client due diligence</strong></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Client due diligence material</td>
                        <td>Five years after the business relationship ends or the occasional transaction is completed</td>
                    </tr>
                </tbody>
            </table>

            <h2>WHAT ARE YOUR RIGHTS IN RELATION TO YOUR DATA?</h2>

            <p>Bridgetech Connect will always seek to process your data in accordance with our obligations, our rights and your rights.</p>

            <p>You will not be subject to decisions based solely on automated data processing without your prior consent.</p>

            <p>In certain circumstances, you have the right to seek the erasure or correction of your data, to object to aspects of how your data is processed and otherwise to seek the restriction of the processing of your data. You also have the right to request the transfer of your data to another party in a commonly used format.</p>

            <p>You have a separate right of access to your data processed by Bridgetech Connect. You may be asked for information to confirm your identity and/or assist Bridgetech Connect to locate the data you are seeking as part of Bridgetech Connect's response to your request. If you wish to exercise your right of access, please contact our data protection enquiry team (outlined below).</p>

            <p>You have the right to raise any concerns or complain about how your data is being processed with:</p>

            <table>
                <tbody>
                    <tr>
                        <td><strong>Location</strong></td>
                        <td><strong>Data protection supervisory authority</strong></td>
                        <td><strong>Contact details</strong></td>
                    </tr>

                    <tr>
                        <td><strong>UK</strong></td>
                        <td>Information Commissioner's Office (ICO)</td>
                        <td>ICO's website: <a href="https://ico.org.uk/concerns/" target="_blank">https://ico.org.uk/concerns/</a> or +44 (0) 303 123 1113 or <a href="mailto:casework@ico.org">casework@ico.org</a></td>
                    </tr>

                    <tr>
                        <td><strong>France</strong></td>
                        <td>Commission nationale de l'informatique et des libertés (CNIL)</td>
                        <td>CNIL's website: <a href="https://www.cnil.fr/" target="_blank">https://www.cnil.fr/</a> or +33 (0) 1 53 73 22 22</td>
                    </tr>

                    <tr>
                        <td><strong>Ireland</strong></td>
                        <td>Data Protection Commissioner (DPC)</td>
                        <td>DPC's website: <a href="https://dataprotection.ie/docs/complaints/1592.htm" target="_blank">https://dataprotection.ie/docs/complaints/1592.htm</a> or +353 1800437 737 or <a href="mailto:info@dataprotection.ie">info@dataprotection.ie</a></td>
                    </tr>

                    <tr>
                        <td><strong>Italy</strong></td>
                        <td>Garante per la Protezione dei Dati Personali (GPDP)</td>
                        <td>GPDP's website: <a href="https://www.garanteprivacy.it/web/garante-privacy-en" target="_blank">https://www.garanteprivacy.it/web/garante-privacy-en</a> or +39 06 696771</td>
                    </tr>

                    <tr>
                        <td><strong>Spain</strong></td>
                        <td>Spanish Data Protection Agency/Agencia Española de Protección de Datos (AEPD)</td>
                        <td>AEPD's website: <a href="https://www.agpd.es/portalwebAGPD/index-iden-idphp.php" target="_blank">https://www.agpd.es/portalwebAGPD/index-iden-idphp.php</a> or +34 901 100 099 / +34 91 266 35 17</td>
                    </tr>
                </tbody>
            </table>

            <p>We have elected the DPC as our lead supervisory authority within the EU.</p>

            <h2>WHERE CAN YOU GET FURTHER INFORMATION?</h2>

            <p>If you have any questions about this policy, want to exercise any of your rights in relation to your data as set out above or want to raise any concerns or complain about how your data is being processed, please use the following contact details in the first instance.</p>

            <p><strong>Email</strong>: info@forseticomplaints.com</p>

            <p>
                <strong>Post</strong>: Data protection enquiry team<br />
                Bridgetech Connect<br />
                Porters Lodge<br />
                County Offices<br />
                Walton Street<br />
                Aylesbury<br />
                Buckinghamshire<br />
                HP20 1UR
            </p>

            <h2>DO YOU NEED EXTRA HELP?</h2>

            <p>If you would like this policy in another format (for example, audio or large print), please contact us (see <strong>Where can you get further information</strong> above).</p>

            <p><strong>Last updated: January 2024</strong></p>
        </div>
    );

    return isAuthenticated ? (
        <>{pageContent}</>
    ) : (
        <PublicPageLayout>
            <Card>{pageContent}</Card>
        </PublicPageLayout>
    );
};

export default PrivacyPolicyPage;
