import '@/themes/default/general.scss';
import '@/themes/default/styles/header.scss';

import '@/styles/global.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { Auth0Provider, type Auth0ProviderOptions } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { dedupeIntegration } from '@sentry/react';
import AppInitWrapper from '@/components/AppInitWrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url
).toString();

const apiV2Url = new URL(import.meta.env.VITE_API_V2_URL as string);

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        dedupeIntegration()
    ],

    // Performance Monitoring
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
    tracePropagationTargets: ['localhost', apiV2Url.host]
});

const providerConfig: Auth0ProviderOptions = {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access'
    },
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: 'localstorage'
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
    <QueryClientProvider client={queryClient}>
        <Auth0Provider
            {...providerConfig}
        >
            <AppInitWrapper/>
        </Auth0Provider>
    </QueryClientProvider>
);
