/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetUsersFromTeam200Response,
  UpdateUserRolesRequest,
  User,
} from '../models/index';
import {
    GetUsersFromTeam200ResponseFromJSON,
    GetUsersFromTeam200ResponseToJSON,
    UpdateUserRolesRequestFromJSON,
    UpdateUserRolesRequestToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface GetUserRequest {
    user_id: number;
    organisation_id?: number;
}

export interface GetUsersFromOrganisationRequest {
    limit?: number;
    search?: string;
    page?: number;
    organisation_id?: number;
}

export interface UpdateUserRolesOperationRequest {
    user_id: number;
    UpdateUserRolesRequest?: UpdateUserRolesRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Get  user
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get  user
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of users from organisation
     */
    async getUsersFromOrganisationRaw(requestParameters: GetUsersFromOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersFromTeam200Response>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersFromTeam200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of users from organisation
     */
    async getUsersFromOrganisation(requestParameters: GetUsersFromOrganisationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersFromTeam200Response> {
        const response = await this.getUsersFromOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update user roles
     */
    async updateUserRolesRaw(requestParameters: UpdateUserRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling updateUserRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/roles`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRolesRequestToJSON(requestParameters.UpdateUserRolesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * update user roles
     */
    async updateUserRoles(requestParameters: UpdateUserRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.updateUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
