/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateComplaintFosRequest
 */
export interface UpdateComplaintFosRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateComplaintFosRequest
     */
    is_fos: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    case_requested_date_received?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    case_requested_date_due?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    case_review?: UpdateComplaintFosRequestCaseReviewEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    case_review_summary?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    case_submitted?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    adjudication_date_received?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    adjudication_outcome?: UpdateComplaintFosRequestAdjudicationOutcomeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    adjudication_outcome_summary?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    adjudication_date_request_final_decision?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateComplaintFosRequest
     */
    final_decision_requested?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    final_decision_date_requested?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    final_decision_requested_by?: UpdateComplaintFosRequestFinalDecisionRequestedByEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    escalation_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    further_info_due_date?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    further_info_date_submitted?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    further_info_summary?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    final_decision_date_received?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    final_decision_outcome?: UpdateComplaintFosRequestFinalDecisionOutcomeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    final_decision_outcome_summary?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateComplaintFosRequest
     */
    redress?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateComplaintFosRequest
     */
    other_remedial?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    remedial_summary?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    remedial_date_due?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComplaintFosRequest
     */
    remedial_date_completed?: string;
}


/**
 * @export
 */
export const UpdateComplaintFosRequestCaseReviewEnum = {
    ChallengeJurisdiction: 'challenge_jurisdiction',
    OfferToSettle: 'offer_to_settle',
    DefendFrlOutcome: 'defend_frl_outcome'
} as const;
export type UpdateComplaintFosRequestCaseReviewEnum = typeof UpdateComplaintFosRequestCaseReviewEnum[keyof typeof UpdateComplaintFosRequestCaseReviewEnum];

/**
 * @export
 */
export const UpdateComplaintFosRequestAdjudicationOutcomeEnum = {
    Business: 'agree_with_business',
    Customer: 'agree_with_customer'
} as const;
export type UpdateComplaintFosRequestAdjudicationOutcomeEnum = typeof UpdateComplaintFosRequestAdjudicationOutcomeEnum[keyof typeof UpdateComplaintFosRequestAdjudicationOutcomeEnum];

/**
 * @export
 */
export const UpdateComplaintFosRequestFinalDecisionRequestedByEnum = {
    Customer: 'customer',
    Business: 'business'
} as const;
export type UpdateComplaintFosRequestFinalDecisionRequestedByEnum = typeof UpdateComplaintFosRequestFinalDecisionRequestedByEnum[keyof typeof UpdateComplaintFosRequestFinalDecisionRequestedByEnum];

/**
 * @export
 */
export const UpdateComplaintFosRequestFinalDecisionOutcomeEnum = {
    Business: 'agree_with_business',
    Customer: 'agree_with_customer'
} as const;
export type UpdateComplaintFosRequestFinalDecisionOutcomeEnum = typeof UpdateComplaintFosRequestFinalDecisionOutcomeEnum[keyof typeof UpdateComplaintFosRequestFinalDecisionOutcomeEnum];


/**
 * Check if a given object implements the UpdateComplaintFosRequest interface.
 */
export function instanceOfUpdateComplaintFosRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "is_fos" in value;

    return isInstance;
}

export function UpdateComplaintFosRequestFromJSON(json: any): UpdateComplaintFosRequest {
    return UpdateComplaintFosRequestFromJSONTyped(json, false);
}

export function UpdateComplaintFosRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateComplaintFosRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_fos': json['is_fos'],
        'case_requested_date_received': !exists(json, 'case_requested_date_received') ? undefined : json['case_requested_date_received'],
        'case_requested_date_due': !exists(json, 'case_requested_date_due') ? undefined : json['case_requested_date_due'],
        'case_review': !exists(json, 'case_review') ? undefined : json['case_review'],
        'case_review_summary': !exists(json, 'case_review_summary') ? undefined : json['case_review_summary'],
        'case_submitted': !exists(json, 'case_submitted') ? undefined : json['case_submitted'],
        'adjudication_date_received': !exists(json, 'adjudication_date_received') ? undefined : json['adjudication_date_received'],
        'adjudication_outcome': !exists(json, 'adjudication_outcome') ? undefined : json['adjudication_outcome'],
        'adjudication_outcome_summary': !exists(json, 'adjudication_outcome_summary') ? undefined : json['adjudication_outcome_summary'],
        'adjudication_date_request_final_decision': !exists(json, 'adjudication_date_request_final_decision') ? undefined : json['adjudication_date_request_final_decision'],
        'final_decision_requested': !exists(json, 'final_decision_requested') ? undefined : json['final_decision_requested'],
        'final_decision_date_requested': !exists(json, 'final_decision_date_requested') ? undefined : json['final_decision_date_requested'],
        'final_decision_requested_by': !exists(json, 'final_decision_requested_by') ? undefined : json['final_decision_requested_by'],
        'escalation_reason': !exists(json, 'escalation_reason') ? undefined : json['escalation_reason'],
        'further_info_due_date': !exists(json, 'further_info_due_date') ? undefined : json['further_info_due_date'],
        'further_info_date_submitted': !exists(json, 'further_info_date_submitted') ? undefined : json['further_info_date_submitted'],
        'further_info_summary': !exists(json, 'further_info_summary') ? undefined : json['further_info_summary'],
        'final_decision_date_received': !exists(json, 'final_decision_date_received') ? undefined : json['final_decision_date_received'],
        'final_decision_outcome': !exists(json, 'final_decision_outcome') ? undefined : json['final_decision_outcome'],
        'final_decision_outcome_summary': !exists(json, 'final_decision_outcome_summary') ? undefined : json['final_decision_outcome_summary'],
        'redress': !exists(json, 'redress') ? undefined : json['redress'],
        'other_remedial': !exists(json, 'other_remedial') ? undefined : json['other_remedial'],
        'remedial_summary': !exists(json, 'remedial_summary') ? undefined : json['remedial_summary'],
        'remedial_date_due': !exists(json, 'remedial_date_due') ? undefined : json['remedial_date_due'],
        'remedial_date_completed': !exists(json, 'remedial_date_completed') ? undefined : json['remedial_date_completed'],
    };
}

export function UpdateComplaintFosRequestToJSON(value?: UpdateComplaintFosRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_fos': value.is_fos,
        'case_requested_date_received': value.case_requested_date_received,
        'case_requested_date_due': value.case_requested_date_due,
        'case_review': value.case_review,
        'case_review_summary': value.case_review_summary,
        'case_submitted': value.case_submitted,
        'adjudication_date_received': value.adjudication_date_received,
        'adjudication_outcome': value.adjudication_outcome,
        'adjudication_outcome_summary': value.adjudication_outcome_summary,
        'adjudication_date_request_final_decision': value.adjudication_date_request_final_decision,
        'final_decision_requested': value.final_decision_requested,
        'final_decision_date_requested': value.final_decision_date_requested,
        'final_decision_requested_by': value.final_decision_requested_by,
        'escalation_reason': value.escalation_reason,
        'further_info_due_date': value.further_info_due_date,
        'further_info_date_submitted': value.further_info_date_submitted,
        'further_info_summary': value.further_info_summary,
        'final_decision_date_received': value.final_decision_date_received,
        'final_decision_outcome': value.final_decision_outcome,
        'final_decision_outcome_summary': value.final_decision_outcome_summary,
        'redress': value.redress,
        'other_remedial': value.other_remedial,
        'remedial_summary': value.remedial_summary,
        'remedial_date_due': value.remedial_date_due,
        'remedial_date_completed': value.remedial_date_completed,
    };
}

