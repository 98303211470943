/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ComplaintFosCaseFileReview = {
    ChallengeJurisdiction: 'challenge_jurisdiction',
    OfferToSettle: 'offer_to_settle',
    DefendFrlOutcome: 'defend_frl_outcome'
} as const;
export type ComplaintFosCaseFileReview = typeof ComplaintFosCaseFileReview[keyof typeof ComplaintFosCaseFileReview];


export function ComplaintFosCaseFileReviewFromJSON(json: any): ComplaintFosCaseFileReview {
    return ComplaintFosCaseFileReviewFromJSONTyped(json, false);
}

export function ComplaintFosCaseFileReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintFosCaseFileReview {
    return json as ComplaintFosCaseFileReview;
}

export function ComplaintFosCaseFileReviewToJSON(value?: ComplaintFosCaseFileReview | null): any {
    return value as any;
}

