import { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, Link } from 'react-router-dom';
import { routes } from '@/config/routes';
import { AppContext } from './context/AppContext';
import { Toast } from 'primereact/toast';
import { apiRequest } from '@/helpers/general';
import { clearStorage, createStorageObject, getStorageObject } from '@/helpers/storage';
import { Button } from 'primereact/button';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import PrivateRoute from './auth0/ProtectedRoute';
import ContentLoadingTemplate from './templates/ContentLoadingTemplate';
import NotFoundPage from '../pages/NotFoundPage';
import { useAuthStore } from '@/Stores/AuthStore';

/**
 * Site's main routing page
 */
const App = () => {
    const toastBCRef = useRef(null);
    const authUser = useAuthStore((state) => state.authUser);

    const [userPreferences, setUserPreferences] = useState(getStorageObject('preferences'));
    const [viewComplaint, setViewComplaint] = useState();
    const [unassignedInboxCases, setUnassignedInboxCases] = useState(0);
    const [unassignedFosInboxCases, setUnassignedFosInboxCases] = useState(0);
    const [unassignedFosOutboxCases, setUnassignedFosOutboxCases] = useState(0);
    const [apiNotification, setApiNotification] = useState(0);

    const [refreshComplaints, setRefreshComplaints] = useState(0);
    const { user, isAuthenticated, logout, isLoading, getAccessTokenSilently } = useAuth0();

    /**
     *
     *
     */
    const refreshUnassignedCases = async () => {
        const accessToken = await getAccessTokenSilently();
        apiRequest('get', 'get-info', {
            data: { query: ['unassigned_cases_inbox', 'unassigned_cases_fos_inbox', 'unassigned_cases_fos_outbox'].join(',') },
            onSuccess: (response) => {
                if (response?.result) {
                    setUnassignedInboxCases(response?.result?.unassigned_cases_inbox);
                    setUnassignedFosInboxCases(response?.result?.unassigned_cases_fos_inbox);
                    setUnassignedFosOutboxCases(response?.result?.unassigned_cases_fos_outbox);
                }
            },
            onFailure: () => {
            }
        }, accessToken);
    };

    /**
     * Logout authenticated user and clear local storage
     */
    const logoutUser = async () => {
        await apiRequest('get', 'cache-clear', {
            data: {
                auth0_user_id: authenticatedUserData?.auth0_id || user?.sub
            },
            onSuccess: () => {
                clearStorage();
                logout({ logoutParams: { returnTo: window.location.origin } });
            },
            onFailure: (error) => {
                console.log(error);
            }
        });

    };

    useEffect(() => {
        createStorageObject('preferences', userPreferences);
    }, [userPreferences]);

    useEffect(() => {
        // grab real-time notifications comming from the api
        const channel = new BroadcastChannel('sw-messages');

        channel.addEventListener('message', (event) => {
            if (event?.data?.action) {
                setApiNotification({
                    type: event?.data?.action,
                    data: event?.data?.data,
                    date: moment().unix()
                });
            }
        });

    }, [navigator.serviceWorker]);

    useEffect(() => {
        if (!localStorage.getItem('accepted_cookies') && toastBCRef.current) {
            toastBCRef.current.show({
                severity: 'info',
                sticky: true,
                closable: false,
                content: (<>
                    <div>
                        This website uses cookies so that we can improve your experience. By continuing to use the site you are agreeing to our use of cookies. For more details
                        please view our <Link to={'/cookies'} className="p-link">Cookies Policy</Link>.
                    </div>
                    <Button className="p-button-outlined p-button-secondary p-button-sm block mt-3" onClick={() => {
                        localStorage.setItem('accepted_cookies', true);
                        toastBCRef.current.clear();
                    }}>Accept and Close</Button>
                </>)
            });
        }
    }, []);

    return (
        <BrowserRouter>
            <AppContext.Provider value={{
                logoutUser,
                authenticatedUserData: authUser,
                userPreferences,
                setUserPreferences,
                unassignedInboxCases,
                unassignedFosInboxCases,
                unassignedFosOutboxCases,
                refreshUnassignedCases,
                viewComplaint,
                setViewComplaint,
                apiNotification,
                setApiNotification,
                refreshComplaints,
                setRefreshComplaints
            }}>
                {isLoading
                    ? <ContentLoadingTemplate/>
                    : <>
                        <Routes>
                            {isAuthenticated ?
                                <Route element={<PrivateRoute/>}>
                                    {routes.private.map((route, key) => {
                                        return <Route key={key} path={route.path} element={route.component}/>;
                                    })}
                                </Route>
                                : routes.public.map((route, key) => {
                                    return <Route key={key} path={route.path} element={route.component}/>;
                                })
                            }
                            <Route path="/404" element={<NotFoundPage/>}/>;
                            <Route path="*" element={<Navigate replace to="/404"/>}/>;
                        </Routes>

                        <Toast ref={toastBCRef} className="cookies-notification" position="bottom-left"/>
                    </>}
            </AppContext.Provider>
        </BrowserRouter>
    );
};

export default App;
