import { type DefaultError, useQuery, type UseQueryResult } from '@tanstack/react-query';
import { vulnerableCustomerCategoryApiClient } from '@/Service/Api/ApiClients/VulnerableCustomerCategoryApiClient';
import { type VulnerableCustomerCategory } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetVulnerableCustomerCategoriesParams = {
    enabled?: boolean
    select?: (vulnerableCustomerCategories: VulnerableCustomerCategory[]) => VulnerableCustomerCategory[];
};
export const useGetVulnerableCustomerCategories = ({
    enabled = true,
    select
}: useGetVulnerableCustomerCategoriesParams): UseQueryResult<VulnerableCustomerCategory[], DefaultError> => useQuery({
    ...QueryKeys.vulnerableCustomerCategories.list,
    queryFn: async () => await vulnerableCustomerCategoryApiClient.getVulnerableCustomerCategoryInTreeStructure(),
    enabled,
    select
});
