import React, { useMemo } from 'react';
import BaseDropdown, { BaseDropdownProps } from "@/components/Core/Form/BaseDropdown";
import useLastOptionElementRef from "@/Hooks/useLastOptionElementRef";
import { Product } from "@/stub";
import { useGetProductsInfinite } from "@/Service/Api/ApiHooks/Product/useGetProductsInfinite";

export type BaseProductSelectProps = BaseDropdownProps & {
    needCategory?: boolean
    categoryId?: number,
    selectedProduct?: Product
};

const BaseProductSelect: React.FC<BaseProductSelectProps> = ({ needCategory, categoryId,selectedProduct, ...props }) => {
    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
    } = useGetProductsInfinite({
        requestParams: {
            category_id: categoryId
        },
        enabled: !needCategory || (needCategory && !!categoryId)
    });

    const items = useMemo(() => {
        let productList = data?.pages?.flatMap((page) => page.data) ?? [];
        if (selectedProduct && !productList.some(item => item.id === selectedProduct.id)) {
            productList = [selectedProduct, ...productList];
        }
        return productList;
    }, [data, selectedProduct]);

    const { lastOptionElementRef } = useLastOptionElementRef({ fetchNextPage: fetchNextPage, hasNextPage: hasNextPage });

    const optionTemplate = (option: Product) => {
        return (
            <div ref={items[items.length - 1].id === option.id ? lastOptionElementRef : null} className="flex align-items-center">
                <p>
                    {option.code}
                </p>
            </div>
        );
    };


    return (
        <BaseDropdown
            options={items}
            optionValue="id"
            optionLabel="description"
            showClear
            onClick={(e) => {
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
            itemTemplate={optionTemplate}
            loading={isLoading}
            {...props}
        />
    );
};

export default BaseProductSelect;
