import { User as Auth0User } from "@auth0/auth0-react";
import { create } from "zustand";
import { AuthUser } from "@/stub";
import { storageRemove, storageSavePrimitive } from "@/Util/localStorageHelpers";

type AuthStoreState = {
    auth0User?: Auth0User
    accessToken?: string
    authUser?: AuthUser
    impersonatedOrgId?: number
};

type AuthStoreActions = {
    setAuth0User: (auth0User?: Auth0User) => void
    setAccessToken: (accessToken?: string) => void
    setAuthUser: (authUser?: AuthUser) => void
    setImpersonatedOrgId: (organisationId?: number) => void
};

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()((set) => ({
    auth0User: undefined,
    accessToken: undefined,
    authUser: undefined,
    impersonatedOrgId: undefined,
    setAuth0User: (auth0User) => set(
        () => ({ auth0User: auth0User })
    ),
    setAccessToken: (accessToken) => set(
        () => ({ accessToken: accessToken })
    ),
    setAuthUser: (authUser) => set(
        () => ({ authUser: authUser })
    ),
    setImpersonatedOrgId: (organisationId) => set(
        () => {
            if (organisationId) {
                storageSavePrimitive('impersonatedOrgId', organisationId);
            } else {
                storageRemove('impersonatedOrgId');
            }
            return { impersonatedOrgId: organisationId };
        }
    )
}));
