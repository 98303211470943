import React, { useEffect, useMemo, useState } from "react";
import { setDocumentTitle } from "@/helpers/general";
import CustomersDatatable from "@/components/Datatables/CustomersDatatable";
import ContentHeader from "@/components/Blocks/ContentHeader";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import styled from "styled-components";
import { useDebounce } from "react-use";
import { GetCustomersRequest } from "@/stub";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }
`;

/**
 * Customers page
 */
const CustomersPage = () => {
    const [searchParams,setSearchParams] = useSearchParams();
    const [searchInput, setSearchInput] = useState<string>(searchParams.get('search') ?? '');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>(searchInput);

    useDebounce(
        () => {
            setDebouncedSearchInput(searchInput);
        },
        600,
        [searchInput]
    );
    const customerIdsParam = useMemo(() => {
        return searchParams.getAll('customer_ids');
    }, [searchParams]);

    const requestParams = useMemo<GetCustomersRequest>(() => {

        return {
            customer_ids: customerIdsParam.length ? customerIdsParam : undefined,
            search: debouncedSearchInput
        };
    }, [customerIdsParam, debouncedSearchInput]);

    useEffect(() => {
        setDocumentTitle('Customers');
    }, []);

    const clearFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSearchInput('');
        searchParams.delete('customer_ids');
        setSearchParams(searchParams);

    };
    return (
        <div>
            <StyledWrap>
                <ContentHeader
                    title="Organisation Customers"
                    description="Forseti Organisation Manager"
                    rightSectionTemplate={
                        <BaseTextInput
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                        />
                    }
                />
                <div className="content-container">
                    {customerIdsParam.length > 0 && <div className="flex align-items-center gap-2">
                        <Message className="rounded-md" severity="info" text="This list is filtered by selected customers" />
                        <Button
                            className='w-1rem h-2rem p-button-secondary p-button-sm'
                            icon="pi pi-times"
                            onClick={clearFilters}
                        />
                    </div>}
                    <CustomersDatatable requestParams={requestParams}/>
                </div>
            </StyledWrap>
        </div>
    );
};

export default CustomersPage;
