import React from 'react';

/**
 * Show a loader over an html parent element
 */
const ContentLoadingTemplate = () => {
    return (
        <div className="content-loading-wrap">
            <div className="content-loading">
                <i className="pi pi-sun pi-spin" />
            </div>
        </div>
    );
};

export default ContentLoadingTemplate;