import { useMutation } from '@tanstack/react-query';
import type { UseFormSetError } from 'react-hook-form';
import { UpdateRootCauseOperationRequest } from "@/stub";
import { rootCauseApiClient } from "@/Service/Api/ApiClients/RootCauseApiClient";

type useUpdateRootCauseParams = {
    setError?: UseFormSetError<never>
};

export const useUpdateRootCause = ({ setError }: useUpdateRootCauseParams = {}) => {
    return useMutation({
        mutationFn: async (data: UpdateRootCauseOperationRequest) => await rootCauseApiClient.updateRootCause(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    Object.keys(errorBody.errors).map(key => {
                        if (setError) {
                            setError(key, { message: errorBody.errors[key][0] });
                        }
                    });
                    throw new Error(errorBody.message);
                }
                )
            )
    });
};
