import React, {useEffect} from "react";
import {Card} from 'primereact/card';
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {setDocumentTitle} from "../helpers/general";
import FullscreenLayout from "../themes/default/layouts/FullscreenLayout";

/**
 * Not found page
 */
const NotFoundPage = () => {
    useEffect(() => {
        setDocumentTitle('Not found');
    }, []);
    return (
        <FullscreenLayout>
            <Card>
                <div className="text-center">
                    <h3>Page not found</h3>
                    <p>We are sorry but the page you are looking for does not exist.</p>
                    <Link to={"/"}>
                        <Button className="mt-5" label="Go to home page"/>
                    </Link>
                </div>
            </Card>
        </FullscreenLayout>
    );
};
export default NotFoundPage;
