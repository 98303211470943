/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Customer,
  GetCustomers200Response,
  GetCustomersPrimary200Response,
  StoreCustomerRequest,
  UpdateCustomerRequest,
} from '../models/index';
import {
    CustomerFromJSON,
    CustomerToJSON,
    GetCustomers200ResponseFromJSON,
    GetCustomers200ResponseToJSON,
    GetCustomersPrimary200ResponseFromJSON,
    GetCustomersPrimary200ResponseToJSON,
    StoreCustomerRequestFromJSON,
    StoreCustomerRequestToJSON,
    UpdateCustomerRequestFromJSON,
    UpdateCustomerRequestToJSON,
} from '../models/index';

export interface CreateCustomerRequest {
    StoreCustomerRequest?: StoreCustomerRequest;
}

export interface GetCustomerRequest {
    customer_id: number;
}

export interface GetCustomersRequest {
    customer_ids?: Array<number>;
    search?: string;
    sort_by?: GetCustomersSortByEnum;
    sort_order?: GetCustomersSortOrderEnum;
    type?: Array<GetCustomersTypeEnum>;
    page?: number;
    limit?: number;
    organisation_id?: number;
}

export interface GetCustomersDsarRequest {
    customer_ids?: Array<number>;
    search?: string;
    sort_by?: GetCustomersDsarSortByEnum;
    sort_order?: GetCustomersDsarSortOrderEnum;
    type?: Array<GetCustomersDsarTypeEnum>;
    page?: number;
    limit?: number;
    organisation_id?: number;
}

export interface GetCustomersPrimaryRequest {
    customer_ids?: Array<number>;
    search?: string;
    sort_by?: GetCustomersPrimarySortByEnum;
    sort_order?: GetCustomersPrimarySortOrderEnum;
    type?: Array<GetCustomersPrimaryTypeEnum>;
    page?: number;
    limit?: number;
    organisation_id?: number;
}

export interface UpdateCustomerOperationRequest {
    customer_id: number;
    UpdateCustomerRequest?: UpdateCustomerRequest;
}

/**
 * 
 */
export class CustomersApi extends runtime.BaseAPI {

    /**
     * Create Customer
     */
    async createCustomerRaw(requestParameters: CreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreCustomerRequestToJSON(requestParameters.StoreCustomerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Create Customer
     */
    async createCustomer(requestParameters: CreateCustomerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
        const response = await this.createCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Customer
     */
    async getCustomerRaw(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customer_id === null || requestParameters.customer_id === undefined) {
            throw new runtime.RequiredError('customer_id','Required parameter requestParameters.customer_id was null or undefined when calling getCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customers/{customer_id}`.replace(`{${"customer_id"}}`, encodeURIComponent(String(requestParameters.customer_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Get Customer
     */
    async getCustomer(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
        const response = await this.getCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of customers
     */
    async getCustomersRaw(requestParameters: GetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCustomers200Response>> {
        const queryParameters: any = {};

        if (requestParameters.customer_ids) {
            queryParameters['customer_ids[]'] = requestParameters.customer_ids;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.type) {
            queryParameters['type[]'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCustomers200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of customers
     */
    async getCustomers(requestParameters: GetCustomersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCustomers200Response> {
        const response = await this.getCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list customers with complaints for dsar report
     */
    async getCustomersDsarRaw(requestParameters: GetCustomersDsarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCustomersPrimary200Response>> {
        const queryParameters: any = {};

        if (requestParameters.customer_ids) {
            queryParameters['customer_ids[]'] = requestParameters.customer_ids;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.type) {
            queryParameters['type[]'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customers/dsar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCustomersPrimary200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list customers with complaints for dsar report
     */
    async getCustomersDsar(requestParameters: GetCustomersDsarRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCustomersPrimary200Response> {
        const response = await this.getCustomersDsarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of primary contact complaint customers
     */
    async getCustomersPrimaryRaw(requestParameters: GetCustomersPrimaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCustomersPrimary200Response>> {
        const queryParameters: any = {};

        if (requestParameters.customer_ids) {
            queryParameters['customer_ids[]'] = requestParameters.customer_ids;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.type) {
            queryParameters['type[]'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customers/primary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCustomersPrimary200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of primary contact complaint customers
     */
    async getCustomersPrimary(requestParameters: GetCustomersPrimaryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCustomersPrimary200Response> {
        const response = await this.getCustomersPrimaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Customer
     */
    async updateCustomerRaw(requestParameters: UpdateCustomerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customer_id === null || requestParameters.customer_id === undefined) {
            throw new runtime.RequiredError('customer_id','Required parameter requestParameters.customer_id was null or undefined when calling updateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customers/{customer_id}`.replace(`{${"customer_id"}}`, encodeURIComponent(String(requestParameters.customer_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCustomerRequestToJSON(requestParameters.UpdateCustomerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Update Customer
     */
    async updateCustomer(requestParameters: UpdateCustomerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
        const response = await this.updateCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetCustomersSortByEnum = {
    Id: 'id',
    Title: 'title',
    Name: 'name',
    Type: 'type',
    IdNumber: 'id_number',
    Email: 'email',
    CreatedAt: 'created_at'
} as const;
export type GetCustomersSortByEnum = typeof GetCustomersSortByEnum[keyof typeof GetCustomersSortByEnum];
/**
 * @export
 */
export const GetCustomersSortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetCustomersSortOrderEnum = typeof GetCustomersSortOrderEnum[keyof typeof GetCustomersSortOrderEnum];
/**
 * @export
 */
export const GetCustomersTypeEnum = {
    Person: 'person',
    Organisation: 'organisation'
} as const;
export type GetCustomersTypeEnum = typeof GetCustomersTypeEnum[keyof typeof GetCustomersTypeEnum];
/**
 * @export
 */
export const GetCustomersDsarSortByEnum = {
    Id: 'id',
    Title: 'title',
    Name: 'name',
    Type: 'type',
    IdNumber: 'id_number',
    Email: 'email',
    CreatedAt: 'created_at'
} as const;
export type GetCustomersDsarSortByEnum = typeof GetCustomersDsarSortByEnum[keyof typeof GetCustomersDsarSortByEnum];
/**
 * @export
 */
export const GetCustomersDsarSortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetCustomersDsarSortOrderEnum = typeof GetCustomersDsarSortOrderEnum[keyof typeof GetCustomersDsarSortOrderEnum];
/**
 * @export
 */
export const GetCustomersDsarTypeEnum = {
    Person: 'person',
    Organisation: 'organisation'
} as const;
export type GetCustomersDsarTypeEnum = typeof GetCustomersDsarTypeEnum[keyof typeof GetCustomersDsarTypeEnum];
/**
 * @export
 */
export const GetCustomersPrimarySortByEnum = {
    Id: 'id',
    Title: 'title',
    Name: 'name',
    Type: 'type',
    IdNumber: 'id_number',
    Email: 'email',
    CreatedAt: 'created_at'
} as const;
export type GetCustomersPrimarySortByEnum = typeof GetCustomersPrimarySortByEnum[keyof typeof GetCustomersPrimarySortByEnum];
/**
 * @export
 */
export const GetCustomersPrimarySortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetCustomersPrimarySortOrderEnum = typeof GetCustomersPrimarySortOrderEnum[keyof typeof GetCustomersPrimarySortOrderEnum];
/**
 * @export
 */
export const GetCustomersPrimaryTypeEnum = {
    Person: 'person',
    Organisation: 'organisation'
} as const;
export type GetCustomersPrimaryTypeEnum = typeof GetCustomersPrimaryTypeEnum[keyof typeof GetCustomersPrimaryTypeEnum];
