/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComplaintCustomerContactType } from './ComplaintCustomerContactType';
import {
    ComplaintCustomerContactTypeFromJSON,
    ComplaintCustomerContactTypeFromJSONTyped,
    ComplaintCustomerContactTypeToJSON,
} from './ComplaintCustomerContactType';

/**
 * 
 * @export
 * @interface UpdateComplaintCustomerPrimaryRequest
 */
export interface UpdateComplaintCustomerPrimaryRequest {
    /**
     * 
     * @type {ComplaintCustomerContactType}
     * @memberof UpdateComplaintCustomerPrimaryRequest
     */
    correspondence: ComplaintCustomerContactType;
    /**
     * 
     * @type {number}
     * @memberof UpdateComplaintCustomerPrimaryRequest
     */
    organisation_id?: number | null;
}

/**
 * Check if a given object implements the UpdateComplaintCustomerPrimaryRequest interface.
 */
export function instanceOfUpdateComplaintCustomerPrimaryRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "correspondence" in value;

    return isInstance;
}

export function UpdateComplaintCustomerPrimaryRequestFromJSON(json: any): UpdateComplaintCustomerPrimaryRequest {
    return UpdateComplaintCustomerPrimaryRequestFromJSONTyped(json, false);
}

export function UpdateComplaintCustomerPrimaryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateComplaintCustomerPrimaryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'correspondence': ComplaintCustomerContactTypeFromJSON(json['correspondence']),
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
    };
}

export function UpdateComplaintCustomerPrimaryRequestToJSON(value?: UpdateComplaintCustomerPrimaryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'correspondence': ComplaintCustomerContactTypeToJSON(value.correspondence),
        'organisation_id': value.organisation_id,
    };
}

