import React from 'react';
import { type FileUploadProps } from 'primereact/fileupload';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import { CloudDownloadIcon } from "@/helpers/svg-icons";
import { FILE_UPLOAD_MAX_SIZE } from "@/config/constants";
import styled from "styled-components";
import { formatFileSize } from "@/helpers/general";
import { ChannelDocument } from "@/stub";
import DownloadButton from "@/components/partials/DownloadButton";
import BaseFileUpload from "@/components/Core/Form/BaseFileUpload";

export type AdvancedFileUploadProps = FileUploadProps & BaseFormInputProps & {
    required?: boolean
    downloadExistingFiles?: boolean
    existingFiles?: ChannelDocument[]
    complaintId?: number
};

const StyledWrap = styled.div`
    .p-fileupload-buttonbar .p-fileupload-cancel {
        display: none !important;
    }

    .p-fileupload-buttonbar .p-fileupload-upload {
        display: none !important;
    }
`;

const AdvancedFileUpload: React.FC<AdvancedFileUploadProps> = ({
    control,
    label,
    errorMessages,
    className,
    required,
    downloadExistingFiles = false,
    existingFiles = [],
    complaintId,
    ...props
}: AdvancedFileUploadProps) => {
    const ptOptions = {
        cancelButton: {
            root: {
                className: 'p-fileupload-cancel'
            }
        },
        uploadButton: {
            root: {
                className: 'p-fileupload-upload'
            }
        },
        ...props?.ptOptions,
    };

    const emptyTemplate = (): React.ReactNode => {
        return (
            <div>
                <div className="empty-upload">
                    <CloudDownloadIcon/>
                    <p>
                        Click <b>Upload</b> or drag and drop
                    </p>
                    <p>Max. File Size: {FILE_UPLOAD_MAX_SIZE / 1048576} MB</p>
                </div>
                {!!existingFiles?.length && existingFiles?.map((file, index) =>
                    <div key={index} className='flex justify-content-between align-items-center mt-2'>
                        <div className="flex align-items-center gap-2">
                            {downloadExistingFiles &&
                                <DownloadButton complaintId={complaintId} file={file} className=''/>}
                            <span>{file.name}</span>
                        </div>

                        <span>{formatFileSize(file.size)}</span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <StyledWrap>
            <BaseFileUpload
                control={control}
                errorMessages={errorMessages}
                label={label}
                required={required}
                className={className}
                mode='advanced'
                customUpload
                emptyTemplate={props.emptyTemplate ?? emptyTemplate}
                pt={ptOptions}
                {...props}
            />
        </StyledWrap>
    );
};

export default AdvancedFileUpload;
