import { ResponseError } from "@/stub";
import { RateLimiterError } from "@/Errors/RateLimiterError";

export const processRateLimiterErrors = async (error: unknown) => {
    if (error instanceof ResponseError) {
        if (error.response.status === 429) {
            const validationResponse: string = await error.response.text();
            throw new RateLimiterError(validationResponse);
        }
    }
};
