import {ToastMessage} from "primereact/toast";
import {create} from "zustand";
import {produce} from "immer";

type ToastMessagesStoreState = {
    toastMessages: ToastMessage[];
}

type ToastMessagesStoreActions = {
    addToastMessage: (toastMessage: ToastMessage) => void;
    getToastMessages: () => ToastMessage[];
}

export const useToastMessagesStore = create<ToastMessagesStoreState & ToastMessagesStoreActions>()((set, get) => ({
    toastMessages: [],
    addToastMessage: (toastMessage) => set(
        produce((state: ToastMessagesStoreState) => {
            state.toastMessages.push(toastMessage);
        })
    ),
    getToastMessages: () => {
        const toastMessages = get().toastMessages;
        set(() => ({toastMessages: []}))
        return toastMessages;
    }
}))