import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { complaintsApiClient } from '@/Service/Api/ApiClients/ComplaintsApiClient';
import { type GetComplaintsRequest } from '@/stub';
import { QueryKeys } from '@/Service/Api/QueryKeys/QueryKeys';

export type useGetComplaintsParams = {
    requestParameters?: GetComplaintsRequest
    enabled?: boolean
    savePreviousData?: boolean
};
export const useGetComplaints = ({ requestParameters, enabled, savePreviousData }: useGetComplaintsParams = {}) => useQuery({
    ...QueryKeys.complaints.list(requestParameters),
    queryFn: async () => await complaintsApiClient.getComplaints(requestParameters),
    enabled,
    placeholderData: savePreviousData ? keepPreviousData : undefined
});
