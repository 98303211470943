/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Action } from './Action';
import {
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';
import type { ChannelCustomer } from './ChannelCustomer';
import {
    ChannelCustomerFromJSON,
    ChannelCustomerFromJSONTyped,
    ChannelCustomerToJSON,
} from './ChannelCustomer';
import type { ComplaintChannel } from './ComplaintChannel';
import {
    ComplaintChannelFromJSON,
    ComplaintChannelFromJSONTyped,
    ComplaintChannelToJSON,
} from './ComplaintChannel';
import type { ComplaintCustomer } from './ComplaintCustomer';
import {
    ComplaintCustomerFromJSON,
    ComplaintCustomerFromJSONTyped,
    ComplaintCustomerToJSON,
} from './ComplaintCustomer';
import type { Decision } from './Decision';
import {
    DecisionFromJSON,
    DecisionFromJSONTyped,
    DecisionToJSON,
} from './Decision';
import type { Letter } from './Letter';
import {
    LetterFromJSON,
    LetterFromJSONTyped,
    LetterToJSON,
} from './Letter';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { RootCause } from './RootCause';
import {
    RootCauseFromJSON,
    RootCauseFromJSONTyped,
    RootCauseToJSON,
} from './RootCause';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Complaint
 */
export interface Complaint {
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    ref_number: string;
    /**
     * 
     * @type {User}
     * @memberof Complaint
     */
    owner: User;
    /**
     * 
     * @type {ComplaintChannel}
     * @memberof Complaint
     */
    channel?: ComplaintChannel | null;
    /**
     * 
     * @type {ChannelCustomer}
     * @memberof Complaint
     */
    main_customer?: ChannelCustomer | null;
    /**
     * 
     * @type {Array<ComplaintCustomer>}
     * @memberof Complaint
     */
    complaint_customers?: Array<ComplaintCustomer> | null;
    /**
     * 
     * @type {Date}
     * @memberof Complaint
     */
    raised_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof Complaint
     */
    created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof Complaint
     */
    closed_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    summary?: string;
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    days_open: number;
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    work_days_open?: number;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    state: ComplaintStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    status?: string;
    /**
     * 
     * @type {Product}
     * @memberof Complaint
     */
    product: Product;
    /**
     * 
     * @type {RootCause}
     * @memberof Complaint
     */
    root_cause: RootCause;
    /**
     * 
     * @type {Array<Letter>}
     * @memberof Complaint
     */
    letters: Array<Letter>;
    /**
     * 
     * @type {Array<Action>}
     * @memberof Complaint
     */
    actions: Array<Action>;
    /**
     * 
     * @type {Decision}
     * @memberof Complaint
     */
    decision?: Decision;
    /**
     * 
     * @type {boolean}
     * @memberof Complaint
     */
    is_fos?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Complaint
     */
    is_escalated?: boolean;
}


/**
 * @export
 */
export const ComplaintStateEnum = {
    Created: 'App\\Models\\ComplaintState\\Created',
    Investigated: 'App\\Models\\ComplaintState\\Investigated',
    Resolved: 'App\\Models\\ComplaintState\\Resolved',
    Closed: 'App\\Models\\ComplaintState\\Closed'
} as const;
export type ComplaintStateEnum = typeof ComplaintStateEnum[keyof typeof ComplaintStateEnum];


/**
 * Check if a given object implements the Complaint interface.
 */
export function instanceOfComplaint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "ref_number" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "raised_date" in value;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "days_open" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "root_cause" in value;
    isInstance = isInstance && "letters" in value;
    isInstance = isInstance && "actions" in value;

    return isInstance;
}

export function ComplaintFromJSON(json: any): Complaint {
    return ComplaintFromJSONTyped(json, false);
}

export function ComplaintFromJSONTyped(json: any, ignoreDiscriminator: boolean): Complaint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ref_number': json['ref_number'],
        'owner': UserFromJSON(json['owner']),
        'channel': !exists(json, 'channel') ? undefined : ComplaintChannelFromJSON(json['channel']),
        'main_customer': !exists(json, 'main_customer') ? undefined : ChannelCustomerFromJSON(json['main_customer']),
        'complaint_customers': !exists(json, 'complaint_customers') ? undefined : (json['complaint_customers'] === null ? null : (json['complaint_customers'] as Array<any>).map(ComplaintCustomerFromJSON)),
        'raised_date': (new Date(json['raised_date'])),
        'created_at': (new Date(json['created_at'])),
        'closed_at': !exists(json, 'closed_at') ? undefined : (new Date(json['closed_at'])),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'days_open': json['days_open'],
        'work_days_open': !exists(json, 'work_days_open') ? undefined : json['work_days_open'],
        'state': json['state'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'product': ProductFromJSON(json['product']),
        'root_cause': RootCauseFromJSON(json['root_cause']),
        'letters': ((json['letters'] as Array<any>).map(LetterFromJSON)),
        'actions': ((json['actions'] as Array<any>).map(ActionFromJSON)),
        'decision': !exists(json, 'decision') ? undefined : DecisionFromJSON(json['decision']),
        'is_fos': !exists(json, 'is_fos') ? undefined : json['is_fos'],
        'is_escalated': !exists(json, 'is_escalated') ? undefined : json['is_escalated'],
    };
}

export function ComplaintToJSON(value?: Complaint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ref_number': value.ref_number,
        'owner': UserToJSON(value.owner),
        'channel': ComplaintChannelToJSON(value.channel),
        'main_customer': ChannelCustomerToJSON(value.main_customer),
        'complaint_customers': value.complaint_customers === undefined ? undefined : (value.complaint_customers === null ? null : (value.complaint_customers as Array<any>).map(ComplaintCustomerToJSON)),
        'raised_date': (value.raised_date.toISOString().substring(0,10)),
        'created_at': (value.created_at.toISOString().substring(0,10)),
        'closed_at': value.closed_at === undefined ? undefined : (value.closed_at.toISOString().substring(0,10)),
        'summary': value.summary,
        'days_open': value.days_open,
        'work_days_open': value.work_days_open,
        'state': value.state,
        'status': value.status,
        'product': ProductToJSON(value.product),
        'root_cause': RootCauseToJSON(value.root_cause),
        'letters': ((value.letters as Array<any>).map(LetterToJSON)),
        'actions': ((value.actions as Array<any>).map(ActionToJSON)),
        'decision': DecisionToJSON(value.decision),
        'is_fos': value.is_fos,
        'is_escalated': value.is_escalated,
    };
}

