import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { customerApiClient } from '@/Service/Api/ApiClients/CustomerApiClient';
import { type GetCustomersRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export interface useGetCustomersParams {
    enabled?: boolean
    requestParams?: GetCustomersRequest
    savePreviousData?: boolean
}

export const useGetCustomers = ({
    enabled = true,
    requestParams,
    savePreviousData
}: useGetCustomersParams = {}) => useQuery({
    ...QueryKeys.customers.list(requestParams),
    queryFn: async () => await customerApiClient.getCustomers(requestParams),
    enabled,
    gcTime: 0,
    placeholderData: savePreviousData ? keepPreviousData : undefined
});
