/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLetterSentBatchRequest
 */
export interface UpdateLetterSentBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLetterSentBatchRequest
     */
    sent_date?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLetterSentBatchRequest
     */
    organisation_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateLetterSentBatchRequest
     */
    ids?: Array<number>;
}

/**
 * Check if a given object implements the UpdateLetterSentBatchRequest interface.
 */
export function instanceOfUpdateLetterSentBatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateLetterSentBatchRequestFromJSON(json: any): UpdateLetterSentBatchRequest {
    return UpdateLetterSentBatchRequestFromJSONTyped(json, false);
}

export function UpdateLetterSentBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLetterSentBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sent_date': !exists(json, 'sent_date') ? undefined : json['sent_date'],
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
    };
}

export function UpdateLetterSentBatchRequestToJSON(value?: UpdateLetterSentBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sent_date': value.sent_date,
        'organisation_id': value.organisation_id,
        'ids': value.ids,
    };
}

