import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import React, { useEffect, useRef, useState } from 'react';
import { DocumentResource } from '@/stub';
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { Document, Page } from "react-pdf";
import { useDownloadComplaintDocument } from "@/Service/Api/ApiHooks/ComplaintDocument/useDownloadComplaintDocument";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { produce } from "immer";

export type PreviewFileModalProps = {
    complaint_id: number
    visible: boolean
    closePreview: () => void
    file: DocumentResource
};

type PaginationOptions = {
    currentPage: number
    numPages: number
};

const PreviewFileModal: React.FC<PreviewFileModalProps> = ({
    visible,
    closePreview,
    file,
    complaint_id
}: PreviewFileModalProps) => {
    const ref = useRef(null);
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [pdf, setPdf] = useState<string | null>(null);
    const [pagination, setPagination] = useState<PaginationOptions>({
        currentPage: 1,
        numPages: 1
    });

    const downloadFileMutation = useDownloadComplaintDocument();

    const firstPage = () => {
        setPagination(produce(draft => {
            draft.currentPage = 1;
        }));
    };

    const nextPage = () => {
        setPagination(produce(draft => {
            draft.currentPage = draft.currentPage + 1;
        }));
    };
    const prevPage = () => {
        setPagination(produce(draft => {
            draft.currentPage = draft.currentPage - 1;
        }));
    };

    const lastPage = () => {
        setPagination(produce(draft => {
            draft.currentPage = draft.numPages;
        }));
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPagination(produce(draft => {
            draft.numPages = numPages;
        }));
    };

    useEffect(() => {
        downloadFileMutation.mutate({
            complaint_id: complaint_id,
            document_id: file.id
        }, {
            onSuccess: (blob) => {
                setPdf(URL.createObjectURL(blob));
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    }, []);

    const modalContent = (
        <div ref={ref}>
            <div className="flex flex-row justify-content-center align-items-center">
                <Button
                    className="mx-2 grey-nav-btn grey-nav-btn-hover"
                    icon="pi pi-fast-backward"
                    onClick={firstPage}
                    disabled={pagination.currentPage === 1}
                />
                <Button
                    className="grey-nav-btn grey-nav-btn-hover mx-2"
                    icon="pi pi-step-backward"
                    onClick={prevPage}
                    disabled={pagination.currentPage === 1}
                />
                <div className="m-2 flex justify-content-center ">
                        Page {pagination.currentPage} of {pagination.numPages}
                </div>
                <Button
                    className="grey-nav-btn grey-nav-btn-hover mx-2"
                    icon="pi pi-step-forward"
                    onClick={nextPage}
                    disabled={pagination.currentPage === pagination.numPages}
                />
                <Button
                    className="grey-nav-btn grey-nav-btn-hover mx-2"
                    icon="pi pi-fast-forward"
                    onClick={lastPage}
                    disabled={pagination.currentPage === pagination.numPages}
                />
            </div>
            <div className="flex flex-column justify-content-center align-items-center">
                {downloadFileMutation.isPending ? <div>Loading PDF...</div> :
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} imageResourcesPath="/img/">
                        <Page pageNumber={pagination.currentPage}
                            height={ref?.current?.offsetWidth * 1.5}/>
                    </Document>
                }
            </div>
        </div>
    )
    ;

    return (
        <Dialog
            id='preview-file-dialog'
            header={`Preview ${file?.name}`}
            className='preview-file-modal'
            visible={visible}
            onHide={closePreview}
            blockScroll
            draggable={false}
        >
            <div className='w-full'>
                {modalContent}
            </div>
        </Dialog>
    );
};

export default PreviewFileModal;
