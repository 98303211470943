import React from "react";
import styled from "styled-components";

const AvatarWrap = styled.div`
    width: 100%;
    margin-bottom: 2rem;

    .avatar-wrap {
        padding-top: 100%;
        border: 0.35rem solid #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px 4px;
        border-radius: 0.7rem;
        position: relative;

        .avatar-placeholder {
            position: absolute;
            width: 100%;
            text-align: center;
            height: 100%;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--gray-500);
            border-radius: 0.5rem;

            i.pi {
                font-size: 6vw;
                color: #fff;
            }

            @media screen and (max-width: 770px) {
                i.pi {
                    font-size: 15vw;
                }
            }
        }

        .edit-avatar, .delete-avatar {
            background: #fff;
            position: absolute;
            right: -0.9rem;
            border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 2px;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .edit-avatar {
            bottom: -0.9rem;
        }

        .delete-avatar {
            top: -0.9rem;
        }

        input[type="file"] {
            display: none;
        }

        .avatar-preview {
            position: absolute;
            top: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
            border-radius: 0.5rem;
        }

        .loading-wrap {
            position: absolute;
            background: rgba(255, 255, 255, 0.8);
            width: 100%;
            height: 100%;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            i.pi {
                color: var(--primary-500);
                font-size: 1.5vw;
            }
        }
    }

    .info-message {
        i.pi {
            color: var(--primary-500);
        }

        p {
            font-size: 0.8rem;
        }
    }
`;

/**
 * Edit/delete user avatar
 */
const UserAvatar = ({avatar}) => {
    return (
        <>
            <AvatarWrap>
                <div className="avatar-wrap">
                    <div className="avatar-placeholder" style={{display: avatar ? 'none' : 'flex'}}>
                        <i className="pi pi-user"></i>
                    </div>

                    {avatar && <div className="avatar-preview" style={{backgroundImage: 'url(' + avatar + ')'}}/>}
                </div>
            </AvatarWrap>
        </>
    );
};

export default UserAvatar;
