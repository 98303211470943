import { useMutation } from '@tanstack/react-query';
import type { UseFormSetError } from 'react-hook-form';
import { type CreateFosInComplaintRequest } from '@/stub';
import { ComplaintFosApiClient } from '@/Service/Api/ApiClients/ComplaintFosApiClient';

type useCreateComplaintFosParams = {
    setError?: UseFormSetError<never>
};

export const useCreateComplaintFos = ({ setError }: useCreateComplaintFosParams = {}) => {
    return useMutation({
        mutationFn: async (data: CreateFosInComplaintRequest) => await ComplaintFosApiClient.createFosInComplaint(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    Object.keys(errorBody.errors).map(key => {
                        setError(key, { message: errorBody.errors[key][0] });
                    });
                    throw new Error(errorBody.message);
                }
                )
            )
    });
};
