/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ContactChannelType = {
    OnComplaintCreate: 'on_complaint_create',
    Contact: 'contact',
    Note: 'note',
    Document: 'document'
} as const;
export type ContactChannelType = typeof ContactChannelType[keyof typeof ContactChannelType];


export function ContactChannelTypeFromJSON(json: any): ContactChannelType {
    return ContactChannelTypeFromJSONTyped(json, false);
}

export function ContactChannelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactChannelType {
    return json as ContactChannelType;
}

export function ContactChannelTypeToJSON(value?: ContactChannelType | null): any {
    return value as any;
}

