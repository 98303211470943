import React, { useId } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, type CheckboxProps } from 'primereact/checkbox';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';

export type BaseCheckboxProps = CheckboxProps & BaseFormInputProps;

const BaseCheckbox: React.FC<BaseCheckboxProps> = ({
    control,
    label,
    errorMessages,
    className,
    ...props
}: BaseCheckboxProps) => {
    const localId = useId();

    return (
        <div className={className}>
            <label className="block mb-2" htmlFor={localId}>{label}{props.required ? '*' : ''}</label>
            {
                control && props.name &&
                <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => {
                        return (
                            <Checkbox
                                {...props}
                                id={localId}
                                name={field.name}
                                invalid={fieldState.invalid}
                                checked={field.value ?? props.checked}
                                disabled={field.disabled}
                                onChange={(e) => {
                                    field.onChange(e.checked);
                                    if (props.onChange) {
                                        props.onChange(e);
                                    }
                                }}
                                onBlur={(e) => {
                                    field.onBlur();
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}

                            />
                        );
                    }}
                />
            }
            {
                !control &&
                <Checkbox
                    id={localId}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseCheckbox;
