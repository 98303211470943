import React, { useMemo, useState } from "react";
import { GetComplaintsRequest, GetComplaintsStatesEnum } from "@/stub";
import { authUserCan } from "@/Util/permissionChecks";
import ContentHeader from "@/components/Blocks/ContentHeader";
import ComplaintsDatatable from "@/components/Datatables/ComplaintsDatatable";
import styled from "styled-components";
import { useDebounce } from "react-use";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { useAuthUser } from "@/Hooks/useAuthUser";

const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }
`;

export type FosInboxPageProps = {
    className?: string
};

const FosInboxPage: React.FC<FosInboxPageProps> = ({
    className
}: FosInboxPageProps) => {
    const authUser = useAuthUser();

    const [searchInput, setSearchInput] = useState<string>('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');

    useDebounce(
        () => {
            setDebouncedSearchInput(searchInput);
        },
        600,
        [searchInput]
    );

    const fosInboxRequestParams = useMemo<GetComplaintsRequest>(() => {
        const unclosedStates: GetComplaintsStatesEnum[] = [
            GetComplaintsStatesEnum.Investigated,
            GetComplaintsStatesEnum.Resolved
        ];
        return {
            assigned_to_id: !authUserCan('read:org_complaint_detail') ? authUser?.id : undefined,
            is_fos: true,
            states: unclosedStates,
            search: debouncedSearchInput
        };
    }, [authUser, debouncedSearchInput]);

    return (
        <div>
            <StyledWrap className={className}>
                <ContentHeader
                    title="FOS Inbox"
                    description="Forseti Complaints Manager"
                    rightSectionTemplate={
                        <BaseTextInput
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                        />
                    }
                />
                <div className="content-container">
                    <ComplaintsDatatable
                        requestParams={fosInboxRequestParams}
                    />
                </div>
            </StyledWrap>
        </div>
    );
};

export default FosInboxPage;
