import { useMutation } from '@tanstack/react-query';
import { type DownloadComplaintDocumentRequest } from '@/stub';
import { ComplaintDocumentsApiClient } from '../../ApiClients/ComplaintDocumentsApiClient';

export const useDownloadComplaintDocument = () => useMutation({
    mutationFn: async (requestParams: DownloadComplaintDocumentRequest) => await ComplaintDocumentsApiClient.downloadComplaintDocument(requestParams)
    // {
    //     const complaintDocumentRaw = ;
    //     const complaintDocument = await complaintDocumentRaw.value();
    //
    //     // if (complaintDocument) {
    //     //     downloadBlob(complaintDocument, data.documentName);
    //     // }
    // }
});
