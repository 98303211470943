import { useEffect } from 'react';
import '../styles/datatables.scss';
import { setDocumentTitle } from '../helpers/general';
import KpiWorkInProgressDatatable from '../components/Datatables/KpiWorkInProgressDatatable';
import { withAuthenticationRequired } from '@auth0/auth0-react';

/**
 * KPI Work In Progress page
 */
const KpiWorkInProgressPage = (): JSX.Element => {
    useEffect(() => {
        setDocumentTitle('KPI Work In Progress');
    }, []);

    return (
        <>
            <div className="page-content">
                <KpiWorkInProgressDatatable />
            </div>
        </>
    );
};

export default withAuthenticationRequired(KpiWorkInProgressPage);
