import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { ClockIcon } from '../../helpers/svg-icons';

const MsgWrap = styled.div`
    .msg-wrap {
        padding: 2px 10px;
        border-radius: 6px;
        background: var(--yellow-100); 

        > span {
            color: var(--yellow-800);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }
    }
`;

const SmallMessage = ({message}) => {
    return (
        <MsgWrap>
            <div className="msg-wrap flex align-items-center ml-3">
                <ClockIcon /> <span className="ml-1">{message}</span>
            </div>
        </MsgWrap>
    );
};

export default SmallMessage;
