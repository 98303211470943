/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpsertComplaintInvestigationRequestEvidenceInner
 */
export interface UpsertComplaintInvestigationRequestEvidenceInner {
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintInvestigationRequestEvidenceInner
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertComplaintInvestigationRequestEvidenceInner
     */
    type: UpsertComplaintInvestigationRequestEvidenceInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintInvestigationRequestEvidenceInner
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertComplaintInvestigationRequestEvidenceInner
     */
    summary: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpsertComplaintInvestigationRequestEvidenceInner
     */
    points: Array<number>;
}


/**
 * @export
 */
export const UpsertComplaintInvestigationRequestEvidenceInnerTypeEnum = {
    NUMBER_null: null,
    NUMBER_null2: null,
    NUMBER_null3: null,
    NUMBER_null4: null,
    NUMBER_null5: null,
    NUMBER_null6: null,
    NUMBER_null7: null,
    NUMBER_null8: null,
    NUMBER_null9: null,
    NUMBER_null10: null,
    NUMBER_null11: null,
    NUMBER_null12: null
} as const;
export type UpsertComplaintInvestigationRequestEvidenceInnerTypeEnum = typeof UpsertComplaintInvestigationRequestEvidenceInnerTypeEnum[keyof typeof UpsertComplaintInvestigationRequestEvidenceInnerTypeEnum];


/**
 * Check if a given object implements the UpsertComplaintInvestigationRequestEvidenceInner interface.
 */
export function instanceOfUpsertComplaintInvestigationRequestEvidenceInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "summary" in value;
    isInstance = isInstance && "points" in value;

    return isInstance;
}

export function UpsertComplaintInvestigationRequestEvidenceInnerFromJSON(json: any): UpsertComplaintInvestigationRequestEvidenceInner {
    return UpsertComplaintInvestigationRequestEvidenceInnerFromJSONTyped(json, false);
}

export function UpsertComplaintInvestigationRequestEvidenceInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertComplaintInvestigationRequestEvidenceInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': json['type'],
        'date': json['date'],
        'summary': json['summary'],
        'points': json['points'],
    };
}

export function UpsertComplaintInvestigationRequestEvidenceInnerToJSON(value?: UpsertComplaintInvestigationRequestEvidenceInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'date': value.date,
        'summary': value.summary,
        'points': value.points,
    };
}

