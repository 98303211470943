import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DataTable, type DataTablePassThroughOptions, } from 'primereact/datatable';
import { GetFcaRmarReportRequest } from '@/stub';
import { Column } from "primereact/column";
import { useGetFcaRmar } from "@/Service/Api/ApiHooks/Reports/useGetFcaRmar";

type TableColumnDefinition = {
    label?: string
    field?: string
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }
`;

export type FcaRmarDatatableProps = {
    requestParams: GetFcaRmarReportRequest
};

/**
 * Files datatable
 */
const FcaRmarDatatable: React.FC<FcaRmarDatatableProps> = ({
    requestParams,
    ...props
}: FcaRmarDatatableProps) => {

    const {
        data: fcaReport,
        isLoading
    } = useGetFcaRmar(requestParams);

    const dynamicColumns = useMemo(() => {
        let columns: TableColumnDefinition[] = [];
        if (!!fcaReport && !!fcaReport[0]?.fca) {
            columns = fcaReport[0].fca.map((fca, index): TableColumnDefinition => {
                return {
                    label: fca.lvl1_name,
                    field: `fca.${index}.value`,
                };
            });
        }
        return columns;
    }, [fcaReport]);

    const tableColumns: TableColumnDefinition[] = useMemo(() => {
        return [
            {
                label: 'Product',
                field: 'product'
            },
            {
                label: 'Total',
                field: 'total'
            },
            ...dynamicColumns
        ];
    }, [dynamicColumns]);

    return (
        <StyledWrap>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={isLoading}
                        emptyMessage="No data found."
                        value={fcaReport}
                        pt={dataTablePtOptions}
                        {...props}
                    >
                        {tableColumns.map(column =>
                            <Column
                                key={column.label}
                                header={column.label}
                                field={column.field}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
        </StyledWrap>
    );
};

export default FcaRmarDatatable;
