import React, { useId } from 'react';
import { formatToUKDate } from "@/Util/formatToUKDate";

export type InputViewProps = {
    label: string
    value: never
    type?: string
};

const InputView: React.FC<InputViewProps> = ({
    label,
    value,
    type
}: InputViewProps) => {
    const localId = useId();
    if (type === 'boolean') {
        value = value ? 'Yes' : 'No';
    } else if (type === 'date') {
        value = formatToUKDate(value);
    }

    return (
        <div key={localId} className="mb-3">
            <span className="inline-input-wrap flex align-items-center">
                <label className="inline-block mr-3 one-line" title={label}>{label}</label>

                {type === 'textarea'
                    ? <div className="input-wrap overflow-ellipsis"
                        dangerouslySetInnerHTML={{ __html: value }}></div>
                    : <div className="input-wrap overflow-ellipsis">{value}</div>
                }
            </span>
        </div>
    );
};

export default InputView;
