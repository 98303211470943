import { TabPanel, TabView } from 'primereact/tabview';
import ComplaintContactsForm, {
    type CorrespondenceOption,
    CustomerContactTypes,
    type FinalizedComplaintContactsData
} from '@/components/Core/Complaint/ComplaintContactsForm';
import React, { useEffect, useMemo, useState } from 'react';
import { type Complaint, ComplaintStateEnum, CustomerTypeEnum } from '@/stub';
import { snakeToNormalCase } from '@/helpers/general';
import { useGetComplaintCustomers } from '@/Service/Api/ApiHooks/Customer/useGetComplaintCustomers';
import IconButton from '@/components/partials/IconButton';
import BaseDropdown from '@/components/Core/Form/BaseDropdown';
import {
    useUpdateComplaintPrimaryCorrespondence
} from '@/Service/Api/ApiHooks/Customer/useUpdateComplaintPrimaryCorrespondence';
import InputView from "@/components/Blocks/InputView";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import {
    ComplaintDefaultCorrespondenceChangedMessage
} from "@/Messages/Toast/ComplaintCustomers/ComplaintDefaultCorrespondenceChangedMessage";
import { useQueryClient } from "@tanstack/react-query";
import { isReadonlyComplaint } from "@/Util/permissionChecks";

const findDefaultCorrespondence = (complaint: Complaint): CorrespondenceOption | undefined => {
    const primaryComplaintCustomer = complaint?.complaint_customers?.find((customer) => customer.is_primary);
    if (primaryComplaintCustomer) {
        return {
            contactType: primaryComplaintCustomer.type,
            label: CustomerContactTypes[primaryComplaintCustomer.type].label
        };
    }
};

export type CustomerTabProps = {
    complaint?: Complaint
};

const Customer = ({
    complaint
}: CustomerTabProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [subCategoryTabIndex, setSubCategoryTabIndex] = useState(0);
    const [editMode, setEditMode] = useState({ 0: false, 1: false, 2: false, 3: false });

    const updatePrimaryCorrespondence = useUpdateComplaintPrimaryCorrespondence({});
    const [currentPrimaryCorrespondence, setCurrentPrimaryCorrespondence] = useState<CorrespondenceOption>();

    useEffect(() => {
        if (complaint) {
            setCurrentPrimaryCorrespondence(findDefaultCorrespondence(complaint));
        }
    }, [complaint]);

    const {
        data: customers
    } = useGetComplaintCustomers({
        enabled: !!complaint,
        requestParams: {
            complaint_id: complaint?.id
        }
    });

    const correspondenceOptions = useMemo(() => {
        const options: CorrespondenceOption[] = [];
        if (customers) {
            customers.map(customer => {
                options.push({
                    contactType: customer.type,
                    label: CustomerContactTypes[customer.type].label
                });
            });
        }
        return options;
    }, [customers]);

    const currentCustomer = useMemo(() => {
        return customers?.find(customer => snakeToNormalCase(customer.type) === Object.values(CustomerContactTypes)[subCategoryTabIndex].label);
    }, [subCategoryTabIndex, customers]);

    const initFormData: FinalizedComplaintContactsData = useMemo(() => {
        if (currentCustomer) {
            return {
                [currentCustomer.type]: {
                    id: currentCustomer.customer.id,
                    id_number: currentCustomer.customer.id_number,
                    display_name: currentCustomer.customer.display_name
                }
            };
        }
        return {};
    }, [currentCustomer]);

    const viewModeFields = useMemo(() => {
        return [
            {
                label: 'Title',
                value: snakeToNormalCase(currentCustomer?.customer.title ?? ''),
                type: 'string',
                visible: currentCustomer?.customer.type === CustomerTypeEnum.Person
            },
            {
                label: 'Is HEC',
                value: currentCustomer?.customer.is_hec,
                type: 'boolean',
                visible: currentCustomer?.customer.type === CustomerTypeEnum.Person
            },
            {
                label: currentCustomer?.customer.type === CustomerTypeEnum.Person ? 'Customer ID' : 'Organisation ID',
                value: currentCustomer?.customer.id_number,
                type: 'string',
                visible: true
            },
            {
                label: currentCustomer?.customer.type === CustomerTypeEnum.Person ? 'First Name' : 'Name',
                value: currentCustomer?.customer.firstname,
                type: 'string',
                visible: true
            },
            {
                label: 'Middle  Name',
                value: currentCustomer?.customer.middlename,
                type: 'string',
                visible: currentCustomer?.customer.type === CustomerTypeEnum.Person
            },
            {
                label: 'Surname',
                value: currentCustomer?.customer.surname,
                type: 'string',
                visible: currentCustomer?.customer.type === CustomerTypeEnum.Person
            },
            {
                label: 'Phone Number',
                value: currentCustomer?.customer.phone,
                type: 'string',
                visible: true
            },
            {
                label: 'Email Address',
                value: currentCustomer?.customer.email,
                type: 'string',
                visible: true
            },
            {
                label: 'Address Line 1',
                value: currentCustomer?.customer.address1,
                type: 'string',
                visible: true
            },
            {
                label: 'Address Line 2',
                value: currentCustomer?.customer.address2,
                type: 'string',
                visible: true
            },
            {
                label: 'Post Code',
                value: currentCustomer?.customer.postcode,
                type: 'string',
                visible: true
            },
            {
                label: 'County',
                value: currentCustomer?.customer.county,
                type: 'string',
                visible: true
            },
            {
                label: 'City',
                value: currentCustomer?.customer.city,
                type: 'string',
                visible: true
            },
            {
                label: 'Country',
                value: currentCustomer?.customer.country,
                type: 'string',
                visible: true
            },
            {
                label: 'Vulnerable Customer',
                value: currentCustomer?.customer.is_vulnerable,
                type: 'boolean',
                visible: currentCustomer?.customer.type === CustomerTypeEnum.Person
            }
        ];
    }, [currentCustomer]);

    const generateInputView = () => {
        if (!currentCustomer) {
            return <div>Not present</div>;
        }
        return viewModeFields.map((field, key) => {
            if (field.visible) {
                return <InputView key={key} label={field.label} value={field.value} type={field.type}/>;
            }
        });
    };

    const changePrimaryCorrespondence = (correspondenceOption: CorrespondenceOption) => {
        updatePrimaryCorrespondence.mutate({
            complaint_id: complaint?.id,
            UpdateComplaintCustomerPrimaryRequest: {
                correspondence: correspondenceOption.contactType
            }
        }, {
            onSuccess: () => {
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.complaints.detail(complaint?.id).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                    })
                ]);
                addToastMessage(ComplaintDefaultCorrespondenceChangedMessage);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const toggleEditMode = (index: number) => {
        setEditMode({
            ...editMode,
            [index]: !editMode[index]
        });
    };

    return (
        <>
            <div className="tc-buttons flex z-1 align-items-center gap-3">
                <form>
                    <BaseDropdown
                        className="flex flex-row align-items-center gap-3"
                        label="Primary correspondence"
                        disabled={isReadonlyComplaint(complaint)}
                        options={correspondenceOptions}
                        optionLabel="label"
                        value={currentPrimaryCorrespondence}
                        onChange={(e) => {
                            changePrimaryCorrespondence(e.value);
                        }}
                    />
                </form>

                {!isReadonlyComplaint(complaint) && <span onClick={() => {
                    toggleEditMode(subCategoryTabIndex);
                }}>
                    <IconButton type="edit" className="ml-2"/>
                </span>}

            </div>
            <TabView
                id='subtabpanels'
                activeIndex={subCategoryTabIndex}
                onTabChange={(e) => {
                    setSubCategoryTabIndex(e.index);
                }}
                className="smaller-tabs no-control-btns"
                scrollable
            >
                {Object.values(CustomerContactTypes).map((subTab, key) => {
                    return (
                        <TabPanel
                            header={subTab.label}
                            key={key}
                        >
                            <div className="formgrid grid my-3 mx-2">
                                {!editMode[subCategoryTabIndex]
                                    ? <div>
                                        {generateInputView()}
                                    </div>
                                    : <ComplaintContactsForm
                                        viewMode
                                        complaint={complaint}
                                        customerTypeIndex={key}
                                        toggleEditMode={toggleEditMode}
                                        complaintContactsData={initFormData}
                                    />
                                }
                            </div>
                        </TabPanel>
                    );
                })}
            </TabView>
        </>);
};

export default Customer;
