import { useMemo } from "react";
import { useAuthStore } from "@/Stores/AuthStore";

export const useOrgId = () => {
    const authUser = useAuthStore((state) => state.authUser);
    const impersonatedOrganisationId = useAuthStore((state) => state.impersonatedOrgId);

    return useMemo<number>(() => {
        return impersonatedOrganisationId ?? authUser?.organisation_id as number;
    }, [authUser, impersonatedOrganisationId]);
};
