import React from "react";
import { Navigate } from "react-router-dom";
import { useOrgId } from "@/Hooks/useOrgId";
import { url } from "@/helpers/general";

const StartPage: React.FC = () => {
    const orgId = useOrgId();

    return (
        <Navigate replace to={url('inbox', { orgId: orgId })}/>
    );
};

export default StartPage;
