/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ComplaintCustomer,
  UpdateComplaintCustomerPrimaryRequest,
  UpdateComplaintCustomerRequest,
} from '../models/index';
import {
    ComplaintCustomerFromJSON,
    ComplaintCustomerToJSON,
    UpdateComplaintCustomerPrimaryRequestFromJSON,
    UpdateComplaintCustomerPrimaryRequestToJSON,
    UpdateComplaintCustomerRequestFromJSON,
    UpdateComplaintCustomerRequestToJSON,
} from '../models/index';

export interface GetComplaintCustomersRequest {
    complaint_id: number;
    organisation_id?: number;
}

export interface UpdateComplaintCustomersRequest {
    complaint_id: number;
    UpdateComplaintCustomerRequest?: UpdateComplaintCustomerRequest;
}

export interface UpdateComplaintPrimaryCorrespondenceRequest {
    complaint_id: number;
    UpdateComplaintCustomerPrimaryRequest?: UpdateComplaintCustomerPrimaryRequest;
}

/**
 * 
 */
export class ComplaintCustomersApi extends runtime.BaseAPI {

    /**
     * get Complaint Customers
     */
    async getComplaintCustomersRaw(requestParameters: GetComplaintCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComplaintCustomer>>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintCustomers.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/customers`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComplaintCustomerFromJSON));
    }

    /**
     * get Complaint Customers
     */
    async getComplaintCustomers(requestParameters: GetComplaintCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComplaintCustomer>> {
        const response = await this.getComplaintCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update Complaint Customers
     */
    async updateComplaintCustomersRaw(requestParameters: UpdateComplaintCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComplaintCustomer>>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateComplaintCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/customers`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateComplaintCustomerRequestToJSON(requestParameters.UpdateComplaintCustomerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComplaintCustomerFromJSON));
    }

    /**
     * update Complaint Customers
     */
    async updateComplaintCustomers(requestParameters: UpdateComplaintCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComplaintCustomer>> {
        const response = await this.updateComplaintCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update Complaint Primary Correspondence
     */
    async updateComplaintPrimaryCorrespondenceRaw(requestParameters: UpdateComplaintPrimaryCorrespondenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateComplaintPrimaryCorrespondence.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/primary-correspondence`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateComplaintCustomerPrimaryRequestToJSON(requestParameters.UpdateComplaintCustomerPrimaryRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update Complaint Primary Correspondence
     */
    async updateComplaintPrimaryCorrespondence(requestParameters: UpdateComplaintPrimaryCorrespondenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateComplaintPrimaryCorrespondenceRaw(requestParameters, initOverrides);
    }

}
