import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { ClockIcon, EditIcon, FileDownloadIcon } from '../../helpers/svg-icons';

const ButtonWrap = styled.div`
    .icon-button {
        border-radius: 50%;
        border: 1px solid var(--primary-700);
        background: rgba(255, 255, 255, 0.50);
        padding: 4px;
        cursor: pointer;
    }
`;

const IconButton = ({ type, className }) => {

    const generateSvgIcon = () => {
        switch (type) {
            case 'download':
                return <FileDownloadIcon />;

            case 'edit':
                return <EditIcon />;                

            default:
                break;
        }
    };

    return (
        <ButtonWrap>
            <div className={'icon-button flex align-items-center align-content-center' + ' ' + className}>
                {generateSvgIcon()}
            </div>
        </ButtonWrap>
    );
};

export default IconButton;
