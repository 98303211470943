/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ComplaintInvestigation,
  EvidenceConsideredType,
  UpsertComplaintInvestigationRequest,
} from '../models/index';
import {
    ComplaintInvestigationFromJSON,
    ComplaintInvestigationToJSON,
    EvidenceConsideredTypeFromJSON,
    EvidenceConsideredTypeToJSON,
    UpsertComplaintInvestigationRequestFromJSON,
    UpsertComplaintInvestigationRequestToJSON,
} from '../models/index';

export interface GetInvestigationFromComplaintRequest {
    complaint_id: number;
}

export interface UpdateEventAndOutcomesInComplaintRequest {
    complaint_id: number;
    UpsertComplaintInvestigationRequest?: UpsertComplaintInvestigationRequest;
}

/**
 * 
 */
export class ComplaintInvestigationApi extends runtime.BaseAPI {

    /**
     * Get evidence considered types
     */
    async getEvidenceConsideredTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EvidenceConsideredType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/investigation/evidence-considered-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EvidenceConsideredTypeFromJSON));
    }

    /**
     * Get evidence considered types
     */
    async getEvidenceConsideredTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EvidenceConsideredType>> {
        const response = await this.getEvidenceConsideredTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get info for investigation tab
     */
    async getInvestigationFromComplaintRaw(requestParameters: GetInvestigationFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplaintInvestigation>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getInvestigationFromComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/investigation`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintInvestigationFromJSON(jsonValue));
    }

    /**
     * Get info for investigation tab
     */
    async getInvestigationFromComplaint(requestParameters: GetInvestigationFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplaintInvestigation> {
        const response = await this.getInvestigationFromComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update complaint investigation event and outcomes
     */
    async updateEventAndOutcomesInComplaintRaw(requestParameters: UpdateEventAndOutcomesInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplaintInvestigation>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateEventAndOutcomesInComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/investigation`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertComplaintInvestigationRequestToJSON(requestParameters.UpsertComplaintInvestigationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintInvestigationFromJSON(jsonValue));
    }

    /**
     * Update complaint investigation event and outcomes
     */
    async updateEventAndOutcomesInComplaint(requestParameters: UpdateEventAndOutcomesInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplaintInvestigation> {
        const response = await this.updateEventAndOutcomesInComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
