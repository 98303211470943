import { useMutation } from '@tanstack/react-query';
import { DeleteComplaintBespokeRequest } from '@/stub';
import { processValidationErrors } from '@/Util/Api/processValidationErrors';
import { BespokeMessagesApiClient } from "@/Service/Api/ApiClients/BespokeMessagesApiClient";

export const useDeleteBespoke = () => {
    return useMutation({
        mutationFn: async (data: DeleteComplaintBespokeRequest) => {
            try {
                return await BespokeMessagesApiClient.deleteComplaintBespoke(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
