import { Configuration } from "@/stub";
import { useAuthStore } from "@/Stores/AuthStore";
import { AppendOrganisationIdMiddleware } from "@/Middleware/AppendOrganisationIdMiddleware";

export const configuration = new Configuration({
    basePath: import.meta.env.VITE_API_V2_URL,
    accessToken: () => useAuthStore.getState().accessToken as string,
    headers: {
        'Accept': 'application/json'
    },
    middleware: [
        AppendOrganisationIdMiddleware
    ]
});
