/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComplaintCustomerContactType } from './ComplaintCustomerContactType';
import {
    ComplaintCustomerContactTypeFromJSON,
    ComplaintCustomerContactTypeFromJSONTyped,
    ComplaintCustomerContactTypeToJSON,
} from './ComplaintCustomerContactType';

/**
 * 
 * @export
 * @interface StoreComplaintRequestCustomersInner
 */
export interface StoreComplaintRequestCustomersInner {
    /**
     * 
     * @type {boolean}
     * @memberof StoreComplaintRequestCustomersInner
     */
    is_primary: boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreComplaintRequestCustomersInner
     */
    customer_id: number;
    /**
     * 
     * @type {ComplaintCustomerContactType}
     * @memberof StoreComplaintRequestCustomersInner
     */
    type: ComplaintCustomerContactType;
}

/**
 * Check if a given object implements the StoreComplaintRequestCustomersInner interface.
 */
export function instanceOfStoreComplaintRequestCustomersInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "is_primary" in value;
    isInstance = isInstance && "customer_id" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function StoreComplaintRequestCustomersInnerFromJSON(json: any): StoreComplaintRequestCustomersInner {
    return StoreComplaintRequestCustomersInnerFromJSONTyped(json, false);
}

export function StoreComplaintRequestCustomersInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreComplaintRequestCustomersInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_primary': json['is_primary'],
        'customer_id': json['customer_id'],
        'type': ComplaintCustomerContactTypeFromJSON(json['type']),
    };
}

export function StoreComplaintRequestCustomersInnerToJSON(value?: StoreComplaintRequestCustomersInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_primary': value.is_primary,
        'customer_id': value.customer_id,
        'type': ComplaintCustomerContactTypeToJSON(value.type),
    };
}

