import { Dialog } from "primereact/dialog";
import { Channel, GetComplaintChannels200Response } from "@/stub";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { useEffect, useState } from "react";
import { formatType } from "@/Util/formatType";

export type ChannelPreviewModalProps = {
    visible: boolean
    closePreview: () => void
    channel: Channel,
    contactsData: GetComplaintChannels200Response
};
const ChannelPreviewModal = ({ contactsData, visible, channel,closePreview }:ChannelPreviewModalProps) => {

    const [contacts, setContacts] = useState<Channel[]>([]);

    useEffect(() => {
        if (contactsData) {
            if (Array.isArray(contacts) && contacts?.length > 0) {
                const concatArrays = [...contacts, ...contactsData.data];

                setContacts([...new Map(concatArrays.map(item => [item.id, item])).values()]);

            } else {
                setContacts(contactsData.data);
            }
        }
    }, [contactsData]);

    const generateContactPreview = () => {
        const previewContact = contacts.find((contact) => contact.id === channel.id);
        if (!previewContact) return <></>;
        return (
            <>
                {previewContact.from && <div className="grid">
                    <div className="col-3 font-semibold mb-2">From:</div>
                    <div className="col-9 truncate-text">
                        {previewContact?.from ?? ''}{' '}
                    </div>
                </div>}

                {previewContact.to && <div className="grid">
                    <div className="col-3 font-semibold mb-2">To:</div>
                    <div className="col-9 truncate-text">
                        {previewContact?.to ?? ''}{' '}
                    </div>
                </div>}

                <div className="grid">
                    <div className="col-3 font-semibold mb-2">Date:</div>
                    <div className="col-9 truncate-text">{formatToUKDate(previewContact?.created_at)}</div>
                </div>

                {previewContact?.action && <div className="grid">
                    <div className="col-3 font-semibold mb-2">Next action date:</div>
                    <div className="col-9 truncate-text">{formatToUKDate(previewContact?.action.due_date)}</div>
                </div>}

                {previewContact.contact_type && <div className="grid">
                    <div className="col-3 font-semibold mb-2">Contact Type:</div>
                    <div className="col-9 truncate-text">{formatType(previewContact?.contact_type)}</div>
                </div>}

                {previewContact.channel_type && <div className="grid">
                    <div className="col-3 font-semibold mb-2">Channel Type:</div>
                    <div className="col-9 truncate-text">{formatType(previewContact?.channel_type)}</div>
                </div>}

                {previewContact.content && <div className="grid">
                    <div className="col-3 font-semibold mb-2">Content:</div>
                    <div className="col-9 contacts-content"
                        dangerouslySetInnerHTML={{ __html: previewContact?.content ?? '' }}></div>
                </div>}

                {previewContact.description && <div className="grid">
                    <div className="col-3 font-semibold mb-2">Description:</div>
                    <div className="col-9 contacts-content"
                        dangerouslySetInnerHTML={{ __html: previewContact?.description ?? '' }}></div>
                </div>}
            </>
        );
    };
    return (
        <Dialog
            id='preview-file-dialog'
            header={`Preview Channel`}
            className='preview-channel-modal'
            visible={visible}
            onHide={closePreview}
            blockScroll
            draggable={false}
        >
            <div className='w-full'>
                {generateContactPreview()}
            </div>
        </Dialog>

    );
};


export default ChannelPreviewModal;
