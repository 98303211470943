import { useMutation } from '@tanstack/react-query';
import { RefreshFosArchiveRequest } from '@/stub';
import { ComplaintFosApiClient } from '@/Service/Api/ApiClients/ComplaintFosApiClient';

export const useRefreshComplaintFosArchive = () => {
    return useMutation({
        mutationFn: async (data: RefreshFosArchiveRequest) => await ComplaintFosApiClient.refreshFosArchive(data)
            .catch(error => error.response.json()
                .then((errorBody) => {
                    throw new Error(errorBody.message);
                }
                )
            )
    });
};
