import { Card } from 'primereact/card';
import FullscreenLayout from "../themes/default/layouts/FullscreenLayout";
import LoginButton from "../components/auth0/LoginButton";
import { Link, useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { useAuthStore } from "@/Stores/AuthStore";
import { url } from "@/helpers/general";
import { useOrgId } from "@/Hooks/useOrgId";

/**
 * Site's login page
 */
const LoginPage = () => {
    useTitle('Login');

    const navigate = useNavigate();

    const authUser = useAuthStore((state) => state.authUser);
    const orgId = useOrgId();

    if (authUser) {
        navigate(url('inbox', { orgId: orgId }));
    }

    return (
        <FullscreenLayout>
            <Card>
                <div className="public-form login-form">
                    <div className="text-center mb-4">
                        By clicking <span className="font-semibold">Login</span> you agree that you have read and accepted the <Link className="fake-link" to={"/terms-of-use"}>Terms
                        of
                        Use</Link>
                    </div>
                    <div>
                        <LoginButton/>
                    </div>
                </div>
            </Card>
        </FullscreenLayout>
    );
};

export default LoginPage;
