import React, {useEffect, useRef} from "react";
import {Toast} from "primereact/toast";
import {useToastMessagesStore} from "@/Stores/ToastMessagesStore";

export type DefaultGlobalToastProps = {}

const DefaultGlobalToast: React.FC<DefaultGlobalToastProps> = ({}: DefaultGlobalToastProps) => {
    const toastMessages = useToastMessagesStore((state) => state.toastMessages);
    const getToastMessages = useToastMessagesStore((state) => state.getToastMessages);

    const toastRef = useRef<Toast>()

    useEffect(() => {
        if (toastMessages.length > 0) {
            const messages = getToastMessages();
            messages.forEach((message) => {
                toastRef.current?.show(message)
            });
        }
    }, [toastMessages]);

    return (
        <Toast ref={toastRef}/>
    );
};

export default DefaultGlobalToast;