import { useMutation } from "@tanstack/react-query";
import { UpdateUserRequest } from "@/stub";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";
import { usersApiClient } from "@/Service/Api/ApiClients/UsersApiClient";

export const useUpdateAuthUser = () => useMutation({
    mutationFn: async (data: UpdateUserRequest) => {
        try {
            await usersApiClient.updateUserRoles(data);
        } catch (error) {
            await processValidationErrors(error);
            throw error;
        }
    }
});
