import { create } from "zustand";
import { produce } from "immer";

type FormStateStoreState = {
    isDirty: boolean;
};

type FormStateStoreActions = {
    setDirty: (dirty: boolean) => void;
    resetDirty: () => void;
};

export const useFormStateStore = create<FormStateStoreState & FormStateStoreActions>()((set) => ({
    isDirty: false,
    setDirty: (dirty) => set(
        produce((state: FormStateStoreState) => {
            state.isDirty = dirty;
        })
    ),
    resetDirty: () => set(
        produce((state: FormStateStoreState) => {
            state.isDirty = false;
        })
    ),
}));
