/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreComplaintRequestChannel } from './StoreComplaintRequestChannel';
import {
    StoreComplaintRequestChannelFromJSON,
    StoreComplaintRequestChannelFromJSONTyped,
    StoreComplaintRequestChannelToJSON,
} from './StoreComplaintRequestChannel';
import type { StoreComplaintRequestCustomersInner } from './StoreComplaintRequestCustomersInner';
import {
    StoreComplaintRequestCustomersInnerFromJSON,
    StoreComplaintRequestCustomersInnerFromJSONTyped,
    StoreComplaintRequestCustomersInnerToJSON,
} from './StoreComplaintRequestCustomersInner';

/**
 * 
 * @export
 * @interface StoreComplaintRequest
 */
export interface StoreComplaintRequest {
    /**
     * 
     * @type {StoreComplaintRequestChannel}
     * @memberof StoreComplaintRequest
     */
    channel?: StoreComplaintRequestChannel;
    /**
     * 
     * @type {Array<StoreComplaintRequestCustomersInner>}
     * @memberof StoreComplaintRequest
     */
    customers: Array<StoreComplaintRequestCustomersInner>;
    /**
     * 
     * @type {string}
     * @memberof StoreComplaintRequest
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof StoreComplaintRequest
     */
    raised_date: string;
    /**
     * 
     * @type {number}
     * @memberof StoreComplaintRequest
     */
    product_id: number;
    /**
     * 
     * @type {number}
     * @memberof StoreComplaintRequest
     */
    root_cause_id: number;
    /**
     * 
     * @type {number}
     * @memberof StoreComplaintRequest
     */
    organisation_id?: number | null;
}

/**
 * Check if a given object implements the StoreComplaintRequest interface.
 */
export function instanceOfStoreComplaintRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customers" in value;
    isInstance = isInstance && "summary" in value;
    isInstance = isInstance && "raised_date" in value;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "root_cause_id" in value;

    return isInstance;
}

export function StoreComplaintRequestFromJSON(json: any): StoreComplaintRequest {
    return StoreComplaintRequestFromJSONTyped(json, false);
}

export function StoreComplaintRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreComplaintRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel': !exists(json, 'channel') ? undefined : StoreComplaintRequestChannelFromJSON(json['channel']),
        'customers': ((json['customers'] as Array<any>).map(StoreComplaintRequestCustomersInnerFromJSON)),
        'summary': json['summary'],
        'raised_date': json['raised_date'],
        'product_id': json['product_id'],
        'root_cause_id': json['root_cause_id'],
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
    };
}

export function StoreComplaintRequestToJSON(value?: StoreComplaintRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel': StoreComplaintRequestChannelToJSON(value.channel),
        'customers': ((value.customers as Array<any>).map(StoreComplaintRequestCustomersInnerToJSON)),
        'summary': value.summary,
        'raised_date': value.raised_date,
        'product_id': value.product_id,
        'root_cause_id': value.root_cause_id,
        'organisation_id': value.organisation_id,
    };
}

