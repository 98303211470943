import React, { useRef } from 'react';
import { Complaint, ComplaintInvestigation, type ComplaintPoints } from '@/stub';
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import { FieldArrayWithId, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import BaseTextEditor from "@/components/Core/Form/BaseTextEditor";
import { isReadonlyComplaint } from "@/Util/permissionChecks";

export type ComplaintPointInputFormProps = BaseFormInputProps & {
    complaint: Complaint
    point: FieldArrayWithId<ComplaintInvestigation, "points", "id">
    index: number
    removePoint: (point: ComplaintPoints, index: number) => void,
    getValues:  UseFormGetValues<ComplaintInvestigation>
    setValue:  UseFormSetValue<ComplaintInvestigation>
};

const ComplaintPointInputForm: React.FC<ComplaintPointInputFormProps> = ({
    complaint,
    control,
    errorMessages,
    point,
    removePoint,
    index,
    getValues,
    setValue
}: ComplaintPointInputFormProps) => {
    const oldValueRef = useRef<string | undefined>(point.content);

    const handleOnBlurInput = () => {
        const oldValue = oldValueRef.current;
        const newValue = getValues(`points.${index}.content` as const);

        getValues('evidence')?.forEach((evidence, evidenceIndex) => {
            const pointIndex = evidence.points?.findIndex(
                (evidencePoint: string) => {
                    return evidencePoint === oldValue;
                }
            );
            if (pointIndex !== -1) {
                setValue(
                    `evidence.${evidenceIndex}.points.${pointIndex}` as const,
                    newValue
                );
            }
        });
        getValues('outcomes')?.forEach((outcome, evidenceIndex) => {
            const pointIndex = outcome.points?.findIndex(
                (evidencePoint: string) => {
                    return evidencePoint === oldValue;
                }
            );
            if (pointIndex !== -1) {
                setValue(
                    `outcomes.${evidenceIndex}.points.${pointIndex}` as const,
                    newValue
                );
            }
        });
    };
    return <div className="flex mb-3">
        <div className='flex flex-row gap-3 w-full align-items-center justify-content-between'>
            <span>#{index + 1}</span>

            <div className="w-full">
                <BaseTextEditor
                    textareaName={`points[${index}].content`}
                    control={control}
                    height='130px'
                    menubar={false}
                    onFocus={() => {
                        oldValueRef.current = getValues(`points.${index}.content` as const);
                    }}
                    onBlur={handleOnBlurInput}
                    disabled={isReadonlyComplaint(complaint)}
                    errorMessages={errorMessages?.points?.[index]?.content?.message}
                />
            </div>

            {!isReadonlyComplaint(complaint) &&
                <span className="cursor-pointer" onClick={() => {
                    removePoint(point, index);
                }}>
                    <i className="pi pi-times"/>
                </span>}
        </div>
    </div>;
};

export default ComplaintPointInputForm;
