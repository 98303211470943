/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DocumentableType = {
    Channel: 'Channel',
    ComplaintFos: 'ComplaintFos',
    Letter: 'Letter',
    Evidence: 'Evidence'
} as const;
export type DocumentableType = typeof DocumentableType[keyof typeof DocumentableType];


export function DocumentableTypeFromJSON(json: any): DocumentableType {
    return DocumentableTypeFromJSONTyped(json, false);
}

export function DocumentableTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentableType {
    return json as DocumentableType;
}

export function DocumentableTypeToJSON(value?: DocumentableType | null): any {
    return value as any;
}

