import { useSearchParams } from "react-router-dom";

export const AuditFilterType = {
    OwnerId: 'assigned_to_id',
    ComplaintId: 'complaint_id',
    DateRangeTo: 'date_range_to',
    DateRangeFrom: 'date_range_from',
    Search: 'search',
    Action: 'action',
    Type: 'type',
    Page: 'page',
    PageSize: 'page_size',
    SortField: 'sort_field',
    SortOrder: 'sort_order',
} as const;

export type AuditFilterType = typeof AuditFilterType[keyof typeof AuditFilterType];


export default function useGetAuditFiltersQuery() {
    const [searchParams] = useSearchParams();

    const ownerQuery = searchParams.get(AuditFilterType.OwnerId);
    const complaintIdQuery = searchParams.get(AuditFilterType.ComplaintId);
    const dataRangeQueryTo = searchParams.get(AuditFilterType.DateRangeTo);
    const dataRangeQueryFrom = searchParams.get(AuditFilterType.DateRangeFrom);
    const searchQuery = searchParams.get(AuditFilterType.Search);
    const pageQuery = searchParams.get(AuditFilterType.Page);
    const pageSizeQuery = searchParams.get(AuditFilterType.PageSize);
    const sortFieldQuery = searchParams.get(AuditFilterType.SortField);
    const sortOrderQuery = searchParams.get(AuditFilterType.SortOrder);
    const typeQuery = searchParams.get(AuditFilterType.Type);
    const actionQuery = searchParams.get(AuditFilterType.Action);



    return {
        actionQuery,
        sortOrderQuery,
        sortFieldQuery,
        pageSizeQuery,
        pageQuery,
        searchQuery,
        dataRangeQueryFrom,
        dataRangeQueryTo,
        complaintIdQuery,
        ownerQuery,
        typeQuery,
    };
}
